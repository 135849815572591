<ng-template #emptyTpl>
  <rmd-message warning
               [text]="emptyMsg">
  </rmd-message>
</ng-template>

<mat-nav-list *ngIf="items?.length; else emptyTpl">
  <mat-list-item *ngFor="let item of items; trackBy: trackItem"
                 [matTooltip]="showTooltip ? (item.text || item.content || null) : null"
                 (click)="click.emit(item); $event.stopPropagation()">
    <span *ngIf="thumbPlaceholder || item.avatarUrl || item.imageUrl || item.logoUrl || item.img"
          mat-list-avatar>
      <app-lazy-img [src]="item.avatarUrl || item.imageUrl || item.logoUrl || item.img || null">
      </app-lazy-img>
    </span>

    <span *ngIf="item.icon"
          class="icon">
      <mat-icon>{{ item.icon }}</mat-icon>
    </span>

    <h4 *ngIf="item.title || item.name"
        matLine
        class="title"
        [innerText]="item.title || item.name">
    </h4>

    <p *ngIf="item.subTitle || item.description || (item.id && !hideIds)"
       matLine
       class="subTitle"
       [innerText]="item.subTitle || item.description || ('ID: ' + item.id)"></p>

    <p *ngIf="item.text || item.content"
       matLine
       class="text"
       [innerText]="item.text || item.content">
    </p>
  </mat-list-item>
</mat-nav-list>
