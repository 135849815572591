<div *ngIf="user" class="app-account-menu" (rmdClickOutside)="toggleDropdown(true)">
    <button mat-button class="trigger" [class.open]="isOpen" (click)="toggleDropdown()">
    <div class="content" fxLayout="row" fxLayoutAlign="start center">
      <app-lazy-img
        class="avatar"
        [placeholder]="'/assets/img/placeholders/noavatar.png'"
        [src]="user.photoUrl"
      >
      </app-lazy-img>

      <span class="name" fxHide fxShow.gt-sm [innerText]="user.displayName || user.email || '?'">
      </span>

      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </div>
  </button>

    <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
        <div class="content">
            <div class="list" (click)="toggleDropdown()">
                <!-- TODO:
        <a matRipple
           class="title"
           [routerLink]="['/apps/profile']"
           routerLinkActive="active"
           >
          Perfil
        </a>
        -->
                <a *ngxPermissionsOnly="['settings:one']" matRipple class="item" fxLayout="row" fxLayoutAlign="start center" [routerLink]="['/settings']" routerLinkActive="active">
                    <mat-icon class="icon">settings</mat-icon>
                    <span class="title">Configuración</span>
                </a>
                <a *ngxPermissionsOnly="['impersonate:supplier']" matRipple class="item" fxLayout="row" fxLayoutAlign="start center" [routerLink]="['/settings/suppliers']" routerLinkActive="active">
                    <mat-icon class="icon">people</mat-icon>
                    <span class="title">Proveedores</span>
                </a>
                <a matRipple class="item" fxLayout="row" fxLayoutAlign="start center" (click)="openManagementDialog()" routerLinkActive="active">
                    <mat-icon class="icon">key</mat-icon>
                    <span class="title">Cambiar contraseña</span>
                </a>
                <!-- <a matRipple
           class="item"
           fxLayout="row"
           fxLayoutAlign="start center"
           target="_blank"
           href="http://docs.bist.mx/">
          <mat-icon class="icon">help</mat-icon>
          <span class="title">Ayuda</span>
        </a> -->
                <mat-divider></mat-divider>
                <a matRipple class="item" fxLayout="row" fxLayoutAlign="start center" [routerLink]="['/auth/logout']" routerLinkActive="active">
                    <mat-icon class="icon">exit_to_app</mat-icon>
                    <span class="title">Cerrar sesión</span>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- Management Dialog -->
<app-dialog #dialog (dismiss)="onCloseManagementDialog()">
    <app-title>
        {{ user ? user.displayName || user.email : 'Datos del Usuario' }}
    </app-title>
    <app-subtitle>{{ user ? 'Datos del Usuario' : '' }}</app-subtitle>

    <div class="width-400">
        <app-form [fields]="formFields" [model]="formData" [noCancelButton]="false" (save)="saveUser($event.value)" (cancel)="dialog.close()">
        </app-form>
    </div>
</app-dialog>