import { DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityData, IdentityService } from '@bist-lib/auth-api';
import { RemissionPackage, RemissionsService, RemissionStatus, WarehousesService } from '@bist-lib/db-api';
import { Remission } from '@bist-lib/db-api/models';
import { Entry } from '@bist-lib/db-api/src/models/remission';
import { InfoService, PageWidgetComponent } from '@bist-web/shared';
import { DxDataGridComponent } from 'devextreme-angular';
import { take } from 'rxjs/operators';

@Component({
  selector: 'operations-resume',
  templateUrl: './operations-resume.component.html',
  styleUrls: ['./operations-resume.component.scss']
})
export class OperationsResumeComponent implements OnInit {
  @ViewChild(PageWidgetComponent, { static: true }) public pageWidget: PageWidgetComponent;
  @ViewChild('remissionsGrid', { static: false }) public remissionsGrid: DxDataGridComponent;

  private supplierId: string;
  remissions: any[];
  public totalRemissions: number;
  public entries: Entry[] = [];
  public totalCount: number;
  public expanded = true;

  constructor(
    private _identityService: IdentityService,
    private _infoService: InfoService,
    private _router: Router,
    private _warehousesService: WarehousesService,
    private _remissionService: RemissionsService
  ) {}

  ngOnInit() {
    this.supplierUserData();
    if (this.supplierId) {
      this.refreshData();
    }
  }

  // refreshData(): void {
  //   this.pageWidget.toggleLoading(true);
  //   this._remissionService
  //     .getListBySupplierId(this.supplierId)
  //     .pipe(take(1))
  //     .subscribe((remissionList: Remission[]) => {
  //       // Removing from remissionList those remissions with Cancelled status
  //       remissionList = remissionList.filter(
  //         (singleRemission: Remission) => singleRemission.status !== RemissionStatus.Cancelled
  //       );

  //       this.remissions = this.groupRemissionsByPurchaseorder(remissionList);
  //       console.log('remissions', this.remissions);
  //       this.pageWidget.toggleLoading(false);
  //     });
  // }
  public refreshData(): void {
    this.pageWidget.toggleLoading(true);
    let dataRemissions = [];
    this._remissionService.getListBySupplierId(this.supplierId).subscribe(
      async (remissions: Remission[]) => {
        dataRemissions = [];
        // console.log('remissiones', remissions);
        this.remissions = remissions.filter(
          (singleRemission: Remission) =>
            singleRemission.status === RemissionStatus.Created ||
            singleRemission.status === RemissionStatus.Delivered ||
            singleRemission.status === RemissionStatus.DeliveredWithObservations
        );

        for (const singleRemission of this.remissions) {

          const dataEntries = await this._remissionService
            .getRemissionsCollection(singleRemission.uuid, 'entries')
            .getList()
            .pipe(take(1))
            .toPromise();

          const dataDepartures = await this._remissionService
            .getRemissionsCollection(singleRemission.uuid, 'departures')
            .getList()
            .pipe(take(1))
            .toPromise();

          const dataEntry = dataEntries
            .filter((singleEntry) => singleEntry.type === 1)
            .map((entry) => {
              let quantityDepartures = entry.quantityDepartures || 0;

              dataDepartures.map((departure) => {
                if (entry.uuid === departure.entryUuid) {
                  quantityDepartures += departure.quantityDepartures;
                  return departure;
                }
              });

              const quantityWarehouse = entry.quantityReceived - quantityDepartures;
              const dataWarehouse: any = {
                remissionId: singleRemission.id,
                remissionUuid: singleRemission.uuid,
                purchaseOrderId: singleRemission.purchaseOrderId,
                dateRemission: singleRemission.createdAt,
                statusReceived: entry.status,
                concept: entry.concept,
                unit: entry.unit,
                supplierCost: entry.supplierCost,
                costReceived: entry.supplierCost * entry.quantityReceived || 0,
                costDepartures: entry.supplierCost * quantityDepartures,
                costSend: entry.supplierCost * entry.quantity,
                costLeftToSend: entry.supplierCost * entry.quantityLeftToSend,
                quantityReceived: entry.quantityReceived || 0,
                quantityDepartures: quantityDepartures,
                quantitySend: entry.quantity,
                quantityToLeftSend: entry.quantityToSend - entry.quantityReceived || 0,
                quantityWarehouse: quantityWarehouse,
                comment: entry.comment
              };

              return dataWarehouse;
            });

          dataEntry.map((warehouse) => {
            dataRemissions.push(warehouse);
          });
        }

        this.totalRemissions = this.remissions.length;
        this.entries = dataRemissions;
        this.pageWidget.toggleLoading(false);
      },
      (err: any) => console.log(err)
    );
  }

  public formatQuantity(data: any): string {
    const decimalPipe: DecimalPipe = new DecimalPipe('en');
    const formated: string = decimalPipe.transform(data.value, '1.2-4');
    return formated;
  }

  private supplierUserData() {
    const currentUser: IdentityData = this._identityService.getCurrentData();

    if (currentUser.supplierId) {
      this.supplierId = currentUser.supplierId;
    } else {
      if (!this._identityService.impersonator) {
        this._infoService.error('Área sólo para proveedores!');
      }
      this._router.navigate([!this._identityService.impersonator ? '/' : '/settings/suppliers']);
    }
  }

  private addQuantitySentToRemissionPackages(remissionList: Remission[]): Remission[] {
    return remissionList.map((singleRemission: Remission) => {
      // Looping through every package inside Remission
      singleRemission.packages.map(async (singlePackage: RemissionPackage) => {
        const dataEntries = await this._remissionService
          .getRemissionsCollection(singleRemission.uuid, 'entries')
          .getList()
          .pipe(take(1))
          .toPromise();

        /**
         * Assigning every package:
         *   purchaseOrderId
         *   remissionId
         */
        singlePackage.purchaseOrderId = singleRemission.purchaseOrderId;
        singlePackage.remissionId = singleRemission.id;

        // Find in every remission.quantitySent if there's some quantitySent
        // of the package given by singlePackage
        const conceptFound: Entry[] = dataEntries.filter(
          (singleQuantitySent: Entry) => singleQuantitySent.concept === singlePackage.concept
        );

        // If the package is found, assign assign to quantitySent the quantity
        if (conceptFound) {
          singlePackage.quantitySent = conceptFound[0].quantity;
        }
      });
      return singleRemission;
    });
  }

  private groupRemissionPackagesByConcept(remissionPackages: any) {
    remissionPackages.map(
      (purchaseOrder: { purchaseOrderId: number; packages: RemissionPackage[] }) => {
        // Looping through RemissionPackage[] inside purchaseOrder group
        purchaseOrder.packages = purchaseOrder.packages.reduce(
          (packagesGroupedByConcept: any, currentPackage: RemissionPackage) => {
            // Getting the package concept
            const concept: string = currentPackage.concept;

            // Finding if theres an index already in packagesGroupedByConcept
            const conceptGroupIndexFound: number = packagesGroupedByConcept.findIndex(
              (singleGroup: { concept: string; packages: RemissionPackage[] }) =>
                singleGroup.concept === concept
            );

            // If there's an index
            if (conceptGroupIndexFound !== -1) {
              // Push the package to that index in packagesGroupedByConcept
              packagesGroupedByConcept[conceptGroupIndexFound].packages.push(currentPackage);
              // Calculate the totalQuantitySent by adding per package their quantitySent property
              packagesGroupedByConcept[
                conceptGroupIndexFound
              ].totalQuantitySent += currentPackage.quantitySent ? currentPackage.quantitySent : 0;

              /**
               *  Calculating the quantityLeftToSend given by:
               * package.quantity - package.quantityApproved
               * since it only considers the quantity received and
               * not the sent
               */
              packagesGroupedByConcept[
                conceptGroupIndexFound
              ].quantityLeftToSend -= currentPackage.quantityApproved
                ? currentPackage.quantityApproved
                : 0;

              // The sum of every package quantityApproved
              packagesGroupedByConcept[
                conceptGroupIndexFound
              ].totalQuantityApproved += currentPackage.quantityApproved
                ? currentPackage.quantityApproved
                : 0;
              // The total lof all the quantity that its left to send * cost
              packagesGroupedByConcept[conceptGroupIndexFound].totalValueOfQuantityLeftToSend =
                packagesGroupedByConcept[conceptGroupIndexFound].quantityLeftToSend *
                packagesGroupedByConcept[conceptGroupIndexFound].cost;
            } else {
              const conceptGroup: {
                concept: string;
                cost: number;
                packages: RemissionPackage[];
                quantitySent: number;
                quantityLeftToSend: number;
                totalQuantity: number;
                totalQuantitySent: number;
                totalQuantityApproved: number;
                totalValueOfQuantityApproved: number;
                totalValueOfQuantityLeftToSend: number;
                unitPrice: number;
                unit: string;
              } = {
                concept: concept,
                cost: currentPackage.cost,
                packages: [],
                totalQuantity: currentPackage.quantity,
                unitPrice:
                  (currentPackage.quantity * currentPackage.cost) / currentPackage.quantity,
                quantitySent: currentPackage.quantitySent,
                quantityLeftToSend: 0,
                totalQuantitySent: currentPackage.quantitySent ? currentPackage.quantitySent : 0,
                totalQuantityApproved: currentPackage.quantityApproved
                  ? currentPackage.quantityApproved
                  : 0,
                totalValueOfQuantityApproved: 0,
                totalValueOfQuantityLeftToSend: 0,
                unit: currentPackage.unit
              };
              // Calculating the quantity left to send via totalQuantity - totalQuantityApproved
              conceptGroup.quantityLeftToSend =
                conceptGroup.totalQuantity - conceptGroup.totalQuantityApproved;
              // The monetary value of the quantityApproved
              conceptGroup.totalValueOfQuantityApproved =
                conceptGroup.totalQuantityApproved * currentPackage.cost;
              // The monetary value of the quantityLeftToSend
              conceptGroup.totalValueOfQuantityLeftToSend =
                conceptGroup.quantityLeftToSend * currentPackage.cost;

              conceptGroup.packages.push(currentPackage);
              packagesGroupedByConcept.push(conceptGroup);
            }
            return packagesGroupedByConcept;
          },
          []
        );
      }
    );
    return remissionPackages;
  }

  private groupByPurchaseOrderId(remissionPackages: RemissionPackage[]) {
    return remissionPackages.reduce(
      (packagesGroupedByPurchaseOrderId: any, remissionPackage: RemissionPackage) => {
        // Getting the purchaseOrderId from Remission
        const purchaseOrderId: number = remissionPackage.purchaseOrderId;

        /**
         * Finding an index in packagesGroupedByPurchaseOrderId,
         * if exists appends the package to the group
         * if not we need to create the index with the purchaseOrderId
         **/
        const purchaseOrderFoundIndex: number = packagesGroupedByPurchaseOrderId.findIndex(
          (singlePurchaseOrderGroup: {
            purchaseOrderId: number;
            remissionPackages: RemissionPackage[];
          }) => singlePurchaseOrderGroup.purchaseOrderId === purchaseOrderId
        );

        if (purchaseOrderFoundIndex !== -1) {
          // If theres an index we push the remissionPackage to the group
          packagesGroupedByPurchaseOrderId[purchaseOrderFoundIndex].packages.push(remissionPackage);
        } else {
          // If not, we need to create the purchase order object and push it to packagesGroupedByPurchaseOrder
          const purchaseOrderObject: { purchaseOrderId: number; packages: RemissionPackage[] } = {
            purchaseOrderId: purchaseOrderId,
            packages: []
          };
          purchaseOrderObject.packages.push(remissionPackage);
          packagesGroupedByPurchaseOrderId.push(purchaseOrderObject);
        }
        return packagesGroupedByPurchaseOrderId;
      },
      []
    );
  }

  private groupByRemissions(remissionsPackages: any) {
    remissionsPackages.map(
      (singlepurchaseOrder: {
        purchaseOrderId: number;
        packages: { concept: string; packages: RemissionPackage[] }[];
      }) => {
        singlepurchaseOrder.packages.map(
          (singlePackage: { concept: string; packages: RemissionPackage[] }) => {
            singlePackage.packages = singlePackage.packages.reduce(
              (packagesGroupedByRemissionId: any, remissionPackage: RemissionPackage) => {
                const remissionId: number = remissionPackage.remissionId;
                const remissionGroupFoundIndex: number = packagesGroupedByRemissionId.findIndex(
                  (singleGroup: { remissionId: number; packages: RemissionPackage[] }) =>
                    singleGroup.remissionId === remissionId
                );
                if (remissionGroupFoundIndex !== -1) {
                  packagesGroupedByRemissionId[remissionGroupFoundIndex].push(remissionPackage);
                  packagesGroupedByRemissionId[remissionGroupFoundIndex].quantitySent +=
                    remissionPackage.quantitySent;
                } else {
                  packagesGroupedByRemissionId.push(remissionPackage);
                }
                return packagesGroupedByRemissionId;
              },
              []
            );
            return singlePackage;
          }
        );
      }
    );
    return remissionsPackages;
  }
  private groupRemissionsByPurchaseorder(remissionList: Remission[]) {
    // Getting Remission.packages packages as an array so we can group them later
    const remissionPackages: RemissionPackage[] = this.getRemissionsPackages(remissionList);

    // Grouping RemissionPackages[] by purchaseOrderId
    const remissionPackagesByPurchaseOrder: any = this.groupByPurchaseOrderId(remissionPackages);

    // Group every PurchaseOrderGroup by packages concept
    const remissionPackagesByConcept: any = this.groupRemissionPackagesByConcept(
      remissionPackagesByPurchaseOrder
    );

    const remissionsPackagesGroupedByRemissions: any = this.groupByRemissions(
      remissionPackagesByConcept
    );

    return remissionsPackagesGroupedByRemissions;
  }

  private getRemissionsPackages(remissionList: Remission[]) {
    /**
     * Adding to the packages inside remissions:
     * remissionId
     * purchaseOrderId
     * quantitySent (in case it has some)
     */
    remissionList = this.addQuantitySentToRemissionPackages(remissionList);

    const remissionPackages: RemissionPackage[] = [];

    /**
     * Flattening the packages,
     * instead of Remission > RemissionPackage[]
     * we're creating a RemissionPackage[] array
     */
    remissionList.map((singleRemission: Remission) => {
      remissionPackages.push(...singleRemission.packages);
    });
    return remissionPackages;
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'totalGroupCount'
      },
      {
        location: 'before',
        widget: 'dxSelectBox',
        options: {
          width: 200,
          items: [
            {
              value: 'id',
              text: 'Sin orden'
            },
            {
              value: 'purchaseOrderId',
              text: 'Por orden de compra'
            },
            {
              value: 'concept',
              text: 'Por concepto'
            },
            {
              value: 'remissionId',
              text: 'Por remisión'
            }
          ],
          displayExpr: 'text',
          valueExpr: 'value',
          value: 'concept',
          onValueChanged: this.groupChanged.bind(this)
        }
      },
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          width: 136,
          text: 'Contraer Todos',
          onClick: this.collapseAllClick.bind(this)
        }
      }
    );
  }

  collapseAllClick(e) {
    this.expanded = !this.expanded;
    e.component.option({
      text: this.expanded ? 'Contraer Todos' : 'Expandir Todos'
    });
  }
  groupChanged(e) {
    this.remissionsGrid.instance.clearGrouping();
    this.remissionsGrid.instance.columnOption(e.value, 'groupIndex', 0);
    this.totalCount = this.totalRemissions;
  }
}
