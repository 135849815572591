<app-dialog #dialogCmp
            [noHeader]="noHeader"
            [stretchHeader]="stretchHeader"
            [noPadding]="noPadding"
            [bigText]="bigText"
            [displayBackButton]="displayBackButton"
            [onBackObservers]="onBackObservers"
            [onRefreshObservers]="onRefreshObservers"
            [onDismissObservers]="widgetDismissObservers"
            [title]="title || null"
            [subTitle]="subTitle || null"
            [actionButtons]="actionButtons"
            [menuItems]="menuItems"
            [helpLink]="helpLink"
            [loading]="loading"
            [loadPercent]="loadPercent"
            [performingAction]="performingAction"
            [actionPercent]="actionPercent"
            [disableClose]="disableClose"
            [noStrictSize]="noStrictSize"
            [width]="width"
            [height]="height">

  <div #containerElem></div>

  <ng-template #containerTpl>
    <app-form #formCmp
              [formGroup]="formGroup"
              [options]="options"
              [fields]="fields"
              [model]="model"
              [displayButtons]="false"
              [canReset]="!!canReset"
              [resetButtonText]="resetButtonText"
              [resetButtonIcon]="resetButtonIcon"
              [noSaveButton]="!!noSaveButton"
              [saveButtonText]="saveButtonText"
              [saveButtonIcon]="saveButtonIcon"
              [noCancelButton]="!!noCancelButton"
              [cancelButtonText]="cancelButtonText"
              [cancelButtonIcon]="cancelButtonIcon"
              (reset)="reset.emit($event)"
              (save)="save.emit($event)"
              (cancel)="cancel.emit($event)">
    </app-form>

    <ng-template [ngIf]="!!displayButtons">
        <ng-template [ngIf]="!formCmp.noSaveButton">
          <app-action-button [icon]="formCmp.saveButtonIcon" [text]="formCmp.saveButtonText" (click)="onSave()"></app-action-button>
        </ng-template>
        <ng-template [ngIf]="!formCmp.noCancelButton">
          <app-button flat [icon]="formCmp.cancelButtonIcon" [text]="formCmp.cancelButtonText" (click)="onCancel()"></app-button>
        </ng-template>
        <ng-template [ngIf]="formCmp.canReset">
          <app-button flat [icon]="formCmp.resetButtonIcon" [text]="formCmp.resetButtonText" (click)="onReset()"></app-button>
        </ng-template>
      </ng-template>
  </ng-template>
</app-dialog>
