<div main-grid-container>
    <div class="grid-error">
        <rmd-message error [text]="requiredMessage"></rmd-message>
    </div>

    <div class="grid-table">
        <dx-data-grid #grid id="gridContainer" [dataSource]="dataSource" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="true" [allowColumnResizing]="true"
            [columnAutoWidth]="false" (onSelectionChanged)="onChangedRowsSelection($event)" (onToolbarPreparing)="onToolbarPreparing($event)">
            <ng-container *ngIf="_gridType === 'requisitionGrid'; else
                purchaseOrderGrid">
                <!-- <dxo-load-panel [enabled]="true"></dxo-load-panel> -->
                <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
                <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel>
                <!-- <dxo-header-filter [visible]="false"></dxo-header-filter> -->
                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
                <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxi-column caption="ID Requisición" dataField="requisitionNro" sortOrder="desc" groupCellTemplate="requisitionIdGroupTemplate" cellTemplate="requisitionIdGroupTemplate" alignment="left" width="12%" [allowSearch]="true"></dxi-column>
                <dxi-column caption="ID Derivado" dataField="derivativeId" sortOrder="desc" groupCellTemplate="derivativeIdGroupTemplate" cellTemplate="derivativeIdGroupTemplate" alignment="left" width="12%" [allowSearch]="true"></dxi-column>
                <dxi-column caption="ID Paquete" dataField="packageId" alignment="left" width="15%" [allowSearch]="true"></dxi-column>
                <dxi-column caption="Código" dataField="code" alignment="left" width="20%"></dxi-column>
                <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" [groupIndex]="0" alignment="left" width="40%" [allowSorting]="true" [allowSearch]="true"></dxi-column>
                <dxi-column caption="Unidad" dataField="unit" alignment="center" width="10%" cellTemplate="unitTemplate"></dxi-column>
                <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" dataType="number" alignment="left" width="10%"></dxi-column>
                <!-- <dxi-column caption="Disponible" dataField="cost" cellTemplate="availableTemplate" alignment="left" width="10%"></dxi-column> -->
                <dxi-column caption="Costo" dataField="cost" cellTemplate="costTemplate" alignment="left" width="12%"></dxi-column>
                <dxi-column caption="Total" dataField="total" width="10%" [calculateCellValue]="calculaTotals" cellTemplate="totalTemplate" alignment="left" width="12%"></dxi-column>
                <div *dxTemplate="let data of 'totalTemplate'">
                    <strong>${{ data.value | number }}</strong>
                </div>
                <div *dxTemplate="let data of 'quantityTemplate'">
                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                </div>
                <div *dxTemplate="let data of 'costTemplate'">
                    <strong>${{ data.value | number: '1.2-2' }}</strong>
                </div>
                <div *dxTemplate="let data of 'conceptGroupTemplate'">
                    {{ data.value }}
                </div>
                <div *dxTemplate="let data of 'requisitionIdGroupTemplate'">
                    REQ-{{ data.value }}
                </div>
                <div *dxTemplate="let data of 'derivativeIdGroupTemplate'">
                    {{ data.value }}
                </div>
                <!-- <div *dxTemplate="let data of 'availableTemplate'">
                    <strong>${{ data.value | number }}</strong>
                </div> -->
                <div *dxTemplate="let data of 'unitTemplate'">
                    <span>{{ data.value }}</span>
                </div>
                <dxo-summary [skipEmptyValues]="true">
                    <dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <dxi-group-item column="total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <!-- <dxi-group-item column="purchasedQuantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item> -->
                </dxo-summary>
                <dxo-summary>
                    <dxi-total-item column="total" valueFormat="currency" displayFormat="Total {0}" summaryType="sum">
                    </dxi-total-item>
                </dxo-summary>
                <div *dxTemplate="let data of 'totalGroupCount'">
                    <div class="informer">
                        <h2 class="count">{{totalCount}}</h2>
                        <span class="name">Total Requisiciones</span>
                    </div>
                </div>
            </ng-container>
            <ng-template #purchaseOrderGrid>
                <dxo-header-filter [visible]="false"></dxo-header-filter>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-selection selectAllMode="page" mode="single"></dxo-selection>
                <div *dxTemplate="let data of 'statusTemplate'">
                    <strong> {{ data.value | purchaseOrderStatus }}</strong>
                </div>
                <div *dxTemplate="let data of 'dateTemplate'">
                    <strong> {{ data.value | timestampToDate }}</strong>
                    <app-timeago-date [timestamp]="data.value"></app-timeago-date>
                </div>
                <div *dxTemplate="let item of 'detail'">
                    <div *ngIf="item.data.packages" class="internal-grid-container">
                        <div class="col-md-12 left17 right17">
                            <h4>OC-{{ item.data.id }}</h4>
                        </div>
                        <dx-data-grid #nestedGrid class="internal-grid col-md-12
                            left30
                            right30 top30" noDataText="No hay paquetes" [dataSource]="item.data.packages" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [columns]="packageColumns" [rowAlternationEnabled]="false"
                            [allowColumnReordering]="false" [allowColumnResizing]="false" [hoverStateEnabled]="true" [columnAutoWidth]="false" [disabled]="item.data.id !==
                            _idOfPackagesSelected && _idOfPackagesSelected" (onSelectionChanged)="onChangedRowsSelection($event)" (onRowValidating)="validatingQuantityOfPackagesToSend($event)">
                            <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
                            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
                            <div *dxTemplate="let data of 'quantityTemplate'">
                                <strong>{{ data.value | number: '1.2-2' }}</strong>
                            </div>
                            <div *dxTemplate="let data of
                                'quantitySentTemplate'">
                                <ng-container *ngIf="data.value; else
                                    noQuantitySent">
                                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                                </ng-container>
                                <ng-template #noQuantitySent>
                                    <span>--</span>
                                </ng-template>
                            </div>

                            <div *dxTemplate="let data of
                                'approvedQuantityTemplate'">
                                <ng-container *ngIf="data.value; else
                                    noQuantityApproved">
                                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                                </ng-container>
                                <ng-template #noQuantityApproved>
                                    <span>--</span>
                                </ng-template>
                            </div>

                            <div *dxTemplate="let data of
                                'quantityToSendTemplate'">
                                <ng-container *ngIf="data.value; else
                                    noQuantityToSend">
                                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                                </ng-container>
                                <ng-template #noQuantityToSend>
                                    <span style="color:#c9c9c9">Introduzca
                                        cantidad a enviar</span>
                                </ng-template>
                            </div>

                            <dxo-summary>
                                <dxi-total-item column="Total" valueFormat="currency" displayFormat="Total
                                    {0}" summaryType="sum">
                                </dxi-total-item>
                            </dxo-summary>
                        </dx-data-grid>
                    </div>
                </div>
            </ng-template>
        </dx-data-grid>
    </div>
</div>