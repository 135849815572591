<app-page-widget [menuItems]="widgetMenu" [actionButtons]="widgetActions" (refresh)="refreshData()">
    <app-title>Seleccione o Cree una Remisión</app-title>
    <app-subtitle></app-subtitle>

    <app-action-button icon="playlist_add" routeData="/supplier-remissions/new">Crear</app-action-button>

    <dx-data-grid #remissionsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="remissions" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no existen registros." [showBorders]="true" [rowAlternationEnabled]="false"
        [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-export [enabled]="true" fileName="lista-remisiones"></dxo-export>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
        <!-- <dxo-search-panel [visible]="true"></dxo-search-panel> -->
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>

        <dxi-column caption="ID" [allowEditing]="false" [allowSearch]="true" dataField="remissionId" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Fecha" [allowEditing]="false" dataType="date" cellTemplate="dateTemplate" dataField="dateRemission" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" [allowSearch]="true" sortOrder="desc" alignment="center">
        </dxi-column>
        <dxi-column caption="Estado" cellTemplate="statusTemplate" dataType="string" dataField="status" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
        </dxi-column>
        <dxi-column caption="Concepto" dataField="concept" [groupIndex]="0" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left">
        </dxi-column>

        <!-- <dxi-column
      caption="Recibió"
      width="20%"
      dataField="receiver"
      cellTemplate="receiverTemplate"
      [allowEditing]="false"
      [allowSearch]="true"
      [allowSorting]="false"
      alignment="center"
    >
    </dxi-column> -->
        <dxi-column caption="Cantidad enviada" dataField="quantitySend" cellTemplate="quantitySentTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Cantidad recibida" dataField="quantityReceived" cellTemplate="quantityApprovedTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Por surtir" cellTemplate="quantityLeftToSendTemplate" [allowEditing]="false" [allowSearch]="true" dataField="quantityToLeftSend" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Detalle" dataField="remissionUuid" cellTemplate="ccButtonTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Observaciones" dataField="comment" cellTemplate="ccButtonCommentTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <!-- <dxi-column caption="Entrada" dataField="remissionUuid" cellTemplate="ccButtonEntryTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column> -->

        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span>
        <i
          class="material-icons icon-gray"
          matTooltip="Detalle"
          (click)="detailRemission(data.key)"
        >
          remove_red_eye</i
        >
      </span>
        </div>
        <div *dxTemplate="let data of 'ccButtonCommentTemplate'">
            <ng-container *ngIf="data.value !== ''; else noReceiver">
                <span>
          <i
            class="material-icons icon-danger"
            matTooltip="Observación"
            (click)="viewComment(data.key)"
          >
            feedback</i
          >
        </span>
            </ng-container>
            <ng-template #noReceiver>
                --
            </ng-template>
        </div>
        <dxo-summary [skipEmptyValues]="true">
            <dxi-group-item column="quantitySend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
            </dxi-group-item>
            <dxi-group-item column="quantityReceived" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
            </dxi-group-item>
            <dxi-group-item column="quantityToLeftSend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
            </dxi-group-item>
        </dxo-summary>

        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'">
            <strong *ngIf="data.value === 1">{{ data.value | remissionStatus }}</strong>
            <strong class="text-danger" *ngIf="data.value !== 1">{{
        data.value | remissionStatus
      }}</strong>
        </div>
        <div *dxTemplate="let data of 'conceptGroupTemplate'">
            {{ data.value }}
        </div>
        <div *dxTemplate="let data of 'receiverTemplate'">
            <ng-container *ngIf="data.value; else noReceiver">
                <strong>{{ data.value }}</strong>
            </ng-container>
            <ng-template #noReceiver>
                --
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'quantitySentTemplate'">
            <strong>{{ data.value | number: '1.2-2' }}</strong>
        </div>
        <div *dxTemplate="let data of 'quantityApprovedTemplate'">
            <ng-container *ngIf="data.value; else noQuantityApproved">
                <span> {{ data.value | number: '1.2-2' }}</span>
            </ng-container>
            <ng-template #noQuantityApproved>
                --
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
            <ng-container *ngIf="data.value; else noQuantityLeft">
                {{ data.value | number: '1.2-2' }}
            </ng-container>
            <ng-template #noQuantityLeft>
                --
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{ totalRemissions }}</h2>
                <span class="name">Total Remisiones</span>
            </div>
        </div>
        <div *dxTemplate="let data of 'conceptGroupTemplate'">
            {{ data.value }}
        </div>
        <div *dxTemplate="let data of 'remissionIdTemplate'">REM-{{ data.value }}</div>
    </dx-data-grid>
</app-page-widget>

<app-dialog #formDialog>
    <ng-container>
        <app-title>Observación realizada por el almacenista</app-title>

        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-top-2" *ngIf="selectedGridItems">
                <div class="content-block margin-top-0">
                    <div class="width-100">
                        <p>
                            <strong>{{ selectedGridItems[0].comment }}</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-dialog>