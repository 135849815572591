<app-page-widget>
  <report-detail
    *ngIf="isDataLoaded"
    [reportId]="reportId"
    [embedToken]="embedToken"
    [embedUrl]="embedUrl"
  ></report-detail>
  <app-loading-indicator
    [loadingVisible]="loadingVisible"
    [message]="messageInfoProcess"
  ></app-loading-indicator>
</app-page-widget>
