<dx-load-panel #loadPanel
               shadingColor="rgba(0,0,0,0.4)"
               [(visible)]="_loadingVisible"
               [showIndicator]="true"
               [showPane]="true"
               [message]="message"
               [shading]="true"
               [closeOnOutsideClick]="false"
               (onShown)="onShown()"
               (onHidden)="onHidden()">
</dx-load-panel>
