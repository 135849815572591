import { NgModule } from "@angular/core";
import { SharedModule } from "@bist-web/shared";
import { MaterialConglomeratesComponent } from './material-conglomerates/material-conglomerates.component';
import { ProjectBudgetReportsRoutingModule } from './project-budget-reports.routing';
import { TotalProjectBudgetComponent } from "./total-project-budget/total-project-budget.component";
import { ConglomeratesBudgetComponent } from './conglomerates-budget/conglomerates-budget.component';
import { BudgetCurvaSComponent } from "./budget-curva-s/budget-curva-s.component";

@NgModule({
  imports: [
    SharedModule,
    ProjectBudgetReportsRoutingModule
  ],
  declarations: [
    TotalProjectBudgetComponent,
    MaterialConglomeratesComponent,
    BudgetCurvaSComponent,
    ConglomeratesBudgetComponent],
})
export class ProjectBudgetReportsModule { }
