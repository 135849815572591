<app-widget class="app-section-nav-widget"
            [round]="false"
            [flat]="true"
            [noHeader]="noHeader"
            [stretchHeader]="stretchHeader"
            [noPadding]="noPadding"
            [bigText]="bigText"
            [onBackObservers]="onBackObservers"
            [onRefreshObservers]="onRefreshObservers"
            [title]="title || null"
            [subTitle]="subTitle || null"
            [actionsOnBottom]="actionsOnBottom"
            [actionButtons]="actionButtons"
            [menuItems]="menuItems"
            [helpLink]="helpLink"
            [loading]="loading"
            [loadPercent]="loadPercent"
            [performingAction]="performingAction"
            [actionPercent]="actionPercent">

  <nav mat-tab-nav-bar>
    <a *ngFor="let item of navItems; trackBy: trackNavItem"
        mat-tab-link
        [routerLink]="item.routeData"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
        [innerText]="item.title">
    </a>
  </nav>

  <ng-template [ngIf]="!noOutlet">
    <div class="route-container"> <!-- TODO: Add to .route-container: [@routeAnimation]="getRouteAnimation(route)" -->
      <router-outlet #route="outlet"></router-outlet>
    </div>
  </ng-template>
</app-widget>
