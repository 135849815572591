<form [formGroup]="form" (ngSubmit)="submit()">
  <formly-form [form]="form" [model]="model" [fields]="fields" [options]="options || {}">
    <div *ngIf="activatedSubmit || activatedCancel" class="buttons-bar">
      <ng-template [ngIf]="activatedSubmit">
        <app-button [text]="textSubmit" [icon]="iconSubmit"></app-button>
      </ng-template>
      <ng-template [ngIf]="activatedCancel">
        <app-button flat [text]="textCancel" [icon]="iconCancel" [default]="true" (click)="onCancel()"></app-button>
      </ng-template>
    </div>
  </formly-form>
</form>
