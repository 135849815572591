<app-page-widget title="Extraordinarios">
    <div class="stepper">
        <mat-horizontal-stepper (selectionChange)="onStepChange($event)" [linear]="true">
            <mat-step label="{{ steps[0].label }}" [completed]="stepOneFormCompleted">
                <form [formGroup]="stepOneFormGroup" #formDirective="ngForm" (ngSubmit)="addExtraordinary(formDirective)">
                    <div>
                        <button mat-raised-button color="primary" type="button" matStepperNext [disabled]="extraTempList.length ===
                            0" style.display='inline-block'>
                            <mat-icon>navigate_next</mat-icon>
                            Siguiente
                        </button>
                        <button mat-raised-button color="primary" type="submit" class="ml-10" [disabled]="stepOneFormGroup.invalid" style.display='inline-block'>
                            <mat-icon>done</mat-icon>
                            Agregar Extraordinario
                        </button>
                        <button mat-raised-button color="primary" type="button" class="ml-10" (click)="uploadEvidence()" [disabled]="evidences.length === 3" style.display='inline-block'>
                            <mat-icon>note_add</mat-icon>
                            Agregar Evidencias
                        </button>
                    </div>
                    <div class="step-one">
                        <div style="width: 100%; margin: auto; margin-bottom:
                            60px;">
                            <div style="width: 33%; float: left;" *ngIf="evidences[0]">
                                <a mat-stroked-button href="{{evidences[0]}}" target="_blank">Evidencia
                                    1</a>
                            </div>
                            <div style="width: 33%; float: left;" *ngIf="evidences[1]">
                                <a mat-stroked-button href="{{evidences[1]}}" target="_blank">Evidencia
                                    2</a>
                            </div>
                            <div style="width: 33%; float: left;" *ngIf="evidences[2]">
                                <a mat-stroked-button href="{{evidences[2]}}" target="_blank">Evidencia
                                    3</a>
                            </div>
                        </div>
                        <mat-form-field class="select-type">
                            <mat-select placeholder="Seleccione el Tipo de
                                Extraordinario" (selectionChange)="changedSelectedType()" formControlName="selectTypeCtrl" required>
                                <mat-option *ngFor="let type of types" [value]="type.value">
                                    {{ type.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="select-type">
                            <textarea matInput #conceptInput maxlength="120" type="textarea" placeholder="Concepto" [errorStateMatcher]="matcher" formControlName="inputConceptCtrl" required></textarea>
                            <mat-hint align="end">{{ conceptInput.value.length }} / 120</mat-hint>
                        </mat-form-field>

                        <table class="example-full-width" cellspacing="0">
                            <tr>
                                <td>
                                    <mat-form-field class="select-type">
                                        <mat-select placeholder="Seleccione
                                            Unidad" formControlName="inputUnitCtrl" required>
                                            <mat-option *ngFor="let type of
                                                unitTypes" [value]="type.value" [disabled]="selectedTypeIsDerivative()
                                                && unitTypeIsNotBag(type)">
                                                {{ type.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Cantidad" name="qtyForm" type="number" value="" formControlName="inputQtyCtrl" required />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <input matInput type="number" name="costForm" placeholder="Costo" formControlName="inputCostCtrl" value="" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Nivel" name="levelForm" formControlName="levelFormCtrl" value="" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Codigo" name="codeSelected" formControlName="inputCodeCtrl" value="" required />
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <input matInput [matDatepicker]="datePicker" placeholder="Fecha Requerido" name="dateFormCtrl" formControlName="dateFormCtrl" [min]="minDate" readonly required />
                                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #datePicker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                        <mat-radio-group aria-label="Seleccione un opciòn" name="chargeFormCtrl" formControlName="chargeFormCtrl">
                            <mat-radio-button value="1">Cobrable Cliente
                            </mat-radio-button>
                            <mat-radio-button value="2">Cobrable Proveedor
                            </mat-radio-button>
                            <mat-radio-button value="3">No cobrable</mat-radio-button>
                        </mat-radio-group>
                        <div class="form-group" *ngIf="stepOneFormGroup.errors?.quantityNoZero
                            &&
                            stepOneFormGroup.controls.inputQtyCtrl.touched">
                            <h4 class="warm">
                                * La cantidad y el costo deben ser mayor a 0
                            </h4>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step label="{{ steps[1].label }}" [stepControl]="stepTwoFormGroup">
                <form [formGroup]="stepTwoFormGroup">
                    <div>
                        <button mat-raised-button color="primary" type="button" matStepperPrevious>
                            <mat-icon>navigate_before</mat-icon>
                            Anterior
                        </button>
                        <button mat-raised-button color="primary" type="button" (click)="createExtraordinary()">
                            <mat-icon>done</mat-icon>
                            Crear solicitud de extraordinarios
                        </button>
                    </div>
                    <div class="spinner-wrapper" *ngIf="loading">
                        <mat-spinner [diameter]="60"></mat-spinner>
                    </div>
                    <div class="step-two" *ngIf="!loading">
                        <dx-data-grid #extraGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="extraTempList" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no
                            éxisten registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true">
                            <dxo-paging [pageSize]="10"></dxo-paging>
                            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>

                            <dxi-column dataField="code" width="8%" [allowEditing]="false" caption="Codigo">
                            </dxi-column>
                            <dxi-column dataField="concept" alignment="left" width="30%" [allowEditing]="false" caption="Concepto"></dxi-column>
                            <dxi-column dataField="cost" alignment="center" cellTemplate="costTemplate" caption="Costo"></dxi-column>
                            <dxi-column dataField="quantity" alignment="center" cellTemplate="quantityTemplate" caption="Cantidad"></dxi-column>
                            <dxi-column caption="Fecha" dataField="requestDate" width="20%" [allowEditing]="false" dataType="date" [allowSorting]="true" sortOrder="desc" alignment="center">
                            </dxi-column>
                            <dxi-column dataField="status" alignment="center" cellTemplate="statusTemplate" caption="Estatus"></dxi-column>
                            <dxi-column dataField="type" alignment="center" cellTemplate="extraordinaryType" caption="Tipo"></dxi-column>
                            <dxi-column dataField="unit" alignment="center" cellTemplate="unitTemplate" caption="Unidad"></dxi-column>
                            <div *dxTemplate="let data of 'extraordinaryType'">
                                <span>{{ data.value | packageTypeModel }}</span>
                            </div>
                            <div *dxTemplate="let data of 'quantityTemplate'">
                                <strong>{{ data.value | number: '1.2-3' }}</strong>
                            </div>
                            <div *dxTemplate="let data of 'costTemplate'">
                                <strong>${{ data.value | number }}</strong>
                            </div>
                            <div *dxTemplate="let data of 'unitTemplate'">
                                <span>{{ data.value }}</span>
                            </div>
                            <div *dxTemplate="let data of 'statusTemplate'">
                                <span>{{ data.value | packageStatus }}</span>
                            </div>
                        </dx-data-grid>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</app-page-widget>