<mat-card class="button-actions">
    <mat-card-content>
        <div class="actions-block py-15">
            <button mat-raised-button [disabled]="disabledSearch" class="action-button" (click)="changeElement('search')" color="primary">
                <mat-icon>search</mat-icon>
                Consultar
            </button>
            <button mat-raised-button [disabled]="disabledDelete" class="action-button ml-5" (click)="changeElement('update')" color="primary">
                <mat-icon>delete_outline</mat-icon>
                Eliminar
            </button>
            <button mat-raised-button [disabled]="disabledAdd" class="action-button ml-5" tooltip="Agregar relación" (click)="changeElement('add')" color="primary">
                <mat-icon>add</mat-icon>
                Agregar
            </button>
            <button mat-raised-button class="action-button ml-5" (click)="saveRelation('create')" color="primary">
                <mat-icon>save</mat-icon>
                Guardar
            </button>
        </div>
    </mat-card-content>
</mat-card>
<app-page-widget>
    <!-- <app-title>Paquetes - {{ action }}</app-title>
    <app-subtitle>Listado</app-subtitle>

    <app-action-button icon="search" tooltip="Consultar relación" (click)="changeElement('search')">Consultar</app-action-button>
    <app-action-button icon="delete_outline" tooltip="Eliminar elementos" (click)="changeElement('update')">Eliminar</app-action-button>
    <app-action-button icon="add" tooltip="Agregar relación" (click)="changeElement('add')">Agregar</app-action-button>
    <app-action-button icon="save" tooltip="Guardar relación" (click)="saveRelation('create')">Guardar</app-action-button>

    <app-action-button flat icon="filter_list" (click)="displayFilters = !displayFilters">
        Filtros
    </app-action-button> -->


    <div class="div-container">
        <div class="left" *ngIf="showTablePackages">
            <dx-data-grid #grid noDataText="Sin datos para mostrar." (onRowPrepared)="onRowPrepared($event)" [dataSource]="packages" [wordWrapEnabled]="true" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true" (onSelectionChanged)="selectionPackage($event)">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-filter-row [visible]="displayFilters"></dxo-filter-row>
                <!-- <dxo-search-panel [visible]="displayFilters" placeholder="Buscar..."></dxo-search-panel> -->
                <dxo-paging [pageSize]="2000"></dxo-paging>

                <dxi-column caption="PQT ID" width="10%" dataField="packageId"> </dxi-column>
                <dxi-column width="18%" caption="Código" dataField="code"> </dxi-column>
                <dxi-column caption="Concepto" dataField="concept"> </dxi-column>

                <div *dxTemplate="let data of 'historyLinkTpl'">
                    <app-package-history [hash]="data.data.hashId"></app-package-history>
                </div>
            </dx-data-grid>
        </div>
        <div class="right">
            <model-detail *ngIf="activatedViewer" [accessToken]="accessToken" [documentUrn]="documentUrn" (dbId)="getId($event)"></model-detail>
        </div>
    </div>

</app-page-widget>