<app-dialog #appDialog (dismiss)="onClosePreviewDialog()">
    <app-title>{{ title }}</app-title>
    <app-subtitle>{{ subTitle }}</app-subtitle>

    <div class="content-block d-flex my-0" *ngIf="controlsActivate">
        <div class="supplierSelector" *ngIf="addIva">
            <div>
                <h3 class="content-title">IVA</h3>
                <mat-form-field class="w-90 mt-10" appearance="fill">
                    <input matInput [formControl]="ivaEstimateFormControl" placeholder="IVA" type="number">
                </mat-form-field>
            </div>
        </div>
        <div class="supplierSelector" *ngIf="addIva">
            <div>
                <h3 class="content-title">IVA Complementario</h3>
                <mat-form-field class="w-90 mt-10" appearance="fill">
                    <input matInput [formControl]="ivaComplementarioEstimateFormControl" placeholder="IVA Complementario" type="number">
                </mat-form-field>
            </div>
        </div>
        <div class="supplierSelector">
            <div>
                <h3 class="content-title">ISR</h3>
                <mat-form-field class="w-90 mt-10" appearance="fill">
                    <input matInput [formControl]="isrEstimateFormControl" placeholder="ISR" type="number">
                </mat-form-field>
            </div>
        </div>
        <div class="supplierSelector">
            <div>
                <h3 class="content-title">Fondo de garantía</h3>
                <mat-form-field class="w-90 mt-10" appearance="fill">
                    <input matInput [formControl]="guarantteFoundEstimateFormControl" placeholder="Fondo de garantía" type="number">
                </mat-form-field>
            </div>
        </div>
        <div class="supplierSelector">
            <div>
                <h3 class="content-title">Penalización</h3>
                <mat-form-field class="w-90 mt-10" appearance="fill">
                    <input matInput [formControl]="penalizacionEstimateFormControl" placeholder="Penalización" type="number">
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <div class="info-label">
                <h4>Contrato: <strong>{{ nroContract }}</strong></h4>
            </div>
        </div>
    </div>

    <ng-container [ngSwitch]="gridType">
        <div class="grid-container" *ngSwitchCase="'package'">
            <dx-data-grid #dataGrid id="grid-container" [dataSource]="packages" [wordWrapEnabled]="false" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnResizing]="true"
                [columnAutoWidth]="false" [selectedRowKeys]="packages" useSelectMode="true" (onSelectionChanged)="onChangedRowsSelection($event)">
                <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
                </dxo-pager>
                <!-- <dxo-search-panel [visible]="true"
                          [width]="240"
                          placeholder="Buscar..."> </dxo-search-panel> -->
                <dxo-header-filter [visible]="false"></dxo-header-filter>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <!-- <dxo-selection selectAllMode="allPages" mode="multiple" [deferred]="false"></dxo-selection> -->

                <dxi-column caption="ID" dataField="packageId" alignment="center" width="10%"></dxi-column>
                <dxi-column caption="Fecha" dataField="createdAt" alignment="center" width="10%" [calculateCellValue]="calculateDateCellValue" cellTemplate="dateTemplate"></dxi-column>
                <dxi-column caption="Nivel" dataField="level" alignment="center" width="10%"></dxi-column>
                <dxi-column caption="Código" dataField="code" alignment="center" width="10%"></dxi-column>
                <dxi-column caption="Concepto" dataField="concept" [groupIndex]="3" alignment="center" width="20%"></dxi-column>
                <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" dataType="number" alignment="center" width="15%"></dxi-column>
                <dxi-column caption="Unidad" dataField="unit" alignment="center" width="5%" cellTemplate="unitTemplate"></dxi-column>
                <dxi-column caption="Costo" dataField="cost" cellTemplate="costTemplate" alignment="center" width="10%"></dxi-column>
                <dxi-column caption="Porcentaje a pagar" dataField="percentToPay" cellTemplate="percentTemplate" alignment="center" width="15%"></dxi-column>
                <dxi-column caption="Cantidad a pagar" dataField="quantityToPay" cellTemplate="quantityTemplate" alignment="center" width="15%"></dxi-column>
                <dxi-column caption="Monto a pagar" dataField="amountToPay" cellTemplate="costTemplate" alignment="right" width="30%"></dxi-column>
                <!-- <dxi-column caption="Total" [calculateCellValue]="calculateTotalCost" cellTemplate="totalTemplate" [allowEditing]="false" alignment="center" width="10%">
                </dxi-column> -->

                <dxo-summary [skipEmptyValues]="true" [calculateCustomSummary]="calculateSelectedRow">
                    <dxi-total-item column="quantityToPay" summaryType="sum" alignment="center" [customizeText]="formatQuantityTotal">
                    </dxi-total-item>

                    <dxi-total-item column="amountToPay" summaryType="sum" alignment="right" [customizeText]="formatTotal" showInColumn="amountToPay">
                    </dxi-total-item>

                    <dxi-total-item name="deductive" summaryType="custom" [customizeText]="formatDeductive" displayFormat="${0}" showInColumn="amountToPay">
                    </dxi-total-item>

                    <dxi-total-item name="subtotalPacksDeductive" summaryType="custom" [customizeText]="formatSubtotalPacksDeductive" displayFormat="${0}" showInColumn="amountToPay">
                    </dxi-total-item>

                    <dxi-total-item name="getGuarantieFound" summaryType="custom" [customizeText]="fondoGarantia" showInColumn="amountToPay">
                    </dxi-total-item>

                    <dxi-total-item name="descuentoPrestamoAbonoPrestamo" summaryType="custom" [customizeText]="formatPrestamo" displayFormat="{0}" showInColumn="amountToPay">
                    </dxi-total-item>

                    <dxi-total-item name="descuentoPrestamoAbonoAnticipo" summaryType="custom" [customizeText]="formatAnticipo" displayFormat="{0}" showInColumn="amountToPay">
                    </dxi-total-item>

                    <dxi-total-item name="nuevoSubtotal" summaryType="custom" [customizeText]="formatNuevoSubtotal" displayFormat="${0}" showInColumn="amountToPay">
                    </dxi-total-item>

                    <!-- ok no mover-->
                    <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getIVA" displayFormat="{0}" showInColumn="amountToPay">
                    </dxi-total-item>

                    <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getIVACompl" displayFormat="{0}" showInColumn="amountToPay">
                    </dxi-total-item>

                    <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getISR" displayFormat="{0}" showInColumn="amountToPay">
                    </dxi-total-item>

                    <!-- ok no mover-->
                    <dxi-total-item *ngIf="addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConIVA" displayFormat="{0}" showInColumn="amountToPay">
                    </dxi-total-item>
                    <!-- --- -->
                    <dxi-total-item *ngIf="!addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalSinIVA" displayFormat="{0}" showInColumn="amountToPay">
                    </dxi-total-item>
                </dxo-summary>

                <div *dxTemplate="let data of 'dateTemplate'">
                    <span> {{ data.value | timestampToDate }}</span>
                </div>
                <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                    <span>{{ data.value | number: '1.2-3' }}</span>
                </div>
                <div *dxTemplate="let data of 'costTemplate'" class="cost">
                    <span>$ {{ data.value | number: '1.2-3' }}</span>
                </div>
                <div *dxTemplate="let data of 'percentTemplate'" class="cost">
                    <span>{{ data.value | number: '1.2-3' }}%</span>
                </div>
                <div *dxTemplate="let data of 'totalTemplate'">
                    <strong>${{ data.value | number: '1.2-3' }}</strong>
                </div>
                <div *dxTemplate="let data of 'unitTemplate'">
                    <span>{{ data.value }}</span>
                </div>
            </dx-data-grid>

            <div class="leftover-container margin-top-2" *ngIf="leftoverPackages.length > 0">
                <span class="span-title">Sobrantes</span>
                <span class="span-subtitle">Paquetes sobrantes a ser aplicados en esta requisición</span>

                <table class="bordered-table">
                    <thead>
                        <tr>
                            <th>Concepto</th>
                            <th>Cantidad</th>
                            <th>Unidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let leftover of leftoverPackages">
                            <td>{{ leftover?.concept }}</td>
                            <td>{{ leftover?.quantity | number: '1.2-3' }}</td>
                            <td>{{ leftover?.unit }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngSwitchCase="'supplier'" class="grid-container">
            <dx-data-grid #dataGrid id="grid-container" [dataSource]="suppliers" [wordWrapEnabled]="false" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
                [allowColumnResizing]="true" [columnAutoWidth]="false" [selectedRowKeys]="suppliers" useSelectMode="true" (onSelectionChanged)="onChangedRowsSelection($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 15]" [showInfo]="true">
                </dxo-pager>
                <!-- <dxo-search-panel [visible]="true"
                          [width]="240"
                          placeholder="Buscar..."> </dxo-search-panel> -->
                <dxo-header-filter [visible]="false"></dxo-header-filter>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-selection selectAllMode="allPages" mode="multiple" [deferred]="false"></dxo-selection>

                <dxi-column caption="Razón Social" dataField="socialReason" alignment="left" width="40%">
                </dxi-column>
                <dxi-column caption="Actividad" dataField="activity" alignment="left" width="30%">
                </dxi-column>
                <dxi-column caption="Contacto" dataField="contactName" alignment="left" width="15%">
                </dxi-column>
                <dxi-column caption="Teléfono" dataField="phone" alignment="center" width="15%">
                </dxi-column>
            </dx-data-grid>
        </div>

        <div *ngSwitchDefault>
            <span style="color: red;">Error! no se reconoce el tipo:
        <strong>{{ gridType }}</strong>
      </span>
        </div>
    </ng-container>
    <div class="footer">
        <div class="actions-block">
            <button class="action-button" mat-raised-button id="btn" color="primary" (click)="generateEstimation()">
            Generar Estimación
        </button>
        </div>
    </div>
</app-dialog>