<app-page-widget noPadding>
    <app-title>Caja Chica</app-title>
    <app-subtitle>Crear</app-subtitle>

    <div class="custom-toolbar" fxLayout="row" fxLayoutAlign="space-between">
        <div>
            <app-button icon="add" (click)="displayFormDialog()">Agregar</app-button>
            <app-button icon="camera_roll" (click)="evidenceDialog.open()">Evidencias</app-button>
            <app-button icon="monetization_on" (click)="taxesDialog.open()">{{ taxButtonLabel || 'Impuesto' }}
            </app-button>
        </div>
        <div>
            <app-button icon="save" (click)="save()">Guardar</app-button>
        </div>
    </div>

    <div class="grid-wrapper">
        <dx-data-grid [wordWrapEnabled]="true" [dataSource]="packages" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Por favor, agregue registros" [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true"
            [allowColumnReordering]="true" [allowColumnResizing]="true">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="Tipo" dataField="type" cellTemplate="packageTypeTemplate" [allowEditing]="false" [allowSorting]="true" sortOrder="desc" alignment="center">
            </dxi-column>
            <dxi-column caption="ID PAQ" width="20%" dataField="packageId" [allowEditing]="false" [allowSorting]="true" sortOrder="desc" alignment="center">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad" width="10%" dataField="quantity" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Precio" width="10%" cellTemplate="priceTemplate" dataField="cost" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Total" width="12%" cellTemplate="totalTemplate" dataField="total" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
            </dxi-column>

            <div *dxTemplate="let data of 'packageTypeTemplate'">
                {{ data.value === packageTypes.indirect ? 'Unidad' : 'Derivado' }}
            </div>
            <div *dxTemplate="let data of 'priceTemplate'">${{ data.value | number }}</div>
            <div *dxTemplate="let data of 'totalTemplate'">
                <b>${{ data.value | number }}</b>
            </div>
        </dx-data-grid>
    </div>
</app-page-widget>

<app-dialog #formDialog>
    <app-title>Agregar concepto</app-title>

    <div class="width-800 margin-top-2">
        <button mat-flat-button (click)="selectPackageType(packageTypes.indirect)" [color]="selectedPackageType === packageTypes.indirect ? 'accent' :
            ''">
            Indirectos
        </button>
        <button mat-flat-button (click)="selectPackageType(packageTypes.derivative)" [color]="selectedPackageType === packageTypes.derivative ? 'accent'
            : ''">
            Derivados
        </button>
    </div>

    <hr>

    <div fxLayout="row" fxLayout.xs="column">

        <div class="padding-top-2 {{ selectedPackage ? 'width-590' : 'width-800'
            }}" *ngIf="selectedPackageType">

            <mat-form-field class="width-100">
                <!-- <input type="text" [(ngModel)]="assigned" matInput [formControl]="assignedCtrl" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnAssigned">
                    <mat-option *ngFor="let item of filteredAssigned$ | async" [value]="item">{{ item.name }} {{ item.lastname }}</mat-option>
                </mat-autocomplete> -->
                <mat-select placeholder="Seleccione un paquete" [formControl]="packageSelectFormControl" (selectionChange)="onAddFormChange($event)" required>
                    <mat-option *ngFor="let pck of packagesData" matTooltip="(Cantidad: {{
                        pck?.quantityOriginal }}) -- (Presupuesto: {{
                            pck?.available | number:'1.2-2' }})" [value]="pck">
                        {{ pck?.label }} -- <span [ngClass]="pck.quantityOriginal> 0 ? 'text-success'
                            : 'text-red'">(Cant: {{
                            pck?.quantityOriginal }}) -- (Presupuesto: {{
                                pck?.available | number:'1.2-2' }})</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-template [ngIf]="selectedPackage">

                <mat-form-field class="width-100" *ngIf="selectedPackageType ===
                    packageTypes.indirect">
                    <input matInput [formControl]="quantityFormControl" (change)="validateFormControls()" placeholder="Cantidad" type="number" [max]="selectedPackage &&
                        selectedPackage.quantity ?
                        selectedPackage.quantity : 0" min="1" required>
                </mat-form-field>

                <mat-form-field class="width-100">
                    <input matInput [formControl]="costFormControl" (change)="validateFormControls()" placeholder="{{
                        selectedPackageType === packageTypes.indirect ? 'Precio
                        Unitario:' :
                        'Costo:' }}" type="number" [max]="selectedPackage &&
                        selectedPackage.cost ?
                        selectedPackage.cost : 0" min="0" required>
                </mat-form-field>


                <ng-template [ngIf]="!formIsValid && formErrors.length> 0">
                    <p class="text-red" *ngFor="let error of formErrors">
                        {{ error }}
                    </p>
                </ng-template>

                <button mat-flat-button (click)="addData()" color="primary">
                    Agregar
                </button>

            </ng-template>

        </div>

        <div class="width-150 margin-left-30 padding-h padding-top-2 text-right
            border" *ngIf="selectedPackage">

            <div *ngIf="selectedPackageType === packageTypes.indirect" class="padding-bottom-1 margin-bottom-1 border-bottom">
                <h5>Cantidad Disponible:</h5>
                <b>{{ selectedPackage.quantityOriginal }}</b>
            </div>
            <div *ngIf="selectedPackageType !== packageTypes.indirect" class="padding-bottom-1 margin-bottom-1 border-bottom">
                <h5>Cantidad</h5>
                <b>{{ selectedPackage.quantity }}</b>
            </div>
            <div *ngIf="selectedPackage?.cost" class="padding-bottom-1
                margin-bottom-1
                border-bottom">
                <h5>Precio Unitario</h5>
                <b>${{ selectedPackage.unitPrice | number: '1.2-2' }}</b>
            </div>
            <div *ngIf="selectedPackage?.cost" class="padding-bottom-2
                text-success">
                <h5>{{ selectedPackageType === packageTypes.indirect ? 'Costo máximo:' : 'Presupuesto disponible:' }}</h5>
                <b>${{ selectedPackage.available | number: '1.2-2'
                    }}</b>
            </div>

            <div *ngIf="selectedPackageType === packageTypes.indirect && total" class="padding-bottom-2">
                <h3>Total:</h3>
                <b>${{ total | number: '1.2-2' }}</b>
            </div>

        </div>

    </div>

</app-dialog>

<app-dialog #evidenceDialog noPadding width="650px" [autoFocus]="false">
    <app-title>Evidencias</app-title>

    <div class="custom-toolbar margin-bottom-2">
        <app-button default (click)="filesUploader.openSelectionDialog()">Comprobante</app-button>
        <app-button default (click)="filesUploader.openSelectionDialog()">Factura</app-button>
        <app-button default (click)="filesUploader.openSelectionDialog()">XML</app-button>
    </div>

    <app-files-uploader #filesUploader bucket="cashbox-evidences" (uploadFinished)="onEvidenceUpload($event)">
    </app-files-uploader>

    <!--
  <app-action-button icon="delete" (click)="removeSelectedEvidence()">Eliminar seleccionados</app-action-button>
  -->
    <mat-selection-list #evidencesList *ngIf="evidence?.length" class="margin-v-2">
        <mat-list-option *ngFor="let ev of evidence; trackBy: trackEvidence">
            {{ ev.name }}
        </mat-list-option>
    </mat-selection-list>
</app-dialog>

<app-dialog #taxesDialog width="500px" [autoFocus]="false">
    <app-title>Impuestos</app-title>

    <div class="margin padding">
        <mat-select placeholder="Seleccione el impuesto aplicable" (selectionChange)="changedSelectedTax($event)">
            <mat-option *ngFor="let tax of taxes" [value]="tax.value"> {{ tax.label }}
            </mat-option>
        </mat-select>
    </div>
    <div class="margin padding">
        <app-button color="primary" (click)="taxesDialog.close()">Aceptar</app-button>
    </div>
</app-dialog>