<app-dialog #dialog noPadding (dismiss)="onCloseDialog()">
  <!-- TODO: (refresh)="projectsList.refresh()" -->
  <app-title>Cambiar de Partida</app-title>
  <app-subtitle>Por favor, seleccione.</app-subtitle>

  <div class="width-600 heigh-600">
    <app-project-selector #projectSelector mode="list" (select)="dialog.close()">
    </app-project-selector>
  </div>

  <hr />
  <div class="padding-bottom padding-left">
    <app-button flat icon="swap_horiz" (click)="gotoProjectSelection()">
      Ver selector de partidas
    </app-button>
  </div>
</app-dialog>

<ng-template [ngxPermissionsExcept]="['impersonate:supplier']">
  <div
    *ngIf="projectIsSelected"
    class="wrapper"
    matTooltip="Click para cambiar de División y/o Partidas"
    (click)="dialog.open()"
  >
    <sup>División / Partidas</sup>

    <div>
      <span [innerText]="company?.name"></span>/
      <span [innerText]="project?.name"></span>
      <mat-icon>swap_horiz</mat-icon>
    </div>
  </div>
</ng-template>

<ng-template [ngxPermissionsOnly]="['impersonate:supplier']">
  <div class="wrapper" matTooltip="Click para cambiar de División y/o Partidas">
    <sup *ngIf="impersonatedSupplier">Personificando</sup>

    <div *ngIf="impersonatedSupplier">
      <span [innerText]="impersonatedSupplier?.socialReason"></span>
    </div>
  </div>
</ng-template>
