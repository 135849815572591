<rmd-message *ngIf="!items?.length"
             warning>No hay elementos para mostrar</rmd-message>

<div fxLayout="row wrap"
     fxLayoutAlign="space-evenly center">

  <div *ngFor="let item of items; trackBy: trackItem"
       fxFlex="330px"
       class="card-slot">

    <mat-card (click)="onClick(item)">
      <figure mat-card-image>
        <app-lazy-img [src]="item.bgImageUrl || item.imageUrl || null"></app-lazy-img>
      </figure>

      <mat-card-header>
        <figure *ngIf="item.avatarUrl || item.logoUrl"
                mat-card-avatar>
          <app-lazy-img [src]="item.avatarUrl || item.logoUrl"></app-lazy-img>
        </figure>

        <mat-card-title [innerText]="item.title || item.name || '?'"></mat-card-title>

        <mat-card-subtitle *ngIf="item.subTitle"
                           [innerText]="item.subTitle"></mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <div *ngIf="item.description"
             [innerText]="item.description">
        </div>
      </mat-card-content>

      <!-- TODO:
      <mat-card-actions align="end">
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
      -->
    </mat-card>

  </div>

</div>
