<app-page-widget (refresh)="refreshData()">
  <app-title>REM-{{ remission?.uuid | idFromUuid }}</app-title>
  <app-subtitle>{{ remission?.createdAt | timestampToDate: 'long' }}</app-subtitle>

  <div class="header-block" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="status-block">
      <div>
        Estado:
        <strong>{{ remission?.status | remissionStatus }}</strong>
      </div>
      <div>
        Orden de compra:
        <strong>{{ remission?.purchaseOrderId }}</strong>
      </div>
      <!-- <div>
        Proveedor:
        <app-user-data *ngIf="remission" [id]="remission.createdBy"></app-user-data>
      </div> -->
    </div>
  </div>

  <div class="content-block-section">
    <h3 class="content-title">Proveedor</h3>
    <div>
      <div>
        <span class="info-label">Proveedor: </span>
        <strong>{{ remission?.supplierId | supplierData | async }}</strong>
      </div>
      <!-- <div>
        <span class="info-label">Moneda: </span>
        <strong>{{ remission?.supplierId | supplierData:'currency' | async }}</strong>
      </div> -->
      <div>
        <span class="info-label">Razón Social: </span>
        <strong>{{ remission?.supplierId | supplierData: 'socialReason' | async }}</strong>
      </div>
      <div>
        <span class="info-label">RFC: </span>
        <strong>{{ remission?.supplierId | supplierData: 'rfc' | async }}</strong>
      </div>
      <div>
        <span class="info-label">Dirección Comercial:</span>
        <strong>{{ remission?.supplierId | supplierData: 'businessAddress' | async }}</strong>
      </div>
      <div>
        <span class="info-label">Dirección Fiscal:</span>
        <strong>{{ remission?.supplierId | supplierData: 'taxAddress' | async }}</strong>
      </div>
      <div>
        <span class="info-label">Teléfono:</span>
        <strong>{{ remission?.supplierId | supplierData: 'phone' | async }}</strong>
      </div>
      <div>
        <span class="info-label">email: </span>
        <strong>{{ remission?.supplierId | supplierData: 'email' | async }}</strong>
      </div>
    </div>
  </div>

  <div class="content-block">
    <h3 class="content-title">Paquetes</h3>
    <dx-data-grid
      id="grid-container"
      noDataText="No hay registros"
      [wordWrapEnabled]="true"
      [dataSource]="remissionPackages"
      [hoverStateEnabled]="false"
      [showColumnLines]="true"
      [showRowLines]="true"
      [showBorders]="false"
      [rowAlternationEnabled]="false"
      [columnAutoWidth]="true"
      [allowColumnReordering]="false"
      [allowColumnResizing]="false"
    >
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>

      <dxi-column
        alignment="center"
        caption="ID"
        dataField="packageId"
        width="20%"
        [allowEditing]="false"
        [allowSearch]="false"
        [allowSorting]="false"
      >
      </dxi-column>
      <dxi-column
        caption="Concepto"
        dataField="concept"
        width="40%"
        cellTemplate="conceptTemplate"
        alignment="left"
        [allowEditing]="false"
        [allowSearch]="false"
        [allowSorting]="true"
      >
      </dxi-column>
      <dxi-column
        caption="Nivel"
        dataField="level"
        width="20%"
        cellTemplate="levelTemplate"
        alignment="center"
        [allowEditing]="false"
        [allowFiltering]="false"
        [allowSorting]="false"
      >
      </dxi-column>
      <dxi-column
        caption="Unidad"
        dataField="unit"
        width="20%"
        alignment="center"
        cellTemplate="unitTemplate"
        [allowEditing]="false"
        [allowFiltering]="false"
        [allowSorting]="false"
      >
      </dxi-column>
      <dxi-column
        caption="Cantidad"
        dataField="quantity"
        width="20%"
        alignment="center"
        cellTemplate="quantityTemplate"
        [allowEditing]="false"
        [allowFiltering]="false"
        [allowSorting]="false"
      >
      </dxi-column>
      <dxi-column
        caption="Cantidad enviada"
        dataField="quantitySent"
        width="20%"
        alignment="center"
        cellTemplate="quantitySentTemplate"
        [allowEditing]="false"
        [allowFiltering]="false"
        [allowSorting]="false"
      >
      </dxi-column>
      <dxi-column
        caption="Cantidad recibida"
        dataField="quantityApproved"
        width="20%"
        alignment="center"
        cellTemplate="quantityApprovedTemplate"
        [allowEditing]="false"
        [allowFiltering]="false"
        [allowSorting]="false"
      >
      </dxi-column>
      <div *dxTemplate="let data of 'conceptTemplate'">
        <span>{{ data.value }}</span>
      </div>
      <div *dxTemplate="let data of 'levelTemplate'">
        <span>{{ data.value }}</span>
      </div>
      <div *dxTemplate="let data of 'unitTemplate'">
        <span>{{ data.value }}</span>
      </div>
      <div *dxTemplate="let data of 'quantityTemplate'">
        <span> {{ data.value | number }}</span>
      </div>
      <div *dxTemplate="let data of 'quantitySentTemplate'">
        <span> {{ data.value | number }}</span>
      </div>
      <div *dxTemplate="let data of 'quantityApprovedTemplate'">
        <ng-container *ngIf="data.value; else noQuantityReceivedYet">
          <span>{{ data.value | number }}</span>
        </ng-container>
        <ng-template #noQuantityReceivedYet>
          --
        </ng-template>
      </div>
    </dx-data-grid>
  </div>
</app-page-widget>
