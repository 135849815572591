import { Component, OnInit } from '@angular/core';
import { ProjectState } from '@bist-lib/app-state';
import { GetReportInGroupRequest } from '@bist-lib/reports/requests';
import { GetReportInGroupResponse } from '@bist-lib/reports/responses';
import { environment } from '@bist-web/environments/environment';
import { PowerBiService } from '@bist-web/shared/services/power-bi/powerbi.service';

@Component({
  selector: 'subcontracts-packages-report',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  embedToken: string;
  embedUrl: string;
  isDataLoaded: boolean;
  loadingVisible: boolean;
  messageInfoProcess: string;
  reportId: string = environment.powerBiConfig.subcontractsPackagesReportId;


  getReportInGroupRequest: GetReportInGroupRequest = {
    format: 'JSON',
    projectId: this.projectState.getSelectedProject().uuid,
    report: 'SUBCONTRACT_01',
    reportId: this.reportId,
    groupId: environment.powerBiConfig.groupId,
    datasetId: environment.powerBiConfig.subcontractsDatasetId,
    tableName: 'Listado paquetes'
  };

  

  constructor(
    private _powerBiService: PowerBiService,
    protected projectState: ProjectState
  ) { }

  ngOnInit() {
    this.refreshData();
    console.log(this.getReportInGroupRequest);
  }

  refreshData(): void {
    this.loadingVisible = true;
    this.messageInfoProcess = 'Cargando...';
    // console.log('BU: ', this.getReportInGroupRequest);
    this._powerBiService.getReportInGroup(this.getReportInGroupRequest)
      .subscribe((response: GetReportInGroupResponse) => {
        console.log('response', response)
        this.embedUrl = response.embedUrl;
        this.embedToken = response.embedToken;
        this.isDataLoaded = true;
        this.loadingVisible = false;
        this.messageInfoProcess = '';
      }, err => console.log('Error: ', err));
  }
}
