import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IdentityData, IdentityService } from '@bist-lib/auth-api';
import {
  ProcessedPackage,
  PurchaseOrder,
  PurchaseOrdersService,
  PurchaseOrderStatus,
  Remission,
  RemissionsService,
  RemissionStatus,
  Warehouse,
  WarehousesService
} from '@bist-lib/db-api';
import { Package, RemissionPackage } from '@bist-lib/db-api/models';
import { PackagesColumns } from '@bist-lib/db-api/src/models/columns/packages-columns';
import { RemissionColumns } from '@bist-lib/db-api/src/models/columns/remissions-columns';
import { ReceivedStatus } from '@bist-lib/db-api/src/models/remission';
import { DialogComponent, PageWidgetComponent } from '@bist-web/shared/components';
import { InfoService } from '@bist-web/shared/services';
import { of, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { COLUMNS_PACKAGES, COLUMNS_REMISSIONS } from './columns';

@Component({
  selector: 'app-new-remission',
  templateUrl: './new-remission.component.html',
  styleUrls: ['./new-remission.component.css']
})
export class NewRemissionComponent implements OnInit {
  @ViewChild(PageWidgetComponent, { static: true }) public pageWidget: PageWidgetComponent;
  @ViewChild('previewDialog', { static: true }) public previewDialog: DialogComponent;
  public selectedPackages: RemissionPackage[];
  public stepLoading: boolean;
  public previw: boolean;
  public create: boolean;
  public packagesWithQuantityToSend: boolean;
  public purchaseOrders: PurchaseOrder[] = [];
  private supplierId: string;

  public steps: { label: string }[] = [
    { label: 'Selección de Paquetes' },
    { label: 'Confirmación de Paquetes' }
  ];
  public columnRemissions: RemissionColumns[] = COLUMNS_REMISSIONS;
  public columnPackages: PackagesColumns[] = COLUMNS_PACKAGES;
  public stepOneFormGroup: FormGroup;
  public stepTwoFormGroup: FormGroup;

  constructor(
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _infoService: InfoService,
    private _purchaseOrderService: PurchaseOrdersService,
    private _identityService: IdentityService,
    private _remissionService: RemissionsService,
    private _warehousesService: WarehousesService
  ) {}

  ngOnInit() {
    this._createOff();
    this._stepLoadingOn();
    this.refreshData();
  }

  private refreshData(): void {
    this.stepOneFormGroup = this._formBuilder.group({
      selectPackagesCtrl: ['', Validators.required]
    });
    this.stepTwoFormGroup = this._formBuilder.group({
      selectSuppliersCtrl: ['', Validators.required]
    });

    this.supplierUserData();
    this.getPurchaseOrderList();
  }

  public getPurchaseOrderList(): void {
    this._stepLoadingOn();
    this._resetSelectedPackages();
    this._purchaseOrderService
      .getList({ where: { supplierId: this.supplierId } })
      .pipe(
        switchMap((purchaseOrderList: PurchaseOrder[]) => {
          purchaseOrderList.filter(
            (singlePurchaseOrder: PurchaseOrder) =>
              singlePurchaseOrder.status >= PurchaseOrderStatus.Approved
          );
          return zip(
            of(purchaseOrderList),
            this._remissionService.getListBySupplierId(purchaseOrderList[0].supplierId)
          );
        }),
        map(([purchaseOrders, remissionList]) => {
          purchaseOrders.map((singlePurchaseOrder: PurchaseOrder) => {
            singlePurchaseOrder.packages.map((purchaseOrderPackage: RemissionPackage) => {
              remissionList.map((singleRemission: Remission) => {
                if (
                  singlePurchaseOrder.id === singleRemission.purchaseOrderId &&
                  singleRemission.status !== RemissionStatus.Cancelled
                ) {
                  const dataEntries = this._remissionService
                    .getRemissionsCollection(singleRemission.uuid, 'entries')
                    .getList()
                    .subscribe((entry) => {
                      // purchaseOrderPackage.quantitySent = 0;
                      entry
                        .filter((singleEntry) => singleEntry.type === 1)
                        .map((singleQuantity) => {
                          // purchaseOrderPackage.quantitySent = 0;
                          if (purchaseOrderPackage.concept === singleQuantity.concept) {
                            if (!purchaseOrderPackage.quantitySent) {
                              purchaseOrderPackage.quantitySent = 0;
                              purchaseOrderPackage.quantityApproved = 0;
                              purchaseOrderPackage.quantityLeftToSend =
                                purchaseOrderPackage.purchasedQuantity;
                            }

                            purchaseOrderPackage.quantitySent += singleQuantity.quantity;
                            purchaseOrderPackage.quantityApproved +=
                              singleQuantity.quantityReceived;
                            purchaseOrderPackage.quantityLeftToSend -=
                              singleQuantity.quantityReceived;

                            // console.log(
                            //   'package', purchaseOrderPackage,
                            //   'quantitySent', purchaseOrderPackage.quantitySent
                            // );
                          }
                        });
                    });
                }
              });
            });
          });
          return purchaseOrders;
        })
      )
      .subscribe(
        (purchaseOrderList: PurchaseOrder[]) => {
          this.purchaseOrders = purchaseOrderList.filter(
            (singlePurchaseOrder: PurchaseOrder) =>
              singlePurchaseOrder.status >= PurchaseOrderStatus.Approved
          );
          // Assigning to every package inside purchaseOrder
          // their purchase order id
          this.purchaseOrders.map((singlePurchaseOrder: PurchaseOrder) => {
            singlePurchaseOrder.packages.map((singlePackage: Package | any) => {
              singlePackage.purchaseOrderId = singlePurchaseOrder.id;
              singlePackage.purchaseOrderUuid = singlePurchaseOrder.uuid;
              singlePackage.warehouse = singlePurchaseOrder.warehouse || null;
              singlePackage.projectId = singlePurchaseOrder.projectId;
            });
          });
          this._stepLoadingOff();
        },
        (err: any) => {
          // console.log(err);
          this._stepLoadingOff();
        }
      );
  }

  public async createRemission() {
    this.pageWidget.togglePerformingAction(true);

    // Retrieving the processed package of each grouped package
    const packages: ProcessedPackage[] = this.selectedPackages.reduce(
      (prev: ProcessedPackage[], pck: any) => [...prev, ...pck.relatedPackages],
      []
    );

    const query = {
      where: {
        projectId: this.selectedPackages[0].projectId
      }
    };

    const warehouses: Warehouse[] = await this._warehousesService
      .getList(query)
      .pipe(take(1))
      .toPromise();

    // Setting up remission object
    const remission: Remission = {
      purchaseOrderId: this.selectedPackages[0].purchaseOrderId,
      purchaseOrderUuid: this.selectedPackages[0].purchaseOrderUuid,
      status: RemissionStatus.Created,
      supplierId: this.selectedPackages[0].selectedSupplierId,
      warehouse: this.selectedPackages[0].warehouse
        ? this.selectedPackages[0].warehouse
        : warehouses[0].uuid || null,
      projectId: this.selectedPackages[0].projectId,
      packages
    };

    // Creating remission
    this._remissionService.createRemission(remission, packages).subscribe(
      async (createdRemission: Remission) => {
        const packagesSelected: any[] = this.selectedPackages;
        // Setting up an object array containing the quantity to send for each package
        for (const pck of packagesSelected) {
          // this.selectedPackages.map((pck: any) => {
          const quantitiesToSend: any = {
            concept: pck.concept,
            code: pck.code,
            packageId: pck.relatedPackages[0].packageId,
            level: pck.relatedPackages[0].level,
            unit: pck.relatedPackages[0].unit,
            quantityToSend: Number(pck.purchasedQuantity),
            quantity: Number(pck.quantityToSend),
            pu: Number(pck.relatedPackages[0].quantity),
            quantityReceived: 0,
            quantityLeftToSend: Number(pck.quantityLeftToSend) || Number(pck.purchasedQuantity),
            cost: pck.cost,
            supplierCost: pck.supplierCost,
            purchaseCost: pck.supplierTotal,
            status: ReceivedStatus.Created,
            dateReceived: null,
            purchaseOrderId: this.selectedPackages[0].purchaseOrderId,
            purchaseOrderUuid: this.selectedPackages[0].purchaseOrderUuid,
            supplierId: this.selectedPackages[0].selectedSupplierId,
            remissionId: createdRemission.id,
            remissionUuid: createdRemission.uuid,
            warehouse: createdRemission.warehouse,
            origin: this.selectedPackages[0].selectedSupplierId,
            type: 1
          };

          const entry = await this._remissionService
            .getRemissionsCollection(createdRemission.uuid, 'entries')
            .create(quantitiesToSend)
            .toPromise();
        }

        this.pageWidget.togglePerformingAction(false);
        this._infoService.success(
          `La Remisión (${createdRemission.id}) se creó satisfactoriamente`
        );
        this._router.navigate(['/supplier-remissions/list']);

        // const entry = {
        //   ...quantitiesToSend,
        //   remissionId: remission.id,
        //   remissionUuid: remission.uuid
        // };
      },
      (err: any) => console.log(err)
    );
  }

  public openPreviewDialog(): void {
    this.previewDialog.open();
  }

  private _stepLoadingOn(): void {
    this.stepLoading = true;
  }

  private _stepLoadingOff(): void {
    this.stepLoading = false;
  }

  private _createOn(): void {
    this.create = true;
  }

  private _createOff(): void {
    this.create = false;
  }

  private _resetSelectedPackages(): void {
    this._createOff();
    this.selectedPackages = [];
  }

  private supplierUserData(): void {
    const currentUser: IdentityData = this._identityService.getCurrentData();

    if (currentUser.supplierId) {
      this.supplierId = currentUser.supplierId;
    } else {
      if (!this._identityService.impersonator) {
        this._infoService.error('Área sólo para proveedores!');
      }
      this._router.navigate([!this._identityService.impersonator ? '/' : '/settings/suppliers']);
    }
  }

  public changedSelectedPackages(object: { event: {}; items: [] }): void {
    if (object && object.items && object.items.length > 0) {
      this.selectedPackages = object.items;
      this.selectedPackages.map((singlePackage: RemissionPackage) => {
        if (!singlePackage.quantityToSend || singlePackage.quantityToSend === 0) {
          this.packagesWithQuantityToSend = false;
          return;
        } else {
          this.packagesWithQuantityToSend = true;
          this._createOn();
        }
      });
    } else {
      this.packagesWithQuantityToSend = false;
    }
  }
}
