<ng-template #contentTpl>
  <mat-icon>{{ data.icon || 'build' }}</mat-icon>
  <span class="nav-menu-item-title" [innerText]="data.title"></span>

  <span fxFlex>
    <!-- fill space --></span>

  <span *ngIf="data.counter" class="badge" [innerText]="data.counter">
  </span>
</ng-template>

<mat-list-item *ngIf="data" class="nav-menu-item-container">
  <ng-template [ngIf]="data.action">
    <a matLine (click)="execAction(data.action)">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
  </ng-template>

  <ng-template [ngIf]="data.routeData">
    <a matLine class="nav-menu-item" [routerLink]="parseRouteData(data.routeData)"
      routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">

      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
  </ng-template>

  <ng-template [ngIf]="data.url">
    <a matLine [href]="data.url" target="_blank">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
  </ng-template>
</mat-list-item>
