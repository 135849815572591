<app-dialog #appDialog>
    <app-title>Datos de la estimación</app-title>

    <dx-data-grid id="grid-container" #dataGrid [dataSource]="estimatePacks" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="false" [showRowLines]="true" [showBorders]="false" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [columnAutoWidth]="false">
        <dxo-editing mode="single" [allowUpdating]="false"></dxo-editing>
        <!-- <dxi-column caption="Asignación" dataField="asignmentSmallId" width="15%" alignment="left" cellTemplate="asignmentIdTemplate"></dxi-column> -->
        <dxi-column caption="ID" dataField="packageId" alignment="left" cellTemplate="idTemplate" width="12%"></dxi-column>
        <dxi-column caption="Concepto" dataField="concept" width="40%"></dxi-column>
        <!-- <dxi-column caption="Unidad" dataField="unit" width="10%" cellTemplate="unitTemplate" alignment="center"></dxi-column> -->
        <!-- <dxi-column caption="P.U" dataField="unitPrice" width="10%" cellTemplate="costoTemplate" alignment="center"></dxi-column>
            <dxi-column caption="P.U Real" dataField="realPrice" width="10%" cellTemplate="costoTemplate" alignment="center"></dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" width="10%" cellTemplate="cantidadTemplate" alignment="center"></dxi-column>
            <dxi-column caption="Costo inicial" dataField="costOriginal" width="15%" cellTemplate="costOriginalTemplate" alignment="center"> -->
        <!-- </dxi-column> -->
        <!-- <dxi-column caption="Adicionales" dataField="additionToQuantity" width="15%" cellTemplate="additionTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Deductiva" dataField="decreaseToQuantity" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column> -->
        <dxi-column caption="Total" dataField="importe" width="15%" [calculateCellValue]="calValues" cellTemplate="importeTemplate"></dxi-column>
        <dxi-column caption="Diponible" dataField="available" [calculateCellValue]="calculateAvailable" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% solicitado" dataField="percentage" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% a solicitar" dataField="percentToPay" [calculateCellValue]="calculatePercentageToDisplay" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% total" dataField="percentTotal" [calculateCellValue]="calculatePercentageTotal" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Importe" dataField="total" [calculateCellValue]="calculatePercentageToPay" width="30%" cellTemplate="estimateamountTemplate" alignment="right"></dxi-column>
        <div *dxTemplate="let d of 'costoTemplate'">
            <span class="current-value">${{ d.value | number }}</span>
        </div>

        <div *dxTemplate="let d of 'decreaseTemplate'">
            <ng-container *ngIf="d.value; else withoutValue">
                {{ d.value | number: '1.2-2' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let d of 'estimateTemplate'">
            <span class="current-value">{{ d.value }}%</span>
        </div>
        <div *dxTemplate="let d of 'importeTemplate'">
            <span class="current-value">${{ d.value | number }}</span>
        </div>
        <div *dxTemplate="let data of 'costOriginalTemplate'">
            <span>${{ data.value | number }}</span>
        </div>
        <div *dxTemplate="let d of 'estimateamountTemplate'">
            <span *ngIf="d.data.concept === 'Fondo de garantia'"> - </span>
            <span class="current-value">${{ d.value }}</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'asignmentIdTemplate'">
            <span *ngIf="data.value">ASG-{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'idTemplate'">
            <span *ngIf="data.value">{{ data.value }}</span> <span *ngIf="!data.value">NA</span>
        </div>

        <dxo-summary [calculateCustomSummary]="calculateSelectedRow">
            <dxi-total-item column="concept" summaryType="count" displayFormat="Conceptos: {0}">
            </dxi-total-item>

            <dxi-total-item name="subtotalPacks" summaryType="custom" [customizeText]="formatSubtotalPacks" displayFormat="${0}" showInColumn="total">
            </dxi-total-item>

        </dxo-summary>

        <dxi-column></dxi-column>
    </dx-data-grid>

</app-dialog>