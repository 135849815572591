<app-page-widget [menuItems]="widgetMenu" [actionButtons]="widgetActions" (refresh)="refreshData()">
    <app-title>Avance de paquetes - {{ title }}</app-title>
    <app-subtitle></app-subtitle>
    <!-- <app-action-button icon="playlist_add" [disabled]="isCreateButtonDisabled" (click)="generateAdvance()">Guardar</app-action-button>
    <app-menu-item icon="playlist_add" (click)="newAdvance()">Guardar</app-menu-item> -->

    <div class="content" *ngIf="packagesAdvances">
        <!-- [columnHidingEnabled]="true" -->
        <dx-data-grid #assignmentsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="packagesAdvances" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
    no
    existen registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" fileName="lista-avances"></dxo-export>
            <!-- <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection> -->
            <dxo-editing mode="cell" [allowUpdating]="false"></dxo-editing>
            <dxi-column caption="ID" width="5%" [allowEditing]="false" sortOrder="desc" [allowSearch]="true" dataField="asignmentSmallId" [groupIndex]="0" groupCellTemplate="assignmentGroupTemplate" cellTemplate="assignmentGroupTemplate" width="10%" [allowEditing]="false"
                [allowSorting]="false" [allowSearch]="true" alignment="center">
            </dxi-column>
            <!-- <dxi-column caption="Ultimo avance" width="20%" [allowEditing]="false" dataType="date" cellTemplate="dateLastTemplate" dataField="dateLastAdvance" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" [allowSearch]="true" sortOrder="desc"
            alignment="center">
        </dxi-column> -->
            <dxi-column caption="Semana" width="15%" [allowEditing]="false" cellTemplate="weekTemplate" dataField="weekOfYear" [allowSorting]="false" [allowSearch]="true" alignment="center">
            </dxi-column>
            <dxi-column caption="ID Paquete" dataField="packageId" width="20%" groupCellTemplate="packageIdGroupTemplate" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" width="15%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" width="15%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" width="30%" groupCellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Nivel" cellTemplate="levelGroupTemplate" groupCellTemplate="levelGroupTemplate" dataType="string" dataField="level" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Proveedor" width="20%" dataField="supplierId" alignment="center" cellTemplate="supplierTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Capturador" width="15%" dataField="createdBy" alignment="center" cellTemplate="userDataTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Avance Anterior Acumulado" width="15%" dataField="percentageAdvance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Avance Semanal" width="15%" dataField="percentToAdvance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Avance a la Fecha" width="15%" dataField="totalAdvance" [calculateCellValue]="calcPercentageTotalAdvance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Evidencias" dataField="quoteUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span *ngIf="data.key.evidences && data.key.evidences.length > 0">
                <i class="material-icons icon-gray" (click)="onSelectEvidences(data.key)">
                    remove_red_eye
                </i>
            </span>
            </div>

            <div *dxTemplate="let data of 'advanceTemplate'">
                <span *ngIf="data.value">{{ data.value | number:'1.2-2' }}%</span>
                <span *ngIf="!data.value">0%</span>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>

            <div *dxTemplate="let data of 'dateLastTemplate'">
                <span *ngIf="data.value"><strong> {{ data.value | timestampToDate }}</strong> -</span>
                <span *ngIf="data.value"> hace {{ data.value | timeAgo }}</span>
                <span *ngIf="!data.value">--</span>
            </div>

            <div *dxTemplate="let data of 'supplierTemplate'">
                <app-supplier-data [id]="data.value"></app-supplier-data>
            </div>
            <div *dxTemplate="let data of 'userDataTemplate'">
                <app-user-data *ngIf="data.value" [id]="data.value"></app-user-data>
                <span *ngIf="!data.value">--</span>
            </div>
            <div *dxTemplate="let data of 'weekTemplate'">
                <span><strong>{{ data.value }}</strong></span>
            </div>
            <div *dxTemplate="let data of 'packageIdGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'levelGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'percentToAdvanceTemplate'">
                <span *ngIf="data.value">{{ data.value | number:'1.2-2' }}</span>
                <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                porcentaje</span>
            </div>
            <div *dxTemplate="let data of 'quantityToAdvanceTemplate'">
                <span *ngIf="data.value">{{ data.value }}</span>
                <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                cantidad</span>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'assignmentGroupTemplate'">ASG-{{ data.value }}
            </div>


            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalAdvances }}</h2>
                    <span class="name">Total Avances</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
</app-page-widget>
<app-dialog #evidenceDialog noPadding width="650px" [autoFocus]="false">
    <app-title>Evidencias</app-title>

    <mat-nav-list *ngIf="evidences?.length">
        <a *ngFor="let ev of evidences; trackBy: trackEvidence" mat-list-item target="_blank" [href]="ev.downloadUrl">
            <mat-icon matListIcon>cloud_download</mat-icon>
            {{ ev.name }}
        </a>
    </mat-nav-list>

</app-dialog>