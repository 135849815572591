<app-page-widget>
    <app-title>Nueva Asignación</app-title>
    <div class="stepper">
        <mat-horizontal-stepper [linear]="true" (selectionChange)="toNextStep($event)">
            <mat-step label="{{ steps[0].label }}" [stepControl]="stepOneFormGroup">
                <form [formGroup]="stepOneFormGroup">
                    <div>
                        <button mat-raised-button color="primary" matStepperNext>
              <mat-icon>navigate_next</mat-icon>
              Siguiente
            </button>
                        <button mat-raised-button color="primary" [disabled]="!preview" (click)="openPreviewDialog()" class="margin-left-12">
              <mat-icon>visibility</mat-icon>
              Vista Previa
            </button>
                    </div>
                    <div class="spinner-wrapper" *ngIf="stepLoading">
                        <mat-spinner [diameter]="60"></mat-spinner>
                    </div>
                    <div class="step-one" *ngIf="!stepLoading">
                        <app-grid-packages-selector #packagesGrid [formControlName]="'selectPackagesCtrl'" required [dataSource]="packages" [(ngModel)]="selectedPackages" [selectedRows]="selectedPackages" (change)="changedSelectedPackages($event)" [requiredMessage]="'No hay paquetes seleccionados *'">
                        </app-grid-packages-selector>
                    </div>
                </form>
            </mat-step>
            <mat-step label="{{ steps[1].label }}" [stepControl]="stepTwoFormGroup">
                <form [formGroup]="stepTwoFormGroup">
                    <div>
                        <button mat-raised-button color="primary" matStepperPrevious>
              <mat-icon>navigate_before</mat-icon>
              Anterior
            </button>
                        <button mat-raised-button color="primary" [disabled]="!preview" class="margin-left-12">
              <mat-icon>visibility</mat-icon>
              Vista Previa
            </button>
                        <button mat-raised-button color="primary" [disabled]="!create" (click)="createAssignment()" class="margin-left-12">
              <mat-icon>done</mat-icon>
              Crear Asignación
            </button>
                    </div>
                    <div class="spinner-wrapper" *ngIf="stepLoading">
                        <mat-spinner [diameter]="60"></mat-spinner>
                    </div>
                    <div class="step-two card" *ngIf="!stepLoading">
                        <div class="mt-10">Seleccione el proveedor</div>
                        <div class="mt-10">
                            <app-select-box [widthSelect]="900" [dataSource]="suppliers" displayExpr="name" (selectItem)="changedSelectedSupplier($event)" placeholder="Buscar Proveedor">
                            </app-select-box>
                        </div>
                        <div class="w-100 d-flex">
                            <div class="w-33">
                                <div class="mt-10">Seleccione el tipo de paquetes</div>
                                <div class="mt-10">
                                    <app-select-box [widthSelect]="250" [dataSource]="typePackages" displayExpr="TypeText" (selectItem)="changedSelectedTypes($event)" placeholder="Tipo de paquetes">
                                    </app-select-box>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-30">

                        <div class="w-100 d-flex">
                            <div class="w-60">

                                <div class="w-100 d-flex">
                                    <div class="w-33">
                                        <div class="mt-30">Introduzca el número de contrato</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="nroContractFormControl" placeholder="Número de contrato" type="text">
                                        </mat-form-field>
                                    </div>
                                    <div class="w-33">
                                        <div class="mt-30">Fianza</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="bailFormControl" placeholder="Fianza" type="text">
                                        </mat-form-field>
                                    </div>
                                    <div class="w-33">
                                        <div class="mt-30">Porcentaje de fondo de garantía</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="guarantyFundFormControl" placeholder="Fondo de garantía" type="number">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="w-100 d-flex">
                                    <div class="w-33">
                                        <div class="mt-30">Alcance</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="departureFormControl" placeholder="Alcance" type="text">
                                        </mat-form-field>
                                    </div>
                                    <div class="w-33">
                                        <div class="mt-30">WBS</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="pepFormControl" placeholder="WBS" type="text">
                                        </mat-form-field>
                                    </div>
                                    <div class="w-33">
                                        <div class="mt-30">Ingrese fecha de inicio y terminación</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <mat-label>Inicio / Fin</mat-label>
                                            <mat-date-range-input [rangePicker]="picker">
                                                <input matStartDate [formControl]="startFormControl" placeholder="Inicio">
                                                <input matEndDate [formControl]="endFormControl" placeholder="Terminación">
                                            </mat-date-range-input>
                                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-date-range-picker #picker></mat-date-range-picker>

                                            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="w-100 d-flex">
                                    <div class="w-33">
                                        <div class="mt-30">IVA (+)</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="ivaFormControl" placeholder="IVA" type="number">
                                        </mat-form-field>
                                    </div>

                                    <div class="w-33">
                                        <div class="mt-30">IVA Complementario (-)</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="ivaComplFormControl" placeholder="IVA Complementario" type="number">
                                        </mat-form-field>
                                    </div>

                                    <div class="w-33">
                                        <div class="mt-30">ISR</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="isrFormControl" placeholder="ISR" type="number">
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="w-100 d-flex">
                                    <div class="w-33">
                                        <div class="mt-30">Penalización</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="penalizacionFormControl" placeholder="Penalización" type="number">
                                        </mat-form-field>
                                    </div>
                                    <div class="w-33">
                                        <div class="mt-30">Tipo de contrato</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="typeContractFormControl" placeholder="Tipo de contrato" type="text">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="w-40">
                                <div class="w-100 d-flex">
                                    <div class="w-33">
                                        <div class="mt-10">Seleccione el tipo de documento</div>
                                        <div class="mt-10">
                                            <app-select-box [widthSelect]="250" [dataSource]="typeDocument" displayExpr="TypeText" (selectItem)="changedSelectedTypeDocument($event)" placeholder="Tipo de documento">
                                            </app-select-box>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 d-flex" *ngIf="!contractUploaded && typeDocumentSelected">
                                    <div class="w-60">
                                        <div class="mt-10">Ingrese fecha de inicio y terminación</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <mat-label>Inicio / Fin</mat-label>
                                            <mat-date-range-input [rangePicker]="pickerDocument">
                                                <input matStartDate [formControl]="startDocumentFormControl" placeholder="Inicio">
                                                <input matEndDate [formControl]="endDocumentFormControl" placeholder="Terminación">
                                            </mat-date-range-input>
                                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matSuffix [for]="pickerDocument"></mat-datepicker-toggle>
                                            <mat-date-range-picker #pickerDocument></mat-date-range-picker>

                                            <mat-error *ngIf="rangeDocument.controls.startDocument.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                            <mat-error *ngIf="rangeDocument.controls.endDocument.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="w-40">
                                        <div class="mt-10">Porcentaje</div>
                                        <mat-form-field class="w-90 mt-10" appearance="fill">
                                            <input matInput [formControl]="percentageFormControl" placeholder="Porcentaje" type="number">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="mt-30" *ngIf="!contractUploaded && typeDocumentSelected">Por favor cargar el contrato</div>
                                <app-files-uploader *ngIf="!contractUploaded && typeDocumentSelected" class="mt-10" #filesUploader bucket="assignments-contract" (uploadFinished)="onContractUpload($event)">
                                </app-files-uploader>
                                <div class="mt-10 text-center d-flex justify-content-center" *ngIf="contractUploaded">
                                    <label class="text-center card bg-bist">
                                        <mat-icon>done</mat-icon>
                                        <h4 class="mb-0 color-white">Documento cargado</h4>
                                    </label>
                                </div>
                                <div class="w-100 mt-10">
                                    <table>
                                        <tr>
                                            <td style='border: 1px solid'>
                                                <strong>Contrato ordinario</strong>
                                            </td>
                                            <td style='border: 1px solid'>
                                                <ng-container *ngFor="let item of contract">
                                                    <a class="ml-5" target="_blank" [href]="item.downloadUrl">
                                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                                    </a>
                                                    <a class="ml-5" (click)="deleteDocument(item)">
                                                        <mat-icon matListIcon color="warn">delete</mat-icon>
                                                    </a>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style='border: 1px solid'>
                                                <strong>Convenio modificatorio</strong>
                                            </td>
                                            <td style='border: 1px solid'>
                                                <ng-container *ngFor="let item of documents">
                                                    <a *ngIf="item.type === 'Convenio modificatorio'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                                    </a>
                                                    <a *ngIf="item.type === 'Convenio modificatorio'" class="ml-5" (click)="deleteDocument(item)">
                                                        <mat-icon matListIcon color="warn">delete</mat-icon>
                                                    </a>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style='border: 1px solid'>
                                                <strong>Fianza cumplimiento</strong>
                                            </td>
                                            <td style='border: 1px solid'>
                                                <ng-container *ngFor="let item of documents">
                                                    <a *ngIf="item.type === 'Fianza cumplimiento'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                                    </a>
                                                    <a *ngIf="item.type === 'Fianza cumplimiento'" class="ml-5" (click)="deleteDocument(item)">
                                                        <mat-icon matListIcon color="warn">delete</mat-icon>
                                                    </a>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style='border: 1px solid'>
                                                <strong>Fianza de garantìa y vicios ocultos</strong>
                                            </td>
                                            <td style='border: 1px solid'>
                                                <ng-container *ngFor="let item of documents">
                                                    <a *ngIf="item.type === 'Fianza de garantìa y vicios ocultos'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                                    </a>
                                                    <a *ngIf="item.type === 'Fianza de garantìa y vicios ocultos'" class="ml-5" (click)="deleteDocument(item)">
                                                        <mat-icon matListIcon color="warn">delete</mat-icon>
                                                    </a>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style='border: 1px solid'>
                                                <strong>Fianza anticipo</strong>
                                            </td>
                                            <td style='border: 1px solid'>
                                                <ng-container *ngFor="let item of documents">
                                                    <a *ngIf="item.type === 'Fianza anticipo'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                                    </a>
                                                    <a *ngIf="item.type === 'Fianza anticipo'" class="ml-5" (click)="deleteDocument(item)">
                                                        <mat-icon matListIcon color="warn">delete</mat-icon>
                                                    </a>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <hr class="mt-30">
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</app-page-widget>

<app-preview #previewDialog [packages]="selectedPackages" [subTitle]="'Paquetes seleccionados para ésta Asignación'" [gridType]="'package'" (previewItems)="packagesSelectionChangedByPreview($event)">
</app-preview>