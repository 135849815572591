<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Prestamos listado</app-title>
    <app-subtitle>Prestamos list</app-subtitle>
    <app-action-button icon="playlist_add" (click)="newLoan()">Crear</app-action-button>
    <app-menu-item icon="playlist_add" (click)="newLoan()">Crear</app-menu-item>
    <!-- <button class="btn" mat-raised-button color="primary" [disabled]="!Rows?.length"
    (click)="seeDetail()">
    Ver detalle de prestamo
  </button> -->
    <!-- ============================================================== -->
    <!-- GRID loans                                                     -->
    <!-- ============================================================== -->
    <dx-data-grid #loanGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="loans" [masterDetail]="{ enabled: false, template:
        'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no éxisten prestamos." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false"
        [allowColumnResizing]="false" [(selectedRowKeys)]="Rows" (onExporting)="onExporting($event)" (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">
        <dxo-paging [pageSize]="15" [pageIndex]="_loansService.pageIndex"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
        </dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>

        <dxo-export [enabled]="true" fileName="lista-prestamos"></dxo-export>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
        <dxi-column caption="ID" dataField="id" [groupIndex]="0" width="6%" cellTemplate="idTemplate" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Fecha" dataField="createdAt" width="15%" cellTemplate="dateTemplate" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" dataType="date" [allowSorting]="true" [allowFiltering]="true" sortOrder="desc" alignment="left"></dxi-column>
        <dxi-column caption="Proveedor" dataField="supplierName" cellTemplate="supplierTemplate" alignment="center" width="25%" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
        <dxi-column caption="Monto" dataField="loanAmount" cellTemplate="loanAmountTemplate" width="10%" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Solicitado por" dataField="createdBy" width="20%" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
        </dxi-column>
        <dxi-column caption="Status" dataField="status" cellTemplate="estimateStatusTemplate" width="10%" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Comentarios" dataField="loanComments" width="10%" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
        <dxi-column caption="Detalle" dataField="uuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span><i class="material-icons icon-gray"
        (click)="onRowClick(data)">
        remove_red_eye
      </i></span>
        </div>
        <!-- <dxi-column caption="Tipo" dataField="type" cellTemplate="typeTemplate" width="10%" alignment="center" [allowEditing]="false"></dxi-column> -->
        <div *dxTemplate="let data of 'idTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'loanAmountTemplate'">
            <strong>$ {{ data.value | number }}</strong>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <strong>{{ data.value | userData | async }}</strong>
        </div>
        <div *dxTemplate="let data of 'supplierTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'estimateStatusTemplate'">
            <strong>{{ data.value | estimateStatus }}</strong>
        </div>
        <div *dxTemplate="let data of 'typeTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{ totalCount }}</h2>
                <span class="name">Total Préstamos</span>
            </div>
        </div>
    </dx-data-grid>
    <!-- ============================================================== -->
    <!-- END GRID loans                                                 -->
    <!-- ============================================================== -->
</app-page-widget>