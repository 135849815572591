<app-dialog #appDialog noPadding [width]="'90%'" (dismiss)="onClosePreviewDialog()">
  <app-title>{{ title }}</app-title>
  <app-subtitle>{{ subTitle }}</app-subtitle>

  <div class="content-cc height-600 padding">
    <div class="content-block">
      <h3 class="content-title">Presupuesto</h3>

      <mat-table #budgetTable [dataSource]="budgetTableDataSource">
        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef> Código </mat-header-cell>
          <mat-cell *matCellDef="let attribute"> {{ attribute.code }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="concept">
          <mat-header-cell *matHeaderCellDef> Concepto </mat-header-cell>
          <mat-cell *matCellDef="let attribute"> {{ attribute.concept }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unit">
          <mat-header-cell *matHeaderCellDef> Unidad </mat-header-cell>
          <mat-cell *matCellDef="let attribute"> {{ attribute.unit }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef> Cantidad </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            {{ attribute.quantity | number: '1.2-3' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cost">
          <mat-header-cell *matHeaderCellDef>
            <ng-template [ngIf]="!packageIsDerivative">
              Precio Unitario
            </ng-template>

            <ng-template [ngIf]="packageIsDerivative">
              Presupuesto Actual Disponible
            </ng-template>
          </mat-header-cell>
          <mat-cell *matCellDef="let attribute"> ${{ attribute.cost | number: '1.2-2' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total">
          <mat-header-cell *matHeaderCellDef>
            <ng-template [ngIf]="!packageIsDerivative">
              Total
            </ng-template>

            <ng-template [ngIf]="packageIsDerivative">
              Presupuesto Inicial
            </ng-template>
          </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative">
              ${{ attribute.total | number: '1.2-2' }}
            </ng-template>

            <ng-template [ngIf]="packageIsDerivative">
              ${{ initialBudget | number: '1.2-2' }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="budgetTableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: budgetTableColumns"></mat-row>
      </mat-table>
    </div>

    <div class="content-block" *ngIf="!thereIsData">
      <h4>No existen Ordenes de Compra para este Paquete!</h4>
      <div class="no-data-message">
        <p>
          Para generar una proyección deben existir en Remesas alguna Orden de Compra autorizada que
          contenga este Paquete.
        </p>
      </div>
    </div>

    <div class="content-block" *ngIf="thereIsData">
      <h3 class="content-title">Proyección</h3>

      <mat-table #projectionTable [dataSource]="projectionTableDataSource">
        <ng-container matColumnDef="weightedAvgPrice">
          <mat-header-cell *matHeaderCellDef> Precio Promedio Ponderado </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              ${{ attribute.weightedAvgPrice | number: '1.2-2' }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="purchasedQuantity">
          <mat-header-cell *matHeaderCellDef> Cantidad Comprada </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              {{ attribute.purchasedQuantity | number: '1.2-3' }}
            </ng-template>
          </mat-cell>
        </ng-container>

<!--        <ng-container matColumnDef="wasteQuantity">-->
<!--          <mat-header-cell *matHeaderCellDef> Cantidad en Merma </mat-header-cell>-->
<!--          <mat-cell *matCellDef="let attribute">-->
<!--            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">-->
<!--              {{ attribute.wasteQuantity | number: '1.2-3' }}-->
<!--            </ng-template>-->
<!--          </mat-cell>-->
<!--        </ng-container>-->

<!--        <ng-container matColumnDef="stockQuantity">-->
<!--          <mat-header-cell *matHeaderCellDef> Cantidad en Stock </mat-header-cell>-->
<!--          <mat-cell *matCellDef="let attribute">-->
<!--            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">-->
<!--              {{ attribute.stockQuantity | number: '1.2-3' }}-->
<!--            </ng-template>-->
<!--          </mat-cell>-->
<!--        </ng-container>-->

        <ng-container matColumnDef="purchasedAmount">
          <mat-header-cell *matHeaderCellDef> Monto Comprado </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            ${{ attribute.purchasedAmount | number: '1.2-2' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="remainingQtyToBuy">
          <mat-header-cell *matHeaderCellDef> Cantidad por Comprar </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              {{ attribute.remainingQtyToBuy | number: '1.2-3' }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="remainingAmountToBuy">
          <mat-header-cell *matHeaderCellDef> Monto por Comprar </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            ${{ attribute.remainingAmountToBuy | number: '1.2-2' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastPrice">
          <mat-header-cell *matHeaderCellDef> Último Precio </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            ${{ attribute.lastPrice | number: '1.2-2' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalAmount">
          <mat-header-cell *matHeaderCellDef> Total </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              ${{ attribute.totalAmount | number: '1.2-2' }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="predictedBalance">
          <mat-header-cell *matHeaderCellDef> Balance Pronosticado </mat-header-cell>
          <mat-cell
            *matCellDef="let attribute"
            [ngStyle]="{
              color: !packageIsDerivative ? (attribute.predictedBalance > 0 ? 'red' : 'green') : ''
            }"
          >
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              ${{ attribute.predictedBalance | number: '1.2-2' }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="projectionTableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: projectionTableColumns"></mat-row>
      </mat-table>
    </div>

    <div class="content-block" *ngIf="thereIsData">
      <h3 class="content-title">Órdenes de Compra</h3>

      <mat-table #OCTable [dataSource]="purchaseOrdersTableDataSource">
        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef>Fecha</mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            {{ attribute?.createdAt | timestampToDate: 'long' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>OC</mat-header-cell>
          <mat-cell
            *matCellDef="let attribute"
            [ngStyle]="{
              'background-color': attribute.purchasedPackagesSummary.simulatePurchaseOrder
                ? '#a4bbd4'
                : 'white'
            }"
          >
            {{ attribute?.id }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="purchasedPacgesSummary">
          <mat-header-cell *matHeaderCellDef>Cantidad</mat-header-cell>
          <mat-cell
            *matCellDef="let attribute"
            [ngStyle]="{
              'background-color': attribute.purchasedPackagesSummary.simulatePurchaseOrder
                ? '#a4bbd4'
                : 'white'
            }"
          >
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              <!--{{ attribute.purchasedPackagesSummary?.supplierQuantity | number:3 }}-->
              {{ attribute.purchasedPackagesSummary?.requiredQuantity | number: '1.2-3' }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cost">
          <mat-header-cell *matHeaderCellDef>Precio Unitario</mat-header-cell>
          <mat-cell
            *matCellDef="let attribute"
            [ngStyle]="{
              'background-color': attribute.purchasedPackagesSummary.simulatePurchaseOrder
                ? '#a4bbd4'
                : 'white'
            }"
          >
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              ${{ attribute?.purchasedPackagesSummary?.supplierCost | number: '1.2-2' }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total">
          <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
          <mat-cell
            *matCellDef="let attribute"
            [ngStyle]="{
              'background-color': attribute.purchasedPackagesSummary.simulatePurchaseOrder
                ? '#a4bbd4'
                : 'white'
            }"
          >
            ${{ attribute?.purchasedPackagesSummary?.supplierTotalCost | number: '1.2-2' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="costDiff">
          <mat-header-cell *matHeaderCellDef>Diferencia PU</mat-header-cell>
          <mat-cell
            *matCellDef="let attribute"
            [ngStyle]="{
              'background-color': attribute.purchasedPackagesSummary.simulatePurchaseOrder
                ? '#a4bbd4'
                : 'white'
            }"
          >
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              <span
                [ngStyle]="{
                  color: attribute.purchasedPackagesSummary.costDiff > 0 ? 'red' : 'green'
                }"
                >${{ attribute?.purchasedPackagesSummary.costDiff | number: '1.2-2' }}</span
              >
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalDiff">
          <mat-header-cell *matHeaderCellDef>Diferencia Total</mat-header-cell>
          <mat-cell
            *matCellDef="let attribute"
            [ngStyle]="{
              'background-color': attribute.purchasedPackagesSummary.simulatePurchaseOrder
                ? '#a4bbd4'
                : 'white'
            }"
          >
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              <span
                [ngStyle]="{
                  color: attribute.purchasedPackagesSummary.totalDiff > 0 ? 'red' : 'green'
                }"
                >${{ attribute?.purchasedPackagesSummary?.totalDiff | number: '1.2-2' }}</span
              >
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="purchaseOrdersTableColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: purchaseOrdersTableColumns"
          [ngClass]="{ 'row-purchase': true }"
        ></mat-row>
      </mat-table>

      <mat-table #totalsOCTable [dataSource]="totalsPurchaseOrdersTableDataSource">
        <ng-container matColumnDef="emptyCell1">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let attribute"> {{ attribute?.emptyCell1 }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="emptyCell2">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let attribute"> {{ attribute?.emptyCell2 }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef>Total Cantidad Comprada</mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              <strong>{{ attribute?.quantity | number: '1.2-3' }}</strong>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="weightedAvgPrice">
          <mat-header-cell *matHeaderCellDef>Precio Promedio Ponderado</mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              <strong>${{ attribute?.weightedAvgPrice | number: '1.2-2' }}</strong>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalPurchasedAmount">
          <mat-header-cell *matHeaderCellDef>Total Comprado</mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <strong>${{ attribute?.totalPurchasedAmount | number: '1.2-2' }}</strong>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="avgCostDiff">
          <mat-header-cell *matHeaderCellDef>Diferencia Promedio PU</mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              <strong [ngStyle]="{ color: attribute.avgCostDiff > 0 ? 'red' : 'green' }">
                ${{ attribute?.avgCostDiff | number: '1.2-2' }}
              </strong>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalOverCost">
          <mat-header-cell *matHeaderCellDef>Total Ahorro/Sobrecosto</mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApply">
              <strong [ngStyle]="{ color: attribute.totalOverCost > 0 ? 'red' : 'green' }">
                ${{ attribute?.totalOverCost | number: '1.2-2' }}
              </strong>
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="totalsPurchaseOrdersTableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: totalsPurchaseOrdersTableColumns"></mat-row>
      </mat-table>
    </div>
  </div>

  <ng-template #notApply>
    NA
  </ng-template>
</app-dialog>
