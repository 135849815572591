<app-page-widget noPadding [menuItems]="widgetMenu" (refresh)="refreshData()">
    <app-title>REQ-{{ requisition?.nroRequisition }}</app-title>
    <app-subtitle>{{ requisition?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <!--
    <app-action-button flat noText icon="filter_list" (click)="displayFilters = !displayFilters">Filtros</app-action-button>
  -->
    <app-action-button flat noText icon="done_all" (click)="viewApprovers()">Aprobaciones
    </app-action-button>

    <div class="actions-block">
        <a mat-raised-button class="action-button mr-5" (click)="goToPreviousUlr()" color="warn">
            <mat-icon>undo</mat-icon>
            Regresar
        </a>
        <ng-template [ngIf]="sendToApproveIsAvailable">
            <button *ngxPermissionsOnly="['requisitions:can_send_to_approve']" mat-raised-button class="action-button" (click)="sendToApprove()" color="primary">
        <mat-icon>call_made</mat-icon>
        Enviar a Aprobar
      </button>
        </ng-template>
        <ng-template [ngIf]="approveIsAvailable">
            <button *ngxPermissionsOnly="['requisitions:can_approve']" mat-raised-button class="action-button" (click)="approve()" color="primary">
        <mat-icon>done</mat-icon>
        Aprobar
      </button>
        </ng-template>
    </div>

    <br />

    <div class="header-block">
        <span class="total-label">
      Total: <strong>${{ requisitionTotal | number: '1.2-2' }}</strong>
    </span>

        <span class="info-label">Estado: <strong>{{ requisition?.status |
        requisitionStatus }}</strong>
    </span>
        <span class="info-label">Solicitado por:
      <app-user-data *ngIf="requisition" [id]="requisition.createdBy"></app-user-data>
    </span>
    </div>

    <div class="content-block">
        <h3 class="content-title">Paquetes Solicitados</h3>
        <dx-data-grid #dataGrid id="grid-container" [dataSource]="packages" [showColumnLines]="false" [masterDetail]="{ enabled: true, template: 'rowDetail' }" [wordWrapEnabled]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false"
            (onRowUpdated)="onRowUpdated($event)">
            <!-- (onRowRemoved)="deleteProduct($event)" -->

            <!-- <dxo-filter-row [visible]="displayFilters"></dxo-filter-row> -->
            <dxo-search-panel [visible]="displayFilters" placeholder="Buscar..."></dxo-search-panel>
            <!--
        <dxo-editing *ngIf="packagesEditonAllowed" mode="cell" [allowUpdating]="true"></dxo-editing>
      -->
            <dxo-editing *ngIf="packagesEditonIsAllowed" mode="form" [allowUpdating]="true"></dxo-editing>
            <dxo-selection selectAllMode="page" mode="single"></dxo-selection>
            <dxi-column caption="ID Paquete" dataField="packageId" [allowEditing]="false" width="10%">
            </dxi-column>
            <dxi-column caption="Código" dataField="code" [allowEditing]="false" width="10%"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" [allowEditing]="false" [groupIndex]="1" width="50%"></dxi-column>
            <!-- <dxi-column caption="Concepto" dataField="concept" [allowEditing]="false" width="20%">
      </dxi-column> -->
            <dxi-column caption="Unidad" dataField="unit" [allowEditing]="false" alignment="center" width="7%" cellTemplate="unitTemplate"></dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" [allowEditing]="false" dataType="number" alignment="left" width="10%"></dxi-column>
            <dxi-column caption="Costo" dataField="cost" [showWhenGrouped]="false" [allowGrouping]="false" cellTemplate="costTemplate" alignment="left" width="10%" [allowEditing]="false">
            </dxi-column>
            <dxi-column caption="Total" [calculateCellValue]="calculateRowTotal" cellTemplate="totalTemplate" [allowEditing]="false" alignment="left" width="10%">
            </dxi-column>
            <dxi-column caption="Ficha" dataField="dataSheetLink" cellTemplate="technicalDataSheetTemplate" alignment="center" width="5%" [allowEditing]="false"></dxi-column>
            <dxi-column caption="Marca" width="10%" cellTemplate="brandTemplate" [allowEditing]="true" dataField="requiredBrand" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Modelo" width="10%" cellTemplate="modelTemplate" [allowEditing]="true" dataField="requiredModel" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Notas" width="10%" cellTemplate="notesTemplate" [allowEditing]="true" dataField="notes" [allowSorting]="false" alignment="center">
            </dxi-column>

            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item>
                <dxi-group-item column="dataSheetLink" summaryType="sum" displayFormat="{0}" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>

            <div *dxTemplate="let pu of 'UnitPrice'">
                <span *ngIf="pu?.value">${{ pu.value | number }}</span>
            </div>
            <div *dxTemplate="let total of 'Totals'">
                <span *ngIf="total?.value">${{ total.value | number }}</span>
            </div>
            <div *dxTemplate="let qty of 'Quantities'">
                <span *ngIf="qty?.value">{{ qty.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'brandTemplate'">
                <span *ngIf="data.value">{{ data.value }}</span> <span *ngIf="!data.value">-</span>
            </div>
            <div *dxTemplate="let data of 'modelTemplate'">
                <span *ngIf="data.value">{{ data.value }}</span> <span *ngIf="!data.value">-</span>
            </div>
            <div *dxTemplate="let data of 'notesTemplate'">
                <span *ngIf="data.value">{{ data.value }}</span> <span *ngIf="!data.value">-</span>
            </div>
            <div *dxTemplate="let data of 'totalTemplate'">
                <strong>${{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'quantityTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'costTemplate'">
                <strong>${{ data.value | number }}</strong>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'technicalDataSheetTemplate'">
                <a *ngIf="getDataSheetUploadIcon()" mat-icon-button [disabled]="!getDataSheetUploadIcon()" (click)="dataSheetDialog(data.key.uuid, data.key.referenceId, data)">
                    <mat-icon>note_add</mat-icon>
                </a>
                <a *ngIf="getDataSheetPreviewIcon(data)" mat-icon-button [disabled]="!getDataSheetPreviewIcon(data)" (click)="openDataSheetPreview(data)">
                    <mat-icon>visibility</mat-icon>
                </a>
            </div>

            <div *dxTemplate="let pck of 'rowDetail'">
                <ng-template [ngIf]="pck.data && pck.data.leftoverMatched" [ngIfElse]="noLeftoverDetail">
                    <span class="span-title margin-bottom">Sobrante aplicado</span>
                    <table class="leftover-detail-table">
                        <thead>
                            <tr>
                                <th>Concepto</th>
                                <th>Cantidad Original</th>
                                <th>Cantidad Aplicada</th>
                                <th>Cantidad Resultante</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let leftover of getLeftoverFromPackage(pck)">
                                <td>{{ leftover.concept }}</td>
                                <td>{{ pck.data.quantityBeforeLeftover | number: '1.2-2' }}</td>
                                <td>{{ leftover.quantity | number: '1.3-3' }}</td>
                                <td>{{ pck.data.quantity | number: '1.3-3' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-template>

                <ng-template #noLeftoverDetail>
                    <span class="span-title">Paquete sin sobrante asociado</span>
                </ng-template>
            </div>
        </dx-data-grid>
    </div>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>