<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Nueva Estimación Contratos tasa 0%</app-title>
    <app-subtitle>Seleccione proveedor y conceptos para la estimación</app-subtitle>

    <div class="header my-15" fxLayout="row">
        <app-select-box class="ml-15" [widthSelect]="480" [dataSource]="suppliers" displayExpr="name" (selectItem)="onSelectBoxSelection($event)" placeholder="selecionar
                  proveedor">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="140" [dataSource]="assignmentsList" displayExpr="id" (selectItem)="onSelectAssignment($event)" placeholder="Asignaciones
                      ">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="260" [dataSource]="contractsList" displayExpr="nroContract" (selectItem)="onSelectAssignmentContract($event)" placeholder="
                    Contratos">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="150" [dataSource]="assignmentsList" displayExpr="totalAssignmentFormat" (selectItem)="onSelectAssignment($event)" placeholder="
                    Montos">
        </app-select-box>
        <div class="d-flex ml-15">
            <div>
                <!-- <h3 class="content-title">Estimación del proveedor por semana</h3> -->
                <app-select-box [widthSelect]="240" [dataSource]="supplierEstimates" displayExpr="weekOfYear" (selectItem)="onSelectEstimate($event)" placeholder="Estimaciones del proveedor">
                </app-select-box>
            </div>
            <button class="action-button-show mt-0" mat-raised-button id="btn" [disabled]="!estimateUuid" color="primary" (click)="goToEstimate()">
            Ver Estimación
          </button>
        </div>
    </div>
    <div class="content-block d-flex my-0">
        <!-- <div class="supplierSelector">
            <div>
                <h3 class="content-title">Proveedor</h3>
                <app-select-box class="" [widthSelect]="550" [dataSource]="suppliers" displayExpr="name" (selectItem)="onSelectBoxSelection($event)" placeholder="selecionar
                    proveedor">
                </app-select-box>
            </div>
        </div>
        <div class="supplierSelector">
            <div>
                <h3 class="content-title">Asignaciones</h3>
                <app-select-box [widthSelect]="200" [dataSource]="assignmentsList" displayExpr="id" (selectItem)="onSelectAssignment($event)" placeholder="Selecionar
                        asignación">
                </app-select-box>
            </div>
        </div>
        <div class="supplierSelector">
            <div>
                <h3 class="content-title">Contratos</h3>
                <app-select-box [widthSelect]="200" [dataSource]="contractsList" displayExpr="nroContract" (selectItem)="onSelectAssignmentContract($event)" placeholder="Selecionar
                        asignación">
                </app-select-box>
            </div>
        </div>
        <div class="supplierSelector">
            <div>
                <h3 class="content-title">Montos</h3>
                <app-select-box [widthSelect]="200" [dataSource]="assignmentsList" displayExpr="totalAssignmentFormat" (selectItem)="onSelectAssignment($event)" placeholder="Selecionar
                        asignación">
                </app-select-box>
            </div>
        </div>
        <div class="supplierSelector d-flex">
            <div>
                <h3 class="content-title">Estimación del proveedor por semana</h3>
                <app-select-box [widthSelect]="300" [dataSource]="supplierEstimates" displayExpr="weekOfYear" (selectItem)="onSelectEstimate($event)" placeholder="Selecionar
                      semana">
                </app-select-box>
            </div>
            <button class="action-button-show" mat-raised-button id="btn" [disabled]="!estimateUuid" color="primary" (click)="goToEstimate()">
              Ver Estimación
          </button> -->
        <!-- NO BORRAR <button class="action-button-show" mat-raised-button id="btn" color="primary" (click)="calAmountToPayDB()">
              Calcular Amount To Pay
          </button> -->
        <!-- </div> -->
        <div class="supplierSelector" *ngIf="documentsEstimateProvider.length > 0">
            <table class="p-10 text-center w-100">
                <tr class="text-center">
                    <td style='border: 1px solid'>
                        <strong>Documentos</strong>
                    </td>
                </tr>
                <tr>
                    <td style='border: 1px solid'>
                        <ng-container *ngFor="let item of documentsEstimateProvider">
                            <a class="ml-5" target="_blank" [href]="item.downloadUrl">
                                <mat-icon matListIcon>cloud_download</mat-icon>
                            </a>
                        </ng-container>
                    </td>
                </tr>
            </table>
        </div>
        <!-- <div class="supplierSelector">
            <div>
                <h3 class="content-title">Estimación del proveedor por semana</h3>
                <app-select-box [widthSelect]="300" [dataSource]="supplierEstimates" displayExpr="weekOfYear" (selectItem)="onSelectEstimate($event)" placeholder="Selecionar
                  semana">
                </app-select-box>
            </div>
        </div>
        <div class="supplierSelector">
            <button class="action-button-show-estimate" mat-raised-button id="btn" [disabled]="!estimateUuid" color="primary" (click)="goToEstimate()">
          Ver Estimación
        </button>
        </div> -->
    </div>

    <div class="content-block d-flex my-0">

        <div class="supplierSelector">
            <div>
                <h3 class="content-title">Número de Estimación (Opcional)</h3>
                <mat-form-field class="w-90 mt-10" appearance="fill">
                    <input matInput [formControl]="nroEstimateFormControl" placeholder="Número de estimación" type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="supplierSelector">
            <div>
                <div class="content-block-section d-flex" *ngIf="assignmentSelected">
                    <!-- <h3 class="content-title">Documentos</h3> -->
                    <table class="p-10 text-center w-100">
                        <tr class="text-center">
                            <td style='border: 1px solid'>
                                <strong>Contrato ordinario</strong>
                            </td>
                            <td style='border: 1px solid'>
                                <strong>Convenio modificatorio</strong>
                            </td>
                            <td style='border: 1px solid'>
                                <strong>Fianza cumplimiento</strong>
                            </td>
                            <td style='border: 1px solid'>
                                <strong>Fianza de garantìa y vicios ocultos</strong>
                            </td>
                            <td style='border: 1px solid'>
                                <strong>Fianza anticipo</strong>
                            </td>

                        </tr>
                        <tr>
                            <td style='border: 1px solid'>
                                <ng-container *ngFor="let item of assignmentSelected.contract">
                                    <a class="ml-5" target="_blank" [href]="item.downloadUrl">
                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                    </a>
                                </ng-container>
                            </td>
                            <td style='border: 1px solid' *ngIf="assignmentSelected.documents">
                                <ng-container *ngFor="let item of assignmentSelected.documents">
                                    <a *ngIf="item.type === 'Convenio modificatorio'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                    </a>
                                </ng-container>
                            </td>
                            <td style='border: 1px solid' *ngIf="assignmentSelected.documents">
                                <ng-container *ngFor="let item of assignmentSelected.documents">
                                    <a *ngIf="item.type === 'Fianza cumplimiento'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                    </a>
                                </ng-container>
                            </td>
                            <td style='border: 1px solid' *ngIf="assignmentSelected.documents">
                                <ng-container *ngFor="let item of assignmentSelected.documents">
                                    <a *ngIf="item.type === 'Fianza de garantìa y vicios ocultos'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                    </a>
                                </ng-container>
                            </td>
                            <td style='border: 1px solid' *ngIf="assignmentSelected.documents">
                                <ng-container *ngFor="let item of assignmentSelected.documents">
                                    <a *ngIf="item.type === 'Fianza anticipo'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                        <mat-icon matListIcon>cloud_download</mat-icon>
                                    </a>
                                </ng-container>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="content-block d-flex mt-0">
        <button class="action-button ml-5" mat-raised-button id="btn" [disabled]="isCreateButtonDisabled" color="primary" (click)="openPreviewDialog()">
        Generar Estimación
      </button>
        <!-- <button mat-raised-button color="primary" [disabled]="!preview" (click)="openPreviewDialog()" class="margin-left-12 action-button">
        <mat-icon>visibility</mat-icon>
        Vista Previa
      </button>
        <button class="action-button ml-5" mat-raised-button id="btn" [disabled]="isCreateButtonDisabled" color="primary" (click)="generateEstimation()">
        Generar Estimación
      </button> -->
    </div>

    <div class="content-block">
        <h3 class="content-title">Paquetes y Conceptos</h3>
    </div>
    <dx-data-grid #assignmentGrid id="grid-container" [dataSource]="asignmentWithPacks" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no existen registros." [showBorders]="true"
        [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" (onSelectionChanged)="onChangedRowsSelection($event)">
        <ng-container *ngIf="viewTable">

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="false"></dxo-search-panel>
            <dxo-grouping #expand [allowCollapsing]="false" [autoExpandAll]="true"></dxo-grouping>
            <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false">
            </dxo-selection>
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxi-column caption="ID" dataField="asignmentSmallId" groupCellTemplate="idGroupTemplate" [groupIndex]="0" width="5%" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"></dxi-column>
            <dxi-column caption="Semana ultima estimación" dataField="weekLastEstimation" cellTemplate="weekTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Semana a Capturar" [allowEditing]="true" cellTemplate="weekTemplate" dataField="weekRequest" [allowSorting]="true" [allowSearch]="true" alignment="center">
            </dxi-column>
            <dxi-column caption="Evidencias" dataField="quoteUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

            <dxi-column caption="Semana ultimo avance" dataField="week" cellTemplate="weekTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Avance Anterior Acumulado" dataField="previousTotalAdvance" cellTemplate="percentageToAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Avance Semanal" dataField="advance" cellTemplate="percentageToAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Avance a la Fecha" dataField="totalAdvance" cellTemplate="percentageAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="% pagado" dataField="percentage" [calculateCellValue]="calcPercentPay" cellTemplate="percentPayTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="% a pagar" dataField="percentToPay" [calculateCellValue]="calcPercentToPay" cellTemplate="percentToPayTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad a pagar" dataField="quantityToPay" [calculateCellValue]="calcQuantityToPay" cellTemplate="quantityToPayTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Monto a pagar" dataField="amountToPay" [calculateCellValue]="calcAmountToPay" cellTemplate="amountPayTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Deductiva" dataField="deductive" cellTemplate="amountToPayTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Acum. Deductiva" dataField="deductiveAcum" cellTemplate="deductiveAcumTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Id Paquete" dataField="packageId" alignment="left" cellTemplate="packageIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" cellTemplate="conceptTemplate" [allowEditing]="false" [allowFiltering]="true" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Nivel" dataField="level" cellTemplate="levelTemplate" [allowEditing]="false" [allowFiltering]="true" [allowSearch]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Unidad" dataField="unit" width="11%" cellTemplate="unitTemplate" [allowFiltering]="true" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="P.U" dataField="unitPrice" cellTemplate="unitPriceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="P.U Real" dataField="realPrice" cellTemplate="unitPriceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Costo inicial" dataField="costOriginal" cellTemplate="costOriginalTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Adicionales" dataField="additionToQuantity" cellTemplate="aditionalsTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Deductiva" dataField="decreaseToQuantity" cellTemplate="decrementsTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Deductiva Asignación" [calculateCellValue]="calcDecreaseToEstimate" cellTemplate="decrementsTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Total" [calculateCellValue]="calculateTotalCost" cellTemplate="TotalTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Disponible" dataField="available" cellTemplate="availableTemplate" [calculateCellValue]="calculateAvailable" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column name="amount" caption="Monto Total Acumulado" [calculateCellValue]="calcAmount" cellTemplate="amountToPayTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
        </ng-container>


        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span *ngIf="data.key.evidences && data.key.evidences.length > 0">
                <i class="material-icons icon-gray" (click)="onSelectEvidences(data.key)">
                    remove_red_eye
                </i>
            </span>
        </div>


        <div *dxTemplate="let data of 'idGroupTemplate'">
            <table style="width:100%">
                <tr *ngIf="data && data.data && data.data.items &&
                        data.data.items[0]">
                    <th>Id Asignacion: {{ data.key }}</th>
                    <th>Fecha: hace {{ data.data.items[0].createdAt | timeAgo }}</th>
                    <th>Solicitante: {{ data.data.items[0].createdBy | userData | async }}</th>
                    <th>Proveedor: {{ data.data.items[0].supplierId | supplierData | async }}
                    </th>
                    <th>Monto: ${{ data.data.items[0].totalAssignment | number:'1.2-3' }}
                    </th>
                </tr>
            </table>
        </div>

        <div *dxTemplate="let data of 'packageIdTemplate'">
            {{ data.value }}
        </div>

        <div *dxTemplate="let data of 'weekTemplate'">
            <span *ngIf="data.value"><strong>{{ data.value }}</strong></span>
            <span *ngIf="!data.value"><strong>--</strong></span>
        </div>

        <div *dxTemplate="let data of 'conceptTemplate'">
            <span *ngIf="data.data.code === payLoanPositive">
                    <button mat-raised-button id="btn" color="primary"
                        (click)="showLoanDetails(data)">
                        Detalles
                    </button>
                    {{ data.value }}
                </span>
            <span *ngIf="data.data.code === payLoanNegative">{{ data.value }}
                    será
                    descontado en
                    {{ data.data.payments }}
                    partes.</span>

            <span *ngIf="data.data.code !== payLoanPositive || data.data.code
                    !==
                    payLoanNegative">{{
                    data.value
                    }}</span>
        </div>
        <div *dxTemplate="let data of 'levelTemplate'">
            {{ data.value }}
        </div>
        <div *dxTemplate="let data of 'deductiveAcumTemplate'">
            {{ data.value | number:'1.2-3' }}
        </div>
        <div *dxTemplate="let data of 'availableTemplate'">
            {{ data.value | number: '1.2-3' }}
        </div>
        <div *dxTemplate="let data of 'aditionalsTemplate'">
            <ng-container *ngIf="data.value; else withoutValue">
                {{ data.value | number: '1.2-3' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'decrementsTemplate'">
            <ng-container *ngIf="data.value; else withoutValue">
                {{ data.value | number: '1.2-3' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'percentPayTemplate'">
            <span *ngIf="data.value"><strong>{{ data.value | number:'1.2-3' }} %</strong></span>
            <span *ngIf="!data.value"><strong>0%</strong></span>
        </div>
        <div *dxTemplate="let data of 'quantityToPayTemplate'">
            <span *ngIf="data.value">{{ data.value | number:'1.2-3' }}</span>
            <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                    cantidad</span>
        </div>
        <div *dxTemplate="let data of 'percentageToAdvanceTemplate'">
            <span *ngIf="data.value">{{ data.value | number:'1.2-3' }} %</span>
            <span *ngIf="!data.value">0%</span>
        </div>
        <div *dxTemplate="let data of 'percentageAdvanceTemplate'">
            <span *ngIf="data.value">{{ data.value | number:'1.2-3' }} %</span>
            <span *ngIf="!data.value">0%</span>
        </div>
        <div *dxTemplate="let data of 'percentToPayTemplate'">
            <span *ngIf="data.value">{{ data.value | number:'1.2-3' }}</span>
            <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                    porcentaje</span>
        </div>
        <div *dxTemplate="let data of 'amountToPayTemplate'">
            <span *ngIf="data.value">${{ data.value | number:'1.2-3' }}</span>
            <span *ngIf="!data.value">0</span>
        </div>
        <div *dxTemplate="let data of 'amountPayTemplate'">
            <span *ngIf="data.value">${{ data.value | number:'1.2-3' }}</span>
            <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                    monto</span>
        </div>
        <div *dxTemplate="let data of 'quantityTemplate'" class="edit-cells-qty">
            <span>{{ data.value | number: '1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'unitPriceTemplate'" class="edit-cells-qty">
            <span>${{ data.value | number:'1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'costOriginalTemplate'" class="edit-cells-qty">
            <span>${{ data.value | number:'1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'costTemplate'" class="edit-cells-qty">
            <span>${{ data.value | number:'1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'" class="edit-cells-status">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'TotalTemplate'">
            <strong *ngIf="data.value !== 0">$ {{ data.value | number:'1.2-3' }}</strong>
            <span *ngIf="data.value === 0">-</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <dxo-summary [calculateCustomSummary]="calculateSelectedRow">
            <dxi-total-item name="total" showInColumn="Total" summaryType="custom" displayFormat="${0}" [customizeText]="formatTotal">
            </dxi-total-item>
            <dxi-total-item name="totalAmount" showInColumn="amount" summaryType="custom" displayFormat="${0}" [customizeText]="formatTotalAmount">
            </dxi-total-item>
            <dxi-total-item name="totalAmountToPay" showInColumn="amountToPay" summaryType="custom" displayFormat="${0}" [customizeText]="formatTotalAmount">
            </dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
    <div class="footer">
        <div class="actions-block">
            <button class="action-button ml-5" mat-raised-button id="btn" [disabled]="isCreateButtonDisabled" color="primary" (click)="openPreviewDialog()">
            Generar Estimación
          </button>
            <!-- <button class="action-button" mat-raised-button id="btn" [disabled]="isCreateButtonDisabled" color="primary" (click)="generateEstimation()">
                Crear Estimación
            </button> -->
        </div>
        <dx-check-box #guarantyFund [disabled]="isCreateButtonDisabled" [value]="false" [width]="220" text="Agregar fondo de garantia">
        </dx-check-box>
    </div>

    <!-- ============================================================== -->
    <!-- popup-loandetails  -->
    <!-- ============================================================== -->
    <dx-popup class="popup" [width]="250" [height]="280" [showTitle]="true" title="Prestamo" [dragEnabled]="true" [closeOnOutsideClick]="true" [(visible)]="showLoanPopUpDetail">
        <div *dxTemplate="let data of 'content'">
            <strong>Monto: </strong> $ {{ loanDetailData.cost }}
            <br />
            <strong>Cantidad de pagos: </strong> {{ loanDetailData.payments }}
            <br />
            <strong>Comentarios: </strong> {{ loanDetailData.comments }}
        </div>
    </dx-popup>
    <!-- ============================================================== -->
    <!-- popup loan details end  -->
    <!-- ============================================================== -->
    <app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
    </app-loading-indicator>
</app-page-widget>
<app-dialog #evidenceDialog noPadding width="650px" [autoFocus]="false">
    <app-title>Evidencias</app-title>

    <mat-nav-list *ngIf="evidences?.length">
        <a *ngFor="let ev of evidences; trackBy: trackEvidence" mat-list-item target="_blank" [href]="ev.downloadUrl">
            <mat-icon matListIcon>cloud_download</mat-icon>
            {{ ev.name }}
        </a>
    </mat-nav-list>

</app-dialog>

<!-- DIALOG DETAIL ESTIMATE -->
<app-supplier-estimate-detail #detailEstimateDialog [_estimateUuid]="estimateUuid"></app-supplier-estimate-detail>

<app-preview-estimate #previewDialog [packages]="selectedPackages" [assignmentList]="assignmentsList" [assignmentSelected]="assignmentSelected" [addIva]="false" [subTitle]="'Paquetes seleccionados para ésta Estimación'" [gridType]="'package'" (generateEstimationConfirm)="generateEstimationConfirm($event)">
</app-preview-estimate>

<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>