<app-page-widget noPadding [menuItems]="widgetMenu">
  <app-title>Asignaciones</app-title>
  <app-subtitle>? Asignaciones ?</app-subtitle>
  <br />
  <mat-grid-list cols="2" rowHeight="100px">
    <app-select-box
      [widthSelect]="650"
      [dataSource]="spliers"
      displayExpr="socialReason"
      (selectItem)="onSelectBoxSelection($event)"
      placeholder="selecionar proveedor"
    >
    </app-select-box>
    <mat-grid-tile> </mat-grid-tile>
  </mat-grid-list>

  <!-- ============================================================== -->
  <!-- GRID SELECCION DE ITEMS PARA ESTIMACIONES  -->
  <!-- ============================================================== -->
  <dx-data-grid
    #estimationGrid
    class="grid-container"
    [wordWrapEnabled]="true"
    [dataSource]="gridSourceAsignment"
    [columns]="asignColumns"
    [masterDetail]="{ enabled: true, template: 'detail' }"
    [hoverStateEnabled]="false"
    [showColumnLines]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [rowAlternationEnabled]="true"
    [columnAutoWidth]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    (onSelectionChanged)="onGridSelection($event)"
  >
    <dxo-paging [pageSize]="5"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
    </dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
    <dxi-column
      caption="id"
      width="10%"
      [allowEditing]="false"
      alignment="center"
      [allowSearch]="false"
      dataField="id"
      [allowSorting]="false"
    >
    </dxi-column>
    <dxi-column
      caption="createdAt"
      width="25%"
      [calculateCellValue]=""
      [allowEditing]="false"
      dataType="date"
      cellTemplate="dateTemplate"
      dataField="createdAt"
      [allowSorting]="true"
      sortOrder="desc"
    >
    </dxi-column>
    <dxi-column
      caption="createdBy"
      width="20%"
      [allowEditing]="false"
      dataField="createdBy"
      [allowSorting]="false"
    >
    </dxi-column>
    <dxi-column
      caption="packages"
      width="10%"
      alignment="center"
      cellTemplate="packagesTemplate"
      [allowEditing]="false"
      dataField="packages"
      [allowSorting]="false"
    >
    </dxi-column>
    <div *dxTemplate="let data of 'dateTemplate'">
      <strong> {{ data.value | timestampToDate }}</strong> -
      <span> hace {{ data.value | timeAgo }}</span>
    </div>
    <div *dxTemplate="let item of 'packagesTemplate'">
      <span>{{ item.value.length }}</span>
      <!-- <ul>
              <li *ngFor="let data of item.value">{{data.length}}</li>
            </ul> -->
    </div>
    <div *dxTemplate="let item of 'detail'; let mivar">
      <!-- <strong>{{item.data.uuid}}</strong> -->
      <dx-data-grid
        #subGrid
        [dataSource]="item.data.packages"
        [wordWrapEnabled]="true"
        (onSelectionChanged)="itemSelected($event, item.data.uuid, subGrid)"
      >
        <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
        <dxi-column caption="id" width="6%" cellTemplate="cellTemplate"></dxi-column>
        <dxi-column dataField="code" width="10%"></dxi-column>
        <dxi-column dataField="concept" width="50%"></dxi-column>
        <dxi-column dataField="unit" alignment="center" width="10%"></dxi-column>
        <dxi-column
          dataField="quantity"
          cellTemplate="quantityTemplate"
          alignment="center"
          width="10%"
        ></dxi-column>
        <dxi-column
          dataField="cost"
          cellTemplate="costTemplate"
          alignment="center"
          width="10%"
        ></dxi-column>
        <dxi-column
          caption="Total"
          [calculateCellValue]="calculateTotal"
          cellTemplate="totalTemplate"
          [allowEditing]="false"
          alignment="center"
          width="10%"
        ></dxi-column>
        <div *dxTemplate="let val of 'cellTemplate'">
          <strong>{{ item.data.packageId }}</strong>
        </div>
        <div *dxTemplate="let data of 'quantityTemplate'">
          <strong *ngIf="data.value !== 0">{{ data.value | number: '1.2-3' }}</strong>
          <span *ngIf="data.value === 0">-</span>
        </div>
        <div *dxTemplate="let data of 'costTemplate'">
          <strong *ngIf="data.value !== 0">$ {{ data.value | number }}</strong>
          <span *ngIf="data.value === 0">-</span>
        </div>
        <div *dxTemplate="let data of 'totalTemplate'">
          <strong *ngIf="data.value !== 0">$ {{ data.value | number }}</strong>
          <span *ngIf="data.value === 0">-</span>
        </div>
      </dx-data-grid>
    </div>
  </dx-data-grid>
  <!-- ============================================================== -->
  <!-- FIN GRID SELECCION DE ITEMS PARA ESTIMACIONES  -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- GRID PARA GENERAR ESTIMACIONES DE LA SELLECCION DE TAB 1  -->
  <!-- ============================================================== -->
  <dx-data-grid
    #estimGrid
    class="grid-container"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [wordWrapEnabled]="true"
    [dataSource]="selectedItems"
    (onSelectionChanged)="onSubItemeSelection($event)"
  >
    <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
    <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
    <dxi-column
      caption="id Subcontrato"
      width="10%"
      cellTemplate="idTemplate"
      [allowEditing]="false"
    ></dxi-column>
    <dxi-column dataField="concept" width="50%" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="unit" width="3%" alignment="center" [allowEditing]="false"></dxi-column>
    <dxi-column
      dataField="quantity"
      cellTemplate="quantityTemplate"
      alignment="center"
      width="10%"
    ></dxi-column>
    <dxi-column
      dataField="cost"
      cellTemplate="costTemplate"
      alignment="center"
      width="10%"
    ></dxi-column>
    <dxi-column
      caption="Total"
      [calculateCellValue]="calculateTotal"
      cellTemplate="totalTemplate"
      [allowEditing]="false"
      alignment="center"
      width="10%"
    ></dxi-column>

    <dxi-column
      caption="% a Pagar"
      dataField="percentage"
      alignment="center"
      width="10%"
      [allowEditing]="true"
      cellTemplate="percetTemplate"
    >
      <dxi-validation-rule
        type="pattern"
        [pattern]="pattern"
        message="Error en valor ingresado acepta solo numeros!"
      ></dxi-validation-rule>
    </dxi-column>

    <div *dxTemplate="let val of 'idTemplate'">
      <strong>{{ val.data.idSubcontract }}</strong>
    </div>
    <div *dxTemplate="let data of 'percetTemplate'">
      <h4>{{ data.value }} %</h4>
    </div>
    <div *dxTemplate="let data of 'quantityTemplate'">
      <strong *ngIf="data.value !== 0">{{ data.value | number: '1.2-3' }}</strong>
      <span *ngIf="data.value === 0">-</span>
    </div>
    <div *dxTemplate="let data of 'costTemplate'">
      <strong *ngIf="data.value !== 0">$ {{ data.value | number }}</strong>
      <span *ngIf="data.value === 0">-</span>
    </div>
    <div *dxTemplate="let data of 'totalTemplate'">
      <strong *ngIf="data.value !== 0">$ {{ data.value | number }}</strong>
      <span *ngIf="data.value === 0">-</span>
    </div>
  </dx-data-grid>
  <!-- ============================================================== -->
  <!-- FINGRID PARA GENERAR ESTIMACIONES DE LA SELLECCION DE TAB 1  -->
  <!-- ============================================================== -->
</app-page-widget>
