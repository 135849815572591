<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Anticipos listado</app-title>
    <app-subtitle>Anticipos list</app-subtitle>

    <app-action-button icon="playlist_add" routeData="/advanced-payments/new-mat">Crear</app-action-button>
    <app-menu-item icon="playlist_add" routeData="/advanced-payments/new-mat">Crear</app-menu-item>
    <!-- ============================================================== -->
    <!-- GRID advanced payments                                         -->
    <!-- ============================================================== -->
    <dx-data-grid #loanGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="advancedPayments" [masterDetail]="{ enabled: false,
        template:
        'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no éxisten anticipos." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [(selectedRowKeys)]="Rows" (onRowClick)="onGridSelection($event)">
        <dxo-paging [pageSize]="5"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
        </dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-export [enabled]="true" fileName="lista-anticipos"></dxo-export>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
        <dxi-column caption="ID" dataField="id" width="6%" cellTemplate="idTemplate" [allowEditing]="false" [allowFiltering]="true"></dxi-column>
        <dxi-column caption="Fecha" dataField="createdAt" width="15%" cellTemplate="dateTemplate" [allowEditing]="false" dataType="date" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" [allowFiltering]="true" sortOrder="desc" alignment="left"></dxi-column>
        <dxi-column caption="Proveedor" dataField="supplier.name" cellTemplate="supplierTemplate" [allowFiltering]="false" alignment="center" width="25%"></dxi-column>
        <dxi-column caption="Monto" dataField="advancedPaymentAmount" cellTemplate="advancedPaymentAmountTemplate" width="10%" [allowFiltering]="true" alignment="center" [allowEditing]="false"></dxi-column>
        <dxi-column caption="Amortizado" dataField="amortized" cellTemplate="advancedPaymentAmountTemplate" width="10%" alignment="center" [allowEditing]="false" [allowFiltering]="false"></dxi-column>
        <dxi-column caption="Por Amortizar" dataField="perAmortized" cellTemplate="advancedPaymentAmountTemplate" width="10%" alignment="center" [allowEditing]="false" [allowFiltering]="false"></dxi-column>
        <dxi-column caption="% Amortizar" dataField="loanAmount" cellTemplate="defaultPercentTemplate" width="10%" alignment="center" [allowEditing]="false" [allowFiltering]="false"></dxi-column>
        <dxi-column caption="Solicitado por" dataField="createdBy" width="20%" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
        </dxi-column>
        <dxi-column caption="Status" dataField="status" cellTemplate="statusTemplate" width="10%" alignment="center" [allowEditing]="false" [allowFiltering]="false" [calculateCellValue]="customizeStatusLabel"></dxi-column>
        <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>

        <div *dxTemplate="let data of 'idTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'advancedPaymentAmountTemplate'">
            <strong>${{ data.value | number:'1.2-2' }}</strong>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'supplierTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'defaultTemplate'">
            <strong>${{ data.value ? data.value : (0 | estimateStatus) }}</strong>
        </div>
        <div *dxTemplate="let data of 'defaultPercentTemplate'">
            <strong>100%</strong>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>
    </dx-data-grid>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess"></app-loading-indicator>