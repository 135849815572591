<app-page-widget [menuItems]="widgetMenu" (refresh)="refreshData()">
    <app-title *ngIf="estimate">EST-{{ estimate?.nroEstimate }}</app-title>
    <app-subtitle *ngIf="estimate">{{ estimate?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <app-action-button flat noText icon="refresh" (click)="refreshUsersApprove()">Refrescar lista de aprobadores
    </app-action-button>
    <app-action-button flat noText icon="done_all" (click)="displayApprovals()">Ver Aprobaciones
    </app-action-button>
    <!-- <app-menu-item icon="done_all" (click)="displayApprovals()">Ver Aprobaciones</app-menu-item> -->
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <div><img class="header-logo" src="./assets/img/small_logo_tbg.png" alt="Logo" /></div> -->
        <div>
            <div class="info-label">
                Estado: <strong>{{ estimate?.status | estimateStatus }}</strong>
            </div>
            <div class="info-label">
                Solicitado por:
                <app-user-data *ngIf="estimate" [id]="estimate.createdBy"></app-user-data>
            </div>
            <div class="info-label">
                Contrato: <strong>{{ nroContract }}</strong>
            </div>
        </div>
        <div>
            <!-- <a mat-raised-button (click)="updateAssignmentPackages()" class="action-button mr-5" color="warn">
                <mat-icon>undo</mat-icon>
                Update
            </a> -->

            <button mat-raised-button class="action-button mr-5" (click)="goToPreviousUlr()" color="warn">
                <mat-icon>undo</mat-icon>
                Regresar
            </button>
            <ng-template [ngIf]="sendToApproveIsAvailable">
                <app-button *ngxPermissionsOnly="['estimates:can_send_to_approve']" icon="call_made" (click)="sendToApprove()">Enviar a Aprobar</app-button>
            </ng-template>
            <ng-template [ngIf]="approveIsAvailable && !approveAvailableSupplier">
                <app-button *ngxPermissionsOnly="['estimates:can_approve']" icon="done" class="action-button" (click)="approve()">Aprobar</app-button>
            </ng-template>
            <ng-template [ngIf]="approveIsAvailable && approveAvailableSupplier">
                <button mat-raised-button class="action-button" (click)="approve()" color="primary">
            <mat-icon>done</mat-icon>
            Aprobar
          </button>
            </ng-template>
            <!-- <ng-template [ngIf]="cancelIsAvailable">
        <app-button icon="close" (click)="confirmCancel()">Cancelar</app-button>
      </ng-template> -->
        </div>
    </div>


    <div *ngIf="sendToApproveIsAvailable || approveIsAvailable">
        <h3 class="content-title">Número de Estimación</h3>
        <mat-form-field class="w-30 mt-10" appearance="fill">
            <input matInput [formControl]="nroEstimateFormControl" placeholder="Número de estimación" type="text">
        </mat-form-field>
        <button mat-raised-button class="action-button ml-5" (click)="updateNroEstimate()" color="primary">Actualizar</button>
    </div>
    <div class="content-block2-section">
        <div class="content-block-section">
            <h3 class="content-title">Proveedor Asignado</h3>
            <div>
                <div>
                    <span class="info-label">Razón Social: </span>
                    <strong>{{ supplier?.socialReason }}</strong>
                </div>
                <div>
                    <span class="info-label">RFC: </span> <strong>{{
                        supplier?.rfc }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Comercial:</span>
                    <strong>{{ supplier?.businessAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Fiscal:</span>
                    <strong>{{ supplier?.taxAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Teléfono:</span> <strong>{{
                        supplier?.phone }}</strong>
                </div>
                <div>
                    <span class="info-label">email: </span> <strong>{{
                        supplier?.email }}</strong>
                </div>
            </div>
            <br />
        </div>

        <div class="content-block-section">
            <h3 class="content-title">Datos para facturación</h3>
            <div>
                <div>
                    <span class="info-label">Razón Social: </span>
                    <strong>{{ supplier?.socialReason }}</strong>
                </div>
                <div>
                    <span class="info-label">RFC: </span> <strong>{{
                        supplier?.rfc }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Comercial:</span>
                    <strong>{{ supplier?.businessAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Fiscal:</span>
                    <strong>{{ supplier?.taxAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Teléfono:</span> <strong>{{
                        supplier?.phone }}</strong>
                </div>
                <div>
                    <span class="info-label">E-Mail:</span> <strong>{{
                        supplier?.email }}</strong>
                </div>
            </div>
            <br />
        </div>
    </div>
    <ng-container *ngIf="estimate">
        <div class="content-block2-section" *ngIf="estimate.commentsRejectedOrder && estimate.commentsRejectedOrder.length > 0">
            <div class="content-block-section mx-0 mt-0 w-100">
                <h3 class="content-title">Comentarios</h3>
                <div>
                    <div *ngFor="let comment of estimate.commentsRejectedOrder; let idx = index">
                        <div>
                            <span class="info-label">Usuario: </span>
                            <app-user-data [id]="comment.userUuid"></app-user-data>
                        </div>
                        <div>
                            <span class="info-label">Fecha: </span>
                            <strong>{{ comment.dateRejected | timestampToDate }}</strong>
                        </div>
                        <div>
                            <span class="info-label">Comentario {{ idx + 1 }}: </span>
                            <strong>{{ comment.comment }}</strong>
                        </div>
                    </div>
                </div>
                <br />
            </div>
            <div class="content-block-section mx-0 mt-0 w-100">
                <h3 class="content-title">Evidencias</h3>
                <div>
                    <div *ngFor="let comment of estimate.commentsRejectedOrder; let idx = index">
                        <div>
                            <app-files-uploader class="mt-10" #filesUploader bucket="estimates-evidences" (uploadFinished)="onEvidenceEstimate($event, idx)">
                            </app-files-uploader>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="comment.evidences">
                            <ng-container *ngFor="let evidence of comment.evidences; let indexEv = index">
                                <span class="info-label align-items-center">Evidencia {{ indexEv + 1 }}: </span>
                                <a target="_blank" [href]="evidence.evidenceUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="estimate">
        <ng-container *ngIf="sendToApproveIsAvailable || estimate.deductiveTotal">
            <div class="content-block2-section">
                <div class="content-block-section mx-0 mt-0 w-100">
                    <h3 class="content-title">Deductiva</h3>
                    <div class="w-100 d-flex" *ngIf="estimate">
                        <div class="w-50 ml-20">
                            <!-- <div class="mt-30">Deductiva</div> -->
                            <mat-form-field class="w-90 mt-30" appearance="fill">
                                <input matInput [disabled]="estimate.deductiveTotal" [formControl]="deductiveFormControl" placeholder="Deductiva" type="number">
                            </mat-form-field>
                        </div>
                        <div class="w-50">
                            <!-- <div class="mt-30">Nota</div> -->
                            <mat-form-field class="w-90 mt-30" appearance="fill">
                                <input matInput [disabled]="estimate.deductiveTotalNote" [formControl]="noteFormControl" placeholder="Nota" type="text">
                            </mat-form-field>
                        </div>
                    </div>
                    <br />
                </div>
                <div class="content-block-section mx-0 mt-0 w-100">
                    <h3 class="content-title">Evidencias Deductiva</h3>
                    <div>
                        <div *ngIf="!estimate.evidencesDeductives || estimate.evidencesDeductives.length === 0">
                            <app-files-uploader class="mt-10" #filesDeductiveUploader bucket="deductives-estimates-evidences" (uploadFinished)="onDeductivesEvidenceEstimate($event)">
                            </app-files-uploader>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="!estimate.evidencesDeductives">
                            <ng-container *ngFor="let evidence of evidencesDeductives; let indexEv = index">
                                <span class="info-label align-items-center">Evidencia {{ indexEv + 1 }}: </span>
                                <a (click)="deleteEvidence(indexEv)">
                                    <mat-icon matListIcon color="warn">delete</mat-icon>
                                </a>
                                <a target="_blank" [href]="evidence.evidenceUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="estimate.evidencesDeductives">
                            <ng-container *ngFor="let evidence of estimate.evidencesDeductives; let indexEv = index">
                                <span class="info-label align-items-center">Evidencia {{ indexEv + 1 }}: </span>
                                <a target="_blank" [href]="evidence.evidenceUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </ng-container>
    </ng-container>

    <dx-data-grid id="grid-container" #dataGrid [dataSource]="estimatePacks" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="false" [showRowLines]="true" [showBorders]="false" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [columnAutoWidth]="false">
        <dxo-editing mode="single" [allowUpdating]="false"></dxo-editing>
        <dxi-column caption="Asignación" dataField="asignmentSmallId" width="15%" alignment="left" cellTemplate="asignmentIdTemplate"></dxi-column>
        <dxi-column caption="ID" dataField="packageId" alignment="left" cellTemplate="idTemplate" width="12%"></dxi-column>
        <dxi-column caption="Cod" dataField="code" alignment="left" cellTemplate="idTemplate" width="12%"></dxi-column>
        <dxi-column caption="Concepto" dataField="concept" width="40%"></dxi-column>
        <dxi-column caption="Unidad" dataField="unit" width="10%" cellTemplate="unitTemplate" alignment="center"></dxi-column>
        <dxi-column caption="P.U" dataField="unitPrice" width="10%" cellTemplate="costoTemplate" alignment="center"></dxi-column>
        <dxi-column caption="P.U Real" dataField="realPrice" width="10%" cellTemplate="costoTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Cantidad" dataField="quantity" width="10%" cellTemplate="cantidadTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Costo inicial" dataField="costOriginal" width="15%" [calculateCellValue]="initialCost" cellTemplate="costOriginalTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="Adicionales" dataField="additionToQuantity" width="15%" cellTemplate="additionTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Deductiva" dataField="decreaseToQuantity" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Total" dataField="importe" width="15%" [calculateCellValue]="calValues" cellTemplate="importeTemplate"></dxi-column>
        <dxi-column caption="Diponible" dataField="available" [calculateCellValue]="calculateAvailable" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% pagado" dataField="percentage" [calculateCellValue]="calcPercentPay" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% a Pagar" dataField="percentToPay" [calculateCellValue]="calculatePercentageToDisplay" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% total" dataField="percentTotal" [calculateCellValue]="calculatePercentageTotal" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Importe" dataField="total" [calculateCellValue]="calculatePercentageToPay" width="30%" cellTemplate="estimateamountTemplate" alignment="right"></dxi-column>
        <!-- [calculateCellValue]="calculateCost" -->
        <div *dxTemplate="let d of 'costoTemplate'">
            <span class="current-value">${{ d.value | number:'1.2-3' }}</span>
        </div>
        <div *dxTemplate="let d of 'cantidadTemplate'">
            <span class="current-value">{{ d.value | number:'1.2-3' }}</span>
        </div>
        <div *dxTemplate="let d of 'additionTemplate'">
            <ng-container *ngIf="d.value; else withoutValue">
                {{ d.value | number:'1.2-3' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let d of 'decreaseTemplate'">
            <ng-container *ngIf="d.value; else withoutValue">
                {{ d.value | number:'1.2-3' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let d of 'estimateTemplate'">
            <span class="current-value">{{ d.value | number:'1.2-3' }}%</span>
        </div>
        <div *dxTemplate="let d of 'importeTemplate'">
            <span class="current-value">${{ d.value | number:'1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'costOriginalTemplate'">
            <span>${{ data.value | number:'1.2-3' }}</span>
        </div>
        <div *dxTemplate="let d of 'estimateamountTemplate'">
            <span *ngIf="d.data.concept === 'Fondo de garantia'"> - </span>
            <span class="current-value">${{ d.value | number:'1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'asignmentIdTemplate'">
            <span *ngIf="data.value">ASG-{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'idTemplate'">
            <span *ngIf="data.value">{{ data.value }}</span> <span *ngIf="!data.value">NA</span>
        </div>

        <dxo-summary *ngIf="estimate" [calculateCustomSummary]="calculateSelectedRow">
            <dxi-total-item column="concept" summaryType="count" displayFormat="Conceptos: {0}">
            </dxi-total-item>

            <dxi-total-item name="subtotalPacks" summaryType="custom" [customizeText]="formatSubtotalPacks" displayFormat="${0}" showInColumn="total">
            </dxi-total-item>

            <dxi-total-item name="deductive" summaryType="custom" [customizeText]="formatDeductive" displayFormat="${0}" showInColumn="total">
            </dxi-total-item>

            <dxi-total-item name="subtotalPacksDeductive" summaryType="custom" [customizeText]="formatSubtotalPacksDeductive" displayFormat="${0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok no mover-->
            <dxi-total-item name="getGuarantieFound" summaryType="custom" [customizeText]="fondoGarantia" showInColumn="total">
            </dxi-total-item>
            <!-- -- -->

            <dxi-total-item name="descuentoPrestamoAbonoPrestamo" summaryType="custom" [customizeText]="formatPrestamo" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <dxi-total-item name="descuentoPrestamoAbonoAnticipo" summaryType="custom" [customizeText]="formatAnticipo" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok no mover-->
            <!-- <dxi-total-item *ngIf="hasGuarantteFound"
                        name="subtotalSinFondo"
                        summaryType="custom"
                        [customizeText]="formatNumberSubtotalSinfondo"
                        displayFormat="${0}"
                        showInColumn="total">
        </dxi-total-item> -->
            <!-- ---- -->
            <dxi-total-item name="nuevoSubtotal" summaryType="custom" [customizeText]="formatNuevoSubtotal" displayFormat="${0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok no mover-->
            <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getIVACompl" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getISR" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok no mover-->
            <dxi-total-item *ngIf="addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getIVACompl" displayFormat="{0}" showInColumn="amountToPay">
            </dxi-total-item>

            <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getISR" displayFormat="{0}" showInColumn="amountToPay">
            </dxi-total-item>
            <!-- --- -->
            <dxi-total-item *ngIf="!addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalSinIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <dxi-total-item *ngIf="estimate.deductiveTotal" name="deductiveTotal" summaryType="custom" [customizeText]="formatDeductiveTotal" displayFormat="${0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok no mover-->
            <dxi-total-item *ngIf="addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConIVAAndDeductiveTotal" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <!-- --- -->
            <dxi-total-item *ngIf="!addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalSinIVAAndDeductiveTotal" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok dont move muestra el TOTAL CUANDO TIENE FONDO DE GARANTIA  -->
            <dxi-total-item *ngIf="hasGuarantteFound && addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConFondoLetra" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <dxi-total-item *ngIf="hasGuarantteFound && !addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConFondoLetraSinIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <!-- -- -->
            <!-- ok dont move muestra el TOTAL CUANDO NO TIENE FONDO DE GARANTIA  -->
            <dxi-total-item *ngIf="!hasGuarantteFound && addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalLetra" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <dxi-total-item *ngIf="!hasGuarantteFound && !addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalLetraSinIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok dont move muestra el TOTAL CUANDO TIENE FONDO DE GARANTIA  -->
            <dxi-total-item *ngIf="hasGuarantteFound && addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConFondoLetraWithDeductiveTotal" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <dxi-total-item *ngIf="hasGuarantteFound && !addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConFondoLetraSinIVAWithDeductiveTotal" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <!-- -- -->
            <!-- ok dont move muestra el TOTAL CUANDO NO TIENE FONDO DE GARANTIA  -->
            <dxi-total-item *ngIf="!hasGuarantteFound && addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalLetraWithDeductiveTotal" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <dxi-total-item *ngIf="!hasGuarantteFound && !addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalLetraSinIVAWithDeductiveTotal" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <!-- -- -->
        </dxo-summary>

        <dxi-column></dxi-column>
    </dx-data-grid>

    <div class="has-fund" *ngIf="hasGuarantteFound">Incluye fondo de garantía</div>
    <br />

    <!-- <div class="content-block2-section">
    <div class="content-block-section">
      <h3 class="content-title">Firma</h3>
      <br />
      <br />
      <div><span class="info-label">Nombre: </span></div>
      <br />
    </div>
    <div class="content-block-section">
      <h3 class="content-title">Firma</h3>
      <br />
      <br />
      <div><span class="info-label">Nombre: </span></div>
      <br />
    </div>
    <div class="content-block-section">
      <h3 class="content-title">Firma</h3>
      <br />
      <br />
      <div><span class="info-label">Nombre: </span></div>
      <br />
    </div>
    <div class="content-block-section">
      <h3 class="content-title">Firma</h3>
      <br />
      <br />
      <div><span class="info-label">Nombre: </span></div>
      <br />
    </div>
  </div> -->
</app-page-widget>