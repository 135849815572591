<div class="center-align">
  <h3 mat-dialog-title [innerText]="title"> </h3>
  <a mat-icon-button (click)="dismiss(true)"><mat-icon>clear</mat-icon>Cerrar</a>

  <mat-dialog-content>
    <div class="dropzone dragz"
         dropZone
         (hovered)="toggleHover($event)"
         (dropped)="startUpload($event)"
         [class.hovering]="isHovering">
      <div class="file center-align" *ngIf="!fileSelected">
        <input class="file-input" type="file" (change)="startUpload($event.target.files)">

        <span>
          Arrastrar y soltar archivo aquí o selecionar desde archivo…
        </span>
      </div>
      <div *ngIf="percentage | async as pct" class="center-align">
        <rmd-loading-indicator bar percent="{{ pct | number }}"></rmd-loading-indicator>
        {{ pct | number }}%
      </div>
      <div *ngIf="(snapshot | async) as snap" class="center-align">
        <strong>{{ snap.bytesTransferred | fileSize }} </strong> de <strong>{{ snap.totalBytes | fileSize }}</strong> <br>
        <button (click)="pauseTask()"  mat-raised-button color="primary"  [disabled]="!isActive(snap)">Pausar</button>
        <button (click)="cancelTask()" mat-raised-button color="primary"  [disabled]="!isActive(snap)">Cancelar</button>
        <button (click)="resumeTask()" mat-raised-button color="primary"  [disabled]="!(snap?.state === 'paused')">Continuar</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
