import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentOrder } from '@bist-lib/db-api';
import { DialogComponent } from '../../components/dialog/dialog.component';


@Component({
  selector: 'payment-order-log',
  templateUrl: './payment-order-log.component.html',
  styleUrls: ['./payment-order-log.component.css']
})
export class PaymentOrderLogComponent implements OnInit {

  @ViewChild('appDialog', { static: true }) public appDialog: DialogComponent;

  public _paymentOrders: PaymentOrder[];
  public budgetTableColumns: any[] = ['id', 'amountPaid', 'createdAt', 'status'];
  constructor(private router: Router) { }

  ngOnInit() {
  }
  public open(paymentOrders: PaymentOrder[]) {
    this._paymentOrders = paymentOrders;
    this.appDialog.open();
  }

  public onClosePreviewDialog(): void {
    // console['log']('Exit CC');
  }
  opSelected(attribute: PaymentOrder) {
    this.appDialog.close();
    this.router.navigate(['payment-orders/detail/', attribute.uuid]);
  }
}
