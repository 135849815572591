<app-dialog #appDialog (dismiss)="onClosePreviewDialog()">
    <app-title>{{ title }}</app-title>
    <app-subtitle>{{ subTitle }}</app-subtitle>

    <h4>
        <strong>Cantidad de paquetes seleccionados:</strong> {{ packages.length }}
    </h4>
    <h4>
        <strong>Total:</strong> ${{ total | number:'1.2-2' }}
    </h4>

    <ng-container [ngSwitch]="gridType">
        <div class="grid-container" *ngSwitchCase="'package'">
            <dx-data-grid #dataGrid id="grid-container" [dataSource]="packages" [wordWrapEnabled]="false" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
                [allowColumnResizing]="true" [columnAutoWidth]="false" [selectedRowKeys]="packages" useSelectMode="true" (onSelectionChanged)="onChangedRowsSelection($event)">
                <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
                </dxo-pager>
                <!-- <dxo-search-panel [visible]="true"
                          [width]="240"
                          placeholder="Buscar..."> </dxo-search-panel> -->
                <dxo-header-filter [visible]="false"></dxo-header-filter>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-selection selectAllMode="allPages" mode="multiple" [deferred]="false"></dxo-selection>

                <dxi-column caption="ID" dataField="packageId" alignment="center" width="10%"></dxi-column>
                <dxi-column caption="Fecha" dataField="createdAt" alignment="center" width="10%" [calculateCellValue]="calculateDateCellValue" cellTemplate="dateTemplate"></dxi-column>
                <dxi-column caption="Nivel" dataField="level" alignment="center" width="10%"></dxi-column>
                <dxi-column caption="Código" dataField="code" alignment="center" width="10%"></dxi-column>
                <dxi-column caption="Concepto" dataField="concept" [groupIndex]="3" alignment="center" width="20%"></dxi-column>
                <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" dataType="number" alignment="center" width="15%"></dxi-column>
                <dxi-column caption="Unidad" dataField="unit" alignment="center" width="5%" cellTemplate="unitTemplate"></dxi-column>
                <dxi-column caption="Costo" dataField="cost" cellTemplate="costTemplate" alignment="center" width="10%"></dxi-column>
                <dxi-column caption="Total" [calculateCellValue]="calculateTotalCost" cellTemplate="totalTemplate" [allowEditing]="false" alignment="center" width="10%">
                </dxi-column>

                <dxo-summary [skipEmptyValues]="true">
                    <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantityTotal" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item>
                    <dxi-group-item column="cost" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <dxi-total-item column="Total" summaryType="sum" alignment="center" [customizeText]="formatTotal">
                    </dxi-total-item>
                </dxo-summary>

                <div *dxTemplate="let data of 'dateTemplate'">
                    <span> {{ data.value | timestampToDate }}</span>
                </div>
                <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                    <span>{{ data.value | number: '1.2-3' }}</span>
                </div>
                <div *dxTemplate="let data of 'costTemplate'" class="cost">
                    <span>$ {{ data.value | number }}</span>
                </div>
                <div *dxTemplate="let data of 'totalTemplate'">
                    <strong>${{ data.value | number: '1.2-2' }}</strong>
                </div>
                <div *dxTemplate="let data of 'unitTemplate'">
                    <span>{{ data.value }}</span>
                </div>
            </dx-data-grid>

            <div class="leftover-container margin-top-2" *ngIf="leftoverPackages.length > 0">
                <span class="span-title">Sobrantes</span>
                <span class="span-subtitle">Paquetes sobrantes a ser aplicados en esta requisición</span>

                <table class="bordered-table">
                    <thead>
                        <tr>
                            <th>Concepto</th>
                            <th>Cantidad</th>
                            <th>Unidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let leftover of leftoverPackages">
                            <td>{{ leftover?.concept }}</td>
                            <td>{{ leftover?.quantity | number: '1.2-3' }}</td>
                            <td>{{ leftover?.unit }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngSwitchCase="'supplier'" class="grid-container">
            <dx-data-grid #dataGrid id="grid-container" [dataSource]="suppliers" [wordWrapEnabled]="false" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
                [allowColumnResizing]="true" [columnAutoWidth]="false" [selectedRowKeys]="suppliers" useSelectMode="true" (onSelectionChanged)="onChangedRowsSelection($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 15]" [showInfo]="true">
                </dxo-pager>
                <!-- <dxo-search-panel [visible]="true"
                          [width]="240"
                          placeholder="Buscar..."> </dxo-search-panel> -->
                <dxo-header-filter [visible]="false"></dxo-header-filter>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-selection selectAllMode="allPages" mode="multiple" [deferred]="false"></dxo-selection>

                <dxi-column caption="Razón Social" dataField="socialReason" alignment="left" width="40%">
                </dxi-column>
                <dxi-column caption="Actividad" dataField="activity" alignment="left" width="30%">
                </dxi-column>
                <dxi-column caption="Contacto" dataField="contactName" alignment="left" width="15%">
                </dxi-column>
                <dxi-column caption="Teléfono" dataField="phone" alignment="center" width="15%">
                </dxi-column>
            </dx-data-grid>
        </div>

        <div *ngSwitchDefault>
            <span style="color: red;">Error! no se reconoce el tipo:
        <strong>{{ gridType }}</strong>
      </span>
        </div>
    </ng-container>
</app-dialog>