<app-page-widget [menuItems]="widgetMenu" (refresh)="refreshData()">
    <app-title *ngIf="estimate">EST-{{ estimate?.uuid | idFromUuid }}</app-title>
    <app-subtitle *ngIf="estimate">{{ estimate?.createdAt | timestampToDate: 'long' }}</app-subtitle>

    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <div class="info-label">
                Solicitado por:
                <app-user-data *ngIf="estimate" [id]="estimate.createdBy"></app-user-data>
            </div>
        </div>
        <div>
            <button mat-raised-button class="action-button mr-5" (click)="goToPreviousUlr()" color="warn">
                <mat-icon>undo</mat-icon>
                Regresar
            </button>
            <ng-template [ngIf]="sendToApproveIsAvailable">
                <app-button class="mr-5" *ngxPermissionsOnly="['estimates-suppliers:can_send_to_approve']" icon="call_made" (click)="sendToApprove()">Enviar a Aprobar</app-button>
            </ng-template>
            <ng-template [ngIf]="approveIsAvailable">
                <app-button *ngxPermissionsOnly="['estimates-suppliers:can_approve']" icon="done" class="action-button mr-5" (click)="approve()">Aprobar</app-button>
            </ng-template>
            <button mat-raised-button *ngxPermissionsOnly="['estimates-suppliers:delete']" class="action-button mr-5" (click)="deleteEstimate()" color="warn">
                <mat-icon>delete</mat-icon>
                Eliminar
            </button>
        </div>
    </div>

    <div class="content-block2-section">
        <div class="content-block-section">
            <h3 class="content-title">Proveedor Asignado</h3>
            <div>
                <div>
                    <span class="info-label">Razón Social: </span>
                    <strong>{{ supplier?.socialReason }}</strong>
                </div>
                <div>
                    <span class="info-label">RFC: </span> <strong>{{
                        supplier?.rfc }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Comercial:</span>
                    <strong>{{ supplier?.businessAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Fiscal:</span>
                    <strong>{{ supplier?.taxAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Teléfono:</span> <strong>{{
                        supplier?.phone }}</strong>
                </div>
                <div>
                    <span class="info-label">email: </span> <strong>{{
                        supplier?.email }}</strong>
                </div>
            </div>
            <br />
        </div>

        <div class="content-block-section">
            <h3 class="content-title">Datos para facturación</h3>
            <div>
                <div>
                    <span class="info-label">Razón Social: </span>
                    <strong>{{ supplier?.socialReason }}</strong>
                </div>
                <div>
                    <span class="info-label">RFC: </span> <strong>{{
                        supplier?.rfc }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Comercial:</span>
                    <strong>{{ supplier?.businessAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Fiscal:</span>
                    <strong>{{ supplier?.taxAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Teléfono:</span> <strong>{{
                        supplier?.phone }}</strong>
                </div>
                <div>
                    <span class="info-label">E-Mail:</span> <strong>{{
                        supplier?.email }}</strong>
                </div>
            </div>
            <br />
        </div>
    </div>

    <dx-data-grid id="grid-container" #dataGrid [dataSource]="estimatePacks" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="false" [showRowLines]="true" [showBorders]="false" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [columnAutoWidth]="true">
        <dxo-editing mode="cell" [allowUpdating]="false"></dxo-editing>
        <dxi-column caption="Asignación" dataField="asignmentSmallId" width="15%" alignment="left" cellTemplate="asignmentIdTemplate"></dxi-column>
        <dxi-column caption="ID" dataField="packageId" alignment="left" cellTemplate="idTemplate" width="12%"></dxi-column>
        <dxi-column caption="Concepto" dataField="concept" width="40%"></dxi-column>
        <dxi-column caption="Unidad" dataField="unit" width="10%" cellTemplate="unitTemplate" alignment="center"></dxi-column>
        <!-- <dxi-column caption="P.U" dataField="unitPrice" width="10%" cellTemplate="costoTemplate" alignment="center"></dxi-column>
        <dxi-column caption="P.U Real" dataField="realPrice" width="10%" cellTemplate="costoTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Cantidad" dataField="quantity" width="10%" cellTemplate="cantidadTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Costo inicial" dataField="costOriginal" width="15%" cellTemplate="costOriginalTemplate" alignment="center"> -->
        <!-- </dxi-column> -->
        <dxi-column caption="Adicionales" dataField="additionToQuantity" width="15%" cellTemplate="additionTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Deductiva" dataField="decreaseToQuantity" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Total" dataField="importe" width="15%" [calculateCellValue]="calValues" cellTemplate="importeTemplate"></dxi-column>
        <dxi-column caption="Diponible" dataField="available" [calculateCellValue]="calculateAvailable" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% solicitado" dataField="percentage" [calculateCellValue]="calcPercentPay" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% a solicitar" dataField="percentToPay" [allowEditing]="true" [calculateCellValue]="calculatePercentageToDisplay" width="10%" cellTemplate="percentageToRequestTemplate" alignment="center"></dxi-column>
        <dxi-column caption="% total" dataField="percentTotal" [calculateCellValue]="calculatePercentageTotal" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Importe" dataField="total" [calculateCellValue]="calculatePercentageToPay" width="30%" cellTemplate="estimateamountTemplate" alignment="right"></dxi-column>
        <!-- [calculateCellValue]="calculateCost" -->
        <div *dxTemplate="let d of 'costoTemplate'">
            <span class="current-value">${{ d.value | number }}</span>
        </div>
        <div *dxTemplate="let d of 'cantidadTemplate'">
            <span class="current-value">{{ d.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let d of 'additionTemplate'">
            <ng-container *ngIf="d.value; else withoutValue">
                {{ d.value | number: '1.2-2' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let d of 'decreaseTemplate'">
            <ng-container *ngIf="d.value; else withoutValue">
                {{ d.value | number: '1.2-2' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let d of 'estimateTemplate'">
            <span class="current-value">{{ d.value }}%</span>
        </div>
        <div *dxTemplate="let d of 'percentageToRequestTemplate'">
            <span class="current-value">{{ d.value }}%</span>
        </div>
        <div *dxTemplate="let d of 'importeTemplate'">
            <span class="current-value">${{ d.value }}</span>
        </div>
        <div *dxTemplate="let data of 'costOriginalTemplate'">
            <span>${{ data.value | number }}</span>
        </div>
        <div *dxTemplate="let d of 'estimateamountTemplate'">
            <span *ngIf="d.data.concept === 'Fondo de garantia'"> - </span>
            <span class="current-value">${{ d.value }}</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'asignmentIdTemplate'">
            <span *ngIf="data.value">ASG-{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'idTemplate'">
            <span *ngIf="data.value">{{ data.value }}</span> <span *ngIf="!data.value">NA</span>
        </div>

        <dxo-summary [calculateCustomSummary]="calculateSelectedRow">
            <dxi-total-item column="concept" summaryType="count" displayFormat="Conceptos: {0}">
            </dxi-total-item>

            <dxi-total-item name="subtotalPacks" summaryType="custom" [customizeText]="formatSubtotalPacks" displayFormat="${0}" showInColumn="total">
            </dxi-total-item>

            <!-- ---- -->
            <dxi-total-item name="nuevoSubtotal" summaryType="custom" [customizeText]="formatNuevoSubtotal" displayFormat="${0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok no mover-->
            <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok no mover-->
            <dxi-total-item *ngIf="addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <!-- --- -->
            <dxi-total-item *ngIf="!addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalSinIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>

            <!-- ok dont move muestra el TOTAL CUANDO TIENE FONDO DE GARANTIA  -->
            <dxi-total-item *ngIf="hasGuarantteFound && addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConFondoLetra" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <dxi-total-item *ngIf="hasGuarantteFound && !addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConFondoLetraSinIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <!-- -- -->
            <!-- ok dont move muestra el TOTAL CUANDO NO TIENE FONDO DE GARANTIA  -->
            <dxi-total-item *ngIf="!hasGuarantteFound && addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalLetra" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <dxi-total-item *ngIf="!hasGuarantteFound && !addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalLetraSinIVA" displayFormat="{0}" showInColumn="total">
            </dxi-total-item>
            <!-- -- -->
        </dxo-summary>

        <dxi-column></dxi-column>
    </dx-data-grid>

    <div class="has-fund" *ngIf="hasGuarantteFound">Incluye fondo de garantía</div>
    <br />

</app-page-widget>