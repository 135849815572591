import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetReportInGroupRequest } from '@bist-lib/reports/requests';
import { environment } from '@bist-web/environments/environment';
import { Observable } from 'rxjs';
import { GetReportInGroupResponse } from '@bist-lib/reports/responses';

@Injectable()
export class PowerBiService {

  constructor(private http: HttpClient) { }

  /**
   * Returns an access token to use Power Bi Rest Api
   * @async
   * @function  getReportInGroup
   * @param     {string}        accessToken - generated when authenticating with Azure's Active Directory tenant.
   * @param     {Object}        getReportInGroup - details about Power Bi's groupId and reportId.
   * @param     {string}        getReportInGroup.groupId - id of Power Bi workspace.
   * @param     {string}        getReportInGroup.reportId - id of Power Bi report we're trying to embed.
   */
  public getReportInGroup(getReportInGroup: GetReportInGroupRequest): Observable<GetReportInGroupResponse> {

    const endpoint: string = environment.powerBiConfig.getReportUrl;
    const httpOptions: {} = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<GetReportInGroupResponse>(endpoint, getReportInGroup, httpOptions);

  }
}
