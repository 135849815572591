<div fxLayout="row" class="app-widget-container">
  <mat-card fxFlex class="app-widget-skel" [class.flat]="flat" [class.round]="round">
    <rmd-loading-indicator *ngIf="performingAction && !loading" bar [percent]="actionPercent">
    </rmd-loading-indicator>

    <!-- Performing action protection -->
    <div *ngIf="performingAction && !noBlocker" class="blocker"></div>

    <mat-card-title
      *ngIf="!noHeader"
      class="app-widget-head"
      [class.stretch]="stretchHeader"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="widget-title-section header">
        <div *ngIf="displayBackButton" class="back-button">
          <ng-template #backBtnWithObservers>
            <app-go-back-button flat noText icon="arrow_back" (click)="back.emit(null)">
            </app-go-back-button>
          </ng-template>

          <app-go-back-button
            *ngIf="!back?.observers.length; else backBtnWithObservers"
            flat
            noText
            icon="arrow_back"
          >
          </app-go-back-button>
        </div>

        <div class="title" [class.bigtext]="bigText">
          <span *ngIf="title" class="toptitle" [innerText]="title"></span>
          <span *ngIf="subTitle" class="subtitle" [innerText]="subTitle"></span>
        </div>
      </div>
      <div class="widget-title-section buttons">
        <ng-template [ngIf]="!actionsOnBottom && actionButtons?.length">
          <app-button
            *ngFor="let actionButton of actionButtons"
            class="action-button"
            [flat]="actionButton.flat"
            [icon]="
              actionButton.icon ||
              (actionButton.flat && !actionButton.text?.length ? 'build' : null)
            "
            [tooltip]="actionButton.tooltip"
            [text]="actionButton.text"
            [noText]="actionButton.flat || !actionButton.text?.length"
            (click)="onMenuOrActionClick(actionButton)"
          >
          </app-button>
        </ng-template>

        <button
          *ngIf="refresh?.observers?.length"
          tabindex="-1"
          class="flat-button"
          mat-icon-button
          matTooltip="Actualizar"
          (click)="refresh.emit()"
        >
          <mat-icon>refresh</mat-icon>
        </button>

        <button
          *ngIf="newStuff?.observers?.length"
          tabindex="-1"
          class="flat-button"
          mat-icon-button
          matTooltip="Nuevo(a)"
          (click)="newStuff.emit()"
        >
          <mat-icon>add</mat-icon>
        </button>

        <a
          *ngIf="helpLink"
          tabindex="-1"
          class="flat-button helplink"
          mat-icon-button
          matTooltip="Abrir página de ayuda relacionada"
          target="_blank"
          [href]="helpLink"
        >
          <mat-icon>help</mat-icon>
        </a>

        <button
          *ngIf="menuItems?.length"
          tabindex="-1"
          class="flat-button"
          mat-icon-button
          matTooltip="Opciones"
          [matMenuTriggerFor]="widgetMenu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <button
          *ngIf="dismiss?.observers?.length"
          tabindex="-1"
          class="flat-button"
          mat-icon-button
          matTooltip="Cerrar"
          (click)="dismiss.emit()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <ng-template #loadingTpl>
      <rmd-loading-indicator [percent]="loadPercent"></rmd-loading-indicator>
    </ng-template>

    <mat-card-content *ngIf="!loading; else loadingTpl" [class.nopadding]="noPadding">
      <!-- CONTENT -->
      <ng-content></ng-content>
    </mat-card-content>

    <ng-template [ngIf]="actionsOnBottom && actionButtons?.length">
      <mat-card-actions>
        <app-button
          *ngFor="let actionButton of actionButtons"
          [flat]="actionButton.flat"
          [icon]="
            actionButton.icon || (actionButton.flat && !actionButton.text?.length ? 'build' : null)
          "
          [tooltip]="actionButton.tooltip"
          [text]="actionButton.text"
          [noText]="!actionButton.text?.length"
          (click)="onMenuOrActionClick(actionButton)"
        >
        </app-button>
      </mat-card-actions>
    </ng-template>

    <mat-menu #widgetMenu="matMenu">
      <button
        *ngFor="let menuItem of menuItems"
        mat-menu-item
        tabindex="-1"
        (click)="onMenuOrActionClick(menuItem)"
      >
        <mat-icon *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
        <span [innerText]="menuItem.title || ' - '"></span>
      </button>
    </mat-menu>
  </mat-card>
</div>
