<app-page-widget #pageWidget (refresh)="budgetWidget.refreshData()">
    <app-title>Resumen de Presupuesto</app-title>
    <app-subtitle>BIST</app-subtitle>

    <app-budget-summary-table #budgetWidget></app-budget-summary-table>
    <!-- <iframe class="responsive-iframe" src="https://datastudio.google.com/embed/reporting/f8fe6fab-7533-4075-87ee-7a5f2f93a8a7/page/Y070B" frameborder="0" style="border:0" allowfullscreen></iframe> -->
    <!-- <div #vizContainer style="display: flex; justify-content: center"></div> -->
    <!-- <div class='tableauPlaceholder' style='width: 1000px; height: 850px;'><object class='tableauViz' width='1000' height='850' style='display:none;'><param name='host_url' value='https%3A%2F%2Fprod-useast-b.online.tableau.com%2F' /> <param name='embed_code_version' value='3' /> <param name='site_root' value='&#47;t&#47;virtualweb' /><param name='name' value='indirect&#47;Dashboard1' /><param name='tabs' value='yes' /><param name='toolbar' value='yes' /><param name='showAppBanner' value='false' /></object></div> -->
    <!-- <iframe width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=c6cf1676-d065-4520-9fea-fd674c35b239&groupId=ac39c53f-cc19-48a1-9bfc-58e42cd0d98d&autoAuth=true&ctid=c11c0995-fd10-442b-b05b-d582c8849e9a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWNlbnRyYWwtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
        frameborder="0" allowFullScreen="true"></iframe> -->
</app-page-widget>