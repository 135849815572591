<span *ngFor="let quote of SUPPLIER_QUOTEDATA; let i= index" class="supplier-btn-wrap" matTooltip="{{ !quote.supplierQuoteLinkFile ?
    'NOTA:
    NO HA COTIZADO (NECESITA
    ADJUNTAR UN ARCHIVO DE COTIZACIÓN)' : 'Click para comparar' }}">
    <button mat-raised-button [color]="this.btnOptions[i] === true ? 'primary' :
        'basic'" [disabled]="!isQuoted(quote)" (click)="toggleSelect(false, i,
        quote)">
        <strong>{{ quote?.supplierId | supplierData | async }}</strong>
        <mat-icon matPrefix>
            {{
            !!this.btnOptions[i]
            ? 'check_circle'
            : !quote.supplierQuoteLinkFile
            ? 'warning'
            : 'add_circle'
            }}
        </mat-icon>
    </button>
</span>

<!--
  <div class="dx-field">
    <div class="dx-field-value">
      <dx-text-box placeholder="Buscar por concepto para comparar"
                   value=""
                   [showClearButton]="true"
                   valueChangeEvent="keyup"
                   (onValueChanged)="onSearchValueChanged($event)">
      </dx-text-box>
    </div>
  </div>
-->
<!-- ============================================================== -->
<!-- Opcion 2 -->
<!-- ============================================================== -->
<!-- <mat-grid-list cols="1" rowHeight="300px"> -->

<!-- ============================================================== -->
<!-- GRID DEVEXTREME COMPARATIVO -->
<!-- ============================================================== -->
<div class="mat-cuotes-content" fxLayout="row" fxLayoutAlign="start stretch">
    <div class="concept-data" [class.margin]="!!SUPPLERS_TO_COMPARE.length">
        <dx-data-grid #quoteGrid id="quoteGrid" width="500px" heigth="500px" [dataSource]="ELEMENT_DATA" [masterDetail]="{ enabled: false,
            template:
            'detail' }" [hoverStateEnabled]="false" [wordWrapEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Seleccionar de
            proveedores." [showBorders]="true" [cellHintEnabled]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="false" [allowColumnReordering]="false" [allowColumnResizing]="false">
            <dxo-editing mode="cell" [allowUpdating]="false"></dxo-editing>
            <dxo-export [enabled]="false" fileName="Cotizacion"></dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-search-panel [visible]="true" placeholder="Buscar en
                solicitados..." [highlightSearchText]="false"></dxo-search-panel>
            <dxo-paging [pageSize]="30"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="Requisición" dataField="requisitionNro" width="25%" cellTemplate="nroRequisitionTemplate" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" width="70%" cellTemplate="conceptTemplate" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <!--
        <dxi-column caption="Unidad"   dataField="unit"     width="6%"  [allowSorting]="false"  alignment="center"> </dxi-column>
      -->
            <dxi-column caption="Cantidad" dataField="quantity" width="15%" [allowSorting]="false" cellTemplate="quantityTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="P.U." dataField="cost" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <!--
        <dxi-column caption="Total"    dataField="cost"     width="15%" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotalCost" alignment="center" > </dxi-column>
      -->
            <div *dxTemplate="let data of 'conceptTemplate'; let i= index" class="min-height-column-template">
                <span *ngIf="data.value.length> maxLengthConcept" matTooltip="{{data.value}}" class="fontSmal">{{
                    data.value.length > maxLengthConcept
                    ? (data.value | slice: 0:maxLengthConcept) + '...'
                    : data.value
                    }}
                </span>
                <span *ngIf="data.value.length <= maxLengthConcept">{{
                    data.value
                    }}</span>
            </div>
            <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                <span matTooltip="{{data.value | number: '1.2-3'}}">{{
                    data.value |
                    number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'costTemplate'" class="cost">
                <span>${{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'nroRequisitionTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'totalTemplate'" class="cost">
                <span>${{ data.value | number }}</span>
            </div>
            <dxo-summary>
                <dxi-total-item column="Total" summaryType="sum" [customizeText]="formatTotal">
                </dxi-total-item>
            </dxo-summary>
            <!-- ============================================================== -->
            <!-- Master Detail -->
            <!-- ============================================================== -->
            <div *dxTemplate="let detail of 'detail'"></div>
        </dx-data-grid>

        <!-- ============================================================== -->
        <!-- END GRID COPARATIVO DEVEXTREME -->
        <!-- ============================================================== -->
    </div>

    <!-- ============================================================== -->
    <!-- GRID PROVEEDORES -->
    <!-- ============================================================== -->

    <div class="s-tables-wrapper">
        <div class="s-tables-wrapper-h">
            <div *ngFor="let quote of SUPPLERS_TO_COMPARE; let i= index" class="mat-custom-table">
                <div class="align-rigth">
                    <strong>Cotización: </strong>
                    <a mat-icon-button [href]="quote.supplierQuoteLinkFile" target="_blank">
                        <mat-icon>file_download</mat-icon>
                    </a>
                    <strong class="center-align"></strong>
                    <app-supplier-data [id]="quote.supplierId"></app-supplier-data>
                </div>
                <div [ngClass]="blocker">
                    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                    <dx-data-grid #supplierQuoteGrid id="supplierQuoteGrid" width="700px" [wordWrapEnabled]="true" [dataSource]="quote.packagesToQuote" [masterDetail]="{
                            enabled: true, template: 'detail' }" [hoverStateEnabled]="false" [wordWrapEnabled]="true" [showColumnLines]="true" [showRowLines]="true" noDataText="Detalle de cotización." [showBorders]="true" [rowAlternationEnabled]="false"
                        [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false">
                        <!-- (onSelectionChanged)="selectionChangedHandler($event, quote)" [selectedRowKeys]="selectedPackages" -->
                        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
                        <dxo-export [enabled]="false" fileName="Cotizacion"></dxo-export>
                        <dxo-filter-row [visible]="false"></dxo-filter-row>
                        <dxo-search-panel [visible]="true" placeholder="Buscar en
                                cotización..." [highlightSearchText]="false"></dxo-search-panel>
                        <dxo-sorting mode="none"> </dxo-sorting>
                        <dxo-paging [pageSize]="1000000"></dxo-paging>
                        <dxo-selection allowSelectAll="true" selectAllMode="page" showCheckBoxesMode="always" mode="single">
                        </dxo-selection>
                        <dxi-column caption="#" dataField="selectedForPo" width="10%" cellTemplate="checkboxTemplate" [allowEditing]="false"></dxi-column>
                        <dxi-column caption="CC" dataField="concept" cellTemplate="ccTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false" alignment="center">
                        </dxi-column>
                        <dxi-column caption="Cantidad cotizada" width="20%" dataField="supplierQuotedQuantity" cellTemplate="supplierQuotedQuantityTemplate" [allowEditing]="false" [allowSorting]="true" alignment="left"></dxi-column>
                        <dxi-column caption="Cantidad a comprar" width="20%" dataField="purchasedQuantity" cellTemplate="purchasedQuantityTemplate" [allowEditing]="true" [allowSorting]="true" alignment="left"></dxi-column>
                        <dxi-column caption="Costo" width="30%" dataField="supplierCost" cellTemplate="supplierCostTemplate" [allowEditing]="false" [allowSorting]="true" alignment="left"></dxi-column>
                        <dxi-column caption="Total" width="20%" [calculateCellValue]="calculateSuplierTotalCost" cellTemplate="supplierTotalTemplate" [allowEditing]="false" [allowSorting]="true" alignment="left">
                        </dxi-column>

                        <div *dxTemplate="let data of 'supplierCostTemplate'" [class.inc]="data.data.cost> data.value" [class.dec]="data.data.cost
                                <data.value" [class.equ]="data.data.cost == data.value">
                            <span class="current-value">${{ data.value || 0 |
                                        number }}</span>
                            <!-- Costo más caro -->
                            <span class="diff" *ngIf="data.data.cost
                                    <data.value">
                                        {{ (data.value * data.data.requiredQuantity) -
                                        (data.data.cost *
                                        data.data.requiredQuantity) | number }}
                                    </span>

                            <!-- Ahorro costo mas bajo -->
                            <span class="diff" *ngIf="data.data.cost>
                                        data.value">
                                        {{ (data.value * data.data.requiredQuantity) -
                                        (data.data.cost *
                                        data.data.requiredQuantity)| number }}
                                    </span>

                            <!-- Costo igual -->
                            <span class="diff" *ngIf="data.data.cost ==
                                        data.value">
                                        ${{ data.value || 0 | number }}
                                    </span>
                        </div>

                        <div *dxTemplate="let data of
                                    'supplierQuotedQuantityTemplate'">
                            <span>{{ data.data.supplierQuotedQuantity | number:
                                        '1.2-4' }}</span>
                        </div>

                        <div *dxTemplate="let data of
                                    'purchasedQuantityTemplate'">
                            <ng-template [ngIf]="data.data.purchasedQuantity" [ngIfElse]="noPurchasedQuantity">
                                <span>{{ data.data.purchasedQuantity | number:
                                            '1.2-2' }}</span>
                            </ng-template>

                            <ng-template #noPurchasedQuantity>
                                <small class="muted">Introduzca cantidad</small>
                            </ng-template>

                        </div>

                        <div *dxTemplate="let data of 'supplierTotalTemplate'">
                            <strong *ngIf="data.value !== 0">${{ data.value |
                                        number }}</strong>
                            <span *ngIf="data.value === 0">-</span>
                        </div>

                        <div *dxTemplate="let data of 'ccTemplate'" class="handOver">
                            <mat-icon (click)="displayInCostCenter(data.key)">
                                <span matTooltip="Ver centro de Costos">insert_chart</span>
                            </mat-icon>
                        </div>

                        <div *dxTemplate="let data of 'checkboxTemplate'; let i=
                                    index" [ngClass]="data.value ? 'selectedAlready' :
                                    'availableToSelect'">
                            <ng-template #ocCancelled>
                                <mat-icon class="gray" matTooltip="Órden de
                                            compra cancelada">delete
                                </mat-icon>
                            </ng-template>

                            <ng-template #normalControls>
                                <ng-container *ngIf="!loading">
                                    <ng-template #available [ngIf]="!data.value" [ngIfElse]="alreadySelected">
                                        <!-- if is avaliblre to assign provider -->
                                        <mat-icon (click)="validatePackageSelection(quote,
                                                    data.value,
                                                    data.data)">
                                            <span matTooltip="Asignar">check_box_outline_blank</span>
                                        </mat-icon>

                                    </ng-template>

                                    <ng-template #alreadySelected>
                                        <!-- if already selected -->
                                        <mat-icon #unSelected (click)="validatePackageSelection(quote,
                                                    data.value,
                                                    data.data)">
                                            <span>check_box</span>
                                        </mat-icon>
                                    </ng-template>
                                </ng-container>

                                <!--              <div *ngIf="data.value; then: alreadySelected; else: available"></div>-->
                            </ng-template>

                            <div *ngIf="data.data.status === 3; then:
                                        ocCancelled; else:
                                        normalControls"></div>
                        </div>

                        <dxo-summary>
                            <dxi-total-item column="Total" summaryType="sum" [customizeText]="formatTotal">
                            </dxi-total-item>
                        </dxo-summary>
                        <!-- ============================================================== -->
                        <!-- Master Detail -->
                        <!-- ============================================================== -->
                        <div *dxTemplate="let detail of 'detail'">
                            <h2>
                                <div class="master-detail-caption">
                                    ID Cotización: <strong>{{ quote.id }}</strong>
                                </div>
                            </h2>
                            <div>
                                <strong>Marca solicitada:</strong> {{ detail.data.requiredBrand }}
                                <br />
                                <strong>Modelo solicitado:</strong> {{ detail.data.requiredModel }}
                                <br />
                                <strong>Notas de la requisición:</strong> {{ detail.data.requiredNotes }} <br />
                                <hr />
                                <strong>Marca cotizada:</strong> {{ detail.data.supplierBrand }}
                                <br />
                                <strong>Modelo cotizado:</strong> {{ detail.data.supplierModel }} <br />
                                <strong>Notas de proveedor:</strong> {{ detail.data.supplierComments }}
                                <br />
                                <strong>Condiciones Comerciales:</strong> {{ detail.data.supplierAgreements }} <br />
                                <strong>Entrega:</strong> {{ detail.data.supplierDeliveryTime }} {{ detail.data.supplierDelivery }} <br />
                            </div>
                            <div>
                                <strong>Ficha Técnica: </strong>
                                <ng-template [ngIf]="detail.data.dataSheetLinkFile !==
                                            ''" [ngIfElse]="noAvailable">
                                    <a mat-icon-button [href]="detail.data.dataSheetLinkFile" target="_blank">
                                        <mat-icon>file_download</mat-icon>
                                    </a>
                                </ng-template>
                                <ng-template #noAvailable>
                                    <strong>No Disponible</strong>
                                </ng-template>
                            </div>
                        </div>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>

<app-dialog #leftoverDialog width="900px" title="Merma/Stock" [mat-dialog-close]='true'>

    <table mat-table [dataSource]="[selectedLeftover]" class="width-100
            mat-elevation-z1" *ngIf="selectedLeftover">

        <ng-container matColumnDef="concept">
            <th mat-header-cell *matHeaderCellDef> Concepto </th>
            <td mat-cell *matCellDef="let element"> {{element?.concept}}
            </td>
        </ng-container>

        <ng-container matColumnDef="supplierId">
            <th mat-header-cell *matHeaderCellDef> Proveedor </th>
            <td mat-cell *matCellDef="let element">
                {{element?.selectedSupplierId | supplierData | async}} </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Cantidad </th>
            <td mat-cell *matCellDef="let element">
                {{element?.leftoverQuantity | number: '1.2-3'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="text-center">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="element.leftoverType">
                    <mat-radio-button value="waste" class="margin-right">Merma</mat-radio-button>
                    <mat-radio-button value="stock">Stock</mat-radio-button>
                </mat-radio-group>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="leftoverDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns:
                leftoverDisplayedColumns;"></tr>
    </table>

    <div class="margin-top-2">
        <button mat-raised-button color="primary" (click)="dialogDismissed(true)" *ngIf="selectedLeftover &&
                selectedLeftover.leftoverType">
                Guardar
            </button>
    </div>

</app-dialog>

<app-cost-center-opt #ccDialog></app-cost-center-opt>