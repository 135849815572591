<div class="pwr mat-elevation-z12">
    <div class="header" fxLayout="column" fxLayoutAlign="center center">
        <div class="logo-wrapper">
            <img class="logo" alt="BIST" src="/assets/img/logo/bist.png">
        </div>

        <h1 class="app-title">Bist ERP</h1>
    </div>

    <app-widget flat>
        <app-title>Recuperar Contraseña</app-title>

        <ng-template #emailSentTpl>
            <p>
                <b>Hecho!</b> Revise su bandeja de entrada.
            </p>
        </ng-template>

        <div *ngIf="!emailSent; else emailSentTpl">
            <app-form [fields]="formFields" [model]="formData" [noCancelButton]="false" saveButtonText="Recuperar Acceso" (save)="sendEmail($event.value)" (cancel)="gotoLoginPage()">
            </app-form>
        </div>
    </app-widget>

    <!-- TODO:
  <p class="note">
    No tiene cuenta de usuario?
    <br>
    <a routerLink="/auth/register">Solicitar acceso</a>
  </p>
  -->
</div>