import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Remission, RemissionPackage, RemissionsService } from '@bist-lib/db-api';
import { Entry } from '@bist-lib/db-api/src/models/remission';
import { PageWidgetComponent } from '@bist-web/shared';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-remission-details',
  templateUrl: './remission-details.component.html',
  styleUrls: ['./remission-details.component.scss']
})
export class RemissionDetailsComponent implements OnInit {
  @ViewChild(PageWidgetComponent, { static: true }) public pageWidget: PageWidgetComponent;

  private remissionId: string;
  public remission: Remission;
  public remissionPackages: RemissionPackage[];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _remissionsService: RemissionsService
  ) {}

  ngOnInit() {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.remissionId = params.remissionId;
      this.refreshData();
    });
  }

  refreshData(): void {
    this.pageWidget.toggleLoading(true);
    this._remissionsService.getObservable(this.remissionId).subscribe(
      async (remission: Remission) => {
        const dataEntries = await this._remissionsService
          .getRemissionsCollection(remission.uuid, 'entries')
          .getList()
          .pipe(take(1))
          .toPromise();

        dataEntries
          .filter((singleEntry) => singleEntry.type === 1)
          .map((entry: Entry) => {
            const matchedPackageIndex: number = remission.packages.findIndex(
              (singlePackage: RemissionPackage) => singlePackage.concept === entry.concept
            );
            if (matchedPackageIndex !== -1) {
              remission.packages[matchedPackageIndex].quantitySent = entry.quantity;
              remission.packages[matchedPackageIndex].quantityApproved = entry.quantityReceived;
            }
          });

        this.remission = remission;
        this.remissionPackages = remission.packages;
        this.pageWidget.toggleLoading(false);
      },
      (err: any) => console.log(err)
    );
  }
}
