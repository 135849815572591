<app-page-widget noPadding (refresh)="refreshData()">
  <app-title>Remisiones</app-title>
  <app-subtitle>Listado</app-subtitle>

  <div class="grid-container-div padding-h padding-bottom-2">
    <dx-data-grid
      #quotesGrid
      id="grid-container"
      [wordWrapEnabled]="true"
      [dataSource]="remissions"
      [masterDetail]="{ enabled: false, template: 'detail' }"
      [hoverStateEnabled]="false"
      [showColumnLines]="true"
      [showRowLines]="true"
      noDataText="No hay registros"
      [showBorders]="false"
      [rowAlternationEnabled]="false"
      [columnAutoWidth]="true"
      [allowColumnReordering]="false"
      [allowColumnResizing]="false"
      (onRowClick)="onRemissionRowClick($event.key)"
    >
      <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-export [enabled]="true" fileName="lista-remisiones"></dxo-export>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>

      <dxi-column
        alignment="center"
        caption="ID"
        dataField="id"
        width="5%"
        [allowEditing]="false"
        [allowSearch]="true"
        [allowSorting]="false"
      >
      </dxi-column>
      <dxi-column
        caption="Fecha"
        dataField="createdAt"
        width="20%"
        dataType="date"
        cellTemplate="dateTemplate"
        sortOrder="desc"
        alignment="center"
        [allowEditing]="false"
        [allowSearch]="true"
        [allowSorting]="true"
        [calculateCellValue]="calculateDateCellValue"
      >
      </dxi-column>
      <dxi-column
        caption="Estado"
        dataField="status"
        width="10%"
        cellTemplate="statusTemplate"
        alignment="center"
        [allowEditing]="false"
        [allowFiltering]="false"
        [allowSorting]="false"
      >
      </dxi-column>
      <dxi-column
        caption="Recibió"
        cellTemplate="receiverTemplate"
        dataField="receiver"
        alignment="center"
        width="20%"
        [allowEditing]="false"
        [allowFiltering]="false"
        [allowSorting]="false"
      >
      </dxi-column>
      <dxi-column
        caption="Cantidad enviada"
        width="15%"
        dataField="quantitiesSentSum"
        cellTemplate="quantitySentTemplate"
        [allowEditing]="false"
        [allowSearch]="false"
        [allowFiltering]="false"
        [calculateCellValue]="calculateQuantitySentCellValue"
        [allowSorting]="false"
        alignment="center"
      >
      </dxi-column>
      <dxi-column
        caption="Cantidad recibida"
        dataField="quantityApproved"
        width="15%"
        cellTemplate="quantityApprovedTemplate"
        [allowEditing]="false"
        [allowSearch]="true"
        [allowSorting]="false"
        alignment="center"
      >
      </dxi-column>
      <dxi-column
        caption="Por surtir"
        width="15%"
        cellTemplate="quantityLeftToSendTemplate"
        [allowEditing]="false"
        [allowSearch]="true"
        dataField="quantityLeftToSend"
        [calculateCellValue]=""
        [allowSorting]="false"
        alignment="center"
      >
      </dxi-column>
      <div *dxTemplate="let data of 'dateTemplate'">
        <strong> {{ data.value | timestampToDate }}</strong> -
        <span> hace {{ data.value | timeAgo }}</span>
      </div>
      <div *dxTemplate="let data of 'statusTemplate'">
        <strong>{{ data.value | remissionStatus }}</strong>
      </div>
      <div *dxTemplate="let data of 'receiverTemplate'">
        <ng-container *ngIf="data.value; else noReceiver">
          <strong>{{ data.value }}</strong>
        </ng-container>
        <ng-template #noReceiver>
          --
        </ng-template>
      </div>
      <div *dxTemplate="let data of 'quantitySentTemplate'">
        <strong>{{ data.value | number: '1.2-2' }}</strong>
      </div>
      <div *dxTemplate="let data of 'quantityApprovedTemplate'">
        <ng-container *ngIf="data.value; else noQuantityApproved">
          <span> {{ data.value | number: '1.2-2' }}</span>
        </ng-container>
        <ng-template #noQuantityApproved>
          --
        </ng-template>
      </div>
      <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
        <ng-container *ngIf="data.value; else noQuantityLeft">
          {{ data.value | number: '1.2-2' }}
        </ng-container>
        <ng-template #noQuantityLeft>
          --
        </ng-template>

        <span> {{ data.value }}</span>
      </div>
    </dx-data-grid>
  </div>
</app-page-widget>
