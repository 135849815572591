<div main-grid-container>
    <div class="grid-error">
        <mat-error>{{ requiredMessage }}</mat-error>
    </div>

    <div class="grid-table">
        <dx-data-grid #dataGrid id="grid-container" [dataSource]="dataSource" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
            [allowColumnResizing]="true" [columnAutoWidth]="false" (onSelectionChanged)="onChangedRowsSelection($event)">
            <dxo-paging [pageSize]="5"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[15,
        30, 50, 100, 500, 1000, 2000]" [showInfo]="true">
            </dxo-pager>
            <!-- <dxo-search-panel [visible]="true" [width]="240" placeholder="Buscar..."> </dxo-search-panel> -->
            <!-- <dxo-header-filter [visible]="true"></dxo-header-filter> -->
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
            <dxi-column caption="ID" dataField="packageId" alignment="left" [allowEditing]="false" width="10%" [allowFiltering]="true"></dxi-column>
            <dxi-column caption="Fecha" dataField="requestDate" alignment="left" [allowEditing]="false" dataType="date" width="10%" [allowFiltering]="true" [calculateCellValue]="calculateDateCellValue" cellTemplate="dateTemplate"></dxi-column>
            <dxi-column caption="Nivel" dataField="level" alignment="left" width="10%" [allowEditing]="false" [allowFiltering]="true"></dxi-column>
            <dxi-column caption="Código" dataField="code" alignment="left" width="15%" [allowEditing]="false"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" alignment="left" width="25%" [allowEditing]="false"></dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" [allowEditing]="false" dataType="number" alignment="left" [allowFiltering]="false" width="10%"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" cellTemplate="unitTemplate" alignment="center" [allowEditing]="false" width="10%" [allowFiltering]="false"></dxi-column>
            <dxi-column caption="PU" dataField="unitPrice" cellTemplate="unitPriceTemplate" alignment="left" [allowEditing]="false" [allowFiltering]="false" width="10%"></dxi-column>
            <dxi-column caption="P.U Real" dataField="realPrice" cellTemplate="unitPriceTemplate" alignment="left" [allowEditing]="true" [allowFiltering]="false" width="10%"></dxi-column>
            <dxi-column caption="Costo Inicial" dataField="costOriginal" cellTemplate="costOriginalTemplate" [allowEditing]="false" alignment="left" [allowFiltering]="false" width="10%"></dxi-column>
            <dxi-column caption="Disponible" dataField="available" cellTemplate="costTemplate" alignment="left" [allowEditing]="false" [allowFiltering]="false" width="10%"></dxi-column>
            <dxi-column caption="En Pasivos" dataField="statusPassive" cellTemplate="passiveStatusTemplate" [allowFiltering]="false" alignment="center" width="10%"></dxi-column>
            <dxi-column *ngIf="canAddPassive" caption="Enviar a Pasivos" dataField="uuid" cellTemplate="passiveTemplate" [allowFiltering]="false" [allowEditing]="false" alignment="center" width="10%"></dxi-column>
            <div *dxTemplate="let data of 'dateTemplate'">
                <span>{{ data.value | timestampToDate }}</span>
            </div>
            <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                <span>{{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of
        'passiveStatusTemplate'">
                <span *ngIf="data.value === 2" class="icon-gray"><strong>Si</strong></span>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'unitPriceTemplate'" class="cost">
                <span>${{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'costOriginalTemplate'" class="cost">
                <span>${{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'costTemplate'" class="cost">
                <span>${{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'passiveTemplate'" class="cost">
                <span>
          <i class="material-icons icon-gray"
            matTooltip="Enviar paquete a pasivos"
            (click)="sendPassive(data)">
            input
          </i></span>
            </div>
        </dx-data-grid>
    </div>
</div>