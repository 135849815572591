<app-page-widget>
    <!-- <app-title>Centro de costos</app-title>
<app-subtitle>Busqueda</app-subtitle> -->
    <!-- TABLE -->
    <dx-data-grid id="gridContainer" #dataGrid [dataSource]="suppliersQuotePackages" [wordWrapEnabled]="true" [allowColumnReordering]="true" [showColumnLines]="false" [showRowLines]="true" [showBorders]="true" (onToolbarPreparing)="onToolbarPreparing($event)">

        <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxi-column dataField="code" caption="#" width="12%"></dxi-column>
        <dxi-column caption="Proveedor" dataField="supplierId" groupCellTemplate="supplierGroupTemplate" cellTemplate="supplierGroupTemplate" width="10%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left" width="22%"></dxi-column>
        <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" [groupIndex]="0" width="10%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
        <dxi-column dataField="unit" caption="Unidad" width="7%" alignment="center"></dxi-column>
        <dxi-column dataField="requiredQuantity" caption="Cantidad requerida" width="13%" alignment="center" cellTemplate="requiredQuantityTemplate"></dxi-column>
        <dxi-column dataField="purchasedQuantity" caption="Cantidad comprada" width="13%" alignment="center" cellTemplate="purchasedQuantityTemplate"></dxi-column>
        <dxi-column caption="Cantidad excedente" dataField="leftoverQuantity" width="10%" [allowSorting]="false" alignment="center" [calculateCellValue]="calculateLeftoverQuantityCellValue" cellTemplate="leftoverQuantityTemplate"></dxi-column>
        <dxi-column caption="Tipo de excedente" dataField="leftoverType" width="8%" [allowSorting]="false" alignment="center" [calculateCellValue]="calculateLeftoverTypeCellValue" cellTemplate="leftoverTypeTemplate">
        </dxi-column>
        <dxi-column caption="Costo" dataField="supplierCost" width="9%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Total" dataField="totalCost" width="15%" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotalCost" alignment="center">
        </dxi-column>
        <dxi-column caption="CC" cellTemplate="ccButtonTemplate" width="5%"></dxi-column>
        <dxo-summary [skipEmptyValues]="true">
            <dxi-group-item column="requiredQuantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
            </dxi-group-item>
            <dxi-group-item column="purchasedQuantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
            </dxi-group-item>
            <!-- <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item> -->
            <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
            </dxi-group-item>
        </dxo-summary>
        <div *dxTemplate="let data of 'requiredQuantityTemplate'" class="cost">
            <span>{{ data.value | number: '1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'purchasedQuantityTemplate'" class="cost">
            <ng-container *ngIf="data.value; else noQuantityPurchased">
                <span>{{ data.value | number: '1.2-3' }}</span>
            </ng-container>
            <ng-template #noQuantityPurchased>
                <span>--</span>
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'totalTemplate'" class="cost">
            <span>$ {{ data.value | number: '1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'conceptGroupTemplate'">
            {{ data.value }}
        </div>
        <div *dxTemplate="let data of 'supplierGroupTemplate'">
            {{ data.value | supplierData: 'costCenter' | async }}
        </div>
        <div *dxTemplate="let data of 'leftoverQuantityTemplate'" class="qty">
            <span>{{ data.value | number: '1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'leftoverTypeTemplate'" class="qty">
            <span *ngIf="data.value === 'waste'">Merma</span>
            <span *ngIf="data.value === 'stock'">Stock</span>
            <span *ngIf="data.value === ''"></span>
        </div>
        <div *dxTemplate="let data of 'costTemplate'" class="cost">
            <span>$ {{ data.value | number: '1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span><i class="material-icons icon-gray"
          (click)="onRowSelected(data.key)">
          remove_red_eye
        </i></span>
        </div>
        <dxo-search-panel placeholder="Buscar por concepto o código" width="50%" alignment="right" [visible]="true">
        </dxo-search-panel>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{totalCount}}</h2>
                <span class="name">Total Paquetes</span>
            </div>
        </div>
    </dx-data-grid>

    <!-- <app-cost-center #ccDialog></app-cost-center> -->
    <app-cost-center-opt #ccOptDialog></app-cost-center-opt>
</app-page-widget>