<ng-template #emptyTpl>
  <rmd-message warning
               [text]="emptyMsg">
  </rmd-message>
</ng-template>

<mat-list *ngIf="items?.length; else emptyTpl">
  <mat-list-item *ngFor="let item of items; trackBy: trackItem">
    <span *ngIf="thumbPlaceholder || item.avatarUrl || item.imageUrl || item.logoUrl || item.img"
          mat-list-avatar>
      <app-lazy-img [src]="item.avatarUrl || item.imageUrl || item.logoUrl || item.img || null">
      </app-lazy-img>
    </span>

    <h4 *ngIf="item.title || item.name"
        matLine
        class="title"
        [innerText]="item.title || item.name">
    </h4>

    <p *ngIf="item.subTitle || item.description || (item.id && !hideIds)"
       matLine
       class="subTitle"
       [innerText]="item.subTitle || item.description || ('ID: ' + item.id)"></p>

    <p *ngIf="item.text || item.content"
       matLine
       class="text"
       [innerText]="item.text || item.content">
    </p>
  </mat-list-item>
</mat-list>
