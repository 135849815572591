<app-page-widget (refresh)="refreshData()">
    <app-title>Reporte Cotizaciones</app-title>
    <report-detail *ngIf="isDataLoaded" [reportId]="reportId" [embedToken]="embedToken" [embedUrl]="embedUrl">
    </report-detail>
    <!-- <iframe *ngIf="isDataLoaded" width="1140" height="600" [src]="url | safehtml" frameborder="0" allowFullScreen="true"></iframe> -->
    <!-- <iframe width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId={{reportId}}&autoAuth=true&ctid=019573ca-d8fb-4057-a30f-a33ed56446e0&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
        frameborder="0" allowFullScreen="true"></iframe> -->
    <app-loading-indicator [loadingVisible]="loadingVisible" [message]="messageInfoProcess">
    </app-loading-indicator>
    <div *ngIf="error" class="text-center">
        <h3>Lo sentimos, intente más tarde</h3>
    </div>
</app-page-widget>