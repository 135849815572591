<app-page-widget noPadding>
  <app-title>Nueva Remisión</app-title>

  <div class="stepper">
    <mat-horizontal-stepper [linear]="true">
      <mat-step label="{{ steps[0].label }}" [stepControl]="stepOneFormGroup">
        <form [formGroup]="stepOneFormGroup">
          <div style="margin-bottom: 20px">
            <button
              mat-raised-button
              color="primary"
              matStepperNext
              [disabled]="!packagesWithQuantityToSend"
            >
              <mat-icon>navigate_next</mat-icon>
              Siguiente
            </button>
          </div>
          <div *ngIf="stepLoading" class="spinner-wrapper">
            <mat-spinner [diameter]="60"></mat-spinner>
          </div>
          <div *ngIf="!stepLoading" class="step-one">
            <app-grid-select-grouped-rows
              [formControlName]="'selectPackagesCtrl'"
              required
              [gridType]="'purchaseOrderGrid'"
              [dataSource]="purchaseOrders"
              [columns]="columnRemissions"
              [packageColumns]="columnPackages"
              [(ngModel)]="selectedPackages"
              (change)="changedSelectedPackages($event)"
              [requiredMessage]="'No hay paquetes seleccionados *'"
            >
            </app-grid-select-grouped-rows>
          </div>
        </form>
      </mat-step>
      <mat-step label="{{ steps[1].label }}" [stepControl]="stepTwoFormGroup">
        <form [formGroup]="stepTwoFormGroup">
          <div style="margin-bottom: 20px">
            <button mat-raised-button color="primary" matStepperPrevious>
              <mat-icon>navigate_before</mat-icon>
              Anterior
            </button>
            <button
              mat-raised-button
              color="primary"
              style="margin-left: 15px"
              [disabled]="!create"
              (click)="createRemission()"
            >
              <mat-icon>done</mat-icon>
              Crear Remisión
            </button>
          </div>
          <div *ngIf="stepLoading" class="spinner-wrapper">
            <mat-spinner [diameter]="60"></mat-spinner>
          </div>
          <div class="step-two" *ngIf="!stepLoading">
            <div class="grid-container">
              <dx-data-grid
                #dataGrid
                id="grid-container"
                [columns]="columnPackages"
                [dataSource]="selectedPackages"
                [wordWrapEnabled]="false"
                [hoverStateEnabled]="false"
                [showColumnLines]="true"
                [showRowLines]="true"
                [showBorders]="true"
                [rowAlternationEnabled]="false"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [columnAutoWidth]="false"
              >
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager
                  [showPageSizeSelector]="true"
                  [allowedPageSizes]="[5, 10, 15]"
                  [showInfo]="true"
                >
                </dxo-pager>

                <div *dxTemplate="let data of 'quantityTemplate'">
                  <strong>{{ data.value | number: '1.2-2' }}</strong>
                </div>

                <div *dxTemplate="let data of 'quantityToSendTemplate'">
                  <ng-container *ngIf="data.value; else noQuantityToSend">
                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                  </ng-container>
                  <ng-template #noQuantityToSend>
                    <span style="color:#c9c9c9">Introduzca cantidad a enviar</span>
                  </ng-template>
                </div>

                <div *dxTemplate="let data of 'quantitySentTemplate'">
                  <ng-container *ngIf="data.value; else noQuantitySent">
                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                  </ng-container>
                  <ng-template #noQuantitySent>
                    --
                  </ng-template>
                </div>

                <div *dxTemplate="let data of 'approvedQuantityTemplate'">
                  <ng-container *ngIf="data.value; else noQuantitySent">
                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                  </ng-container>
                  <ng-template #noQuantitySent>
                    --
                  </ng-template>
                </div>
              </dx-data-grid>
            </div>
            <!-- END GRID SUPPLIERS -->
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</app-page-widget>

<!-- <app-preview
  #previewDialog
  [suppliers]="selectedSuppliers"
  [subTitle]="'Proveedores seleccionados para esta Cotización'"
  [gridType]="'supplier'"
  (previewItems)="supplierSelectionChangedByPreview($event)"
>
</app-preview> -->
