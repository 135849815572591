<rmd-base-cp><ng-content></ng-content></rmd-base-cp>

<app-button class="app-go-back-button icon-no-space"
            [icon]="icon || 'arrow_back_ios'"
            [text]="text"
            [tooltip]="tooltip"
            [noText]="noText"
            [disabled]="disabled"
            [flat]="flat"
            [small]="small"
            (click)="onClick($event)">
</app-button>
