<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>ANT- {{ advancedPayInfo?.uuid | idFromUuid }}</app-title>
    <app-subtitle>{{ advancedPayInfo?.createdAt | timestampToDate:'long' }}</app-subtitle>
    <app-action-button flat noText icon="done_all" (click)="displayApprovals()">Aprobaciones</app-action-button>
    <div [hidden]="!cancelIsAvailable">
        <app-menu-item icon="close" (click)="confirmCancel()">Cancelar</app-menu-item>
    </div>

    <br>

    <div class="header" fxLayout="row" fxLayoutAlign="space-between flex-start">
        <div class="status-block">
            <div>
                <span class="info-label">Estado:
                    <strong>{{ advancedPayInfo?.status | advancedPaymentStatus
                        }}</strong>
                </span>
            </div>
            <div>
                <span class="info-label">Solicitado por:
                    <app-user-data *ngIf="advancedPay"
                        [id]="advancedPayInfo.createdBy"></app-user-data>
                </span>
            </div>
            <div>
                <span class="info-label">Proveedor:
                    <app-supplier-data *ngIf="advancedPay"
                        [id]="advancedPayInfo.supplierId"></app-supplier-data>
                </span>
            </div>
        </div>

        <div class="actions-block">
            <button mat-raised-button class="action-button mr-5" (click)="goToPreviousUlr()" color="warn">
                <mat-icon>undo</mat-icon>
                Regresar
            </button>
            <ng-template [ngIf]="sendToApproveIsAvailable">
                <app-button *ngxPermissionsOnly="['advanced-payments:can_send_to_approve']" icon="call_made" (click)="sendToApprove()">Enviar a Aprobar</app-button>
            </ng-template>
            <ng-template [ngIf]="approveIsAvailable && !hideApproval">
                <app-button *ngxPermissionsOnly="['advanced-payments:can_approve']" icon="done" (click)="approve()">Aprobar</app-button>
            </ng-template>
        </div>
    </div>

    <!-- ============================================================== -->
    <!-- GRID advancedPayments detail                                   -->
    <!-- ============================================================== -->
    <dx-data-grid #advancedPayGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="advancedPay" [masterDetail]="{ enabled: false, template:
        'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Sin datos." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true">
        <dxo-paging [pageSize]="5"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
        </dxo-pager>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxo-search-panel [visible]="false"></dxo-search-panel>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column caption="id" dataField="id" width="6%" cellTemplate="idTemplate" [allowEditing]="false"></dxi-column>
        <dxi-column caption="Fecha" dataField="createdAt" width="15%" cellTemplate="dateTemplate" [allowEditing]="false" dataType="date" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" sortOrder="desc" alignment="left"></dxi-column>
        <dxi-column caption="Proveedor" dataField="supplierId" cellTemplate="supplierTemplate" alignment="center" width="25%"></dxi-column>
        <dxi-column caption="Monto" dataField="advancedPaymentAmount" cellTemplate="advancedPaymentAmountTemplate" width="10%" alignment="center" [allowEditing]="false"></dxi-column>
        <dxi-column caption="Solicitado por" dataField="createdBy" width="20%" cellTemplate="createdByTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false"> </dxi-column>
        <dxi-column caption="Status" dataField="status" cellTemplate="statusTemplate" width="10%" alignment="center" [allowEditing]="false"></dxi-column>
        <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>

        <div *dxTemplate="let data of 'idTemplate'">
            <strong>{{data.value}}</strong>
        </div>
        <div *dxTemplate="let data of 'advancedPaymentAmountTemplate'">
            <strong>${{data.value | number}}</strong>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <strong>{{ data.value | userData | async }}</strong>
        </div>
        <div *dxTemplate="let data of 'supplierTemplate'">
            <strong>{{ data.value | supplierData | async }}</strong>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'">
            <strong>{{ data.value | estimateStatus}}</strong>
        </div>
        <div *dxTemplate="let data of 'typeTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>

    </dx-data-grid>
    <!-- ============================================================== -->
    <!-- END GRID advancedPayment detail                                           -->
    <!-- ============================================================== -->
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess"></app-loading-indicator>