<app-page-widget noPadding
                 helpLink="http://docs.bist.mx/roles-materiales/"
                 (refresh)="refreshData()">
  <app-title>Roles</app-title>
  <app-subtitle>Seleccione para gestionar miembros y permisos</app-subtitle>

  <app-action-button icon="group_add"
                     (click)="openManagementDialog()">Crear</app-action-button>
  <app-menu-item icon="add"
                 (click)="permissionsQuickAddDialog.open()">Crear permiso</app-menu-item>

  <app-datatable #rolesDatatable
                 canSelect
                 [columns]="rolesDtColumns"
                 [rows]="roles"
                 (changedRowsSelection)="openManagementDialog($event)">
  </app-datatable>
</app-page-widget>

<!-- Management Dialog -->
<app-dialog #roleManagementDialog
            noPadding
            width="60%"
            (dismiss)="onCloseManagementDialog()">
  <app-title>{{ selectedRole ? (selectedRole.description || selectedRole.name) : 'Datos del Rol' }}</app-title>
  <app-subtitle>{{ selectedRole ? 'Datos del Rol' : '' }}</app-subtitle>

  <app-menu-item icon="delete"
                 (click)="deleteRole(selectedRole)">Eliminar Rol</app-menu-item>

  <mat-tab-group [dynamicHeight]="true">
    <mat-tab label="Datos">

      <app-form padding
                [fields]="roleFormFields"
                [model]="selectedRole || {}"
                [noCancelButton]="false"
                (save)="saveRole($event.value)"
                (cancel)="roleManagementDialog.close()">
      </app-form>

    </mat-tab>
    <mat-tab *ngIf="selectedRole"
             label="Miembros">

      <app-datatable canSelectMultiple
                     selectWithCheckboxes
                     [filterBy]="['id', 'displayName', 'position']"
                     [columns]="usersDtColumns"
                     [rows]="users"
                     [selected]="selectedRoleMembers"
                     (changedRowsSelection)="setRoleMembers(selectedRole, $event)">
      </app-datatable>

    </mat-tab>
    <mat-tab *ngIf="selectedRole"
             label="Permisos">

      <app-datatable canSelectMultiple
                     selectWithCheckboxes
                     [filterBy]="['name', 'description']"
                     [columns]="permissionsDtColumns"
                     [rows]="permissions"
                     [selected]="selectedRolePermissions"
                     (changedRowsSelection)="setRolePermissions(selectedRole, $event)">
      </app-datatable>

    </mat-tab>
  </mat-tab-group>
</app-dialog>

<!-- TODO: Permissions Management Dialog
<app-dialog #permisionsDialog noPadding width="300px">
  <app-title>Permisos</app-title>
  <app-subtitle>Gestión de permisos RBAC</app-subtitle>

  <app-datatable filterBy="description"
                 [columns]="permissionsDtColumns"
                 [rows]="[]">
  </app-datatable>
</app-dialog>
-->

<!-- Permissions Dialog for Quick-add -->
<app-dialog #permissionsQuickAddDialog
            noPadding
            width="300px">
  <app-title>Permisos</app-title>
  <app-subtitle>Agregado rápido</app-subtitle>

  <app-form padding
            [fields]="permissionsFormFields"
            [noCancelButton]="false"
            (save)="permissionsQuickAdd($event.value)"
            (cancel)="permissionsQuickAddDialog.close()">
  </app-form>
</app-dialog>
