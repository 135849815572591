<h3 mat-dialog-title [innerText]="data.title"></h3>
<div>
  <mat-dialog-content>
    <app-formly-form [form]="data.formGroup"
                     [fields]="data.formFields"
                     [options]="data.formOptions"
                     (save)="getData($event)"
                     (cancel)="dismiss($event)"
                     [textSubmit]="textSubmit"
                     [textCancel]="textCancel"
                     [activatedCancel]="true">
    </app-formly-form>
  </mat-dialog-content>
</div>
