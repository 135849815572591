<rmd-loading-indicator *ngIf="loading"></rmd-loading-indicator>

<mat-horizontal-stepper *ngIf="!loading" #stepper [linear]="true">
  <mat-step *ngIf="corporateDivisions">
    <ng-template matStepLabel>Proyecto</ng-template>

    <app-nav-list
      *ngIf="!useCards"
      [items]="corporateDivisions"
      (click)="selectCorporateDivision($event)"
    >
    </app-nav-list>
    <app-cards-set
      *ngIf="useCards"
      [items]="corporateDivisions"
      (select)="selectCorporateDivision($event)"
    >
    </app-cards-set>
  </mat-step>
  <mat-step *ngIf="companies || corporateDivisions">
    <ng-template matStepLabel>División</ng-template>

    <app-nav-list *ngIf="!useCards" [items]="companies" (click)="selectCompany($event)">
    </app-nav-list>
    <app-cards-set *ngIf="useCards" [items]="companies" (select)="selectCompany($event)">
    </app-cards-set>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Partida</ng-template>

    <app-nav-list *ngIf="!useCards" [items]="projects" (click)="selectProject($event)">
    </app-nav-list>
    <app-cards-set *ngIf="useCards" [items]="projects" (select)="selectProject($event)">
    </app-cards-set>
  </mat-step>
  <!-- TODO:
  <mat-step>
    <ng-template matStepLabel>Sub-Partida</ng-template>

    <app-nav-list *ngIf="!useCards"
                  [items]="subProjects"
                  (click)="selectSubProject($event)">
    </app-nav-list>
    <app-cards-set *ngIf="useCards"
                   [items]="subProjects"
                   (select)="selectSubProject($event)">
    </app-cards-set>
  </mat-step>
  -->
</mat-horizontal-stepper>

<!-- Management Dialog -->
<app-dialog #managementDialog>
  <app-title>Nuevo(a)</app-title>
  <app-subtitle>Proyecto/División/Partida</app-subtitle>

  <div class="width-500" *ngIf="formDataLoaded">
    <mat-form-field class="width-100">
      <mat-label>Seleccione una opción</mat-label>
      <mat-select [(value)]="newSelectedType" (selectionChange)="changedSelectType($event.value)">
        <mat-option value="division">Proyecto</mat-option>
        <mat-option value="company">División</mat-option>
        <mat-option value="project">Partida</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>

    <div class="padding-top-2" *ngIf="newSelectedType">
      <app-form
        [fields]="formFields"
        [model]="formData"
        [noCancelButton]="false"
        (save)="saveNew($event.value)"
        (cancel)="managementDialog.close()"
      >
      </app-form>
    </div>
  </div>
</app-dialog>
