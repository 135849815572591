<div class="content-block">
  <h3 class="content-title">Presupuesto</h3>

  <mat-table #budgetTable [dataSource]="viewModel">
    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef> Código </mat-header-cell>
      <mat-cell *matCellDef="let attribute"> {{ attribute.code }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="concept">
      <mat-header-cell *matHeaderCellDef> Concepto </mat-header-cell>
      <mat-cell *matCellDef="let attribute"> {{ attribute.concept }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unit">
      <mat-header-cell *matHeaderCellDef> Unidad </mat-header-cell>
      <mat-cell *matCellDef="let attribute"> {{ attribute.unit }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef> Cantidad </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        {{ attribute.quantity | number: '1.2-3' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef>
        <ng-template [ngIf]="!packageIsDerivative">
          Precio Unitario
        </ng-template>

        <ng-template [ngIf]="packageIsDerivative">
          Presupuesto Actual Disponible
        </ng-template>
      </mat-header-cell>
      <mat-cell *matCellDef="let attribute"> ${{ attribute.unitPrice | number: '1.2-2' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="total">
      <mat-header-cell *matHeaderCellDef>
        <ng-template [ngIf]="!packageIsDerivative">
          Total
        </ng-template>

        <ng-template [ngIf]="packageIsDerivative">
          Presupuesto Inicial
        </ng-template>
      </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <ng-template [ngIf]="!packageIsDerivative">
          ${{ attribute.total | number: '1.2-2' }}
        </ng-template>

        <ng-template [ngIf]="packageIsDerivative">
          ${{ initialBudget | number: '1.2-2' }}
        </ng-template>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableHeaderDefinition"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableHeaderDefinition"></mat-row>
  </mat-table>
</div>
