<app-page-widget noPadding>
    <app-title>Nueva Cotización</app-title>

    <div class="stepper">
        <mat-horizontal-stepper [linear]="true" (selectionChange)="toNextStep($event)">
            <mat-step label="{{ steps[0].label }}" [stepControl]="stepOneFormGroup">
                <form [formGroup]="stepOneFormGroup">
                    <div>
                        <button mat-raised-button color="primary" matStepperNext>
                            <mat-icon>navigate_next</mat-icon>
                            Siguiente
                        </button>
                    </div>
                    <div *ngIf="stepLoading" class="spinner-wrapper">
                        <mat-spinner [diameter]="60"></mat-spinner>
                    </div>
                    <div *ngIf="!stepLoading" class="step-one">
                        <app-grid-select-grouped [formControlName]="'selectPackagesCtrl'" required [gridType]="'requisitionGrid'" [dataSource]="requisitions" [columns]="columnsRequisitions" [(ngModel)]="selectedPackages" [requiredMessage]="'No hay paquetes seleccionados
                            *'">
                        </app-grid-select-grouped>
                    </div>
                </form>
            </mat-step>
            <mat-step label="{{ steps[1].label }}" [stepControl]="stepTwoFormGroup">
                <form [formGroup]="stepTwoFormGroup">
                    <div>
                        <button mat-raised-button color="primary" matStepperPrevious>
                            <mat-icon>navigate_before</mat-icon>
                            Anterior
                        </button>
                        <button mat-raised-button color="primary" [disabled]="!preview" (click)="openPreviewDialog()" class="margin-left-12">
                            <mat-icon>visibility</mat-icon>
                            Vista Previa
                        </button>
                        <button mat-raised-button color="primary" [disabled]="!create" (click)="createQuote()" class="margin-left-12">
                            <mat-icon>done</mat-icon>
                            Crear Cotización
                        </button>
                        <mat-form-field class="w-90 mt-10 ml-2" appearance="fill">
                            <input matInput [formControl]="nroQuoteFormControl" placeholder="Número de cotización" type="text">
                        </mat-form-field>
                    </div>
                    <div *ngIf="stepLoading" class="spinner-wrapper">
                        <mat-spinner [diameter]="60"></mat-spinner>
                    </div>
                    <div class="step-two" *ngIf="!stepLoading">
                        <div class="grid-container-div">
                            <dx-data-grid #suppliersDataGrid id="gridContainer" [dataSource]="suppliers" [wordWrapEnabled]="false" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
                                [allowColumnResizing]="true" [columnAutoWidth]="false" (onSelectionChanged)="changedSelectedSuppliers()">
                                <dxo-paging [pageSize]="10"></dxo-paging>
                                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 15]" [showInfo]="true">
                                </dxo-pager>
                                <dxo-search-panel [visible]="true" [width]="240" [highlightSearchText]="false" placeholder="Buscar...">
                                </dxo-search-panel>

                                <dxo-header-filter [visible]="false"></dxo-header-filter>
                                <dxo-filter-row [visible]="false"></dxo-filter-row>
                                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
                                <dxi-column caption="Razón Social" dataField="socialReason" alignment="left" width="40%"></dxi-column>
                                <dxi-column caption="Actividad" dataField="activity" alignment="left" width="30%"></dxi-column>
                                <dxi-column caption="Contacto" dataField="contactName" alignment="left" width="15%"></dxi-column>
                                <dxi-column caption="Teléfono" dataField="phone" alignment="center" width="15%"></dxi-column>
                            </dx-data-grid>
                        </div>
                        <!-- END GRID SUPPLIERS -->
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</app-page-widget>

<app-preview #previewDialog [suppliers]="selectedSuppliers" [subTitle]="'Proveedores seleccionados para esta Cotización'" [gridType]="'supplier'" (previewItems)="supplierSelectionChangedByPreview($event)">
</app-preview>