import { Injectable } from '@angular/core';
import { Fill, Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { LoantTypeCode, Package } from '@bist-lib/db-api/models';
import { DecimalPipe } from '@angular/common';
import { ProjectState } from '@bist-lib/app-state/src/state';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {
  private decimalPipe = new DecimalPipe('en');

  constructor(private projectState: ProjectState) {}

  public calculateImportColumn(columns: any) {
    const used: number = columns.costOriginal - columns.available;
    const quantityVariationsTotal: number =
      (columns.additionToQuantity ? columns.additionToQuantity : 0) -
      (columns.decreaseToQuantity ? columns.decreaseToQuantity : 0);
    const quantity: number = columns.quantity;
    const cost: number = columns.realPrice || columns.unitPrice;
    const percent: number = Number(columns.percentToPay);

    const totalWithVariationsAdded: number =
      (quantity + quantityVariationsTotal) * cost - used;

    const totalAmountToPayBeforeAditionals: number =
      (cost * quantity - used) * (percent / 100);

    const percentToPayAfterAditionals: number =
      totalAmountToPayBeforeAditionals / totalWithVariationsAdded;

    if (
      columns.code === 'ADVANCEDPAYMENT' ||
      columns.code === LoantTypeCode.PayLoanPositive
    ) {
      return Number(
        ((quantity + quantityVariationsTotal) * cost * percent) / 100
      );
    } else if (
      columns.code === 'ADVANCEDPAYMENT DISCOUNT' ||
      columns.code === LoantTypeCode.PayLoanNegative
    ) {
      return Number(
        -Math.abs(((quantity + quantityVariationsTotal) * cost * percent) / 100)
      );
    } else {
      return Number(totalWithVariationsAdded * (percent / 100));
      // return Number(totalWithVariationsAdded * percentToPayAfterAditionals);
    }
  }

  exportExcel(excelData) {
    const decimalPipe: DecimalPipe = new DecimalPipe('en');

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers;
    const estimate = excelData.estimate;
    const supplier = excelData.supplier;
    const nroContract = excelData.nroContract;
    const pep = excelData.pep;
    const dateStart = excelData.dateStart;
    const dateEnd = excelData.dateEnd;
    const totalAssignment = excelData.totalAssignment;
    const dataAmountPay = excelData.dataAmountPay;
    const packagesId = excelData.packagesId;
    const totalAnticipoGlobal = excelData.totalAnticipo;
    const estimateId = estimate.nroEstimate
      ? estimate.nroEstimate
      : estimate.id;
    const estimateOrderId = excelData.estimateOrder;
    const bail = excelData.bail;
    const project = this.projectState.getSelectedProject();
    const group = excelData.group;
    let totalAmountPay = excelData.totalAmountPay;
    let amountLastAcumTotal = 0;
    let amountImportTotal = 0;
    let totalAmountAcum = 0;
    let anticipo = 0;
    let totalAnticipo = excelData.totalAnticipo;
    let guarantedFound = 0;
    let totalPackages = 0;

    excelData.packages.forEach((singlePackage) => {
      if (singlePackage.concept === 'Fondo de garantía') {
        guarantedFound = singlePackage.unitPrice;
      }

      // if(excelData.totalAnticipo === 0) {
      if (singlePackage.concept === 'Descuento Anticipo') {
        // totalAnticipo = singlePackage.cost;
        anticipo += -Math.abs(
          (singlePackage.available * singlePackage.percentToPay) / 100
        );
      } else if (singlePackage.concept === 'Anticipo') {
        const quantityVariationsTotal: number =
          (singlePackage.additionToQuantity
            ? singlePackage.additionToQuantity
            : 0) -
          (singlePackage.decreaseToQuantity
            ? singlePackage.decreaseToQuantity
            : 0);

        const quantity: number = singlePackage.quantity;
        const cost: number = singlePackage.realPrice || singlePackage.unitPrice;
        const percent: number = Number(singlePackage.percentToPay);

        const totalWithVariationsAdded: number =
          (quantity + quantityVariationsTotal) * cost;
        // totalAnticipo = singlePackage.cost;
        anticipo +=
          ((singlePackage.available || singlePackage.unitPrice) *
            singlePackage.percentToPay) /
          100;
      }
      // }
    });

    let packagesEstimateId = [];

    let importProject = 0;
    let previousPercent = 0;
    let percentToPay = 0;
    let percentAcumAvailable = 0;
    let amountLastAcum = 0;
    let amountImport = 0;
    let totalAmountAcumPackage = 0;
    let availableAmount = 0;

    //Agrupamos todos los paquetes en 1 solo
    const packages = excelData.packages
      .filter(
        (singlePackage) =>
          singlePackage.concept !== 'Fondo de garantía' &&
          singlePackage.concept !== 'Descuento Anticipo' &&
          singlePackage.concept !== 'Anticipo'
      )
      .map((singlePackage) => {
        const quantityVariationsTotal: number =
          (singlePackage.additionToQuantity
            ? singlePackage.additionToQuantity
            : 0) -
          (singlePackage.decreaseToQuantity
            ? singlePackage.decreaseToQuantity
            : 0);

        const quantity: number = singlePackage.quantity;

        packagesEstimateId.push(singlePackage.packageId);
        amountImportTotal += this.calculateImportColumn(singlePackage);
        const amountLastAcumPackage = dataAmountPay[singlePackage.packageId];
        const amountLastAcumImportTotal =
          dataAmountPay[singlePackage.packageId] - amountImportTotal;
        const amountAcum =
          this.calculateImportColumn(singlePackage) + amountLastAcum;

        amountLastAcumTotal += amountLastAcumImportTotal;

        totalAmountAcum += amountAcum;
        const percentAcumAvailablePackage =
          100 - (singlePackage.percentage + singlePackage.percentToPay);

        importProject += singlePackage.cost * quantity;
        previousPercent += singlePackage.percentage;
        percentToPay += singlePackage.percentToPay;
        percentAcumAvailable += percentAcumAvailablePackage;
        amountLastAcum += amountLastAcumPackage;
        amountImport += this.calculateImportColumn(singlePackage);
        totalAmountAcumPackage += amountAcum;
        availableAmount +=
          singlePackage.cost * quantity * (percentAcumAvailable / 100);

        return {
          key: singlePackage.packageId,
          concept: singlePackage.concept,
          unit: singlePackage.unit,
          importProject: `${decimalPipe.transform(
            singlePackage.cost * quantity,
            '1.2-2'
          )}`,
          previousPercent: `${decimalPipe.transform(
            singlePackage.percentage,
            '1.2-2'
          )}%`,
          percentToPay: `${decimalPipe.transform(
            singlePackage.percentToPay,
            '1.2-2'
          )}%`,
          percentAcumAvailable: `${decimalPipe.transform(
            percentAcumAvailable,
            '1.2-2'
          )}%`,
          amountLastAcum: `$ ${decimalPipe.transform(amountLastAcum, '1.2-2')}`,
          amountImport: `$ ${decimalPipe.transform(
            this.calculateImportColumn(singlePackage),
            '1.2-2'
          )}`,
          totalAmountAcum: `$ ${decimalPipe.transform(amountAcum, '1.2-2')}`,
          availableAmount: `$ ${decimalPipe.transform(
            singlePackage.cost * quantity * (percentAcumAvailable / 100),
            '1.2-2'
          )}`,
        };
      });

    totalPackages = packages.length * 100;

    packagesId.forEach((singlePackageId) => {
      if (packagesEstimateId.indexOf(singlePackageId) < 0) {
        amountLastAcumTotal += dataAmountPay[singlePackageId];
      }
    });

    if (!group) {
      totalAmountPay = amountLastAcumTotal;
    }

    let amountToExercise = `$ ${decimalPipe.transform(
      totalAssignment - (amountImportTotal + totalAmountPay),
      '1.2-2'
    )}`;
    let importOrder = amountImportTotal - -1 * anticipo - guarantedFound;

    previousPercent = (amountLastAcum * 100) / totalAssignment;
    percentToPay = (amountImport * 100) / totalAssignment;

    let dataForExcel = [
      [
        pep,
        project.name,
        '',
        `$ ${decimalPipe.transform(totalAssignment, '1.2-2')}`,
        `${decimalPipe.transform(
          (amountLastAcum * 100) / totalAssignment,
          '1.2-2'
        )}%`,
        `${decimalPipe.transform(
          (amountImport * 100) / totalAssignment,
          '1.2-2'
        )}%`,
        `${decimalPipe.transform(
          100 - (previousPercent + percentToPay),
          '1.2-2'
        )}%`,
        `$ ${decimalPipe.transform(amountLastAcum, '1.2-2')}`,
        `$ ${decimalPipe.transform(amountImport, '1.2-2')}`,
        `$ ${decimalPipe.transform(totalAmountAcumPackage, '1.2-2')}`,
        `$ ${decimalPipe.transform(
          totalAssignment - totalAmountAcumPackage,
          '1.2-2'
        )}`,
      ],
    ];

    // packages.forEach((row: any) => {
    //   dataForExcel.push(Object.values(row))
    // })

    const fontTitle = {
      name: 'Calibri',
      size: 16,
      bold: true,
      color: { argb: '0085A3' },
    };
    const fontHead = { name: 'Calibri', size: 9, bold: true };
    const font = { name: 'Calibri', size: 8, bold: false };
    const fontAmount = { name: 'Calibri', size: 8, bold: true };
    const fillHead: Fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BFBFBF' },
      bgColor: { argb: '' },
    };

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Estimación');

    //Add Row and formatting
    worksheet.mergeCells('D2', 'I5');
    let titleRow = worksheet.getCell('D2');
    titleRow.value = title;
    titleRow.font = fontTitle;
    titleRow.border = { top: { style: 'thin' }, bottom: { style: 'thin' } };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('J2:K5');
    let d = new Date();
    let date = d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear();
    let dateCell = worksheet.getCell('J2');
    dateCell.value = date;
    dateCell.font = fontHead;
    dateCell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    worksheet.mergeCells('A2:C5');
    // worksheet.addImage(myLogoImage, 'A1:B4');
    let imageCell = worksheet.getCell('A2');
    imageCell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
    };

    //Blank Row
    worksheet.addRow([]);

    worksheet.mergeCells('A7:B7');
    let titleWorkData = worksheet.getCell('A7');
    titleWorkData.value = 'DATOS DE LA OBRA';
    titleWorkData.font = fontHead;
    titleWorkData.fill = fillHead;
    titleWorkData.alignment = { vertical: 'middle', horizontal: 'left' };

    let colunmA = [
      Object.values({ name: 'CONTRATO N°', value: `${nroContract}` }),
      Object.values({ name: 'ELEMENTO PEP', value: `${pep}` }),
      Object.values({ name: 'OBRA', value: `${project.name}` }),
      Object.values({ name: 'LOCALIDAD Y/O MUNICIPIO', value: '' }),
      Object.values({ name: 'CONTRATISTA', value: supplier }),
    ];

    colunmA.forEach((dataColunm) => {
      let column = worksheet.addRow(dataColunm);
      column.font = font;
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 30;

    let titleContractData = worksheet.getCell('C7');
    titleContractData.value = 'DATOS DEL CONTRATO';
    titleContractData.font = fontHead;
    titleContractData.fill = fillHead;
    titleContractData.alignment = { vertical: 'middle', horizontal: 'left' };

    //DAYS TITLE
    let titleDays = worksheet.getCell('E7');
    titleDays.value = 'Días';
    titleDays.font = fontHead;
    titleDays.fill = fillHead;
    titleDays.alignment = { vertical: 'middle', horizontal: 'right' };

    //DAYS VALUE
    let days = worksheet.getCell('F7');
    days.value = '127';
    days.font = fontHead;
    days.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2BF41C' },
      bgColor: { argb: '' },
    };
    days.alignment = { vertical: 'middle', horizontal: 'center' };

    //INICIO TITLE
    let titleStartData = worksheet.getCell('C8');
    titleStartData.value = 'INICIO';
    titleStartData.font = font;
    titleStartData.alignment = { vertical: 'middle', horizontal: 'left' };

    //INICIO VALUE
    let startValue = worksheet.getCell('D8');
    startValue.value = `${moment(dateStart).format('DD-MM-YYYY')}`;
    startValue.font = font;
    startValue.alignment = { vertical: 'middle', horizontal: 'center' };

    //FINISH TITLE
    let titleFinishData = worksheet.getCell('E8');
    titleFinishData.value = 'TERMINACIÓN';
    titleFinishData.font = font;
    titleFinishData.alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(5).width = 12;

    //INICIO VALUE
    let finishValue = worksheet.getCell('F8');
    finishValue.value = `${moment(dateEnd).format('DD-MM-YYYY')}`;
    finishValue.font = font;
    finishValue.alignment = { vertical: 'middle', horizontal: 'center' };

    //IMPORT CONTRACT
    worksheet.mergeCells('C9:E9');
    let titleImportContract = worksheet.getCell('C9');
    titleImportContract.value = 'IMPORTE DE CONTRATO';
    titleImportContract.font = font;
    titleImportContract.alignment = { vertical: 'middle', horizontal: 'left' };

    //VALUE IMPORT CONTRACT
    worksheet.mergeCells('F9');
    let titleValueImportContract = worksheet.getCell('F9');
    titleValueImportContract.value = `$ ${decimalPipe.transform(
      totalAssignment,
      '1.2-2'
    )}`;
    titleValueImportContract.font = fontAmount;
    titleValueImportContract.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    //IMPORT ACUM LAST
    worksheet.mergeCells('C10:E10');
    let titleImportAcumLast = worksheet.getCell('C10');
    titleImportAcumLast.value = 'IMPORTE ACUMULADO ANTERIOR';
    titleImportAcumLast.font = font;
    titleImportAcumLast.alignment = { vertical: 'middle', horizontal: 'left' };

    //VALUE IMPORT ACUM LAST
    worksheet.mergeCells('F10');
    let titleValueImportAcumLast = worksheet.getCell('F10');
    titleValueImportAcumLast.value = `$ ${decimalPipe.transform(
      totalAmountPay,
      '1.2-2'
    )}`;
    titleValueImportAcumLast.font = fontAmount;
    titleValueImportAcumLast.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    //IMPORT ESTIMATE
    worksheet.mergeCells('C11:E11');
    let titleImportEstimate = worksheet.getCell('C11');
    titleImportEstimate.value = 'IMPORTE ESTIMACIÓN ACTUAL';
    titleImportEstimate.font = font;
    titleImportEstimate.alignment = { vertical: 'middle', horizontal: 'left' };

    //VALUE IMPORT ESTIMATE
    worksheet.mergeCells('F11');
    let titleValueEstimate = worksheet.getCell('F11');
    titleValueEstimate.value = `$ ${decimalPipe.transform(
      amountImportTotal,
      '1.2-2'
    )}`;
    titleValueEstimate.font = fontAmount;
    titleValueEstimate.alignment = { vertical: 'middle', horizontal: 'left' };

    //IMPORT ACUM ACTUAL
    worksheet.mergeCells('C12:E12');
    let titleImportAcumActual = worksheet.getCell('C12');
    titleImportAcumActual.value = 'IMPORTE ACUMULADO ACTUAL';
    titleImportAcumActual.font = font;
    titleImportAcumActual.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    //VALUE IMPORT ACUM ACTUAL
    worksheet.mergeCells('F12');
    let titleValueImportAcumActual = worksheet.getCell('F12');
    titleValueImportAcumActual.value = `$ ${decimalPipe.transform(
      amountImportTotal + totalAmountPay,
      '1.2-2'
    )}`;
    titleValueImportAcumActual.font = fontAmount;
    titleValueImportAcumActual.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    //IMPORT TO EXERCISE
    worksheet.mergeCells('C13:E13');
    let titleImportExercise = worksheet.getCell('C13');
    titleImportExercise.value = 'IMPORTE POR EJERCER';
    titleImportExercise.font = font;
    titleImportExercise.alignment = { vertical: 'middle', horizontal: 'left' };

    //VALUE IMPORT ACUM ACTUAL
    worksheet.mergeCells('F13');
    let titleValueImportExercise = worksheet.getCell('F13');
    titleValueImportExercise.value = amountToExercise;
    titleValueImportExercise.font = fontAmount;
    titleValueImportExercise.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    //IMPORT ORDER
    worksheet.mergeCells('C14:E14');
    let titleImportOrder = worksheet.getCell('C14');
    titleImportOrder.value = 'IMPORTE DEL PEDIDO';
    titleImportOrder.font = font;
    titleImportOrder.alignment = { vertical: 'middle', horizontal: 'left' };

    //VALUE IMPORT ACUM ACTUAL
    worksheet.mergeCells('F14');
    let titleValueImportOrder = worksheet.getCell('F14');
    titleValueImportOrder.value = `$ ${decimalPipe.transform(
      importOrder,
      '1.2-2'
    )}`;
    titleValueImportOrder.font = fontAmount;
    titleValueImportOrder.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    worksheet.getColumn(6).width = 15;

    //TITLE STATUS FIANZA
    worksheet.mergeCells('I7');
    let titleStatus = worksheet.getCell('I7');
    titleStatus.value = 'ESTATUS DE FIANZA';
    titleStatus.font = fontHead;
    titleStatus.fill = fillHead;
    titleStatus.alignment = { vertical: 'middle', horizontal: 'left' };

    worksheet.getColumn(9).width = 18;

    //VALUE STATUS FIANZA
    worksheet.mergeCells('J7');
    let valueBail = worksheet.getCell('J7');
    valueBail.value = `${bail}`;
    valueBail.font = fontAmount;
    valueBail.alignment = { vertical: 'middle', horizontal: 'center' };

    //VICIOS OCULTOS
    worksheet.mergeCells('K7');
    let titleValueStatus = worksheet.getCell('K7');
    titleValueStatus.value = 'VICIOS OCULTOS';
    titleValueStatus.font = fontHead;
    titleValueStatus.fill = fillHead;
    titleValueStatus.alignment = { vertical: 'middle', horizontal: 'left' };

    worksheet.getColumn(11).width = 17;

    //TITLE ESTIMATE NRO
    worksheet.mergeCells('G8:H9');
    let titleEstimateNro = worksheet.getCell('G8');
    titleEstimateNro.value = 'ESTIMACIÓN N°:';
    titleEstimateNro.font = font;
    titleEstimateNro.alignment = { vertical: 'middle', horizontal: 'center' };

    //VALUE ESTIMATE NRO
    worksheet.mergeCells('I8:I9');
    let titleValueEstimateNro = worksheet.getCell('I8');
    titleValueEstimateNro.value = `E-${estimateId}`;
    titleValueEstimateNro.font = fontAmount;
    titleValueEstimateNro.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    //TITLE ESTIMATE ORDER NRO
    worksheet.mergeCells('G10:H10');
    let titleEstimateOrderNro = worksheet.getCell('G10');
    titleEstimateOrderNro.value = 'ORDEN DE ESTIMACIÓN N°:';
    titleEstimateOrderNro.font = font;
    titleEstimateOrderNro.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    //VALUE ESTIMATE NRO
    worksheet.mergeCells('I10');
    let titleValueEstimateOrderNro = worksheet.getCell('I10');
    titleValueEstimateOrderNro.value = `OE-${estimateOrderId}`;
    titleValueEstimateOrderNro.font = fontAmount;
    titleValueEstimateOrderNro.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    worksheet.addRow([]);
    worksheet.addRow([]);

    //IMPORT ANTICIPO
    worksheet.mergeCells('G12');
    let titleImportAnticipo = worksheet.getCell('G12');
    titleImportAnticipo.value = 'Importe de anticipo';
    titleImportAnticipo.font = font;
    titleImportAnticipo.alignment = { vertical: 'middle', horizontal: 'left' };

    //VALUE IMPORT ANTICIPO
    worksheet.mergeCells('H12');
    let titleValueImportAnticipo = worksheet.getCell('H12');
    titleValueImportAnticipo.value = `$ ${decimalPipe.transform(
      totalAnticipoGlobal,
      '1.2-2'
    )}`;
    titleValueImportAnticipo.font = fontAmount;
    titleValueImportAnticipo.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    //SALDO AMORTIZAR
    worksheet.mergeCells('I12');
    let titleSaldoAmortizar = worksheet.getCell('I12');
    titleSaldoAmortizar.value = 'SALDO P/AMORTIZAR';
    titleSaldoAmortizar.font = font;
    titleSaldoAmortizar.alignment = { vertical: 'middle', horizontal: 'left' };

    //VALUE SALDO AMORTIZAR
    worksheet.mergeCells('K12');
    let titleValueSaldoAmortizar = worksheet.getCell('K12');
    titleValueSaldoAmortizar.value = `$ ${decimalPipe.transform(
      totalAnticipo - -1 * anticipo,
      '1.2-2'
    )}`;
    titleValueSaldoAmortizar.font = fontAmount;
    titleValueSaldoAmortizar.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    //ANTICIPO A AMORTIZAR ESTA ESTIMACION
    worksheet.mergeCells('G13');
    let titleAnticipoAmortizar = worksheet.getCell('G13');
    titleAnticipoAmortizar.value = 'Anticipo a amortizar esta estimación';
    titleAnticipoAmortizar.font = font;
    titleAnticipoAmortizar.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    worksheet.getColumn(7).width = 25;

    //ANTICIPO A AMORTIZAR ESTA ESTIMACION
    worksheet.mergeCells('I13');
    let titleValueAnticipoAmortizar = worksheet.getCell('I13');
    titleValueAnticipoAmortizar.value = `$ ${decimalPipe.transform(
      -1 * anticipo,
      '1.2-2'
    )}`;
    titleValueAnticipoAmortizar.font = fontAmount;
    titleValueAnticipoAmortizar.alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };

    //Adding Header Row
    header.forEach((element, idx) => {
      switch (idx) {
        case 0:
          worksheet.mergeCells('A16:A17');
          let titleA = worksheet.getCell('A16');
          titleA.value = element;
          titleA.fill = fillHead;
          titleA.font = fontHead;
          titleA.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleA.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 1:
          worksheet.mergeCells('B16:B17');
          let titleB = worksheet.getCell('B16');
          titleB.value = element;
          titleB.fill = fillHead;
          titleB.font = fontHead;
          titleB.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleB.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 2:
          worksheet.mergeCells('C16:C17');
          let titleC = worksheet.getCell('C16');
          titleC.value = element;
          titleC.fill = fillHead;
          titleC.font = fontHead;
          titleC.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleC.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 3:
          worksheet.mergeCells('D16:D17');
          let titleD = worksheet.getCell('D16');
          titleD.value = element;
          titleD.fill = fillHead;
          titleD.font = fontHead;
          titleD.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleD.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 4:
          worksheet.mergeCells('E16:E17');
          let titleE = worksheet.getCell('E16');
          titleE.value = element;
          titleE.fill = fillHead;
          titleE.font = fontHead;
          titleE.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleE.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 5:
          worksheet.mergeCells('F16:F17');
          let titleF = worksheet.getCell('F16');
          titleF.value = element;
          titleF.fill = fillHead;
          titleF.font = fontHead;
          titleF.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleF.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 6:
          worksheet.mergeCells('G16:G17');
          let titleG = worksheet.getCell('G16');
          titleG.value = element;
          titleG.fill = fillHead;
          titleG.font = fontHead;
          titleG.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleG.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 7:
          worksheet.mergeCells('H16:H17');
          let titleH = worksheet.getCell('H16');
          titleH.value = element;
          titleH.fill = fillHead;
          titleH.font = fontHead;
          titleH.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleH.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 8:
          worksheet.mergeCells('I16:I17');
          let titleI = worksheet.getCell('I16');
          titleI.value = element;
          titleI.fill = fillHead;
          titleI.font = fontHead;
          titleI.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleI.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 9:
          worksheet.mergeCells('J16:J17');
          let titleJ = worksheet.getCell('J16');
          titleJ.value = element;
          titleJ.fill = fillHead;
          titleJ.font = fontHead;
          titleJ.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleJ.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 10:
          worksheet.mergeCells('K16:K17');
          let titleK = worksheet.getCell('K16');
          titleK.value = element;
          titleK.fill = fillHead;
          titleK.font = fontHead;
          titleK.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleK.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 11:
          worksheet.mergeCells('L16:L17');
          let titleL = worksheet.getCell('L16');
          titleL.value = element;
          titleL.fill = fillHead;
          titleL.font = fontHead;
          titleL.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleL.alignment = { vertical: 'middle', horizontal: 'center' };
          break;

        default:
          break;
      }
    });
    worksheet.getColumn(4).width = 17;
    worksheet.getColumn(5).width = 22;
    worksheet.getColumn(6).width = 17;
    worksheet.getColumn(8).width = 19;
    worksheet.getColumn(10).width = 17;

    // Adding Data with Conditional Formatting
    dataForExcel.forEach((d) => {
      let row = worksheet.addRow(d);

      let packages = row.getCell(18);

      row.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    worksheet.getColumn(3).width = 20;
    worksheet.addRow([]);

    const total = amountImportTotal - guarantedFound - -1 * anticipo;

    let resume = [
      {
        name: 'SUBTOTAL DE LA ESTIMACIÓN',
        value: `$ ${decimalPipe.transform(amountImportTotal, '1.2-2')}`,
      },
      {
        name: 'MULTAS Y/O DEDUCTIVAS',
        value: `$ ${decimalPipe.transform(guarantedFound, '1.2-2')}`,
      },
      {
        name: 'AMORTIZACIÓN',
        value: `$ ${decimalPipe.transform(-1 * anticipo, '1.2-2')}`,
      },
      {
        name: 'TOTAL DE LA ESTIMACIÓN',
        value: `$ ${decimalPipe.transform(total, '1.2-2')}`,
      },
      {
        name: 'I.V.A 16%',
        value: `$ ${decimalPipe.transform(total * 0.16, '1.2-2')}`,
      },
      {
        name: 'TOTAL DE LA ESTIMACIÓN',
        value: `$ ${decimalPipe.transform(total + total * 0.16, '1.2-2')}`,
      },
    ];

    let titleResume = worksheet.addRow([]);
    let cellResume = titleResume.getCell(8);
    cellResume.value = 'RESUMEN';
    cellResume.font = fontHead;
    cellResume.alignment = { vertical: 'middle', horizontal: 'right' };

    resume.forEach((dataColunm, idx) => {
      switch (idx) {
        case 0:
          let titleSubTotal = worksheet.addRow([]);

          //Merge Cells
          worksheet.mergeCells(
            `I${titleSubTotal.number}:J${titleSubTotal.number}`
          );

          let cell = titleSubTotal.getCell(10);
          cell.value = dataColunm.name;
          cell.font = fontHead;
          cell.alignment = { vertical: 'middle', horizontal: 'right' };

          let cellValue = titleSubTotal.getCell(11);
          cellValue.value = dataColunm.value;
          cellValue.font = fontHead;
          cellValue.alignment = { vertical: 'middle', horizontal: 'right' };

          break;
        case 1:
          let titleMultas = worksheet.addRow([]);

          //Merge Cells
          worksheet.mergeCells(`I${titleMultas.number}:J${titleMultas.number}`);

          let cellMultas = titleMultas.getCell(10);
          cellMultas.value = dataColunm.name;
          cellMultas.font = fontHead;
          cellMultas.alignment = { vertical: 'middle', horizontal: 'right' };

          let cellMultasValue = titleMultas.getCell(11);
          cellMultasValue.value = dataColunm.value;
          cellMultasValue.font = fontHead;
          cellMultasValue.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };

          break;
        case 2:
          let titleAmortized = worksheet.addRow([]);

          //Merge Cells
          worksheet.mergeCells(
            `I${titleAmortized.number}:J${titleAmortized.number}`
          );

          let cellAmortized = titleAmortized.getCell(10);
          cellAmortized.value = dataColunm.name;
          cellAmortized.font = fontHead;
          cellAmortized.alignment = { vertical: 'middle', horizontal: 'right' };

          let cellAmortizedValue = titleAmortized.getCell(11);
          cellAmortizedValue.value = dataColunm.value;
          cellAmortizedValue.font = fontHead;
          cellAmortizedValue.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };

          break;
        case 3:
          let titleTotal = worksheet.addRow([]);

          //Merge Cells
          worksheet.mergeCells(`I${titleTotal.number}:J${titleTotal.number}`);

          let cellTotal = titleTotal.getCell(10);
          cellTotal.value = dataColunm.name;
          cellTotal.font = fontHead;
          cellTotal.alignment = { vertical: 'middle', horizontal: 'right' };

          let cellTotalValue = titleTotal.getCell(11);
          cellTotalValue.value = dataColunm.value;
          cellTotalValue.font = fontHead;
          cellTotalValue.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };

          break;
        case 4:
          let titleIva = worksheet.addRow([]);

          //Merge Cells
          worksheet.mergeCells(`I${titleIva.number}:J${titleIva.number}`);

          let cellIva = titleIva.getCell(10);
          cellIva.value = dataColunm.name;
          cellIva.font = fontHead;
          cellIva.alignment = { vertical: 'middle', horizontal: 'right' };

          let cellIvaValue = titleIva.getCell(11);
          cellIvaValue.value = dataColunm.value;
          cellIvaValue.font = fontHead;
          cellIvaValue.alignment = { vertical: 'middle', horizontal: 'right' };

          break;
        case 5:
          let titleTotalWithIva = worksheet.addRow([]);

          //Merge Cells
          worksheet.mergeCells(
            `I${titleTotalWithIva.number}:J${titleTotalWithIva.number}`
          );

          let cellTotalWithIva = titleTotalWithIva.getCell(10);
          cellTotalWithIva.value = dataColunm.name;
          cellTotalWithIva.font = fontHead;
          cellTotalWithIva.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };

          let cellTotalWithIvaValue = titleTotalWithIva.getCell(11);
          cellTotalWithIvaValue.value = dataColunm.value;
          cellTotalWithIvaValue.font = fontHead;
          cellTotalWithIvaValue.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          };

          break;

        default:
          break;
      }
    });

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

  exportExcelEstimates(excelData) {
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Estimaciones');
    let title = 'Listado de Paquetes';

    const headers = excelData.headers;
    const packages = excelData.packages;

    const fontHead = { name: 'Calibri', size: 9, bold: true };
    const fillHead: Fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BFBFBF' },
      bgColor: { argb: '' },
    };

    console.log(headers);
    console.log(packages);

    //Adding Header Row
    headers.forEach((element, idx) => {
      switch (idx) {
        case 0:
          worksheet.mergeCells('A01:A01');
          let titleA = worksheet.getCell('A01');
          titleA.value = element;
          titleA.fill = fillHead;
          titleA.font = fontHead;
          titleA.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleA.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 1:
          worksheet.mergeCells('B01:B01');
          let titleB = worksheet.getCell('B01');
          titleB.value = element;
          titleB.fill = fillHead;
          titleB.font = fontHead;
          titleB.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleB.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 2:
          worksheet.mergeCells('C01:C01');
          let titleC = worksheet.getCell('C01');
          titleC.value = element;
          titleC.fill = fillHead;
          titleC.font = fontHead;
          titleC.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleC.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 3:
          worksheet.mergeCells('D01:D01');
          let titleD = worksheet.getCell('D01');
          titleD.value = element;
          titleD.fill = fillHead;
          titleD.font = fontHead;
          titleD.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleD.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 4:
          worksheet.mergeCells('E01:E01');
          let titleE = worksheet.getCell('E01');
          titleE.value = element;
          titleE.fill = fillHead;
          titleE.font = fontHead;
          titleE.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleE.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 5:
          worksheet.mergeCells('F01:F01');
          let titleF = worksheet.getCell('F01');
          titleF.value = element;
          titleF.fill = fillHead;
          titleF.font = fontHead;
          titleF.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleF.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 6:
          worksheet.mergeCells('G01:G01');
          let titleG = worksheet.getCell('G01');
          titleG.value = element;
          titleG.fill = fillHead;
          titleG.font = fontHead;
          titleG.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleG.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 7:
          worksheet.mergeCells('H01:H01');
          let titleH = worksheet.getCell('H01');
          titleH.value = element;
          titleH.fill = fillHead;
          titleH.font = fontHead;
          titleH.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleH.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 8:
          worksheet.mergeCells('I01:I01');
          let titleI = worksheet.getCell('I01');
          titleI.value = element;
          titleI.fill = fillHead;
          titleI.font = fontHead;
          titleI.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleI.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 9:
          worksheet.mergeCells('J01:J01');
          let titleJ = worksheet.getCell('J01');
          titleJ.value = element;
          titleJ.fill = fillHead;
          titleJ.font = fontHead;
          titleJ.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleJ.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 10:
          worksheet.mergeCells('K01:K01');
          let titleK = worksheet.getCell('K01');
          titleK.value = element;
          titleK.fill = fillHead;
          titleK.font = fontHead;
          titleK.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleK.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 11:
          worksheet.mergeCells('L01:L01');
          let titleL = worksheet.getCell('L01');
          titleL.value = element;
          titleL.fill = fillHead;
          titleL.font = fontHead;
          titleL.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleL.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 12:
          worksheet.mergeCells('M01:M01');
          let titleM = worksheet.getCell('M01');
          titleM.value = element;
          titleM.fill = fillHead;
          titleM.font = fontHead;
          titleM.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleM.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 13:
          worksheet.mergeCells('N01:N01');
          let titleN = worksheet.getCell('N01');
          titleN.value = element;
          titleN.fill = fillHead;
          titleN.font = fontHead;
          titleN.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleN.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 14:
          worksheet.mergeCells('O01:O01');
          let titleO = worksheet.getCell('O01');
          titleO.value = element;
          titleO.fill = fillHead;
          titleO.font = fontHead;
          titleO.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleO.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 15:
          worksheet.mergeCells('P01:P01');
          let titleP = worksheet.getCell('P01');
          titleP.value = element;
          titleP.fill = fillHead;
          titleP.font = fontHead;
          titleP.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleP.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 16:
          worksheet.mergeCells('Q01:Q01');
          let titleQ = worksheet.getCell('Q01');
          titleQ.value = element;
          titleQ.fill = fillHead;
          titleQ.font = fontHead;
          titleQ.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleQ.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 17:
          worksheet.mergeCells('R01:R01');
          let titleR = worksheet.getCell('R01');
          titleR.value = element;
          titleR.fill = fillHead;
          titleR.font = fontHead;
          titleR.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleR.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 18:
          worksheet.mergeCells('S01:S01');
          let titleS = worksheet.getCell('S01');
          titleS.value = element;
          titleS.fill = fillHead;
          titleS.font = fontHead;
          titleS.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleS.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 19:
          worksheet.mergeCells('T01:T01');
          let titleT = worksheet.getCell('T01');
          titleT.value = element;
          titleT.fill = fillHead;
          titleT.font = fontHead;
          titleT.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleT.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 20:
          worksheet.mergeCells('U01:U01');
          let titleU = worksheet.getCell('U01');
          titleU.value = element;
          titleU.fill = fillHead;
          titleU.font = fontHead;
          titleU.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleU.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 21:
          worksheet.mergeCells('V01:V01');
          let titleV = worksheet.getCell('V01');
          titleV.value = element;
          titleV.fill = fillHead;
          titleV.font = fontHead;
          titleV.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          titleV.alignment = { vertical: 'middle', horizontal: 'center' };
          break;

        default:
          break;
      }
    });

    let packagesArray = [];

    console.log(packages);

    packages.forEach((singlePackage) => {
      let singlePackagesArray = [];

      const total = Number(this.calValues(singlePackage));

      singlePackagesArray.push(singlePackage.nroContract);
      singlePackagesArray.push(singlePackage.estimateId);
      singlePackagesArray.push(singlePackage.asignmentSmallId);
      singlePackagesArray.push(singlePackage.supplierName);
      singlePackagesArray.push(singlePackage.packageId);
      singlePackagesArray.push(singlePackage.concept);
      singlePackagesArray.push(singlePackage.unit);
      singlePackagesArray.push(
        this.decimalPipe.transform(singlePackage.cost, '1.2-2')
      );
      singlePackagesArray.push(
        this.decimalPipe.transform(singlePackage.realPrice, '1.2-2')
      );
      singlePackagesArray.push(
        this.decimalPipe.transform(Number(singlePackage.quantity), '1.2-2')
      );
      singlePackagesArray.push(
        this.decimalPipe.transform(
          singlePackage.quantity * singlePackage.realPrice,
          '1.2-2'
        )
      );
      singlePackagesArray.push(
        this.decimalPipe.transform(
          Number(singlePackage.additionToQuantity),
          '1.2-2'
        )
      );
      singlePackagesArray.push(
        this.decimalPipe.transform(
          Number(singlePackage.decreaseToQuantity),
          '1.2-2'
        )
      );
      singlePackagesArray.push(this.decimalPipe.transform(total, '1.2-2'));
      singlePackagesArray.push(
        this.decimalPipe.transform(
          Number(this.calcPercentPay(singlePackage)),
          '1.2-2'
        ) + '%'
      );
      //$ PAGADO
      singlePackagesArray.push(
        this.decimalPipe.transform(
          Number(singlePackage.amountPayed || 0),
          '1.2-2'
        ) + '%'
      );
      //% A PAGAR
      singlePackagesArray.push(
        this.decimalPipe.transform(
          Number(this.calculatePercentageToDisplay(singlePackage)),
          '1.2-2'
        ) + '%'
      );
      //IMPORTE
      singlePackagesArray.push(this.calculatePercentageToPay(singlePackage));
      //TOTAL A LA FECHA
      singlePackagesArray.push(
        Number(this.calculatePercentageTotal(singlePackage)) + '%'
      );
      //$ PAGADO A LA FECHA
      singlePackagesArray.push(this.calculateTotalPayed(singlePackage));
      //DISPONIBLE
      singlePackagesArray.push(
        this.decimalPipe.transform(
          Number(this.calculateAvailable(singlePackage)),
          '1.2-2'
        )
      );
      //FECHA DE APROBACION
      singlePackagesArray.push(singlePackage.updatedAt);

      packagesArray.push(singlePackagesArray);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 51;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 70;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 25;
    worksheet.getColumn(9).width = 25;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 25;
    worksheet.getColumn(13).width = 25;
    worksheet.getColumn(14).width = 25;
    worksheet.getColumn(15).width = 25;
    worksheet.getColumn(16).width = 25;
    worksheet.getColumn(17).width = 25;
    worksheet.getColumn(18).width = 25;
    worksheet.getColumn(19).width = 30;
    worksheet.getColumn(20).width = 30;
    worksheet.getColumn(21).width = 30;
    worksheet.getColumn(22).width = 30;

    let dataForExcel = packagesArray;
    // Adding Data with Conditional Formatting
    dataForExcel.forEach((d) => {
      let row = worksheet.addRow(d);

      let packages = row.getCell(18);

      row.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.xlsx');
    });
  }

  public calValues(singlePackage: Package): number {
    const used: number = singlePackage.costOriginal - singlePackage.available;
    const quantityVariationsTotal: number =
      (singlePackage.additionToQuantity
        ? singlePackage.additionToQuantity
        : 0) -
      (singlePackage.decreaseToQuantity ? singlePackage.decreaseToQuantity : 0);
    const quantity: number = singlePackage.quantity + quantityVariationsTotal;
    const cost: number = singlePackage.realPrice || singlePackage.unitPrice;
    let operation: number = 0;
    if (singlePackage.code === 'ADVANCEDPAYMENT') {
      operation = Number(cost);
      return operation;
    } else if (singlePackage.code === 'LOAN') {
      operation = Number(-Math.abs(cost));
      return operation;
    } else if (singlePackage.code === 'ADVANCEDPAYMENT DISCOUNT') {
      operation = Number(-Math.abs(cost));
      return operation;
    } else {
      // operation = Number(quantity) * Number(cost) - used;
      operation = Number(quantity) * Number(cost);
      return operation;
    }
  }

  calculatePercentageTotal = (singlePackage: Package) => {
    const amountPayed = singlePackage.amountPayed || 0;
    const quantityVariationsTotal: number =
      Number(singlePackage.quantity) +
      (singlePackage.additionToQuantity
        ? singlePackage.additionToQuantity
        : 0) -
      (singlePackage.decreaseToQuantity ? singlePackage.decreaseToQuantity : 0);

    const costTotal = quantityVariationsTotal * singlePackage.unitPrice;

    return this.decimalPipe.transform(
      Number(singlePackage.percentToPay) + (amountPayed * 100) / costTotal,
      '1.2-2'
    );
  };

  calculateTotalPayed = (singlePackage: Package) => {
    const amountPayed = singlePackage.amountPayed || 0;
    const quantityVariationsTotal: number =
      Number(singlePackage.quantity) +
      (singlePackage.additionToQuantity
        ? singlePackage.additionToQuantity
        : 0) -
      (singlePackage.decreaseToQuantity ? singlePackage.decreaseToQuantity : 0);

    const costTotal = quantityVariationsTotal * singlePackage.unitPrice;

    const percentTotal =
      Number(singlePackage.percentToPay) + (amountPayed * 100) / costTotal;

    const calc = (percentTotal * costTotal) / 100;

    return this.decimalPipe.transform(
      (percentTotal * costTotal) / 100,
      '1.2-2'
    );
  };

  calculateAvailable = (singlePackage: Package) => {
    const quantityVariationsTotal: number =
      Number(singlePackage.quantity) +
      Number(
        singlePackage.additionToQuantity ? singlePackage.additionToQuantity : 0
      ) -
      Number(
        singlePackage.decreaseToQuantity ? singlePackage.decreaseToQuantity : 0
      );

    const rowData: number =
      (Number(singlePackage.realPrice) || Number(singlePackage.unitPrice)) *
      quantityVariationsTotal;

    const costTotal = quantityVariationsTotal * singlePackage.unitPrice;
    const amountPayed = singlePackage.amountPayed || 0;
    const percentagePay: number = (amountPayed * 100) / costTotal;

    return rowData - rowData * (percentagePay / 100);
  };

  public calcPercentPay = (singlePackage: Package) => {
    const amountPayed = singlePackage.amountPayed || 0;
    const quantityVariationsTotal: number =
      Number(singlePackage.quantity) +
      (singlePackage.additionToQuantity
        ? singlePackage.additionToQuantity
        : 0) -
      (singlePackage.decreaseToQuantity ? singlePackage.decreaseToQuantity : 0);

    const costTotal = quantityVariationsTotal * singlePackage.unitPrice;

    return (amountPayed * 100) / costTotal;
  };

  public calcAmountPayed = (singlePackage: Package) => {
    const amountPayed = singlePackage.amountPayed || 0;

    return amountPayed;
  };

  calculatePercentageToDisplay = (singlePackage: Package) => {
    return Number(singlePackage.percentToPay);
  };

  calculatePercentageToPay = (singlePackage: Package) => {
    const used: number = singlePackage.costOriginal - singlePackage.available;
    const quantityVariationsTotal: number =
      (singlePackage.additionToQuantity
        ? singlePackage.additionToQuantity
        : 0) -
      (singlePackage.decreaseToQuantity ? singlePackage.decreaseToQuantity : 0);
    const quantity: number = singlePackage.quantity;
    const cost: number = singlePackage.realPrice || singlePackage.unitPrice;
    const percent: number = Number(singlePackage.percentToPay);

    const totalWithVariationsAdded: number =
      (quantity + quantityVariationsTotal) * cost - used;

    if (
      singlePackage.code === 'ADVANCEDPAYMENT' ||
      singlePackage.code === LoantTypeCode.PayLoanPositive
    ) {
      return this.decimalPipe.transform(
        Number((quantity * cost * percent) / 100),
        '1.2-2'
      );
    } else if (
      singlePackage.code === 'ADVANCEDPAYMENT DISCOUNT' ||
      singlePackage.code === LoantTypeCode.PayLoanNegative
    ) {
      return this.decimalPipe.transform(
        Number(-Math.abs((quantity * cost * percent) / 100)),
        '1.2-2'
      );
    } else {
      return this.decimalPipe.transform(
        Number(totalWithVariationsAdded * (percent / 100)),
        '1.2-2'
      );
    }
  };
}
