<ng-template [ngIf]="allowedItems?.length">
  <mat-nav-list fxLayout="column">
    <ng-container *ngFor="let item of allowedItems; let i = index">
      <h3 matSubheader class="menu-title" *ngIf="item.section">
        {{ item.section | uppercase }}
      </h3>
      <app-nav-menu-item [data]="item"> </app-nav-menu-item>
    </ng-container>
  </mat-nav-list>
</ng-template>
