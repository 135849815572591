import { Pipe, PipeTransform } from "@angular/core";

const paymentMethod: { [key: number]: string } = {
  1: 'Efectivo',
  2: 'Crédito'
};

@Pipe({ name: 'purchaseOrderPaymentMethod' })
export class PurchaseOrderPaymentMethodPipe implements PipeTransform {
  public transform(value: number): string {
    return paymentMethod[value] || '?';
  }
}
