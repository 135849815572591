<app-page-widget (refresh)="refreshData()">
    <app-title>Estimaciones</app-title>
    <app-subtitle>Seleccione para ver detalles</app-subtitle>

    <app-action-button icon="cloud_download" (click)="exportExcel()">Exportar Excel</app-action-button>
    <app-action-button icon="playlist_add" (click)="newEstimate()">Crear</app-action-button>

    <div class="header my-15" fxLayout="row">
        <app-select-box class="select ml-15" [widthSelect]="400" [dataSource]="suppliers" valueExpr="uuid" displayExpr="name" value="supplierId" (selectItem)="onSelectBoxSelectionSuppliers($event)" placeholder="Seleccionar proveedor">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="200" [dataSource]="nroEstimatesList" displayExpr="nroEstimate" (selectItem)="onSelectEstimate($event)" placeholder="Selecionar
          estimación">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="180" [dataSource]="statusEstimates" displayExpr="TypeText" (selectItem)="onSelectBoxSelectionEstatus($event)" placeholder="Selecionar
          Estatus">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="260" [dataSource]="contractsList" displayExpr="nroContract" (selectItem)="onSelectEstimateContract($event)" placeholder="Selecionar
          contrato">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="260" [dataSource]="totalEstimatesList" displayExpr="totalEstimateFormat" (selectItem)="onSelectEstimateTotal($event)" placeholder="Selecionar
          Monto de estimación">
        </app-select-box>
        <!-- <app-select-box class="select ml-15" [widthSelect]="400" [dataSource]="selectStatus" displayExpr="TypeText" (selectItem)="onSelectBoxSelectionEstatus($event)" placeholder="Seleccionar estado">
      </app-select-box> -->
    </div>

    <div *ngIf="estimateSelected" class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <div><img class="header-logo" src="./assets/img/small_logo_tbg.png" alt="Logo" /></div> -->
        <div>
            <div class="info-label">
                Estado: <strong>{{ estimateSelected?.status | estimateStatus }}</strong>
            </div>
            <div class="info-label">
                Monto: <strong>{{ calculateEstimateAmountCellValue(estimateSelected) }}</strong>
            </div>
            <div class="info-label">
                Solicitado por:
                <app-user-data *ngIf="estimateSelected" [id]="estimateSelected.createdBy"></app-user-data>
            </div>
            <div class="info-label">
                Contrato: <strong>{{ estimateSelected.nroContract }}</strong>
            </div>
            <!-- <div class="info-label" *ngIf="estimateSelected.documents">
                Documentos:
                <table>
                    <tr>
                        <td style='border: 1px solid'>
                            <ng-container *ngFor="let item of estimateSelected.documents">
                                <a class="ml-5" target="_blank" [href]="item.downloadUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                                <a class="ml-5" (click)="deleteDocument(item)">
                                    <mat-icon matListIcon color="warn">delete</mat-icon>
                                </a>
                            </ng-container>
                        </td>
                    </tr>
                </table>
            </div> -->
        </div>
    </div>

    <div *ngIf="estimateSelected" class="content-block2-section">
        <div class="content-block-section">
            <h3 class="content-title">Proveedor Asignado</h3>
            <div>
                <div>
                    <span class="info-label">Razón Social: </span>
                    <strong>{{ supplier?.socialReason }}</strong>
                </div>
                <div>
                    <span class="info-label">RFC: </span> <strong>{{
                      supplier?.rfc }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Comercial:</span>
                    <strong>{{ supplier?.businessAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Fiscal:</span>
                    <strong>{{ supplier?.taxAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Teléfono:</span> <strong>{{
                      supplier?.phone }}</strong>
                </div>
                <div>
                    <span class="info-label">email: </span> <strong>{{
                      supplier?.email }}</strong>
                </div>
            </div>
            <br />
        </div>

        <div class="content-block-section">
            <h3 class="content-title">Datos para facturación</h3>
            <div>
                <div>
                    <span class="info-label">Razón Social: </span>
                    <strong>{{ supplier?.socialReason }}</strong>
                </div>
                <div>
                    <span class="info-label">RFC: </span> <strong>{{
                      supplier?.rfc }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Comercial:</span>
                    <strong>{{ supplier?.businessAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Dirección Fiscal:</span>
                    <strong>{{ supplier?.taxAddress }}</strong>
                </div>
                <div>
                    <span class="info-label">Teléfono:</span> <strong>{{
                      supplier?.phone }}</strong>
                </div>
                <div>
                    <span class="info-label">E-Mail:</span> <strong>{{
                      supplier?.email }}</strong>
                </div>
            </div>
            <br />
        </div>
    </div>

    <!-- ============================================================== -->
    <!-- GRID Estimates                                                 -->
    <!-- ============================================================== -->
    <!-- <ng-container *ngIf="_estimatesService.pageIndex >= 0"> -->

    <div class="grid-container-div" *ngIf="estimates.length > 0; else noEstimates">
        <dx-data-grid #estimateGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="estimates" [masterDetail]="{ enabled: false, template:
            'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no éxisten estimaciones." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true"
            [allowColumnResizing]="true" [(selectedRowKeys)]="rows" (onExporting)="onExporting($event)" (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">
            <dxo-paging [pageSize]="15" [pageIndex]="_estimatesService.pageIndex"></dxo-paging>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <!-- <dxo-search-panel [visible]="true" p3laceholder="Buscar..."></dxo-search-panel> -->
            <dxo-export [enabled]="true" fileName="lista-estimaciones"></dxo-export>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10,
              20]" [showInfo]="true">
            </dxo-pager>
            <dxo-selection selectAllMode="page" mode="single" [allowSelectAll]="false"></dxo-selection>
            <!-- <dxi-column caption="Nro" dataField="nroEstimate" width="6%" [groupIndex]="0" groupCellTemplate="idTemplate" cellTemplate="idTemplate" [allowEditing]="false" [allowSearch]="true"></dxi-column> -->
            <dxi-column caption="id" dataField="nroEstimate" width="6%" [groupIndex]="0" groupCellTemplate="idTemplate" cellTemplate="idTemplate" [allowEditing]="false" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Fecha" dataField="createdAt" width="15%" cellTemplate="dateTemplate" dataType="date" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true" sortOrder="desc" alignment="left"></dxi-column>
            <dxi-column caption="Proveedor" dataField="supplierId" cellTemplate="supplierTemplate" alignment="center" [allowFiltering]="false" width="25%" [visible]="true"></dxi-column>
            <dxi-column caption="Proveedor" dataField="supplierName" alignment="center" [allowFiltering]="false" width="25%" [visible]="false"></dxi-column>
            <dxi-column caption="Solicitado por" dataField="createdBy" width="20%" cellTemplate="createdByTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [visible]="true">
            </dxi-column>
            <dxi-column caption="Solicitado por" dataField="createdByDisplayName" width="20%" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [visible]="false">
            </dxi-column>
            <dxi-column caption="Monto" dataField="estimateAmount" width="10%" alignment="center" [calculateCellValue]="calculateEstimateAmountCellValue" [allowEditing]="false" [allowSearch]="true">
            </dxi-column>
            <dxi-column caption="Status" dataField="status" cellTemplate="statusTemplate" width="10%" alignment="center" [allowEditing]="false" [allowFiltering]="false" [calculateCellValue]="customizeStatusLabel"></dxi-column>
            <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
            <dxi-column caption="Vista Previa" dataField="uuid" cellTemplate="previewButtonTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Detalle" dataField="uuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span><i class="material-icons icon-gray"
            (click)="seeDetail(data)">
            remove_red_eye
          </i></span>
            </div>

            <div *dxTemplate="let data of 'previewButtonTemplate'">
                <span>
                <i class="material-icons icon-green"
                      (click)="previewDetailEstimate(data.key)">
                  <span class="material-symbols-outlined">
                        preview
                  </span>
                </i>
                </span>
            </div>

            <div *dxTemplate="let data of 'idTemplate'">
                <strong>EST-{{ data.value }}</strong>
            </div>

            <div *dxTemplate="let data of 'dateTemplate'">
                <strong>{{ data.value | timestampToDate }}</strong> -
                <span>hace {{ data.value | timeAgo }}</span>
            </div>

            <div *dxTemplate="let data of 'createdByTemplate'">
                <app-user-data [id]="data.value"></app-user-data>
            </div>

            <div *dxTemplate="let data of 'supplierTemplate'">
                <app-supplier-data [id]="data.value"></app-supplier-data>
            </div>

            <div *dxTemplate="let data of 'statusTemplate'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'approverRemaining'">
                <ng-container *ngIf="data.value.length> 0">
                    <ul class="approversRemaining">
                        <li *ngFor="let item of data.value">
                            <app-user-data [id]="item[0]"></app-user-data>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                    <span>Todos los usuarios han aprobado</span>
                </ng-container>
                <ng-container *ngIf="data.value === ''">
                    <span>No se ha enviado a aprobación</span>
                </ng-container>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalEstimates }}</h2>
                    <span class="name">Total Estimaciones</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
    <ng-template #noEstimates>
        <div class="content text-center">
            <h2>No posee estimaciones</h2>
        </div>
    </ng-template>

    <!-- </ng-container> -->
</app-page-widget>