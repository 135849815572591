<div class="login mat-elevation-z12">
  <div class="header" fxLayout="column" fxLayoutAlign="center center">
    <div class="logo-wrapper">
      <img class="logo" alt="BIST" src="/assets/img/logo/bist.png" />
    </div>
  </div>

  <app-login-form [loading]="loading" [error]="authError" (login)="login($event)"></app-login-form>

  <!-- TODO:
  <p class="note">
    No tiene cuenta de usuario?
    <br>
    <a routerLink="/auth/register">Solicitar acceso</a>
  </p>
  -->
</div>
