<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Paquetes disponibles para entregar</app-title>
    <app-subtitle>Listado</app-subtitle>

    <div class="grid-container-div padding-h padding-bottom-2">

        <div class="padding-v margin-v color-info">
            Si desea realizar una exportación a excel de la información sin agrupaciones, por favor seleccione <span>Sin Orden</span>.
        </div>
        <dx-data-grid #remissionsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="entries" [masterDetail]="{
            enabled: false,
            template: 'detail'
            }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="No hay
            registros" [showBorders]="false" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" (onToolbarPreparing)="onToolbarPreparing($event)">
            <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
            <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false">
            </dxo-selection>
            <!-- <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export> -->
            <dxo-export [enabled]="true" fileName="lista-remisiones"></dxo-export>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column alignment="center" caption="REM" dataField="remissionId" cellTemplate="remissionIdTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column alignment="center" caption="OC" dataField="purchaseOrderId" cellTemplate="pOGroupTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Almacen" dataField="warehouse" cellTemplate="warehousesTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" [groupIndex]="0" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <!-- <dxi-column caption="Fecha" dataField="dateRemission" dataType="date" cellTemplate="dateTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="true" [calculateCellValue]="calculateDateCellValue">
            </dxi-column> -->
            <!-- <dxi-column caption="Estado" dataField="statusReceived" cellTemplate="statusTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column> -->
            <dxi-column caption="Precio unitario" dataField="supplierCost" alignment="center" cellTemplate="costTemplate">
            </dxi-column>
            <dxi-column caption="Costo Enviado" dataField="costSend" alignment="center" cellTemplate="costTemplate">
            </dxi-column>
            <dxi-column caption="Costo Recibido" dataField="costReceived" alignment="center" cellTemplate="costTemplate">
            </dxi-column>
            <dxi-column caption="Costo Salida" [allowEditing]="false" [allowSearch]="true" dataField="costDepartures" cellTemplate="costTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Costo Almacen" [allowEditing]="false" [allowSearch]="true" dataField="costWarehouse" cellTemplate="costTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Enviado" dataField="quantitySend" alignment="center" cellTemplate="quantitySentTemplate">
            </dxi-column>
            <dxi-column caption="Recibido" dataField="quantityReceived" alignment="center" cellTemplate="quantitySentTemplate">
            </dxi-column>
            <dxi-column caption="Salida" [allowEditing]="false" [allowSearch]="true" dataField="quantityDepartures" cellTemplate="quantityDeparturesTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="En almacen" [allowEditing]="false" [allowSearch]="true" dataField="quantityWarehouse" cellTemplate="quantityWarehouseTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <!-- <dxi-column caption="Detalle" dataField="remissionUuid" cellTemplate="ccButtonTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Salida" dataField="remissionUuid" cellTemplate="ccButtonEntryTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column> -->
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span>
                    <i
                        class="material-icons icon-gray"
                        matTooltip="Detalle"
                        (click)="detailRemission(data.key)">
                        remove_red_eye</i></span>
            </div>
            <div *dxTemplate="let data of 'ccButtonEntryTemplate'">
                <span>
                    <i
                        class="material-icons icon-gray"
                        matTooltip="Salida"
                        (click)="departureRemission(data)">
                        input</i></span>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="costSend" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="costReceived" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="costDepartures" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="costWarehouse" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantitySend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityReceived" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityDepartures" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityWarehouse" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <!-- <dxi-total-item column="quantitySend" [customizeText]="formatFooterQuantity" summaryType="sum">
                </dxi-total-item>
                <dxi-total-item column="quantityReceived" [customizeText]="formatFooterQuantity" summaryType="sum">
                </dxi-total-item>
                <dxi-total-item column="quantityDepartures" [customizeText]="formatFooterQuantity" summaryType="sum">
                </dxi-total-item>
                <dxi-total-item column="quantityWarehouse" [customizeText]="formatFooterQuantity" summaryType="sum">
                </dxi-total-item> -->
                <dxi-total-item column="costSend" [customizeText]="formatFooterCost" summaryType="sum">
                </dxi-total-item>
                <dxi-total-item column="costReceived" [customizeText]="formatFooterCost" summaryType="sum">
                </dxi-total-item>
                <dxi-total-item column="costDepartures" [customizeText]="formatFooterCost" summaryType="sum">
                </dxi-total-item>
                <dxi-total-item column="costWarehouse" [customizeText]="formatFooterCost" summaryType="sum">
                </dxi-total-item>
            </dxo-summary>

            <div *dxTemplate="let data of 'warehousesTemplate'">
                <ng-container *ngIf="data.value; else noWarehouse">
                    <app-warehouse-data [id]="data.value"></app-warehouse-data>
                </ng-container>
                <ng-template #noWarehouse>
                    --
                </ng-template>
            </div>

            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>
            <div *dxTemplate="let data of 'pOGroupTemplate'">OC-{{ data.value }}</div>
            <div *dxTemplate="let data of 'statusTemplate'">
                <strong>{{ data.value | remissionStatus }}</strong>
            </div>
            <div *dxTemplate="let data of 'receiverTemplate'">
                <ng-container *ngIf="data.value; else noReceiver">
                    <strong>{{ data.value }}</strong>
                </ng-container>
                <ng-template #noReceiver>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantityWarehouseTemplate'">
                <ng-container *ngIf="data.value> 0; else noReceiver">
                    <strong>{{ data.value }}</strong>
                </ng-container>
                <ng-template #noReceiver>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantitySentTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'quantityApprovedTemplate'">
                <ng-container *ngIf="data.value; else noQuantityApproved">
                    <span> {{ data.value | number: '1.2-2' }}</span>
                </ng-container>
                <ng-template #noQuantityApproved>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
                <ng-container *ngIf="data.value; else noQuantityLeft">
                    {{ data.value | number: '1.2-2' }}
                </ng-container>
                <ng-template #noQuantityLeft>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'costTemplate'">
                <ng-container *ngIf="data.value; else noQuantityLeft">
                    ${{ data.value | number: '1.2-2' }}
                </ng-container>
                <ng-template #noQuantityLeft>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantityDeparturesTemplate'">
                <ng-container *ngIf="data.value; else noQuantityLeft">
                    {{ data.value | number: '1.2-2' }}
                </ng-container>
                <ng-template #noQuantityLeft>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalRemissions }}</h2>
                    <span class="name">Total Remisiones</span>
                </div>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'remissionIdTemplate'">REM-{{ data.value }}</div>
        </dx-data-grid>
    </div>
</app-page-widget>