<ng-template [ngIf]="columns">
    <!-- TODO: DISABLE UNTIL #151 -->
    <ng-template [ngIf]="0">
        <div *ngIf="enableFiltering || filterBy" class="search-bar">
            <rmd-search-bar big customBar [placeholder]="'Filtrar' + (filterBy ? (' por ' + filterByText) : '') + '...'" (change)="filterRows($event)">
            </rmd-search-bar>
        </div>
    </ng-template>

    <ngx-datatable class="app-datatable material expandable" [columnMode]="'force'" [headerHeight]="30" [footerHeight]="50" [rowHeight]="40" [scrollbarV]="false" [selected]="_selectedRows" [selectionType]="selectionType" [selectAllRowsOnPage]="false" [rows]="currentRows"
        [limit]="10" [groupRowsBy]="groupedBy" (select)='onSelect($event)'>

        <ngx-datatable-column *ngIf="canSelectMultiple && selectWithCheckboxes" [width]="36" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
        </ngx-datatable-column>

        <!-- TODO:
    <ngx-datatable-column *ngIf="rowDetails"
                          [width]="36"
                          [sortable]="false"
                          [canAutoResize]="false"
                          [draggable]="false"
                          [resizeable]="false"
                          [headerCheckboxable]="true"
                          [checkboxable]="true">
      <ng-template let-row="row"
                   let-expandedRows="_expandedRows"
                   ngx-datatable-cell-template>
        <a href="javascript:void(0)"
           [class.datatable-icon-right]="!_expandedRows"
           [class.datatable-icon-down]="_expandedRows"
           title="Ver detalles">
        </a>
      </ng-template>
    </ngx-datatable-column> -->

        <ngx-datatable-group-header *ngIf="groupedBy !== null" #dataTableGroupHeader [rowHeight]="40" (toggle)="onDetailToggle($event)">
            <ng-template let-group="group" let-expandedRows="_expandedRows" ngx-datatable-group-header-template>
                <div style="padding-left: 5px;">
                    <a [class.datatable-icon-right]="!_expandedRows" [class.datatable-icon-down]="_expandedRows" title="Ver Detalles" (click)="toggleExpandRow(group)">
                        <b>{{ group.value[0].code }}</b>
                    </a>
                </div>
            </ng-template>
        </ngx-datatable-group-header>

        <ngx-datatable-column *ngFor="let column of columns" [prop]="column.field || column.prop || column.name" [name]="column.title || column.name || column.field || column.prop || '?'" [canAutoResize]="!column.width" [resizeable]="true" [width]="column.width || null">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span [innerHtml]="formatCellValue(column, value)"></span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column *ngIf="showImpersonate" name="Personificar" prop="impersonate" [cellClass]="'text-center'" [maxWidth]="100">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <i class="material-icons" *ngIf="value">
          transfer_within_a_station
        </i>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</ng-template>