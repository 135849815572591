import { Extension } from 'ng2-adsk-forge-viewer';

declare const THREE: any;

export class MyExtension extends Extension {
  // Extension must have a name
  public static extensionName: string = 'MyExtension';

  // Toolbar test
  private subToolbar: Autodesk.Viewing.UI.ToolBar;
  private onToolbarCreatedBinded: any;

  public activate() {
    return true;
  }

  public deactivate() {
    return true;
  }

  public load() {
    // Called when Forge Viewer loads your extension
    console.log('MyExtension loaded!');
    const ids = [21975,26469,26453,21957,36959,36983,37007,37031,37033,37009,36985];

    console.log(this.viewer);

    this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (e) => {
      if (e.dbIdArray.length) {
        const dbId = e.dbIdArray[0];
        console.log(dbId);
        this.viewer.setThemingColor(dbId, new THREE.Vector4(0, 1, 1,1));
      }
    });

    this.viewer.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, (e) => {
      console.log('AQYUIIIIUIUIu', e);
      const ids = [21975,26469,26453,21957,36959,36983,37007,37031,37033,37009,36985, 6050];
      for (let index = 0; index < ids.length; index++) {
        this.viewer.setThemingColor(ids[index], new THREE.Vector4(0, 1, 1,1));
      }
    });

    // Initialise a toolbar
    if (this.viewer.toolbar) {
      // Toolbar is already available, create the UI
      this.createUI();
    } else {
      // Toolbar hasn't been created yet, wait until we get notification of its creation
      this.onToolbarCreatedBinded = this.onToolbarCreated.bind(this);
      this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
    }

    // Must return true or extension will fail to load
    return true;
  }

  public unload() {
    if (this.subToolbar) {
      this.viewer.toolbar.removeControl(this.subToolbar);
      this.subToolbar = null;
    }

    // Called when Forge Viewer unloads your extension
    console.log('MyExtension unloaded.');
    // Must return true or extension will fail to unload
    return true;
  }

  public onToolbarCreated() {
    this.viewer.removeEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
    this.onToolbarCreatedBinded = null;
    this.createUI();
  }

  private createUI() {
    // Button 1
    const button1 = new Autodesk.Viewing.UI.Button('my-view-front-button');
    button1.onClick = e => this.setViewCube('front');
    button1.addClass('my-view-front-button');
    button1.setToolTip('View front');

    // Button 2
    const button2 = new Autodesk.Viewing.UI.Button('my-view-back-button');
    button2.onClick = e => this.setViewCube('back');
    button2.addClass('my-view-back-button');
    button2.setToolTip('View Back');

    // SubToolbar
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('my-custom-view-toolbar');
    this.subToolbar.addControl(button1);
    this.subToolbar.addControl(button2);

    this.viewer.toolbar.addControl(this.subToolbar);
  }

  private setViewCube(orientation: 'front'|'back') {
    const ext = (this.viewer.getExtension('Autodesk.ViewCubeUi') as any);
    ext.setViewCube(orientation);
  }

  public changedIsolate(e) {
    console.log(e);
    console.log(this.viewer);
    this.viewer.isolate(e);
  }
}