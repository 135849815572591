<app-page-widget (refresh)="refreshData()" [menuItems]="widgetMenu" id="remission">
    <app-title>REM-{{ remission?.uuid | idFromUuid }}</app-title>
    <app-subtitle>{{ remission?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <button mat-raised-button class="action-button" *ngIf="isRemissionReadyToDownload" (click)="downloadRemission()" color="primary">
        <mat-icon>cloud_download</mat-icon>
        Descargar remisión
    </button>
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="status-block">
            <div>
                Estado:
                <strong>{{ remission?.status | remissionStatus }}</strong>
            </div>
            <div>
                Orden de compra:
                <strong>{{ remission?.purchaseOrderId }}</strong>
            </div>
            <!-- <div>
        Proveedor:
        <app-user-data *ngIf="remission" [id]="remission.createdBy"></app-user-data>
      </div> -->
        </div>
    </div>

    <div class="content-block-section">
        <h3 class="content-title">Dirección de envío</h3>
        <div>
            <div>
                <span class="info-label">Calle: </span>
                <strong>{{ warehouse?.shippingStreet }}</strong>
            </div>
            <div>
                <span class="info-label">No. Ext: </span>
                <strong>{{ warehouse?.shippingNoExt }}</strong>
            </div>
            <div>
                <span class="info-label">No. Int: </span>
                <strong>{{ warehouse?.shippingNoInt }}</strong>
            </div>
            <div>
                <span class="info-label">Colonia:</span>
                <strong>{{ warehouse?.shippingColony }}</strong>
            </div>
            <div>
                <span class="info-label">Municipio:</span>
                <strong>{{ warehouse?.shippingMunicipality }}</strong>
            </div>
            <div>
                <span class="info-label">Código postal:</span>
                <strong>{{ warehouse?.shippingPostalCode }}</strong>
            </div>
            <div>
                <span class="info-label">Entre calles: </span>
                <strong>{{ warehouse?.shippingBetweenStreets}}</strong>
            </div>
            <div>
                <span class="info-label">Referencias: </span>
                <strong>{{ warehouse?.shippingReferences }}</strong>
            </div>
            <div>
                <span class="info-label">Notas: </span>
                <strong>{{ warehouse?.shippingNotes }}</strong>
            </div>
        </div>
    </div>

    <div class="content-block">
        <h3 class="content-title">Paquetes</h3>
        <dx-data-grid id="grid-container" noDataText="No hay registros" [wordWrapEnabled]="true" [dataSource]="remissionPackages" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="false" [rowAlternationEnabled]="false"
            [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <!-- <dxi-column alignment="center" caption="ID" dataField="packageId" width="20%" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
            </dxi-column> -->
            <dxi-column caption="Concepto" dataField="concept" width="40%" cellTemplate="conceptTemplate" alignment="left" [allowEditing]="false" [allowSearch]="false" [allowSorting]="true">
            </dxi-column>
            <dxi-column caption="Nivel" dataField="level" width="20%" cellTemplate="levelTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Unidad" dataField="unit" width="20%" alignment="center" cellTemplate="unitTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" width="20%" alignment="center" cellTemplate="quantityTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Cantidad enviada" dataField="quantitySent" width="20%" alignment="center" cellTemplate="quantitySentTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Cantidad recibida" dataField="quantityApproved" width="20%" alignment="center" cellTemplate="quantityApprovedTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <div *dxTemplate="let data of 'conceptTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'levelTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'quantityTemplate'">
                <span> {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'quantitySentTemplate'">
                <span> {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'quantityApprovedTemplate'">
                <ng-container *ngIf="data.value; else noQuantityReceivedYet">
                    <span>{{ data.value | number }}</span>
                </ng-container>
                <ng-template #noQuantityReceivedYet>
                    --
                </ng-template>
            </div>
        </dx-data-grid>
    </div>
</app-page-widget>