<app-page-widget noPadding>
    <app-title>Listado</app-title>
    <app-subtitle>Por favor, seleccione un modelo</app-subtitle>

    <app-action-button icon="save" tooltip="Guardar Modelo" disabled (disabled)="true" (click)="saveData()">Guardar</app-action-button>

    <ng-container *ngIf="isDataLoaded">
        <div class="div-container">
            <div class="left">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                        <button mat-icon-button (click)="selectModel(node)">{{node.item}}</button>
                    </mat-tree-node>
                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                        <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button> {{node.item}}
                        <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="example-tree-progress-bar"></mat-progress-bar>
                    </mat-tree-node>
                </mat-tree>
            </div>
            <div class="right">
                <model-detail *ngIf="documentUrn" [accessToken]="accessToken" [documentUrn]="documentUrn"></model-detail>
            </div>
        </div>

    </ng-container>

</app-page-widget>