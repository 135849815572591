import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IsolateEventArgs, ShowEventArgs, ViewerOptions } from 'ng2-adsk-forge-viewer';
import { InfoService } from '../../services/info/info.service';
import { ForgeService } from '@bist-web/shared/services/forge/forge.service';
import { PackagesModelsService } from '@bist-lib/db-api';

@Component({
  selector: 'model-detail',
  templateUrl: './model-detail.component.html',
  styleUrls: ['./model-detail.component.css']
})
export class ModelDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('forge', { static: false }) forgeElement: ElementRef;
  @Input('accessToken') accessToken: string;
  @Input('documentUrn') documentUrn: string;
  @Output() dbId = new EventEmitter();
  accessTokenIsLoaded: boolean = false;
  public dbIdsData: number[] = [];
  public forgeIds = [];
  public allDbIdsData;
  public viewer: any;
  public packageSelected = false;
  private typeAction: String;

  public viewerOptions: ViewerOptions;

  constructor(
    private _forgeService: ForgeService,
    private _packagesModelsService: PackagesModelsService,
    private _infoService: InfoService
    ){


  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.viewerOptions = {
      initializerOptions: {
        env: "AutodeskProduction",
        getAccessToken: (
          onGetAccessToken: (token: string, expire: number) => void
        ) => {
          const expireTimeSeconds = 60 * 30;
          onGetAccessToken(this.accessToken, expireTimeSeconds);
        },
        api: "derivativeV2",
        enableMemoryManagement: true
      },
      viewerConfig: {
        // extensions: ["Autodesk.DocumentBrowser", MyExtension.extensionName],
        theme: "bim-theme"
      },
      onViewerScriptsLoaded: () => {
        // Register a custom extension
        // Extension.registerExtension(MyExtension.extensionName, MyExtension);
      },
      onViewerInitialized: (args) => {
        this.viewer = args;
        args.viewerComponent.DocumentId = this.documentUrn;
      },
      // showFirstViewable: false,
      // headlessViewer: true,
      // Set specific version number
      //version: "7.41"
    };
  }

  ngAfterViewInit(): void {
    this._forgeService.type.subscribe( (value: string) => {
      // console.log(this.viewer.viewer.model);
      this.typeAction = value;
      if(this.viewer) {
        this.firstLoad(this.viewer.viewer, value);
      }
    });

    this._packagesModelsService.forgeIdsSelected.subscribe( (value) => {
      this.forgeIds = value;
      this.packageSelected = true;
      if(this.viewer) {
        this.onLoad(this.viewer.viewer);
      }
    })
  }

  public selectionChanged(event) {
    const dbIds = event.dbIdArray;

    if(!this.packageSelected){
      this._infoService.info('Debe seleccionar un paquete');
      return;
    }

    if(!this.typeAction || this.typeAction === 'search' || this.typeAction === '') {
      this._infoService.info('Debe elegir una acción (Eliminar o Agregar)');
      return;
    }

    // if(this.dbIdsData.length === 0) {
    //   dbIds.forEach((dbId: number) => {
    //     this.dbIdsData.push(dbId);
    //     event.model.setThemingColor(dbId, new THREE.Vector4(0, 1, 1,1));
    //   });
    // }else{
    //   event.model.clearThemingColors(event.model);
    //   dbIds.forEach((dbId: number) => {
    //     const index = this.dbIdsData.indexOf(dbId);

    //     if(index > -1) {
    //       this.dbIdsData.splice(index, 1);
    //     }else{
    //       this.dbIdsData.push(dbId);
    //     }
    //   });

    //   this.dbIdsData.forEach( (singleId: number) => {
    //     event.model.setThemingColor(singleId, new THREE.Vector4(0, 1, 1,1));
    //   })
    // }

    if(dbIds.length > 0) {
      event.model.getBulkProperties2(dbIds, {
        propFilter: ['ID_FORGE'],
        ignoreHidden: true
      }, (response) => {
        let dbIds = [];

        response.forEach(element => {
          event.model.setThemingColor(element.dbId, new THREE.Vector4(0, 1, 1,1));
          dbIds.push(parseInt(element.properties[0].displayValue));
        });

        this.dbId.emit(dbIds);
      });
    }
  }

  public isolateChanged(event: IsolateEventArgs) {
  }

  public onShow(event: ShowEventArgs) {
  }

  public onLoad(event) {
    const typeAction = sessionStorage.getItem('TYPE_ACTION');

    if(this.forgeIds.length === 0) {
      const forgeIdsString = sessionStorage.getItem('FORGE_IDS').slice(1, -1);

      const forgeIds = forgeIdsString.split(',');

      const newForgeIds: number[] = forgeIds.map( (singleId) => parseInt(singleId));

      this.forgeIds = forgeIds.length > 0 ? newForgeIds : [];
    }

    this.firstLoad(event, typeAction);

  }

  firstLoad(viewer, typeAction = null) {
    const dbIds = viewer.model.getFragmentList().fragments.fragId2dbId;
    viewer.model.clearThemingColors(viewer.model);

      viewer.model.getBulkProperties2(dbIds, {
        propFilter: ['ID_FORGE'],
        ignoreHidden: true
      }, (response) => {
        this.allDbIdsData = response;
        const dataIdForge = [];

        const responseMap = response.map( (data) => {
          return {dbId: data.dbId, idForge: parseInt(data.properties[0].displayValue)};
        });

        let lastIdx = 0;

        let forgeIdsSort = responseMap.sort( (a,b) => {
          if (a.idForge > b.idForge) return 1;
          if (a.idForge < b.idForge) return -1;

          return 0;
        });

        let dbIdsSort = this.forgeIds.sort( (a,b) => a - b);

        for(let i = 0; i < dbIdsSort.length; i++){
          for(let idx = lastIdx; idx < forgeIdsSort.length; idx++){
            if(forgeIdsSort[idx].idForge === dbIdsSort[i]){
              dataIdForge.push(forgeIdsSort[idx].dbId);
              viewer.model.setThemingColor(forgeIdsSort[idx].dbId, new THREE.Vector4(0, 1, 1,1));
              lastIdx = idx;
              break;
            }
          }
        }

        if(typeAction === 'update') {
          this.viewer.viewer.isolate(dataIdForge);
        }else if(typeAction === 'add') {
          const isolate: number[] = [];
          dbIds.forEach( (singleElement: number) => {
            if(dataIdForge.indexOf(singleElement) < 0) {
              isolate.push(singleElement);
            };
          });
          this.viewer.viewer.isolate(isolate);
        }else{
          this.viewer.viewer.isolate(dataIdForge);
        }

      });
  }

  applyIsolate() {
    this.viewer.viewer.isolate(this.dbIdsData);
  }
}
