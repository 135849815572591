<app-dialog #dialog [actionsOnBottom]="false" [autoFocus]="false" width="90%">
  <app-title>Historial de paquete</app-title>
  <app-subtitle>Número de cambios: {{ history?.length || 0 }}</app-subtitle>

  <app-action-button flat noText icon="filter_list" (click)="displayFilters = !displayFilters">
    Filtros
  </app-action-button>

  <div *ngIf="history" class="max-height-500">
    <dx-data-grid
      noDataText="Sin datos para mostrar."
      [dataSource]="history"
      [wordWrapEnabled]="true"
      [showBorders]="true"
      [showColumnLines]="true"
      [showRowLines]="true"
    >
      <dxo-filter-row [visible]="displayFilters"></dxo-filter-row>
      <dxo-search-panel [visible]="displayFilters" placeholder="Buscar..."></dxo-search-panel>
      <dxo-paging [pageSize]="10"></dxo-paging>

      <dxi-column width="10%" caption="PQT ID" dataField="packageId"></dxi-column>
      <dxi-column width="15%" caption="Código" dataField="code"></dxi-column>
      <dxi-column
        width="14%"
        caption="Fecha"
        dataField="createdAt"
        alignment="left"
        sortOrder="asc"
        cellTemplate="dateTpl"
      >
      </dxi-column>
      <dxi-column width="14%" caption="Usuario" dataField="createdBy" cellTemplate="createdByTpl">
      </dxi-column>
      <dxi-column
        caption="Acción"
        width="5%"
        dataField="historyOp"
        cellTemplate="historyOpTpl"
        alignment="center"
      >
      </dxi-column>
      <dxi-column caption="Motivo" dataField="opMotive" cellTemplate="opMotiveTpl" alignment="left">
      </dxi-column>
      <dxi-column
        caption="OP"
        width="5%"
        dataField="opType"
        alignment="center"
        cellTemplate="opTypeTpl"
      >
      </dxi-column>
      <dxi-column caption="Cantidad previa" dataField="opPreviousQuantity"></dxi-column>
      <dxi-column caption="Cantidad de la operación" dataField="opQuantity"></dxi-column>
      <dxi-column caption="Resultado final" dataField="opResult" cellTemplate="opResultTpl">
      </dxi-column>

      <div *dxTemplate="let data of 'dateTpl'">
        <app-timeago-date [timestamp]="data.value"></app-timeago-date>
      </div>
      <div *dxTemplate="let data of 'createdByTpl'">
        <app-user-data [id]="data.value"></app-user-data>
      </div>
      <div *dxTemplate="let data of 'historyOpTpl'">
        <mat-icon style="font-size: 22px" [matTooltip]="historyTypes[data.value] || '?'">
          {{ historyTypeIcons[data.value] || '?' }}
        </mat-icon>
      </div>
      <div *dxTemplate="let data of 'opMotiveTpl'">{{ opMotives[data.value] || '?' }}</div>
      <div *dxTemplate="let data of 'opTypeTpl'">
        <mat-icon style="font-size: 22px" [matTooltip]="'Operación: ' + opTypes[data.value] || '?'">
          {{ opTypeIcons[data.value] || '?' }}
        </mat-icon>
      </div>
      <div *dxTemplate="let data of 'opResultTpl'">
        <b>{{ data.value }}</b>
      </div>
    </dx-data-grid>
  </div>
</app-dialog>

<a class="dialog-trigger" matTooltip="Click para ver historial de paquete" (click)="dialog.open()">
  <mat-icon *ngIf="!noIcon">{{ icon }}</mat-icon>
  <span *ngIf="text" [innerText]="text"></span>
</a>
