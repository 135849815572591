<app-page-widget (refresh)="refreshData()">
    <app-title></app-title>
    <app-subtitle></app-subtitle>

    <div class="actions-block">
        <button mat-raised-button [disabled]="!allowPayButton" class="action-button" (click)="payOrders()" color="primary">
            <mat-icon>attach_money</mat-icon>
            Pagar
        </button>
    </div>

    <h2>Total Pasivos: ${{ totalPassivesAmount | number : '1.2-3' }}</h2>

    <div id="purchase-orders" class="order-type-block">
        <div class="header-block">
            <span class="amount-value-label">Ordenes de Compra: </span>
            <span class="amount-value"><strong>${{ purchaseOrdersPassivesSummary
                    |
                    number : '1.2-3' }}</strong></span>
        </div>

        <div class="content-block">
            <dx-data-grid id="poGrid" #poGrid [dataSource]="purchaseOrders" (onSelectionChanged)="onSelectPoRow($event)" (onRowUpdated)="onRowUpdated($event)">
                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
                <dxi-column dataField="id" caption="OC ID" cellTemplate="ocIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"> </dxi-column>
                <dxi-column dataField="supplier.name" caption="Proveedor" cellTemplate="supplierTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="left">
                </dxi-column>
                <dxi-column dataField="orderAmount" caption="Sub Total" cellTemplate="orderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="taxes" caption="IVA" cellTemplate="taxesTemplate" [calculateCellValue]="calculatePcoTaxes" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"> </dxi-column>
                <dxi-column dataField="totalOrderAmount" caption="Total" cellTemplate="totalOrderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="paidAmmount" caption="Monto Pagado" cellTemplate="paidAmmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="ammountToPay" caption="Monto por Pagar" cellTemplate="ammountToPayTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="toPay" caption="Pagar $" dataType="number" cellTemplate="toPayTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">
                    <dxi-validation-rule type="custom" [validationCallback]="isValid" message="Cantidad
                        Invalida">
                    </dxi-validation-rule>
                </dxi-column>
                <!-- <dxi-column dataField="deletePassive" caption="Eliminar" cellTemplate="deletePassive" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column> -->
                <dxo-editing mode="cell" [allowUpdating]="true">
                </dxo-editing>

                <div class="padding-top-10" *dxTemplate="let orderAmount of
                    'orderAmountTemplate'">
                    <span *ngIf="orderAmount?.value">${{orderAmount.value |
                        number :
                        '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let data of
                    'supplierTemplate'">
                    <ng-container *ngIf="data.value; else noSupplier">
                        <span>{{ data.value }}</span>
                    </ng-container>
                    <ng-template #noSupplier>
                        No hay nombre de proveedor
                    </ng-template>
                </div>
                <div class="padding-top-10" *dxTemplate="let taxes of
                    'taxesTemplate'">
                    <span *ngIf="taxes?.value">${{taxes.value | number :
                        '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let totalOrderAmount of
                    'totalOrderAmountTemplate'">
                    <span *ngIf="totalOrderAmount?.value">${{totalOrderAmount.value
                        |
                        number : '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let paidAmount of
                    'paidAmmountTemplate'">
                    <span *ngIf="paidAmount?.value">${{paidAmount.value | number
                        :
                        '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let amountToPay of
                    'ammountToPayTemplate'">
                    <span *ngIf="amountToPay?.value">${{amountToPay.value |
                        number :
                        '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let toPay of
                    'toPayTemplate'">
                    <span *ngIf="toPay?.value">${{toPay.value | number :
                        '1.2-2'}}</span>
                    <span *ngIf="!toPay || !toPay.value" class="placeholder-color">Ingrese
                        Cantidad</span>
                </div>
                <div *dxTemplate="let data of 'ocIdTemplate'">
                    <button mat-button (click)="goToPoDetail(data)">
                        <span style="color:blueviolet">
                            <strong>{{data.data.id}}</strong>
                        </span>
                    </button>
                </div>
                <!-- <div *dxTemplate="let data of 'deletePassive'">
                    <button mat-icon-button color="warn" (click)="deletePassive(data)">
            <mat-icon>delete_outline</mat-icon>
          </button>
                </div> -->
            </dx-data-grid>
        </div>
    </div>

    <div id="payment-check-orders" class="order-type-block">
        <div class="header-block">
            <span class="amount-value-label">Ordenes de Cheque: </span>
            <span class="amount-value"><strong>${{
                    paymentCheckOrdersPassivesSummary |
                    number : '1.2-2' }}</strong></span>
        </div>

        <div class="content-block">
            <dx-data-grid id="pcoGrid" #pcoGrid [dataSource]="paymentCheckOrders" (onSelectionChanged)="onSelectPcoRow($event)" (onRowUpdated)="onRowUpdated($event)">
                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
                <dxi-column dataField="id" caption="OCH ID" cellTemplate="ocIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"> </dxi-column>
                <dxi-column dataField="supplier.name" caption="Proveedor" cellTemplate="supplierTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="left">
                </dxi-column>
                <dxi-column dataField="orderAmount" caption="Sub Total" cellTemplate="orderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="taxes" caption="Impuestos" cellTemplate="taxesTemplate" [calculateCellValue]="calculatePcoTaxes" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"> </dxi-column>
                <dxi-column dataField="totalOrderAmount" caption="Total" cellTemplate="totalOrderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="paidAmmount" caption="Monto Pagado" cellTemplate="paidAmmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="ammountToPay" caption="Monto por Pagar" cellTemplate="ammountToPayTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="toPay" caption="Pagar $" dataType="number" cellTemplate="toPayTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">
                    <dxi-validation-rule type="custom" [validationCallback]="isValid" message="Cantidad
                        Invalida">
                    </dxi-validation-rule>
                </dxi-column>
                <dxo-editing mode="cell" [allowUpdating]="true">
                </dxo-editing>

                <div *dxTemplate="let orderAmount of 'orderAmountTemplate'">
                    <span *ngIf="orderAmount?.value">${{orderAmount.value |
                        number :
                        '1.2-2'}}</span>
                </div>
                <div *dxTemplate="let data of 'supplierTemplate'">
                    <ng-container *ngIf="data.value; else noSupplier">
                        <span>{{ data.value }}</span>
                    </ng-container>
                    <ng-template #noSupplier>
                        No hay nombre de proveedor
                    </ng-template>
                </div>
                <div *dxTemplate="let taxes of 'taxesTemplate'">
                    <span *ngIf="taxes?.value">${{taxes.value | number :
                        '1.2-2'}}</span>
                </div>
                <div *dxTemplate="let totalOrderAmount of
                    'totalOrderAmountTemplate'">
                    <span *ngIf="totalOrderAmount?.value">${{totalOrderAmount.value
                        |
                        number : '1.2-2'}}</span>
                </div>
                <div *dxTemplate="let paidAmount of 'paidAmmountTemplate'">
                    <span *ngIf="paidAmount?.value">${{paidAmount.value | number
                        :
                        '1.2-2'}}</span>
                </div>
                <div *dxTemplate="let amountToPay of 'ammountToPayTemplate'">
                    <span *ngIf="amountToPay?.value">${{amountToPay.value |
                        number :
                        '1.2-2'}}</span>
                </div>
                <div *dxTemplate="let toPay of 'toPayTemplate'">
                    <span *ngIf="toPay?.value">${{toPay.value | number :
                        '1.2-2'}}</span>
                </div>
                <div *dxTemplate="let data of 'ocIdTemplate'">
                    <button mat-button (click)="goToPcoDetail(data)">
                        <span style="color:blueviolet">
                            <strong>{{data.data.id}}</strong>
                        </span>
                    </button>
                </div>
            </dx-data-grid>
        </div>
    </div>

    <div id="purchase-orders" class="order-type-block">
        <div class="header-block">
            <span class="amount-value-label">Ordenes de Estimación: </span>
            <span class="amount-value"><strong>${{ estimateOrdersPassivesSummary
                    |
                    number : '1.2-2' }}</strong></span>
        </div>

        <div class="content-block">
            <dx-data-grid id="eoGrid" #eoGrid [dataSource]="estimateOrders" (onSelectionChanged)="onSelectEoRow($event)" (onRowUpdated)="onRowUpdated($event)">
                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
                <dxi-column dataField="id" caption="OE ID" cellTemplate="ocIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"> </dxi-column>
                <dxi-column dataField="supplier.name" caption="Proveedor" cellTemplate="supplierTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="left">
                </dxi-column>
                <dxi-column dataField="orderAmount" caption="Sub Total" cellTemplate="orderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="taxAmount" caption="IVA" cellTemplate="taxesTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField=ivaCompl caption="IVA Complementario" cellTemplate="taxesTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField=isr caption="ISR" cellTemplate="taxesTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="totalOrderAmount" caption="Total" cellTemplate="totalOrderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="paidAmmount" caption="Monto Pagado" cellTemplate="paidAmmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="ammountToPay" caption="Monto por Pagar" cellTemplate="ammountToPayTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="toPay" caption="Pagar $" dataType="number" cellTemplate="toPayTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">
                    <dxi-validation-rule type="custom" [validationCallback]="isValid" message="Cantidad
                        Invalida">
                    </dxi-validation-rule>
                </dxi-column>
                <dxo-editing mode="cell" [allowUpdating]="true">
                </dxo-editing>

                <div class="padding-top-10" *dxTemplate="let orderAmount of
                    'orderAmountTemplate'">
                    <span *ngIf="orderAmount?.value">${{orderAmount.value |
                        number :
                        '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let data of
                    'supplierTemplate'">
                    <ng-container *ngIf="data.value; else noSupplier">
                        <span>{{ data.value }}</span>
                    </ng-container>
                    <ng-template #noSupplier>
                        No hay nombre de proveedor
                    </ng-template>
                </div>
                <div class="padding-top-10" *dxTemplate="let taxes of
                    'taxesTemplate'">
                    <span *ngIf="taxes?.value">${{taxes.value | number :
                        '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let totalOrderAmount of
                    'totalOrderAmountTemplate'">
                    <span *ngIf="totalOrderAmount?.value">${{totalOrderAmount.value
                        |
                        number : '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let paidAmount of
                    'paidAmmountTemplate'">
                    <span *ngIf="paidAmount?.value">${{paidAmount.value | number
                        :
                        '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let amountToPay of
                    'ammountToPayTemplate'">
                    <span *ngIf="amountToPay?.value">${{amountToPay.value |
                        number :
                        '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let toPay of
                    'toPayTemplate'">
                    <span *ngIf="toPay?.value">${{toPay.value | number :
                        '1.2-3'}}</span>
                </div>
                <div *dxTemplate="let data of 'ocIdTemplate'">
                    <button mat-button (click)="goToEoDetail(data)">
                        <span style="color:blueviolet">
                            <strong>{{data.data.id}}</strong>
                        </span>
                    </button>
                </div>
            </dx-data-grid>
        </div>
    </div>


</app-page-widget>