import { CompilerOptions, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { RootModule } from './root';

if (environment.production) {
  enableProdMode();
}

const bootstrapOptions: CompilerOptions | any = {
  preserveWhitespaces: false
  // XXX: ngZone: 'noop', /* NgZone | 'zone.js' | 'noop' */
};

platformBrowserDynamic()
  .bootstrapModule(RootModule, bootstrapOptions)
  .then((ref) => {
    /* Ensure Angular destroys itself on hot reloads */

    const win: Window | any = window;

    if (win['ngRef']) {
      win['ngRef'].destroy();
    }

    win['ngRef'] = ref;

    /* Otherwise, log the boot error */
  })
  .catch((err: any) => console['error'](err));

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));
