import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@bist-web/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ForgeService {
  public dbIdsData = [];
  public type: BehaviorSubject<String> = new BehaviorSubject<String>('');

  constructor(private http?: HttpClient) { }

  /**
   * Returns an access token to use Power Bi Rest Api
   * @async
   * @function  getReportInGroup
   * @param     {string}        accessToken - generated when authenticating with Azure's Active Directory tenant.
   * @param     {Object}        getReportInGroup - details about Power Bi's groupId and reportId.
   * @param     {string}        getReportInGroup.groupId - id of Power Bi workspace.
   * @param     {string}        getReportInGroup.reportId - id of Power Bi report we're trying to embed.
   */
  public getModelsList(getModelsList): Observable<any> {

    const endpoint: string = environment.forgeConfig.getModelsUrl;
    const httpOptions: {} = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(endpoint, getModelsList, httpOptions);

  }

  getChildren(projectId: string, folderUrn: string, type:string, getModelsList): any {
    const endpoint: string = environment.forgeConfig.getFoldersUrl;
    const httpOptions: {} = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    getModelsList.projectId = projectId;
    getModelsList.folderUrn = folderUrn;
    getModelsList.type      = type;

    return this.http.post<any>(endpoint, getModelsList, httpOptions);
  }

  isExpandable(name): any {
    // return this.dataMap.has(node);
  }

  getToken() {
    const endpoint: string = environment.forgeConfig.getTokenUrl;
    const httpOptions: {} = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get<any>(endpoint);
  }
}
