<app-page-widget>
    <app-title>Nuevo Préstamo</app-title>
    <app-subtitle> Para: Contratos tasa 0% / Subcontratos</app-subtitle>

    <div class="content-section">
        <div class="content-sub-section">
            <h3 class="content-title">Seleccionar sección y proveedor:</h3>
            <div class="content-section">
                <app-select-box class="selectProv" [widthSelect]="300" [dataSource]="categoryes" displayExpr="TypeText" (selectItem)="onSelectBoxSelectionCategory($event)" placeholder="Selecionar Tipo">
                </app-select-box>
                <div class="selectProv">
                    <app-select-box class="" [widthSelect]="550" [dataSource]="spliers" displayExpr="socialReason" (selectItem)="onSelectBoxSelectionSupplier($event)" placeholder="Selecionar Proveedor">
                    </app-select-box>
                </div>
            </div>
            <div class="footer margin-left-20">
                <small>
          Generar préstamo sobre asignación previamente aprobada sélo se permite un prestamo por
          asignación.
        </small>
            </div>
            <h3 class="content-title margin-top-40">Datos para generar préstamo:</h3>
            <div class="content-section">
                <form #f="ngForm" [formGroup]="myForm" class=" margin-left-10">
                    <mat-form-field>
                        <input formControlName="monto" matInput type="number" placeholder="Monto" required />
                    </mat-form-field>
                    <mat-form-field class="margin-left-50">
                        <input #inputPayments matInput type="number" formControlName="pagos" placeholder="Pagos" required />
                    </mat-form-field>
                    <mat-form-field class="margin-left-50">
                        <textarea #inputComments matInput formControlName="comentarios" placeholder="Comentarios" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5"></textarea>
                    </mat-form-field>
                </form>
            </div>

            <h3 class="content-title margin-top-40">Seleccionar Asignación:</h3>

            <dx-data-grid #assignmentsGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="assignments" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no éxisten registros." [showBorders]="true" [rowAlternationEnabled]="false"
                [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" [(selectedRowKeys)]="Rows" (onRowClick)="rowDetails($event)">
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <!-- <dxo-search-panel [visible]="true"></dxo-search-panel> -->
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
                <dxi-column caption="ID" dataField="id" width="7%" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column caption="Fecha" width="25%" dataField="createdAt" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" dataType="date" [allowFiltering]="true" cellTemplate="dateTemplate" [allowSorting]="true" sortOrder="desc" alignment="center">
                </dxi-column>
                <!-- <dxi-column caption="Status" dataField="status" width="15%" cellTemplate="assignmentStatusTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center"> </dxi-column> -->
                <dxi-column caption="Solicitante" dataField="createdBy" cellTemplate="createdByTemplate" width="25%" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
                </dxi-column>
                <dxi-column caption="Proveedor" dataField="supplierId" cellTemplate="supplierTemplate" width="40%" [allowEditing]="false" [allowSorting]="false" [allowFiltering]="false">
                </dxi-column>
                <dxi-column caption="Monto" width="15%" dataField="totalAssignment" cellTemplate="amountTemplate" [allowEditing]="false" [allowSorting]="false" [allowFiltering]="false">
                </dxi-column>
                <div *dxTemplate="let data of 'assignmentStatusTemplate'">
                    <span>{{ data.value | assignmentStatus }}</span>
                </div>
                <div *dxTemplate="let data of 'dateTemplate'">
                    <strong> {{ data.value | timestampToDate }}</strong> -
                    <span> hace {{ data.value | timeAgo }}</span>
                </div>
                <div *dxTemplate="let data of 'amountTemplate'">
                    <span>$ {{ data.value | number }}</span>
                </div>
                <div *dxTemplate="let data of 'createdByTemplate'">
                    <strong>{{ data.value | userData | async }}</strong>
                </div>
                <div *dxTemplate="let data of 'supplierTemplate'">
                    <strong>{{ data.value | supplierData | async }}</strong>
                </div>
            </dx-data-grid>
            <div class="footer">
                <button mat-raised-button [disabled]="!Rows?.length" id="btn" color="primary" type="submit" (click)="onSubmit(f.value)">
          <mat-icon>monetization_on</mat-icon> Crear préstamo
        </button>
            </div>
        </div>
    </div>
</app-page-widget>