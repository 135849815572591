<rmd-base-cp><ng-content></ng-content></rmd-base-cp>

<ng-template [ngIf]="info">
  <div class="rmd-message info" >
    <mat-icon>{{ icon || 'info_outline' }}</mat-icon>
    <span [innerText]="text || 'Information'"></span>
  </div>
</ng-template>

<ng-template [ngIf]="warning">
  <div class="rmd-message warning" >
    <mat-icon>{{ icon || 'warning' }}</mat-icon>
    <span [innerText]="text || 'Warning!'"></span>
  </div>
</ng-template>

<ng-template [ngIf]="error">
  <mat-error class="rmd-message error" >
    <mat-icon>{{ icon || 'error_outline' }}</mat-icon>
    <span [innerText]="text || 'Error!'"></span>
  </mat-error>
</ng-template>
