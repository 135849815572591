import { Component, OnInit } from '@angular/core';
import { CostsCenterService } from '@bist-lib/db-api';
import { PurchaseOrdersSummaryVm, PurchaseOrdersTableVm, PurchaseOrderVm } from '@bist-lib/db-api/src/models/ViewModels/cost-center-opt-Vms';

@Component({
  selector: 'cost-center-opt-purchase-orders-table',
  templateUrl: './purchase-orders-table.component.html',
  styleUrls: ['./purchase-orders-table.component.scss']
})
export class PurchaseOrdersTableComponent implements OnInit {
  viewModel: PurchaseOrdersTableVm;

  poTableHeaderDef: string[] = [
    'createdAt',
    'poId',
    'quantity',
    'unitPrice',
    'total',
    'puDiff',
    'totalDiff'
  ];
  poTableSource: PurchaseOrderVm[];

  summaryTableDef: string[] = [
    'emptyCell1',
    'emptyCell2',
    'purchasedQuantityTotal',
    'weightedAvgPrice',
    'quantity',
    'averageDiffPrice',
    'totalOutcome'
  ];
  summaryTableSource: PurchaseOrdersSummaryVm[];

  concept: string;
  constructor(private costCenterService: CostsCenterService) {}

  ngOnInit() {
    this.viewModel = {
      budgetUnitPrice: 0,
      purchaseOrderVms: [],
      summary: undefined
    };
  }
  loadPurchaseOrdersData(
    purchaseOrderVms: PurchaseOrderVm[],
    concept: string,
    budgetUnitPrice: number,
    summary: PurchaseOrdersSummaryVm
  ) {
    this.concept = concept;
    // const summary = new CostCenterSummaryPipe().transform(purchaseOrderVms, budgetUnitPrice)

    this.viewModel = {
      budgetUnitPrice,
      summary,
      purchaseOrderVms
    };

    this.poTableSource = this.viewModel.purchaseOrderVms;
    this.summaryTableSource = [this.viewModel.summary];

    // return this.viewModel;
  }
}
