<app-page-widget [(menuItems)]="widgetMenu" noPadding (refresh)="refreshData()">
    <app-title>ASG-{{ assignment?.uuid | idFromUuid }}</app-title>
    <app-subtitle>{{ assignment?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <app-action-button flat noText icon="done_all" (click)="viewApprovers()">Aprobaciones
    </app-action-button>
    <div class="actions-block">
        <a mat-raised-button class="action-button mr-5" routerLink="./workforce/assignments" color="warn">
            <mat-icon>undo</mat-icon>
            Regresar
        </a>
        <ng-template [ngIf]="approveIsAvailable">
            <button mat-raised-button *ngxPermissionsOnly="['assignments:can_approve']" class="action-button" (click)="approve()" color="primary">
                <mat-icon>done</mat-icon>
                Aprobar
            </button>
        </ng-template>
        <ng-template [ngIf]="sendToApproveIsAvailable">
            <button mat-raised-button *ngxPermissionsOnly="['assignments:can_send_to_approve']" class="action-button" (click)="sendToApprove()" color="primary">
                <mat-icon>call_made</mat-icon>
                Enviar a Aprobar
            </button>
        </ng-template>
    </div>
    <!-- <button mat-raised-button class="action-button" (click)="recoverPackages()" color="primary">
            <mat-icon>call_made</mat-icon>
            recoverPackages
        </button> -->

    <div class="header-block">
        <span class="total-label">Asignación original:
            <strong>${{ assignmentTotal | number: '1.2-2' }}</strong>
        </span>
        <span class="total-label">Deductiva de la asignación:
            <strong>${{ decreaseContract | number: '1.2-2' }}</strong>
        </span>
        <span class="total-label">Total asignado:
            <strong>${{ totalWithDecrease | number: '1.2-2' }}</strong>
        </span>
        <span class="info-label">Estatus:
            <strong>{{ assignment?.status | assignmentStatus }}</strong>
        </span>
        <span class="info-label">Solicitado por:
            <app-user-data *ngIf="assignment" [id]="assignment.createdBy"></app-user-data>
        </span>
    </div>

    <div id="packages-detail" class="content-block-section">
        <h3 class="content-title">Proveedor Asignado</h3>
        <div>
            <div>
                <span class="info-label">Razón Social: </span>
                <strong>{{ supplier?.socialReason }}</strong>
                <button mat-raised-button [disabled]="assignment?.status === 6" *ngxPermissionsOnly="['assignments:can_change_provider']" class="action-button" (click)="changeProvider()" color="primary">
                    <mat-icon>call_made</mat-icon>
                    Cambiar proveedor
                </button>
            </div>
            <div>
                <span class="info-label">RFC: </span>
                <strong>{{ supplier?.rfc }}</strong>
            </div>
            <div>
                <span class="info-label">Dirección Comercial:</span>
                <strong>{{ supplier?.businessAddress }}</strong>

            </div>
            <div>
                <span class="info-label">Dirección Fiscal:</span>
                <strong>{{ supplier?.taxAddress }}</strong>
            </div>
            <div>
                <span class="info-label">Teléfono:</span>
                <strong>{{ supplier?.phone }}</strong>
            </div>
            <div>
                <span class="info-label">email: </span>
                <strong>{{ supplier?.email }}</strong>
            </div>
        </div>
        <br />
    </div>

    <div class="content-block-section d-flex">
        <!-- <h3 class="content-title">Documentos</h3> -->
        <table class="p-10 text-center w-100">
            <tr class="text-center">
                <td style='border: 1px solid'>
                    <strong>Contrato ordinario</strong>
                </td>
                <td style='border: 1px solid'>
                    <strong>Convenio modificatorio</strong>
                </td>
                <td style='border: 1px solid'>
                    <strong>Fianza cumplimiento</strong>
                </td>
                <td style='border: 1px solid'>
                    <strong>Fianza de garantìa y vicios ocultos</strong>
                </td>
                <td style='border: 1px solid'>
                    <strong>Fianza anticipo</strong>
                </td>

            </tr>
            <tr>
                <td style='border: 1px solid' *ngIf="assignment">
                    <ng-container *ngFor="let item of assignment.contract">
                        <a class="ml-5" target="_blank" [href]="item.downloadUrl">
                            <mat-icon matListIcon>cloud_download</mat-icon>
                        </a>
                    </ng-container>
                </td>
                <td style='border: 1px solid' *ngIf="assignment && assignment.documents">
                    <ng-container *ngFor="let item of assignment.documents">
                        <a *ngIf="item.type === 'Convenio modificatorio'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                            <mat-icon matListIcon>cloud_download</mat-icon>
                        </a>
                    </ng-container>
                </td>
                <td style='border: 1px solid' *ngIf="assignment && assignment.documents">
                    <ng-container *ngFor="let item of assignment.documents">
                        <a *ngIf="item.type === 'Fianza cumplimiento'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                            <mat-icon matListIcon>cloud_download</mat-icon>
                        </a>
                    </ng-container>
                </td>
                <td style='border: 1px solid' *ngIf="assignment && assignment.documents">
                    <ng-container *ngFor="let item of assignment.documents">
                        <a *ngIf="item.type === 'Fianza de garantìa y vicios ocultos'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                            <mat-icon matListIcon>cloud_download</mat-icon>
                        </a>
                    </ng-container>
                </td>
                <td style='border: 1px solid' *ngIf="assignment && assignment.documents">
                    <ng-container *ngFor="let item of assignment.documents">
                        <a *ngIf="item.type === 'Fianza anticipo'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                            <mat-icon matListIcon>cloud_download</mat-icon>
                        </a>
                    </ng-container>
                </td>
            </tr>
        </table>
    </div>

    <div id="packages" class="content-block">
        <h3 class="content-title">Paquetes</h3>

        <dx-data-grid #grid id="grid-container" [dataSource]="packages" [showColumnLines]="false" [wordWrapEnabled]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false">
            <dxo-search-panel [visible]="false"></dxo-search-panel>
            <dxo-selection selectAllMode="page" mode="single"></dxo-selection>
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
            <dxi-column caption="ID Paquete" dataField="packageId" [allowEditing]="false" width="10%">
            </dxi-column>
            <dxi-column caption="Código" dataField="code" [allowEditing]="false" width="10%"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" [allowEditing]="false" [groupIndex]="0" width="50%"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" [allowEditing]="false" width="20%">
            </dxi-column>
            <dxi-column caption="Unidad" dataField="unit" [allowEditing]="false" alignment="center" width="7%" cellTemplate="unitTemplate"></dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" [allowEditing]="false" dataType="number" alignment="center" width="10%"></dxi-column>
            <dxi-column caption="ADICIONALES" dataField="additionToQuantity" cellTemplate="additionTemplate" [allowEditing]="false" dataType="number" alignment="center" width="10%"></dxi-column>
            <dxi-column caption="DEDUCTIVA" dataField="decreaseToQuantity" cellTemplate="decreaseTemplate" [allowEditing]="false" dataType="number" alignment="center" width="10%"></dxi-column>
            <dxi-column caption="Precio unitario" dataField="unitPrice" [showWhenGrouped]="false" [allowGrouping]="false" cellTemplate="costTemplate" alignment="left" width="10%" [allowEditing]="false">
            </dxi-column>
            <dxi-column caption="P.U Real" dataField="realPrice" [showWhenGrouped]="false" [allowGrouping]="false" cellTemplate="costTemplate" alignment="left" width="10%" [allowEditing]="false">
            </dxi-column>
            <dxi-column caption="Total" [calculateCellValue]="calculateTotal" cellTemplate="Totals" [allowEditing]="false" alignment="left" width="10%"></dxi-column>

            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item>
                <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>

            <div *dxTemplate="let pu of 'UnitPrice'">
                <span *ngIf="pu?.value">${{ pu.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let total of 'Totals'">
                <span *ngIf="total?.value">${{ total.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let qty of 'Quantities'">
                <span *ngIf="qty?.value">{{ qty.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'notesTemplate'">
                <span *ngIf="data.value">{{ data.value }}</span>
                <span *ngIf="!data.value">-</span>
            </div>
            <div *dxTemplate="let data of 'quantityTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'additionTemplate'">
                <ng-container *ngIf="data.value; else noAddition">
                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                </ng-container>
                <ng-template #noAddition>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'decreaseTemplate'">
                <ng-container *ngIf="data.value; else noDecrease">
                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                </ng-container>
                <ng-template #noDecrease>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'costTemplate'">
                <strong>$ {{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>
        </dx-data-grid>
    </div>
</app-page-widget>
<app-dialog #formDialog>
    <ng-container>
        <app-title>Nuevo proveedor</app-title>

        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-top-2">
                <div class="content-block margin-top-0">
                    <app-select-box [widthSelect]="900" [dataSource]="suppliers" displayExpr="name" (selectItem)="changedSelectedSupplier($event)" placeholder="Buscar Proveedor">
                    </app-select-box>
                </div>
                <div class="content-block margin-top-0">
                    <ng-container>
                        <mat-form-field class="width-100">
                            <textarea matInput maxlength="120" type="textarea" placeholder="Comentario" [formControl]="commentFormControl"></textarea>
                        </mat-form-field>
                    </ng-container>
                </div>
                <button class="float-right" [disabled]="isDisabled()" mat-flat-button (click)="saveChange()" color="primary">
          Guardar
        </button>
            </div>
        </div>
    </ng-container>
</app-dialog>