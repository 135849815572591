<app-widget [round]="false"
            [flat]="flat"
            [noHeader]="noHeader"
            [stretchHeader]="stretchHeader"
            [noPadding]="noPadding"
            [bigText]="bigText"
            [actionsOnBottom]="actionsOnBottom"
            [displayBackButton]="displayBackButton"
            [onBackObservers]="onBackObservers"
            [onRefreshObservers]="onRefreshObservers"
            [onNewObservers]="onNewObservers"
            [onDismissObservers]="onDismissObservers"
            [title]="title || null"
            [subTitle]="subTitle || null"
            [actionButtons]="actionButtons"
            [menuItems]="menuItems"
            [helpLink]="helpLink"
            [loading]="loading"
            [loadPercent]="loadPercent"
            [performingAction]="performingAction"
            [actionPercent]="actionPercent">
  <ng-content></ng-content>
</app-widget>
