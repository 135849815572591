<ng-template #indicator>
    <ng-template [ngIf]="bar">
        <div class="progress-bar" [matTooltip]="tooltip">
            <mat-progress-bar [mode]="mode" [color]="color" [value]="percent"></mat-progress-bar>
        </div>
    </ng-template>

    <ng-template [ngIf]="!bar">
        <ng-template [ngIf]="!indeterminate">
            <div class="progress-spinner" [class.small]="small" [matTooltip]="tooltip">
                <mat-progress-spinner mode="determinate" [color]="color" [diameter]="diameter" [value]="percent"></mat-progress-spinner>
            </div>
        </ng-template>

        <ng-template [ngIf]="indeterminate">
            <div class="spinner" [class.small]="small" [matTooltip]="tooltip">
                <mat-spinner mode="indeterminate" [color]="color" [diameter]="diameter"></mat-spinner>
            </div>
        </ng-template>
    </ng-template>
</ng-template>

<ng-template [ngIf]="centered">
    <div class="rli-wrap">
        <div class="rli-inner">
            <ng-container *ngTemplateOutlet="indicator"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="!centered">
    <ng-container *ngTemplateOutlet="indicator"></ng-container>
</ng-template>