<dx-data-grid #grid
              id="grid-container"
              [wordWrapEnabled]="true"
              [dataSource]="dataSource"
              [columns]="columnDefinition"
              [masterDetail]="{ enabled: masterDetail, template: 'detail' }"
              [hoverStateEnabled]="hoverStateEnabled"
              [showColumnLines]="showColumnLines"
              [showRowLines]="showRowLines"
              [showBorders]="showBorders"
              [rowAlternationEnabled]="rowAlternationEnabled"
              [columnAutoWidth]="columnAutoWidth"
              [allowColumnReordering]="allowColumnReordering"
              [allowColumnResizing]="allowColumnResizing"
              (onSelectionChanged)="newSelection($event)"
              [selectedRowKeys]="selectedRows">
  <dxo-search-panel [visible]="search"
                    placeholder="Buscar..."></dxo-search-panel>
  <dxo-filter-row [visible]="filterRow"></dxo-filter-row>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-selection selectAllMode="page"
                 [mode]="selectionMode"></dxo-selection>
</dx-data-grid>
