<app-page-widget (refresh)="refreshData()">
    <app-title>Derivados</app-title>
    <app-subtitle>Listado</app-subtitle>

    <button mat-raised-button id="btn" color="primary" [disabled]="!selectedGridItems" (click)="showCreatePopup()">
        Crear Requisición
    </button>
    <hr />
    <!-- <br /><br /> -->

    <!-- ============================================================== -->
    <!-- GRID INDIRECTS DERIVATIVES -->
    <!-- ============================================================== -->
    <dx-data-grid #derivGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="indirectDerivativePackages" [masterDetail]="{ enabled:
        false, template: 'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true"
        (onSelectionChanged)="onGridSelection($event)">
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column caption="ID" width="5%" [allowEditing]="false" dataField="id" [allowSorting]="false" [allowFiltering]="true" alignment="center">
        </dxi-column>
        <dxi-column caption="EXT" width="5%" [allowEditing]="false" dataField="ext" [allowSorting]="false" [allowFiltering]="true" alignment="center">
        </dxi-column>
        <dxi-column caption="Fecha requerido" width="15%" [allowEditing]="false" cellTemplate="dateTemplate" dataType="date" dataField="requestDate" [calculateCellValue]="calculateDateCellValue" [allowFiltering]="true" [allowSorting]="true" sortOrder="desc" alignment="center">
        </dxi-column>
        <dxi-column caption="Concepto" width="60%" [allowEditing]="false" dataField="concept" [allowSorting]="false" [allowFiltering]="true">
        </dxi-column>
        <dxi-column caption="Unidad" width="10%" [allowEditing]="false" dataField="unit" [allowSorting]="false" cellTemplate="unitTemplate" alignment="center" [allowFiltering]="false">
        </dxi-column>
        <dxi-column caption="Disponible" width="10%" [allowEditing]="false" dataField="cost" cellTemplate="costTemplate" [calculateCellValue]="calculateAvailable" [allowSorting]="false" alignment="center" [allowFiltering]="false">
        </dxi-column>
        <dxi-column caption="Usado" width="10%" [allowEditing]="false" dataField="used" cellTemplate="usedTemplate" [calculateCellValue]="calculateUsed" [allowSorting]="false" alignment="center" [allowFiltering]="false">
        </dxi-column>
        <dxi-column caption="En Pasivo" width="10%" [allowEditing]="false" dataField="statusPassive" cellTemplate="passiveStatusTemplate" [allowSorting]="false" alignment="center" [allowFiltering]="false">
        </dxi-column>
        <dxi-column *ngIf="canAddPassive" caption="Enviar a Pasivos" dataField="uuid" cellTemplate="passiveTemplate" [allowFiltering]="false" alignment="center" width="10%"></dxi-column>
        <div *dxTemplate="let data of 'costTemplate'">
            <span>${{ data.value | number }}</span>
        </div>
        <div *dxTemplate="let data of 'usedTemplate'">
            <span>${{ data.value | number }}</span>
        </div>
        <div *dxTemplate="let data of 'passiveStatusTemplate'">
            <span *ngIf="data.value === 2" class="icon-gray"><strong>Si</strong></span>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'passiveTemplate'" class="cost">
            <span>
                <i class="material-icons icon-gray"
                    matTooltip="Enviar paquete a pasivos"
                    (click)="sendPassive(data)">
                    input
                </i></span>
        </div>
    </dx-data-grid>

    <dx-popup class="popup" [width]="900" [height]="600" [showTitle]="true" title="Producto" [dragEnabled]="true" [closeOnOutsideClick]="true" [(visible)]="createPopup">
        <div *dxTemplate="let data of 'content'">
            <strong> Disponible: </strong>
            <strong>${{ selectedPackageAvailableAmount | number }}</strong><br /><strong>Escriba
                el producto:</strong>
            <div class="example-container margin-top-2">
                <mat-form-field>
                    <input matInput placeholder="Descripción" [formControl]="derivativeProduct" />
                </mat-form-field>
                <button mat-icon-button id="btnInnerDerivative" color="primary" (click)="addDerivativeProduct()" [disabled]="derivativeProduct.invalid">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
            </div>
            <!-- ============================================================== -->
            <!-- GRID DERIVATIVES ADDED -->
            <!-- ============================================================== -->
            <dx-data-grid #selectGrid id="grid-container-select" [wordWrapEnabled]="true" [dataSource]="selectedPackageDerivatives" [masterDetail]="{
                enabled: false, template: 'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true"
                noDataText="Actualmente no existen
                derivados de la selección." [(selectedRowKeys)]="selectedRows" (onSelectionChanged)="onSelectGridGridChangue($event,
                selectedRows)">
                <dxo-filter-row [visible]="displayFilters"></dxo-filter-row>
                <dxo-search-panel [visible]="displayFilters" placeholder="Buscar..."></dxo-search-panel>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
                <dxo-editing mode="cell" [allowUpdating]="true"> </dxo-editing>
                <dxo-selection selectAllMode="page" mode="multiple" [allowSelectAll]="false" [deferred]="false"></dxo-selection>
                <dxi-column caption="Descripcion" dataField="concept" width="80%" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
                </dxi-column>
                <dxi-column caption="Unidad" dataField="unit" width="10%" [allowEditing]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <div *dxTemplate="let data of 'createdByTemplate'">
                    <span>{{ data.value | number: '1.2-2' }}</span>
                </div>
            </dx-data-grid>
            <br />
            <div class="align-right">
                <button mat-raised-button color="primary" (click)="createRequisition()" [disabled]="disabled" type="submit">
                    <mat-icon>save</mat-icon>
                    Crear requisición con derivados
                </button>
            </div>
        </div>
    </dx-popup>
</app-page-widget>