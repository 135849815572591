<app-page-widget (refresh)="refreshData()">
    <app-title>Administrador de Paquetes</app-title>
    <app-action-button icon="file_upload" tooltip="Importar desde archivo Excel" routeData="/settings/packages-import">Importar</app-action-button>
    <app-action-button icon="edit" color="accent" (click)="createPackage()" tooltip="Importar desde archivo Excel">Crear paquete</app-action-button>
    <!-- <app-action-button icon="delete" color="accent" (click)="deleteAllPackages()" tooltip="Eliminar todos los paquetes">Eliminar paquetes</app-action-button> -->

    <!-- <input #fileSelector type="file" multiple="false" (change)="setDataToImport(fileSelector.files[0])" /> -->
    <app-action-button flat noText icon="filter_list" (click)="displayFilters = !displayFilters">
        Filtros
    </app-action-button>
    <mat-tab-group [dynamicHeight]="true">
        <mat-tab label="Listado de paquetes">
            <!-- <app-subtitle>Listado</app-subtitle> -->
            <dx-data-grid noDataText="Sin datos para mostrar." [dataSource]="packages" [wordWrapEnabled]="true" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true">
                <dxo-filter-row [visible]="displayFilters"></dxo-filter-row>
                <dxo-search-panel [visible]="displayFilters" placeholder="Buscar..."></dxo-search-panel>
                <dxo-paging [pageSize]="10"></dxo-paging>

                <dxi-column caption="PQT ID" width="10%" dataField="packageId"> </dxi-column>
                <dxi-column width="18%" caption="Código" dataField="code"> </dxi-column>
                <dxi-column caption="Concepto" dataField="concept"> </dxi-column>
                <dxi-column caption="Cantidad Original" dataField="quantity" cellTemplate="numberTemplate"> </dxi-column>
                <dxi-column caption="Aditiva" dataField="additionToQuantity" cellTemplate="numberTemplate"> </dxi-column>
                <dxi-column caption="Deductiva" dataField="decreaseToQuantity" cellTemplate="numberTemplate"> </dxi-column>
                <dxi-column width="8%" caption="Historial" cellTemplate="historyLinkTpl" alignment="center">
                </dxi-column>
                <dxi-column width="8%" caption="Mod. Paquete" cellTemplate="editTpl" alignment="center">
                </dxi-column>
                <dxi-column width="8%" caption="Eliminar" cellTemplate="deleteTpl" alignment="center">
                </dxi-column>

                <div *dxTemplate="let data of 'historyLinkTpl'">
                    <app-package-history [hash]="data.data.hashId"></app-package-history>
                </div>
                <div *dxTemplate="let data of 'numberTemplate'">
                    {{ data.value | number:'1.2-2' }}
                </div>
                <div *dxTemplate="let data of 'editTpl'">
                    <span><i class="material-icons icon-gray"
            (click)="editPackage(data.key)">
            edit
        </i></span>
                </div>
                <div *dxTemplate="let data of 'deleteTpl'">
                    <span><i class="material-icons icon-delete"
            (click)="deletePackage(data.key)">
            delete
        </i></span>
                </div>
            </dx-data-grid>

            <dx-popup class="popup" style="z-index: 0;" [width]="550" [height]="550" [showTitle]="true" [title]="title" [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="createPopup">
                <div *dxTemplate="let data of 'content'">
                    <div class="example-container">
                        {{ updatePackage.concept }}
                    </div>
                    <br /><br />
                    <table class="table" style="width: 100%;">
                        <thead>
                            <tr class="text-center">
                                <th><strong> Unidad </strong></th>
                                <th><strong> Cantidad </strong></th>
                                <th><strong> Aditiva </strong></th>
                                <th><strong> Deductiva </strong></th>
                                <th><strong> Cantidad actual </strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center">
                                <td>{{ updatePackage.unit }}</td>
                                <td>{{ updatePackage.quantity | number }}</td>
                                <td>{{ updatePackage.additionToQuantity | number }}</td>
                                <td>{{ updatePackage.decreaseToQuantity | number }}</td>
                                <td>{{ currentQuantity | number }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br /><br />
                    <form #f="ngForm" [formGroup]="myForm" class="loan-form">

                        <div class="example-container">
                            <!-- <mat-form-field style="width: 45%; margin-right: 15px;">
                        <input formControlName="packageId" matInput placeholder="Id" type="text" />
                    </mat-form-field> -->
                            <mat-form-field style="width: 100%;">
                                <input formControlName="quantity" matInput placeholder="Cantidad" type="number" />
                            </mat-form-field>
                        </div>
                        <!-- <div class="example-container">
                    <mat-form-field style="width: 100%;">
                        <input formControlName="unit" matInput placeholder="Unidad" type="text" />
                    </mat-form-field>
                </div>
                <div class="example-container">
                    <mat-form-field style="width: 100%;">
                        <input formControlName="concept" matInput placeholder="Concepto" type="text" />
                    </mat-form-field>
                </div> -->
                        <br />
                        <div class="align-right">
                            <button mat-raised-button color="primary" (click)="updateQuantityPackage()" type="button">
                      <mat-icon>save</mat-icon>
                      Editar paquete
                  </button>
                        </div>
                    </form>
                </div>
            </dx-popup>
        </mat-tab>
        <mat-tab label="Paquetes modificados">
            <dx-data-grid noDataText="Sin datos para mostrar." [dataSource]="packagesModifications" [wordWrapEnabled]="true" [showBorders]="true" [showColumnLines]="true" [showRowLines]="true">
                <dxo-filter-row [visible]="displayFilters"></dxo-filter-row>
                <dxo-search-panel [visible]="displayFilters" placeholder="Buscar..."></dxo-search-panel>
                <dxo-paging [pageSize]="10"></dxo-paging>

                <dxi-column caption="Fecha" dataField="createdAt" dataType="date" cellTemplate="dateTemplate" dataType="date" sortOrder="desc" alignment="left" [allowSorting]="true" [allowEditing]="false" [allowSearch]="true" [calculateCellValue]="calculateDateCellValue"></dxi-column>
                <dxi-column caption="Creado por" dataField="createdBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
                <dxi-column caption="PQT ID" width="10%" dataField="packageId"> </dxi-column>
                <dxi-column width="10%" caption="Código" dataField="code"> </dxi-column>
                <dxi-column caption="Concepto" dataField="concept"> </dxi-column>
                <dxi-column caption="Cantidad Original" dataField="quantity" cellTemplate="numberTemplate"> </dxi-column>
                <dxi-column caption="Aditiva" dataField="additionToQuantity" cellTemplate="numberTemplate"> </dxi-column>
                <dxi-column caption="Deductiva" dataField="decreaseToQuantity" cellTemplate="numberTemplate"> </dxi-column>
                <dxi-column caption="Nueva cantidad" dataField="newQuantity" cellTemplate="numberTemplate"> </dxi-column>
                <dxi-column caption="Aprobadores Faltantes" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
                <dxi-column width="8%" caption="Aprobar" cellTemplate="ccButtonTemplate" alignment="center">
                </dxi-column>

                <div *dxTemplate="let data of 'ccButtonTemplate'">
                    <span>
                      <i class="material-icons icon-gray" (click)="approve(data.data)">
                          check_circle_outline
                      </i>
                  </span>
                </div>

                <div *dxTemplate="let data of 'createdByTemplate'">
                    <app-user-data [id]="data.value"></app-user-data>
                </div>

                <div *dxTemplate="let data of 'numberTemplate'">
                    {{ data.value | number:'1.2-2' }}
                </div>
                <!-- <div *dxTemplate="let data of 'createdByTemplate'">
                    <span>{{ data.value }}</span>
                </div> -->
                <div *dxTemplate="let data of 'dateTemplate'">
                    <strong> {{ data.value | timestampToDate }}</strong> -
                    <span> hace {{ data.value | timeAgo }}</span>
                </div>

                <div *dxTemplate="let data of 'approverRemaining'">
                    <ng-container *ngIf="data.value.length> 0">
                        <ul class="approversRemaining">
                            <li *ngFor="let item of data.value">
                                <app-user-data [id]="item[0]"></app-user-data>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                        <span>Todos los usuarios han aprobado</span>
                    </ng-container>
                    <ng-container *ngIf="data.value === ''">
                        <span>No se ha enviado a aprobación</span>
                    </ng-container>
                </div>
            </dx-data-grid>
        </mat-tab>
    </mat-tab-group>

</app-page-widget>

<app-dialog #formDialog width="500px" height="600px" title="Crear paquete">
    <app-form class="margin-top-2" [fields]="dataFormFields" saveButtonText="Continuar" [noCancelButton]="false" (save)="savePackage($event.value)" (cancel)="formDialog.close()">
    </app-form>
</app-dialog>