<app-section-nav noOutlet>
    <app-title>Contratos tasa 0%</app-title>

    <app-nav-item routeData="./assignments">Asignaciones</app-nav-item>
    <app-nav-item (click)="resetTableEstimate()" routeData="./estimations/list">Estimaciones</app-nav-item>
    <!-- <app-nav-item routeData="./estimations/create">Crear Estimaciones</app-nav-item>
  <app-nav-item routeData="./estimations/list">Lista Estimaciones</app-nav-item> -->
    <app-nav-item routeData="./supplier-balance">Estado de Cuenta</app-nav-item>
    <app-nav-item routeData="./workforce-packages-report">Reporte Listado de paquetes</app-nav-item>
    <app-nav-item routeData="./assignments-list-report">Reporte Listado de asignaciones</app-nav-item>
</app-section-nav>

<router-outlet></router-outlet>