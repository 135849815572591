<app-widget [flat]="true" stretchHeader [performingAction]="loading">

    <form *ngIf="form" novalidate [attr.disabled]="loading" [formGroup]="form">
        <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
            <!-- TODO: [disabled]="loading" -->
            <app-form-input placeholder="E-Mail" [control]="identityFc"></app-form-input>
            <app-form-password [control]="credentialFc"></app-form-password>

            <div class="extra-options" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-checkbox class="remember-me">Recordar</mat-checkbox>

                <a class="forgot-password" routerLink="/auth/password-recovery">Recuperar contraseña</a>
            </div>

            <rmd-message *ngIf="error" error [text]="errorText || 'Sus credenciales no son válidas'"></rmd-message>

            <app-button [block]="true" [flat]="false" [disabled]="loading || !form.valid" text="Iniciar sesión" (click)="onLogin()">
            </app-button>
        </div>
    </form>

</app-widget>