<app-page-widget>
    <app-title>Nuevo Anticipo</app-title>
    <app-subtitle> Para: Materiales / Maquinas y herramientas</app-subtitle>

    <div class="content-section">
        <div class="content-sub-section">
            <h3 class="content-title">Seleccionar sección y proveedor:</h3>
            <div class="content-section">
                <app-select-box class="selectProv" [widthSelect]="300" [dataSource]="categoryes" displayExpr="TypeText" (selectItem)="onSelectBoxSelectionCategory($event)" placeholder="selecionar tipo">
                </app-select-box>
                <div class="selectProv">
                    <app-select-box class="" [widthSelect]="550" [dataSource]="spliers" displayExpr="socialReason" (selectItem)="onSelectBoxSelectionSupplier($event)" placeholder="selecionar proveedor">
                    </app-select-box>
                </div>
            </div>

            <div class="footer margin-left-20">
                <small>
          Generar anticipo para proveedor, solo se permite un anticipo por
          proveedor.
        </small>
            </div>

            <h3 class="content-title margin-top-40">Datos para generar anticipo:</h3>

            <div class="content-section margin-left-20">
                <form #f="ngForm" [formGroup]="myForm" class="loan-form">
                    <mat-form-field class="loan-full-width">
                        <input formControlName="monto" matInput type="number" placeholder="Monto" required />
                    </mat-form-field>
                    <br />
                </form>
            </div>

            <div class="footer">
                <button mat-raised-button [disabled]="myForm.invalid" (click)="onSubmit(f.value)" id="btn" color="primary" type="submit">
          <mat-icon>monetization_on</mat-icon> Crear anticipo
        </button>
            </div>
        </div>
    </div>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess"></app-loading-indicator>