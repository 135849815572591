import { Directive, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  /* tslint:disable-next-line */
  selector: 'input[type=file]',
  /* tslint:disable-next-line */
  host: {
    '(change)': 'onChange($event.target.files)',
    '(blur)': 'onTouched()',
  },
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FileInputValueAccesorDirective  , multi: true }
  ]
})
export class FileInputValueAccesorDirective implements ControlValueAccessor {
  /* https://github.com/angular/angular/issues/7341 */

  public value: any;
  public onChange: Function = (_: any) => {};
  public onTouched: Function = () => {};

  public writeValue(value: any): void {
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
