<app-page-widget [menuItems]="widgetMenu" [actionButtons]="widgetActions" (refresh)="refreshData()">
    <app-title>Seleccione una Asignación</app-title>
    <app-subtitle></app-subtitle>

    <div class="grid-container-div" *ngIf="assignments.length > 0">
        <dx-data-grid #assignmentsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="assignments" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
        no
        existen registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">
            <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
            <dxo-paging [pageSize]="10" [pageIndex]="_supplierAssignmentsService.pageIndexSubcontracts"></dxo-paging>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" fileName="lista-asignaciones-subcontratos"></dxo-export>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="ID" dataField="assignmentPmId" width="5%" sortOrder="desc" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false" alignment="center" [groupIndex]="0" groupCellTemplate="assignmentGroupTemplate" cellTemplate="assignmentGroupTemplate">
            </dxi-column>
            <dxi-column caption="Contrato" dataField="assignmentContract" sortOrder="desc" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false" alignment="center" groupCellTemplate="assignmentGroupContractTemplate" cellTemplate="assignmentGroupContractTemplate">
            </dxi-column>
            <dxi-column caption="Fecha" width="10%" dataField="assignmentCreatedAt" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" dataType="date" cellTemplate="dateTemplate" [allowSorting]="true" sortOrder="desc" alignment="center">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" width="30%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Proveedor" dataField="supplierId" cellTemplate="supplierTemplate" width="30%" dateType="string" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Monto" width="15%" dataType="number" dataField="totalAssignment" cellTemplate="amountTemplate" [calculateCellValue]="calculateMonto" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Detalle" dataField="assignmentUuid" cellTemplate="ccButtonTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span><i class="material-icons icon-gray"
                        (click)="detailAssignment(data.key)">
                        remove_red_eye
                    </i></span>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="totalAssignment" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>

            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'supplierTemplate'">
                <strong>{{ data.value | supplierData | async }}</strong>
            </div>
            <div *dxTemplate="let data of 'userDataTemplate'">
                <app-user-data [id]="data.value"></app-user-data>
            </div>
            <div *dxTemplate="let data of 'amountTemplate'">
                <span>{{ data.value | number:'1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'assignmentGroupTemplate'">ASG-{{ data.value | idFromUuid }}
            </div>
            <div *dxTemplate="let data of 'assignmentGroupContractTemplate'">
                <span *ngIf="data.value === ''"></span>
                <span *ngIf="data.value !== ''">CONT-{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalAssignments }}</h2>
                    <span class="name">Total Asignaciones</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
    <div class="grid-container-div text-center" *ngIf="assignments.length === 0">
        <h2>No posee asignaciones</h2>
    </div>

</app-page-widget>