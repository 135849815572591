import { Component, OnInit } from '@angular/core';
import { ProjectState } from '@bist-lib/app-state';
import { GetReportInGroupRequest } from '@bist-lib/reports/requests';
import { GetReportInGroupResponse } from '@bist-lib/reports/responses';
import { environment } from '@bist-web/environments/environment';
import { PowerBiService } from '@bist-web/shared/services/power-bi/powerbi.service';

@Component({
  selector: 'bist2-quoted-packages-report',
  templateUrl: './quoted-packages-report.component.html',
  styleUrls: ['./quoted-packages-report.component.scss']
})
export class QuotedPackagesReportComponent implements OnInit {
  embedToken: string;
  embedUrl: string;
  isDataLoaded: boolean;
  loadingVisible: boolean;
  error: boolean = false;
  messageInfoProcess: string;
  reportId: string = environment.powerBiConfig.quotedPackagesReportId;
  // datasetId: string = environment.powerBiConfig.quotesDatasetId;
  // url: string = `https://app.powerbi.com/reportEmbed?reportId=${environment.powerBiConfig.quotedPackagesReportId}&autoAuth=true&ctid=019573ca-d8fb-4057-a30f-a33ed56446e0&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9`;

  getReportInGroupRequest: GetReportInGroupRequest = {
    format: 'JSON',
    projectId: this.projectState.getSelectedProject().uuid,
    report: 'QUOTES_QUOTED_PACKAGES',
    reportId: this.reportId,
    groupId: environment.powerBiConfig.groupId,
    datasetId: environment.powerBiConfig.quotesDatasetId,
    tableName: 'Paquetes cotizados'
  };

  constructor(protected projectState: ProjectState, private _powerBiService: PowerBiService) {}

  ngOnInit() {
    // this.url = `https://app.powerbi.com/reportEmbed?reportId=${this.reportId}&autoAuth=true&ctid=019573ca-d8fb-4057-a30f-a33ed56446e0&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9`;
    this.refreshData();
  }

  /**
   * Refresh Report Data
   * @returns void
   */
  refreshData(): void {
    this.loadingVisible = true;
    this.messageInfoProcess = 'Cargando...';
    this._powerBiService.getReportInGroup(this.getReportInGroupRequest).subscribe(
      (response: GetReportInGroupResponse) => {
        this.embedUrl = response.embedUrl;
        this.embedToken = response.embedToken;
        this.isDataLoaded = true;
        this.loadingVisible = false;
        this.messageInfoProcess = '';
      },
      (error) => {
        this.loadingVisible = false;
        this.messageInfoProcess = '';
        this.error = true;
      }
    );
  }
}
