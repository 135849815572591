<app-page-widget (dismiss)="gotoList()">
  <app-title>Importar Proveedores desde Excel</app-title>
  <app-subtitle>Por favor, seleccione un archivo</app-subtitle>

  <app-excel-import
    headerSlice="0"
    [map]="map"
    [service]="suppliersService"
    [uploadType]="'SuppliersService'"
  ></app-excel-import>
</app-page-widget>
