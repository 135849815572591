<app-dialog #appDialog
            (dismiss)="onCloseTemplateDialog()">
  <app-title>{{ title }}</app-title>
  <app-subtitle>{{ subTitle }}</app-subtitle>
  <div class="container-email">
    <header>
      <h4> AYUDA! Correo electrónico
        <i class="material-icons c5e-help-icon"> help_outline </i>
      </h4>

      <mat-chip-list>
        <mat-chip matTooltip="Se remplaza por el asunto del mensaje"
                  color="primary"
                  selected>%TITTLE_EVENT%</mat-chip>
        <mat-chip matTooltip="Nombre de usuario a notificar"
                  color="primary"
                  selected>%VAR_NAME%</mat-chip>
        <mat-chip matTooltip="Nombre de usuario a notificar"
                  color="primary"
                  selected>%FROM_USER%</mat-chip>
        <mat-chip matTooltip="Fecha de solicitud"
                  color="primary"
                  selected>%FROM_DATE%</mat-chip>
        <mat-chip matTooltip="Nombre de la compañia"
                  color="primary"
                  selected>%COMPANY_ID%</mat-chip>
        <mat-chip matTooltip="Compañia ID, falta, logo, direccion, etc para poder remplazar"
                  color="primary"
                  selected>%COMPANY%</mat-chip>
      </mat-chip-list>

    </header>

    <i class="material-icons">email</i>
    <span class="flex">
      <label> Mensaje:
      </label> Vista previa de plantilla </span>

    <form (ngSubmit)="saveTemplate()"
          [formGroup]="formHtml">

      <div #dataContainer
           class="bgpreview"
           [(innerHTML)]="bodyMessage">

      </div>
      <div class="form-group">
        <br>
        <mat-form-field>
          <input matInput
                 #subject
                 name="subject"
                 maxlength="256"
                 formControlName="messageSubject"
                 [(ngModel)]="subjectMessage"
                 placeholder="Asunto">
          <mat-hint align="end">{{subject.value.length}} / 256</mat-hint>
          <mat-icon *ngIf="!isNew"
                    matSuffix>mode_edit</mat-icon>
          <mat-icon *ngIf="isNew"
                    matSuffix>subject</mat-icon>
        </mat-form-field>
        <hr>
        <div *ngIf="isNew">

          <mat-select placeholder="Seleccione Tipo de plantilla"
                      [(value)]="emailSelectedType">
            <mat-option *ngFor="let type of emailtypeList"
                        [value]="type.value"
                        name="types"
                        formControlName="typesSelected"
                        ngDefaultControl>
              {{type.tittle}}
            </mat-option>
          </mat-select>
          <hr>
        </div>

        <textarea calss="messageBox"
                  rows="15"
                  cols="170"
                  name="message"
                  formControlName="bodyMessHtml"
                  [(ngModel)]="bodyMessage">
                      </textarea>
        <br>
        <button class="btn"
                mat-raised-button
                color="primary"
                type="button"
                (click)="canEditTogle()">
          <i class="material-icons">edit</i>
          Editar
        </button>
        <button class="btn"
                type="submit"
                mat-raised-button
                [disabled]="canEdit"
                color="primary">
          <i class="material-icons">save</i>
          Guardar
        </button>

        <button class="btn"
                mat-raised-button
                color="primary"
                [disabled]="canEdit"
                type="reset">
          <i class="material-icons">clear</i>
          Limpiar
        </button>

      </div>
    </form>
  </div>
</app-dialog>
