<ng-template #publicTpl>
    <router-outlet></router-outlet>
</ng-template>

<mat-sidenav-container *ngIf="wrap; else publicTpl" class="dashboard-layout">
    <mat-sidenav #acSideNav class="ac-sidenav" position="end" mode="over" [opened]="false">
        <!-- <app-dashboard-action-center></app-dashboard-action-center> -->
        <!-- Notifications -->

        <h4 class="section-title">Notificaciones</h4>

        <div *ngFor="let notification of notifications; trackBy:
            trackNotification" class="notification-ctr" (click)="handleNotificationClick(notification)">
            <app-widget stretchHeader [title]="notification.title" (dismiss)="removeNotification(notification)">
                <div fxLayout="row" fxLayout.xs="column">
                    <mat-icon fxFlex="30px" color="warn">{{ notification.icon }}</mat-icon>
                    <div fxFlex [innerText]="notification.content">
                    </div>
                </div>
            </app-widget>
        </div>
    </mat-sidenav>

    <mat-sidenav #sideNav msIconSidenav class="sidenav" position="start" mode="side" opened="1">
        <mat-toolbar class="sidenav-toolbar mat-elevation-z2">
            <span class="logo">
                <img src="/assets/img/logo/bist.png">
            </span>
            <span class="app-title">BIST</span>
        </mat-toolbar>

        <div class="menu-container">
            <perfect-scrollbar>
                <app-nav-menu name="main"></app-nav-menu>
            </perfect-scrollbar>
        </div>
    </mat-sidenav>

    <div class="content-container" fxLayout="column">
        <mat-toolbar class="main-toolbar mat-elevation-z2">
            <app-projects-switcher></app-projects-switcher>

            <span fxFlex class="flex-divider">
            </span>

            <button (click)="displayNotifications()" mat-icon-button #notificationsButton [fxHide]="true" [fxHide.gt-sm]="false">
                <mat-icon [matBadge]="notifications.length"
                    [matBadgeHidden]="hidden"
                    matBadgeSize="small"
                    matBadgeColor="warn">notifications_none</mat-icon>
            </button>

            <!-- <app-button #notificationsButton [fxHide]="true" [fxHide.gt-sm]="false" flat noText
        [tooltip]="notifications.length ? ('Tiene notificaciones (' + notifications.length + '). ' + unreadNotifications.length + ' sin leer.') : 'No hay notificaciones'"
        [icon]="unreadNotifications.length ? 'email' : 'mail_outline'"
        (click)="displayNotifications()">
      </app-button> -->
            <app-button [fxHide]="true" [fxHide.gt-sm]="false" [flat]="true" noText tooltip="Alternar pantalla completa" [icon]="fullscreen ?
                'fullscreen_exit' : 'fullscreen'" (window:resize)="fullscreen=
                isInFullScreen" (click)="toggleFullscreen()">
            </app-button>

            <app-button [fxHide]="true" [fxHide.gt-sm]="false" [flat]="true" noText [tooltip]="sideNav.opened ? 'Ocultar menu' : 'Mostrar menu'" [icon]="sideNav.opened ? 'dashboard' : 'menu'" (click)="sideNav.toggle()">
            </app-button>

            <app-dashboard-account-menu></app-dashboard-account-menu>
        </mat-toolbar>

        <div #scrollContainer class="main-container">
            <perfect-scrollbar>
                <router-outlet></router-outlet>
            </perfect-scrollbar>
        </div>
    </div>
</mat-sidenav-container>