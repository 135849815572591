<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Partidas</app-title>
    <app-subtitle>Seleccione para administrar y seleccionar miembros</app-subtitle>
    <app-action-button icon="assessment" (click)="openManagementDialog()">Crear</app-action-button>
    <app-datatable #projectsDatatable [columns]="dtColumns" [rows]="projects" (changedRowsSelection)="openManagementDialog($event)">
    </app-datatable>
</app-page-widget>

<app-dialog #managementDialog noPadding width="60%" (dismiss)="onCloseManagementDialog()">
    <app-title>{{ selectedProject ? selectedProject.name : 'Datos de la Partida' }}
    </app-title>
    <app-subtitle>{{ selectedProject ? 'Datos de la Partida' : '' }}</app-subtitle>
    <app-menu-item icon="delete" (click)="deleteProject(selectedProject)">Eliminar Projecto
    </app-menu-item>

    <mat-tab-group [dynamicHeight]="true">
        <mat-tab label="Datos">
            <app-form *ngIf="formDataLoaded" padding [fields]="formFields" [model]="formData" [noCancelButton]="false" (save)="saveProject($event.value)" (cancel)="managementDialog.close()">
            </app-form>
        </mat-tab>
        <mat-tab *ngIf="selectedProject" label="Miembros">
            <app-datatable canSelectMultiple selectWithCheckboxes [filterBy]="['name',
        'description']" [columns]="usersDtColumns" [rows]="users" [selected]="selectedProjectMembers" (changedRowsSelection)="setProjectMembers(selectedProject, $event)">
            </app-datatable>
        </mat-tab>
    </mat-tab-group>
</app-dialog>