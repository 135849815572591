import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Remission, RemissionPackage, RemissionsService } from '@bist-lib/db-api';
import { PageWidgetComponent } from '@bist-web/shared';

@Component({
  selector: 'app-remission-details',
  templateUrl: './remission-details.component.html',
  styleUrls: ['./remission-details.component.scss']
})
export class RemissionDetailsComponent implements OnInit {
  @ViewChild(PageWidgetComponent, { static: true }) public pageWidget: PageWidgetComponent;

  private remissionId: string;
  public remission: Remission;
  public remissionPackages: RemissionPackage[];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _remissionsService: RemissionsService
  ) {}

  ngOnInit() {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.remissionId = params.remissionId;
      this.refreshData();
    });
  }

  refreshData(): void {
    this.pageWidget.toggleLoading(true);
    this._remissionsService.getObservable(this.remissionId).subscribe(
      (remission: Remission) => {
        // console.log('remission', remission);
        remission.quantitiesToSend.map((singleQuantity: { concept: string; quantity: number }) => {
          // console.log('quantities to send:', singleQuantity);
          const matchedPackageIndex: number = remission.packages.findIndex(
            (singlePackage: RemissionPackage) => singlePackage.concept === singleQuantity.concept
          );
          // console.log('index of matched package', matchedPackageIndex);
          if (matchedPackageIndex !== -1) {
            // console.log('package modified: ', remission.packages[matchedPackageIndex]);
            remission.packages[matchedPackageIndex].quantitySent = singleQuantity.quantity;
          }
        });
        this.remission = remission;
        this.remissionPackages = remission.packages;
        this.pageWidget.toggleLoading(false);
      },
      (err: any) => console.log(err)
    );
  }
}
