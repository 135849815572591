<app-page-widget (refresh)="refreshData()">
    <app-title>Estado de Cuenta</app-title>
    <app-subtitle>Seleccione Proveedor y Asignación para cargar el Estado de Cuenta</app-subtitle>

    <div class="header-block">
        <div class="supplier-select-box">
            <dx-select-box placeholder="Proveedor" [searchEnabled]="true" [showClearButton]="true" [dataSource]="suppliers" displayExpr="name" (onValueChanged)="onChangeSupplier($event)"></dx-select-box>
        </div>
        <div class="assignment-select-box">
            <dx-select-box placeholder="Asignación" [showClearButton]="true" [disabled]="!isAssignmentBoxAvailable" [items]="asignmentNumberList" (onValueChanged)="onChangeAssignmentNumber($event)"></dx-select-box>
        </div>
        <div class="contract-select-box">
            <dx-select-box placeholder="Contrato" [showClearButton]="true" [disabled]="!isAssignmentBoxAvailable" [items]="asignmentContractList" (onValueChanged)="onChangeAssignmentContract($event)"></dx-select-box>
        </div>
        <div class="load-balance-button">
            <button mat-raised-button color="primary" [disabled]="!isLoadBalanceButtonAvailable" (click)="loadSupplierBalance()">
        Estado de Cuenta
      </button>
        </div>
    </div>

    <div class="content-block">
        <div class="supplier-table-container">
            <table class="supplier-table" *ngIf="assignmentNumber > 0 || assignmentContract !== ''">
                <tbody>
                    <tr>
                        <th style="width: 201px;">Proveedor</th>
                        <th style="width: 202px;">No. de Asignación</th>
                        <th style="width: 202px;">Valor Contratado</th>
                        <th style="width: 202px;">Valor Actual</th>
                    </tr>
                    <tr>
                        <td style="width: 201px;">{{ socialReason }}</td>
                        <td style="width: 202px;" *ngIf="assignmentContract !== ''">
                            <ng-container *ngFor="let item of assignmentsNumberByContract; let idx = index">
                                <strong>{{ item }}</strong>
                                <strong *ngIf="idx + 1 < assignmentsNumberByContract.length">,</strong>
                            </ng-container>
                        </td>
                        <td style="width: 202px;" *ngIf="assignmentNumber > 0">
                            <strong>{{ assignmentNumber }}</strong>
                        </td>
                        <td style="width: 202px;">
                            <strong>${{ initialAmounts.totalAssignment | number: '1.2-2' }}</strong>
                        </td>
                        <td style="width: 202px;">
                            <ng-container *ngIf="initialAmounts.actualAssignmentTotal; else noActualAssignmentTotal">
                                <strong>${{ initialAmounts.actualAssignmentTotal | number: '1.2-2' }}</strong>
                            </ng-container>
                            <ng-template #noActualAssignmentTotal>
                                --
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="balance-table-container">
        <table class="balance-table" *ngIf="displayTable">
            <caption>
                Contratos tasa 0%
            </caption>
            <tbody>
                <tr>
                    <th style="width: 201px;" colspan="5">Estimaciones Aprobadas</th>
                    <th style="width: 201px;" colspan="3">Prestamos-Anticipos</th>
                    <th style="width: 201px;" colspan="2">Fondo de Garantia</th>
                    <th style="width: 201px;" colspan="3">Amortizaciones</th>
                    <th style="width: 201px;" colspan="4">Facturas Aprobadas</th>
                </tr>
                <tr>
                    <td style="width: 201px;" colspan="5">Importe</td>
                    <td style="width: 201px;" colspan="3">
                        <strong>${{ balanceSummary.loansAndAdvancedPayments | number: '1.2-2' }}</strong>
                    </td>
                    <td style="width: 201px;" colspan="2">
                        <strong>${{ initialAmounts.guarantyFund | number: '1.2-2' }}</strong>
                    </td>
                    <td style="width: 201px;" colspan="3">
                        <strong>${{ balanceSummary.amortization | number: '1.2-2' }}</strong>
                    </td>
                    <td style="width: 201px;" colspan="3">Importe sin IVA</td>
                </tr>
                <tr>
                    <td style="width: 202px;">Sem</td>
                    <td style="width: 202px;">Est</td>
                    <td style="width: 202px;">Actual</td>
                    <td style="width: 202px;">Acumulado</td>
                    <td style="width: 202px;">%</td>
                    <td style="width: 202px;">Actual</td>
                    <td style="width: 202px;">Acumulado</td>
                    <td style="width: 202px;">%</td>
                    <td style="width: 202px;">Actual</td>
                    <td style="width: 202px;">Saldo</td>
                    <td style="width: 202px;">Amortización</td>
                    <td style="width: 202px;">Acumulado</td>
                    <td style="width: 202px;">Saldo</td>
                    <td style="width: 202px;">Facturado</td>
                    <td style="width: 202px;">Acumulado</td>
                    <td style="width: 202px;">%</td>
                </tr>
                <ng-container *ngFor="let row of balance; let i = index">
                    <tr>
                        <td>{{ row.week }}</td>
                        <td>{{ row.estNumber }}</td>
                        <td>${{ row.estAmount | number: '1.2-2' }}</td>
                        <td>${{ row.estAccumAmount | number: '1.2-2' }}</td>
                        <td>{{ row.estPercentage | number: '1.2-2' }}%</td>
                        <td>${{ row.loansAndAdvancedPayAmount | number: '1.2-2' }}</td>
                        <td>${{ row.loansAndAdvancedPayAccum | number: '1.2-2' }}</td>
                        <td>{{ row.loansAndAdvancedPayPercentage | number: '1.2-2' }}%</td>
                        <td>${{ row.estGuarantyFund | number: '1.2-2' }}</td>
                        <td>${{ row.estAccumGuarantyFund | number: '1.2-2' }}</td>
                        <td>${{ row.amortizationAmount | number: '1.2-2' }}</td>
                        <td>${{ row.amortizationAccum | number: '1.2-2' }}</td>
                        <td>${{ row.amountToBeAmortized | number: '1.2-2' }}</td>
                        <!-- <td></td> -->
                        <td></td>
                        <td>
                            <strong>${{ row.invoicedAccum | number: '1.2-2' }}</strong>
                        </td>
                        <td>{{ row.invoicedPercentageAccum | number: '1.2-2' }}%</td>
                    </tr>
                    <ng-container *ngFor="let paymentOrder of row.paymentOrdersAmount">
                        <tr *ngIf="paymentOrder">
                            <td>{{ row.week }}</td>
                            <td>{{ row.estNumber }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <!-- <td></td> -->
                            <td></td>
                            <td>${{ paymentOrder.totalOrderAmount | number: '1.2-2' }}</td>
                            <td>${{ paymentOrder.totalOrderAccum | number: '1.2-2' }}</td>
                            <td></td>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr class="totalsRow">
                    <td style="width: 202px;" colspan="3">Avance</td>
                    <td style="width: 202px;">
                        <strong>${{ balanceSummary.amount | number: '1.2-2' }}</strong>
                    </td>
                    <td style="width: 202px;">
                        <strong>{{ balanceSummary.percentage | number: '1.2-2' }}%</strong>
                    </td>
                    <td style="width: 202px;">Por Amortizar</td>
                    <td style="width: 202px;">
                        <strong>${{ balanceSummary.amountToBeAmortized | number: '1.2-2' }}</strong>
                    </td>
                    <td style="width: 202px;">
                        <strong>{{ balanceSummary.loansAndAdvancedPayPercentage | number: '1.2-2' }}%</strong>
                    </td>
                    <td style="width: 202px;">Retenido</td>
                    <td style="width: 202px;">
                        <strong>${{ balanceSummary.guarantyFund | number: '1.2-2' }}</strong>
                    </td>
                    <td style="width: 202px;" colspan="2">Saldo Amortizado</td>
                    <td style="width: 202px;" colspan="1">
                        <strong>${{ balanceSummary.amortization | number: '1.2-2' }}</strong>
                    </td>
                    <td style="width: 202px;" colspan="1">Total Pagos Real</td>
                    <td style="width: 202px;">
                        <strong>${{ balanceSummary.realTotalPayments | number: '1.2-2' }}</strong>
                    </td>
                    <td>
                        <strong>%{{ balanceSummary.realTotalPercentage | number: '1.2-2' }}</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="balance-table-container" *ngIf="packageHistory.length > 0">
        <table class="balance-table">
            <caption>
                Historial de paquetes
            </caption>
            <tbody>
                <tr>
                    <th style="width: 100px;" colspan="1">ID de Paquete</th>
                    <th style="width: 201px;" colspan="5">Concepto</th>
                    <th style="width: 201px;" colspan="3">Ultima actualización</th>
                    <th style="width: 201px;" colspan="1">Línea base</th>
                </tr>
                <ng-container *ngFor="let package of packageHistory; let i = index">
                    <tr class="package-history-bg">
                        <td style="width: 100px;" colspan="1">{{ package.packageId }}</td>
                        <td style="width: 201px;" colspan="5">{{ package.concept }}</td>
                        <td style="width: 201px;" colspan="3">{{ package.lastUpdated | date: 'medium' }}</td>
                        <td style="width: 100px;" colspan="1">
                            <ng-container *ngIf="package.isBaseline === 1; else noBaseline">
                                Si
                            </ng-container>
                            <ng-template #noBaseline>
                                No
                            </ng-template>
                        </td>
                    </tr>

                    <tr>
                        <td style="width: 202px;"></td>
                        <td style="width: 202px;"></td>
                        <td style="width: 202px;"><b> Fecha de actualización</b></td>
                        <td style="width: 202px;"><b>Cantidad original</b></td>
                        <td style="width: 202px;"><b>Paquete entrante</b></td>
                        <td style="width: 202px;"><b>Paquete entrante es linea base</b></td>
                        <td style="width: 202px;"><b>Adicional</b></td>
                        <td style="width: 202px;"><b>Deductiva</b></td>
                        <td style="width: 202px;"></td>
                        <td style="width: 202px;"></td>
                    </tr>
                    <ng-container *ngFor="let history of package.history">
                        <tr>
                            <td></td>
                            <td></td>
                            <td>{{ history.updatedAt | date }}</td>
                            <td>
                                <ng-container *ngIf="history.originalPackage; else noOriginalPackage">
                                    $ {{ history.originalPackage.quantity | number: '1.2-2' }}
                                </ng-container>
                                <ng-template #noOriginalPackage>
                                    --
                                </ng-template>
                            </td>
                            <td>
                                <ng-container *ngIf="history.incomingPackage; else noIncoming">
                                    $ {{ history.incomingPackage.quantity | number: '1.2-2' }}
                                </ng-container>
                                <ng-template #noIncoming>
                                    --
                                </ng-template>
                            </td>
                            <td>
                                <ng-container *ngIf="history.isBaseLine === 1; else noLineaBase">
                                    Si
                                </ng-container>
                                <ng-template #noLineaBase>
                                    No
                                </ng-template>
                            </td>
                            <td>
                                <ng-container *ngIf="history.increaseToQuantity; else noIncremento">
                                    $ {{ history.increaseToQuantity | number: '1.2-2' }}
                                </ng-container>
                                <ng-template #noIncremento>
                                    --
                                </ng-template>
                            </td>
                            <td>
                                <ng-container *ngIf="history.decreaseToQuantity; else noDecremento">
                                    $ {{ history.decreaseToQuantity | number: '1.2-2' }}
                                </ng-container>
                                <ng-template #noDecremento>
                                    --
                                </ng-template>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</app-page-widget>