<rmd-base-cp><ng-content></ng-content></rmd-base-cp>

<ng-template #flatTpl>
  <button mat-button
          tabindex="tabIndex"
          class="md-icon-button app-button flat"
          [class.small]="small"
          [class.block]="block"
          [class.notext]="noText"
          [disabled]="disabled"
          [matTooltip]="tooltip || text"
          (click)="onClick($event)">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <span *ngIf="!noText" class="app-button-text" [innerText]="text"></span>
  </button>
</ng-template>

<button *ngIf="!flat; else flatTpl"
        mat-raised-button
        tabindex="tabIndex"
        class="md-icon-button app-button"
        [color]="color"
        [class.small]="small"
        [class.block]="block"
        [class.notext]="noText"
        [disabled]="disabled"
        [matTooltip]="tooltip || text"
        (click)="onClick($event)">
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  <span *ngIf="!noText" class="app-button-text" [innerText]="text"></span>
</button>
