<app-dialog #appDialog noPadding [width]="'90%'" (dismiss)="onClosePreviewDialog()">
  <app-title>Órdenes de Pago</app-title>

  <div class="content-cc height-600 padding">
    <div class="content-block">
      <h3 class="content-title">Historial</h3>

      <mat-table [dataSource]="_paymentOrders">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Orden de pago </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            <a (click)="opSelected(attribute)">OP-{{ attribute.id }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amountPaid">
          <mat-header-cell *matHeaderCellDef> Monto total de la orden </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            $ {{ attribute.amountPaid | number: '1.2-2' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            {{ attribute.createdAt | timestampToDate: 'long' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
          <mat-cell *matCellDef="let attribute">
            {{ attribute.status | paymentOrderStatus }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="budgetTableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: budgetTableColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</app-dialog>
