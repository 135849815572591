<app-page-widget noPadding (refresh)="refreshData()">
  <app-title>División</app-title>
  <app-subtitle>Seleccione para gestionar</app-subtitle>

  <app-action-button icon="domain" (click)="openManagementDialog()">Crear</app-action-button>

  <app-datatable
    #companiesDatatable
    [columns]="dtColumns"
    [rows]="companies"
    (changedRowsSelection)="openManagementDialog($event)"
  >
  </app-datatable>
</app-page-widget>

<app-dialog #managementDialog (dismiss)="onCloseManagementDialog()">
  <app-title>{{ selectedCompany ? selectedCompany.name : 'Datos del la División' }}</app-title>
  <app-subtitle>{{ selectedCompany ? 'Datos de la División' : '' }}</app-subtitle>

  <app-menu-item icon="delete" (click)="deleteCompany(selectedCompany)"
    >Eliminar División</app-menu-item
  >

  <div class="width-400">
    <app-form
      *ngIf="formDataLoaded"
      [fields]="formFields"
      [model]="formData"
      [noCancelButton]="false"
      (save)="saveCompany($event.value)"
      (cancel)="managementDialog.close()"
    >
    </app-form>
  </div>
</app-dialog>
