<app-page-widget [menuItems]="widgetMenu" [actionButtons]="widgetActions" (refresh)="refreshData()">
    <app-title>Seleccione o Cree una paquete extraordinario</app-title>
    <app-subtitle></app-subtitle>

    <app-action-button icon="playlist_add" (click)="newExtraordinary()">Crear</app-action-button>
    <app-menu-item icon="playlist_add" (click)="newExtraordinary()">Agregar Extraordinario
    </app-menu-item>

    <div class="content">
        <dx-data-grid #extraordinaryGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="extraordinariesList" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no éxisten registros." [showBorders]="true"
            [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" (onRowClick)="onRowClick($event)" (onExporting)="onExporting($event)" (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)">
            <dxo-filter-row [visible]="true" placeholder="Buscar..."></dxo-filter-row>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-export [enabled]="true" fileName="lista-extraordinarios"></dxo-export>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="ID" width="10%" [groupIndex]="0" sortOrder="desc" groupCellTemplate="extraordinaryIdGroupTemplate" cellTemplate="extraordinaryIdGroupTemplate" [allowEditing]="false" [allowFiltering]="true" dataField="id" [allowSorting]="false" alignment="center"></dxi-column>
            <dxi-column caption="Fecha" width="25%" [allowEditing]="false" dataType="date" cellTemplate="dateTemplate" dataField="createdAt" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" [allowFiltering]="true" sortOrder="desc" alignment="center"></dxi-column>
            <dxi-column caption="Estado" width="20%" cellTemplate="assignmentStatusTemplate" [allowEditing]="false" dataField="status" [allowSorting]="false" [allowFiltering]="false" alignment="center" [calculateCellValue]="customizeStatusLabel">
            </dxi-column>
            <dxi-column caption="Solicitante" dataField="createdBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [visible]="true"></dxi-column>
            <dxi-column caption="Solicitante" dataField="createdByDisplayName" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [visible]="false"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [visible]="true"></dxi-column>
            <dxi-column caption="Cod" dataField="code" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [visible]="true"></dxi-column>
            <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
            <div *dxTemplate="let data of 'assignmentStatusTemplate'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'extraordinaryIdGroupTemplate'">
                <span>EXT-{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong>
                <app-timeago-date [timestamp]="data.value"></app-timeago-date>
            </div>
            <div *dxTemplate="let data of 'amountTemplate'">
                <span>$ {{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'createdByTemplate'">
                <app-user-data [id]="data.value"></app-user-data>
            </div>
            <div *dxTemplate="let data of 'approverRemaining'">
                <ng-container *ngIf="data.value.length> 0">
                    <ul class="approversRemaining">
                        <li *ngFor="let item of data.value">
                            <app-user-data [id]="item[0]"></app-user-data>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="data.value.length === 0 && data.value !==
                    ''">
                    <span>Todos los usuarios han aprobado</span>
                </ng-container>
                <ng-container *ngIf="data.value === ''">
                    <span>No se ha enviado a aprobación</span>
                </ng-container>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalExtraordinaries }}</h2>
                    <span class="name">Total de extraordinarios</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
</app-page-widget>