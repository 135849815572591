<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Almacenes</app-title>
    <app-subtitle>Seleccione para administrar y seleccionar miembros</app-subtitle>
    <app-action-button icon="assessment" (click)="openManagementDialog()">Crear</app-action-button>
    <app-datatable #projectsDatatable [columns]="dtColumns" [rows]="warehouses" (changedRowsSelection)="openManagementDialog($event)">
    </app-datatable>
</app-page-widget>

<app-dialog class="width-500" #managementDialog noPadding width="30%" (dismiss)="onCloseManagementDialog()">
    <app-title>{{ selectedWarehouse ? selectedWarehouse.name : 'Datos del almacen' }} </app-title>
    <app-subtitle>{{ selectedWarehouse ? 'Datos del Almacen' : '' }}</app-subtitle>
    <app-menu-item icon="delete" (click)="deleteWarehouse(selectedWarehouse)">Eliminar Almacen
    </app-menu-item>

    <mat-tab-group [dynamicHeight]="true">
        <mat-tab label="Datos">
            <div class="width-700 height-500">
                <app-form *ngIf="formDataLoaded" padding [fields]="formFields" [model]="formData" [noCancelButton]="false" (save)="saveWarehouse($event.value)" (cancel)="managementDialog.close()">
                </app-form>
            </div>
        </mat-tab>
        <mat-tab *ngIf="selectedWarehouse" label="Miembros">
            <div class="width-700 height-500">
                <app-datatable canSelectMultiple selectWithCheckboxes [filterBy]="['name', 'description']" [columns]="usersDtColumns" [rows]="users" [selected]="selectedWarehouseMembers" (changedRowsSelection)="setWarehouseMembers(selectedWarehouse, $event)">
                </app-datatable>
            </div>
        </mat-tab>
    </mat-tab-group>
</app-dialog>