<app-page-widget (refresh)="refreshData()">
    <app-title>Modelo</app-title>
    <app-subtitle></app-subtitle>

    <div class="div-container">
        <div class="left" style="height: 450px; width: 50%;">
            <model-detail *ngIf="documentUrn !== ''" [accessToken]="accessToken" [documentUrn]="documentUrn"></model-detail>
        </div>
        <div class="right">
            <!-- <div class="pies-container"> -->
            <dx-pie-chart id="pie" title="Area of Countries" palette="bright" [dataSource]="areas" (onPointClick)="pointClickHandler($event)" (onLegendClick)="legendClickHandler($event)">
                <dxi-series argumentField="country" valueField="area">
                    <dxo-label [visible]="true">
                        <dxo-connector [visible]="true" [width]="1"></dxo-connector>
                    </dxo-label>
                </dxi-series>
                <dxo-size [width]="500"></dxo-size>
                <dxo-export [enabled]="true"></dxo-export>
            </dx-pie-chart>
            <!-- </div> -->
        </div>
    </div>

</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>