<ng-template #loadingTpl>
    <rmd-loading-indicator></rmd-loading-indicator>
</ng-template>

<div class="content" *ngIf="!_loading; else loadingTpl">
    <div fxlayout="row wrap" fxlayoutalign="space-evenly center" style="flex-flow: wrap; box-sizing: border-box; display: flex; place-content: center space-evenly; align-items: center;">

        <mat-card class="card">
            <mat-card-content>
                <h3>Paquetes: {{ totalPackages | number:'1.0-2' }}</h3>
            </mat-card-content>
        </mat-card>
        <mat-card class="card">
            <mat-card-content>
                <h3>Presupuesto: ${{ totalBudget | number:'1.2-2' }}</h3>
            </mat-card-content>
        </mat-card>
    </div>
    <dx-tree-list #budgetGrid id="treeList" [wordWrapEnabled]="true" [dataSource]="this._packagesSummary" [rootValue]="-1" [autoExpandAll]="false" keyExpr="id" parentIdExpr="headId" [hoverStateEnabled]="false" [showColumnLines]="false" [showRowLines]="false"
        noDataText="Sin paquetes
    disponibles." [showBorders]="false" [rowAlternationEnabled]="true" [columnAutoWidth]="true" [allowColumnReordering]="true" [rowRenderingMode]="false" [allowColumnResizing]="true">
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxo-paging [pageSize]="60" [pageIndex]="0"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column caption="Tipo" dataField="type" width="30%" [allowSorting]="false" cellTemplate="typeCellTemplate" [allowSearch]="false" alignment="left">
        </dxi-column>
        <dxi-column caption="No. Paquetes" dataField="numPaks" width="30%" [allowSorting]="false" [allowSearch]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Presupuesto" dataField="totalCost" width="30%" cellTemplate="budgetTemplate" [allowSorting]="false" [allowSearch]="false" alignment="right">
        </dxi-column>

        <div *dxTemplate="let data of 'typeCellTemplate'">
            <strong>{{ data.value | packageTypeModel }}</strong>
        </div>
        <div *dxTemplate="let data of 'budgetTemplate'">
            <strong *ngIf="data.value != 'Derivados'">${{ data.value | number:'1.2-2'
        }}</strong>
        </div>
        <dxo-summary [skipEmptyValues]="true">
            <dxi-total-item column="numPaks" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity">
            </dxi-total-item>
            <dxi-total-item column="totalCost" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal"></dxi-total-item>
        </dxo-summary>
    </dx-tree-list>

    <div>

    </div>

    <dx-pie-chart id="chart" [dataSource]="this._packagesSummary" palette="Harmony Light" (onPointClick)="pointClickHandler($event)" (onLegendClick)="legendClickHandler($event)">
        <dxi-series argumentField="type" valueField="numPaks" name="Tipo">
            <dxo-label [visible]="true" position="columns" [customizeText]="customizeLabel">
                <dxo-font [size]="11"></dxo-font>
                <dxo-connector [visible]="true" [width]="1.5"></dxo-connector>
            </dxo-label>
        </dxi-series>
        <dxo-size [width]="1000"></dxo-size>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-tooltip [enabled]="true"></dxo-tooltip>
    </dx-pie-chart>
</div>