<ng-container>
    <div class="text-center">
        <strong><h3>El proveedor <span class="text-primary">{{ data.supplierId | supplierData | async }}</span> posee un anticipo</h3></strong>
    </div>
    <div>
        <div>
            <h4>
                Monto del anticipo:
                <strong>${{ data.advancedPaymentAmount | number:'1.2-2' }}</strong>
            </h4>
        </div>
        <div>
            <h4>
                Pagado:
                <strong>${{ data.payOut | number:'1.2-2' }}</strong>
            </h4>
        </div>
        <div>
            <h4>
                Amortizado:
                <strong>${{ data.amortized | number:'1.2-2' }}</strong>
            </h4>
        </div>
        <div>
            <h4>
                Por amortizar:
                <strong>${{ data.payOut - data.amortized | number:'1.2-2' }}</strong>
            </h4>
        </div>
        <div>
            <h4>
                Total Cotizado:
                <strong>${{ data.totalQuote | number:'1.2-2' }}</strong>
            </h4>
        </div>
    </div>
    <div class="text-center">
        <h3>¿Cuánto desea amortizar en esta cotización?</h3>
        <h4>El monto a amortizar no debe ser mayor a lo pendiente por amortizar y al total cotizado</h4>
    </div>
    <mat-form-field class="width-100">
        <input matInput [(ngModel)]="amountAmortized" [formControl]="amountFormControl" (keyup)="validateFormControls()" placeholder="Monto sobre la cotización" type="number" max="100" min="1" required>
    </mat-form-field>
    <mat-form-field class="width-100">
        <input matInput disabled [(ngModel)]="percentAmortized" [formControl]="amountPercentFormControl" (keyup)="validateFormControls()" placeholder="Porcentaje sobre la cotización" type="number" max="100" min="1" required>
    </mat-form-field>

    <div class="text-center">
        <h4>Total a amortizar: ${{ totalAmortized | number:'1.2-2'}} <small>Incluye IVA</small></h4>
    </div>
    <div class="mt-5 text-right">
        <button mat-raised-button [disabled]="disabledSaveAdvanced" color="primary" (click)="saveData()">
            Guardar
        </button>
    </div>
</ng-container>