<app-section-nav noOutlet>
    <app-title>Avance de paquetes</app-title>

    <app-nav-item routeData="./workforce-advances/list">Contratos tasa 0%</app-nav-item>
    <app-nav-item routeData="./subcontract-advances/list">Subcontrato</app-nav-item>
    <app-nav-item routeData="./subcontract-advances/tree-list">Subcontrato Lista</app-nav-item>
    <app-nav-item routeData="./workforce-advances/tree-list">Contratos tasa 0% Lista</app-nav-item>
    <app-nav-item routeData="./report">Reporte</app-nav-item>

</app-section-nav>

<router-outlet></router-outlet>