<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Nueva Estimación</app-title>
    <app-subtitle>Seleccione los conceptos para la estimación</app-subtitle>

    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <button mat-raised-button class="action-button mr-5" (click)="changeTypeAssignment('0%')" color="warn">
                Contrato Tasa 0%
            </button>
            <button mat-raised-button class="action-button mx-5 ml-5" (click)="changeTypeAssignment('subcontract')" color="primary">
                Subcontrato
            </button>
            <!-- <button class="action-button-show" mat-raised-button id="btn" color="primary" (click)="calAmountToPayDB()">
                Calcular Amount To Pay
            </button> -->
        </div>
    </div>

    <div class="content-block d-flex my-0">
        <div class="supplierSelector">
            <div>
                <h3 class="content-title">Documentos</h3>
                <app-files-uploader class="mt-10" #filesUploader bucket="estimates-documents" (uploadFinished)="onDocumentUpload($event)">
                </app-files-uploader>
            </div>

        </div>
        <div class="supplierSelector d-flex align-items-center">
            <div>
                <table>
                    <tr>
                        <td style='border: 1px solid'>
                            <strong>Documentos cargados</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style='border: 1px solid'>
                            <ng-container *ngFor="let item of documents">
                                <a class="ml-5" target="_blank" [href]="item.downloadUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                                <a class="ml-5" (click)="deleteDocument(item)">
                                    <mat-icon matListIcon color="warn">delete</mat-icon>
                                </a>
                            </ng-container>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="content-block">
        <button mat-raised-button color="primary" [disabled]="!preview" (click)="openPreviewDialog()" class="margin-left-12">
            <mat-icon>visibility</mat-icon>
            Vista Previa
        </button>
    </div>

    <div class="content-block">
        <h3 class="content-title">Paquetes y Conceptos</h3>
    </div>

    <dx-data-grid #assignmentGrid id="grid-container" [dataSource]="asignmentWithPacks" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no existen registros." [showBorders]="true"
        [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" (onSelectionChanged)="onChangedRowsSelection($event)">
        <ng-container *ngIf="viewTable">

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel [visible]="false"></dxo-search-panel>
            <dxo-grouping #expand [allowCollapsing]="false" [autoExpandAll]="true"></dxo-grouping>
            <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false">
            </dxo-selection>
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxi-column caption="ID" dataField="assignmentPmId" groupCellTemplate="idGroupTemplate" [groupIndex]="0" width="5%" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"></dxi-column>
            <dxi-column caption="Detalle" dataField="asignmentSmallId" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

            <dxi-column caption="Semana a Capturar" [allowEditing]="true" cellTemplate="weekTemplate" dataField="weekRequest" [allowSorting]="true" [allowSearch]="true" alignment="center">
            </dxi-column>
            <dxi-column caption="Evidencias" dataField="quoteUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Semana ultimo avance" dataField="week" cellTemplate="weekTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Avance Anterior Acumulado" dataField="previousTotalAdvance" cellTemplate="percentageToAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Avance Semanal" dataField="advance" cellTemplate="percentageToAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Avance a la Fecha" dataField="totalAdvance" cellTemplate="percentageAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <!-- <dxi-column caption="Avance Anterior Acumulado" dataField="previousTotalAdvance" cellTemplate="percentageToAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Avance Anterior" dataField="percentToAdvance" cellTemplate="percentageToAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Avance acumulado" dataField="percentageAdvance" cellTemplate="percentageAdvanceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column> -->

            <dxi-column caption="% solicitado" dataField="percentage" [calculateCellValue]="calcPercentPay" cellTemplate="percentPayTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="% aprobado" dataField="percentageApproved" cellTemplate="percentPayTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="% a solicitar" dataField="percentToPay" [calculateCellValue]="calcPercentToPay" cellTemplate="percentToPayTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad a solicitar" dataField="quantityToPay" [calculateCellValue]="calcQuantityToPay" cellTemplate="quantityToPayTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Monto a solicitar" dataField="amountToPay" [calculateCellValue]="calcAmountToPay" cellTemplate="amountPayTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>

            <dxi-column caption="Id Paquete" dataField="packageId" alignment="left" cellTemplate="packageIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" cellTemplate="conceptTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Nivel" dataField="level" cellTemplate="levelTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Unidad" dataField="unit" cellTemplate="unitTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>

            <dxi-column caption="P.U" dataField="unitPrice" cellTemplate="unitPriceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="P.U Real" dataField="realPrice" cellTemplate="unitPriceTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Costo inicial" dataField="costOriginal" cellTemplate="costOriginalTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Adicionales" dataField="additionToQuantity" cellTemplate="aditionalsTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Deductiva" dataField="decreaseToQuantity" cellTemplate="decrementsTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>

            <dxi-column caption="Total" [calculateCellValue]="calculateTotalCost" cellTemplate="TotalTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Disponible" dataField="available" cellTemplate="availableTemplate" [calculateCellValue]="calculateAvailable" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column name="amount" caption="Monto Total Acumulado" [calculateCellValue]="calcAmount" cellTemplate="amountToPayTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
        </ng-container>

        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span *ngIf="data.key.percentageApproved != 0">
                <i class="material-icons icon-gray pointer" (click)="onSelectDetails(data.key)">
                    remove_red_eye
                </i>
            </span>
        </div>

        <div *dxTemplate="let data of 'idGroupTemplate'">
            <table style="width:100%">
                <tr *ngIf="data && data.data && data.data.items &&
                        data.data.items[0]">
                    <th>Id Asignacion: {{ data.key | idFromUuid }}</th>
                    <th>Fecha: hace {{ data.data.items[0].createdAt | timeAgo }}</th>
                    <th>Solicitante: {{ data.data.items[0].createdBy | userData | async }}</th>
                    <th>Proveedor: {{ data.data.items[0].supplierId | supplierData | async }}
                    </th>
                    <th>Monto: ${{ data.data.items[0].totalAssignment | number }}
                    </th>
                </tr>
            </table>
        </div>

        <div *dxTemplate="let data of 'weekTemplate'">
            <span *ngIf="data.value"><strong>{{ data.value }}</strong></span>
            <span *ngIf="!data.value"><strong>--</strong></span>
        </div>

        <div *dxTemplate="let data of 'amountPayTemplate'">
            <span *ngIf="data.value">${{ data.value }}</span>
            <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                    monto</span>
        </div>

        <div *dxTemplate="let data of 'packageIdTemplate'">
            {{ data.value }}
        </div>

        <div *dxTemplate="let data of 'deductiveAcumTemplate'">
            {{ data.value }}
        </div>

        <div *dxTemplate="let data of 'conceptTemplate'">
            <span *ngIf="data.data.code === payLoanPositive">
                    <button mat-raised-button id="btn" color="primary"
                        (click)="showLoanDetails(data)">
                        Detalles
                    </button>
                    {{ data.value }}
                </span>
            <span *ngIf="data.data.code === payLoanNegative">{{ data.value }}
                    será
                    descontado en
                    {{ data.data.payments }}
                    partes.</span>

            <span *ngIf="data.data.code !== payLoanPositive || data.data.code
                    !==
                    payLoanNegative">{{
                    data.value
                    }}</span>
        </div>
        <div *dxTemplate="let data of 'levelTemplate'">
            {{ data.value }}
        </div>
        <div *dxTemplate="let data of 'aditionalsTemplate'">
            <ng-container *ngIf="data.value; else withoutValue">
                {{ data.value | number: '1.2-2' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'decrementsTemplate'">
            <ng-container *ngIf="data.value; else withoutValue">
                {{ data.value | number: '1.2-2' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'quantityToPayTemplate'">
            <span *ngIf="data.value">{{ data.value }}</span>
            <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                    cantidad</span>
        </div>
        <div *dxTemplate="let data of 'percentPayTemplate'">
            <span *ngIf="data.value">{{ data.value }} %</span>
            <span *ngIf="!data.value">0%</span>
        </div>
        <div *dxTemplate="let data of 'percentToPayTemplate'">
            <span *ngIf="data.value">{{ data.value }}</span>
            <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                    porcentaje</span>
        </div>
        <div *dxTemplate="let data of 'availableTemplate'">
            {{ data.value | number: '1.2-2' }}
        </div>
        <div *dxTemplate="let data of 'percentToPay'">
            <span *ngIf="data.value">{{ data.value}}</span>
            <span *ngIf="!data.value">-</span>
        </div>
        <div *dxTemplate="let data of 'amountToPayTemplate'">
            <span *ngIf="data.value">${{ data.value | number }}</span>
            <span *ngIf="!data.value">0</span>
        </div>
        <div *dxTemplate="let data of 'percentageToAdvanceTemplate'">
            <span *ngIf="data.value">{{ data.value}} %</span>
            <span *ngIf="!data.value">0%</span>
        </div>
        <div *dxTemplate="let data of 'percentageAdvanceTemplate'">
            <span *ngIf="data.value">{{ data.value}} %</span>
            <span *ngIf="!data.value">0%</span>
        </div>
        <div *dxTemplate="let data of 'quantityTemplate'" class="edit-cells-qty">
            <span>{{ data.value | number: '1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'unitPriceTemplate'" class="edit-cells-qty">
            <span>${{ data.value | number }}</span>
        </div>
        <div *dxTemplate="let data of 'costOriginalTemplate'" class="edit-cells-qty">
            <span>${{ data.value | number }}</span>
        </div>
        <div *dxTemplate="let data of 'costTemplate'" class="edit-cells-qty">
            <span>${{ data.value | number }}</span>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'" class="edit-cells-status">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'TotalTemplate'">
            <strong *ngIf="data.value !== 0">$ {{ data.value | number }}</strong>
            <span *ngIf="data.value === 0">-</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <dxo-summary [calculateCustomSummary]="calculateSelectedRow">
            <dxi-total-item column="Total" summaryType="sum" [customizeText]="formatTotal">
            </dxi-total-item>
            <dxi-total-item column="amount" summaryType="sum" [customizeText]="formatTotalAmount">
            </dxi-total-item>
            <dxi-total-item column="amountToPay" summaryType="sum" [customizeText]="formatTotalAmount">
            </dxi-total-item>
        </dxo-summary>
    </dx-data-grid>

    <div class="footer">
        <div class="actions-block">
            <button class="action-button" mat-raised-button id="btn" [disabled]="isCreateButtonDisabled" color="primary" (click)="generateEstimation()">
                Generar Estimación
            </button>
        </div>
        <!-- <dx-check-box #guarantyFund [disabled]="isCreateButtonDisabled" [value]="false" [width]="220" text="Agregar fondo de garantia">
        </dx-check-box> -->
    </div>

    <!-- ============================================================== -->
    <!-- popup-loandetails  -->
    <!-- ============================================================== -->
    <dx-popup class="popup" [width]="250" [height]="280" [showTitle]="true" title="Prestamo" [dragEnabled]="true" [closeOnOutsideClick]="true" [(visible)]="showLoanPopUpDetail">
        <div *dxTemplate="let data of 'content'">
            <strong>Monto: </strong> $ {{ loanDetailData.cost }}
            <br />
            <strong>Cantidad de pagos: </strong> {{ loanDetailData.payments }}
            <br />
            <strong>Comentarios: </strong> {{ loanDetailData.comments }}
        </div>
    </dx-popup>
    <!-- ============================================================== -->
    <!-- popup loan details end  -->
    <!-- ============================================================== -->
    <app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
    </app-loading-indicator>
</app-page-widget>
<app-dialog #estimatesDialog noPadding width="650px" [autoFocus]="false">
    <app-title>Estimaciones</app-title>
    <table class="table responsive w-100 p-15">
        <thead>
            <tr>
                <th>Semana</th>
                <th>Solicitado</th>
                <th>Porcentaje Aprobado</th>
                <th>Monto Aprobado</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of estimates" class="text-center">
                <td scope="row">{{ item.weekOfYear }}</td>
                <td scope="row">{{ item.percentToRequest }}%</td>
                <td>{{ getPercentToApproved(item.packages) }}</td>
                <td>{{ getAmountToApproved(item.packages) }}</td>
            </tr>
        </tbody>
    </table>
</app-dialog>

<app-preview-estimate #previewDialog [packages]="selectedPackages" [subTitle]="'Paquetes seleccionados para ésta Estimación'" [gridType]="'package'" (previewItems)="packagesSelectionChangedByPreview($event)">
</app-preview-estimate>

<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>