<app-page-widget noPadding (refresh)="refreshData()">
  <app-title>Usuarios</app-title>
  <app-subtitle>Seleccione para gestionar</app-subtitle>

  <app-action-button icon="person_add" (click)="openManagementDialog()">Crear</app-action-button>

  <app-datatable
    #usersDatatable
    [columns]="dtColumns"
    [rows]="users"
    (changedRowsSelection)="openManagementDialog($event)"
  >
  </app-datatable>
</app-page-widget>

<!-- Management Dialog -->
<app-dialog #managementDialog (dismiss)="onCloseManagementDialog()">
  <app-title>
    {{ selectedUser ? selectedUser.displayName || selectedUser.email : 'Datos del Usuario' }}
  </app-title>
  <app-subtitle>{{ selectedUser ? 'Datos del Usuario' : '' }}</app-subtitle>

  <app-menu-item icon="lock" (click)="toggleDisableStatus(selectedUser)"
    >Habilitar/Deshabilitar Usuario</app-menu-item
  >
  <!-- <app-menu-item icon="delete" (click)="deleteUser(selectedUser)">Eliminar Usuario</app-menu-item> -->

  <div class="width-400">
    <app-form
      [fields]="formFields"
      [model]="formData"
      [noCancelButton]="false"
      (save)="saveUser($event.value)"
      (cancel)="managementDialog.close()"
    >
    </app-form>
  </div>
</app-dialog>
