<app-dialog #dialog>
  <app-title>Datos de Usuario</app-title>

  <ng-template [ngIf]="data">
    <img *ngIf="data.photoUrl" class="app-user-data-img" [src]="data.photoUrl">

    <p *ngIf="data.displayName">Nombre: <b [innerText]="data.displayName"></b></p>
    <p>E-Mail: <b [innerText]="data.email"></b></p>
    <p *ngIf="data.phoneNumber">Teléfono: <b [innerText]="data.phoneNumber"></b></p>
    <p *ngIf="data.position">Cargo: <b [innerText]="data.position"></b></p>
    <br>
  </ng-template>
</app-dialog>

<a matTooltip="Click para ver detalles"
   [innerText]="data?.displayName || data?.email || '?'"
   (click)="dialog.open()">
</a>
