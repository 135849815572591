<app-page-widget (refresh)="refreshData()">
    <app-title>Indirectos Unidades</app-title>
    <app-subtitle>Unidades</app-subtitle>

    <button mat-raised-button id="btn" color="primary" [disabled]="!selectedGridItems" (click)="showCreatePopup()">
    Crear Requisicion
  </button>
    <hr />
    <!-- ============================================================== -->
    <!-- GRID INDIRECTS UNIDADES  -->
    <!-- ============================================================== -->
    <dx-data-grid #unitsGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="packages" [masterDetail]="{ enabled: false, template:
    'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" [(selectedRowKeys)]="Rows"
        (onRowClick)="onGridSelection($event, Rows)">
        <!-- (onSelectionChanged)="onGridSelection($event)"> -->
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column caption="ID" width="5%" [allowEditing]="false" dataField="id" [allowSorting]="false" alignment="center" [allowFiltering]="true">
        </dxi-column>
        <dxi-column caption="EXT" width="5%" [allowEditing]="false" dataField="ext" [allowSorting]="false" [allowFiltering]="true" alignment="center">
        </dxi-column>
        <dxi-column caption="Fecha requerido" width="15%" [allowEditing]="false" dataType="date" [calculateCellValue]="calculateDateCellValue" cellTemplate="dateTemplate" dataField="requestDate" [allowSorting]="true" [allowFiltering]="true" sortOrder="desc" alignment="center">
        </dxi-column>
        <dxi-column caption="Concepto" width="55%" [allowEditing]="false" dataField="concept" [allowSorting]="false" [allowFiltering]="true">
        </dxi-column>
        <dxi-column caption="Unidad" width="10%" cellTemplate="unitTemplate" [allowEditing]="false" dataField="unit" [allowSorting]="false" [allowFiltering]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Disponible" width="10%" cellTemplate="quantityTemplate" [allowEditing]="false" dataField="quantity" [allowFiltering]="false" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="En Pasivo" width="10%" [allowEditing]="false" dataField="statusPassive" cellTemplate="passiveStatusTemplate" [allowSorting]="false" alignment="center" [allowFiltering]="false">
        </dxi-column>
        <dxi-column *ngIf="canAddPassive" caption="Enviar a Pasivos" dataField="uuid" cellTemplate="passiveTemplate" [allowFiltering]="false" alignment="center" width="10%"></dxi-column>

        <div *dxTemplate="let data of 'quantityTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'passiveStatusTemplate'">
            <span *ngIf="data.value === 2" class="icon-gray"><strong>Si</strong></span>
        </div>
        <div *dxTemplate="let data of 'passiveTemplate'" class="cost">
            <span>
        <i class="material-icons icon-gray"
          matTooltip="Enviar paquete a pasivos"
          (click)="sendPassive(data)">
          input
        </i></span>
        </div>
    </dx-data-grid>

    <dx-popup class="popup" [width]="450" [height]="400" [showTitle]="true" title="Información del producto" [dragEnabled]="true" [closeOnOutsideClick]="true" [(visible)]="createPopup">
        <div *dxTemplate="let data of 'content'">
            <p>
                Producto:
                <strong>{{ selectedGridItems[0].concept }}</strong>
            </p>
            <p>
                Cantidad disponible:
                <strong>{{ currentQtyAvailable }}</strong>
            </p>
            <p>
                Costo unitario:
                <strong>$ {{ selectedGridItems[0].cost | number }}</strong>
            </p>
            Cantidad a solicitar:
            <form (ngSubmit)="createRequisition()" class="example-form">
                <mat-form-field class="example-full-width">
                    <input #qtyInput matInput placeholder="" type="number" [formControl]="quantityForm" [errorStateMatcher]="matcher" [(ngModel)]="qtyModel" required />
                    <mat-hint>La cantidad no debe ser mayor a la cantidad disponible!
                    </mat-hint>
                    <mat-error *ngIf="quantityForm.hasError('required')">
                        Cantidad es <strong>requerida</strong>
                    </mat-error>
                </mat-form-field>
                <div class="align-center mt-3">
                    <button mat-raised-button color="primary" type="submit">Crear
            requisicion</button>
                </div>
            </form>
        </div>
    </dx-popup>
</app-page-widget>