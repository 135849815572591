<app-page-widget (refresh)="refreshData()">
    <app-title></app-title>
    <app-subtitle></app-subtitle>

    <div class="actions-block">
        <button mat-raised-button [disabled]="!allowPayButton" class="action-button" (click)="payOrders()" color="primary">
            <mat-icon>attach_money</mat-icon>
            Pagar
        </button>
    </div>

    <h2>Total Remesas: ${{ totalRemittancesAmount | number : '1.2-2' }}</h2>
    <div id="purchase-orders" class="order-type-block">
        <div class="header-block">
            <span class="amount-value-label">Órdenes de Compra: </span>
            <span class="amount-value"><strong>${{ purchaseOrdersSummary |
                    number
                    : '1.2-2' }}</strong></span>
        </div>

        <div class="content-block">
            <dx-data-grid id="purchaseOrdersGrid" #purchaseOrdersGrid [dataSource]="purchaseOrders" (onSelectionChanged)="onSelectPurchaseOrderRow($event)" (onRowUpdated)="onRowUpdated($event)">
                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false">
                </dxo-selection>
                <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
                <dxi-column dataField="id" caption="Órdenes de Compra Id" cellTemplate="ocIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="supplierId" caption="Proveedor" cellTemplate="providerTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="orderAmount" caption="Sub Total" cellTemplate="orderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="taxes" caption="IVA" cellTemplate="taxesTemplate" [calculateCellValue]="calculateCheckOrderTaxes" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"> </dxi-column>
                <dxi-column dataField="amountAmortized" caption="Anticipo" cellTemplate="amortizedTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="totalOrderAmount" caption="Total" cellTemplate="totalOrderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="ammountToPay" caption="Monto por Pagar" cellTemplate="ammountToPayTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="toPay" caption="Pagar $" dataType="number" cellTemplate="toPayTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">
                    <dxi-validation-rule type="custom" [validationCallback]="isValid" message="Cantidad
                        Invalida">
                    </dxi-validation-rule>
                </dxi-column>
                <dxi-column dataField="observation" caption="Observación" dataType="text" cellTemplate="observationTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">

                </dxi-column>
                <dxi-column dataField="orderToDelete" width="8%" caption="Eliminar" cellTemplate="deletePurchaseOrder" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <div class="padding-top-10" *dxTemplate="let orderAmount of
                    'orderAmountTemplate'">
                    <span *ngIf="orderAmount?.value">${{orderAmount.value
                        |
                        number
                        : '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let taxes of
                    'taxesTemplate'">
                    <span *ngIf="taxes?.value">${{taxes.value | number :
                        '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let totalOrderAmount
                    of
                    'totalOrderAmountTemplate'">
                    <span *ngIf="totalOrderAmount?.value">${{totalOrderAmount.value
                        | number :
                        '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let amountAmortized
                    of
                    'amortizedTemplate'">
                    <span *ngIf="amountAmortized?.value">${{amountAmortized.value
                        | number :
                        '1.2-2'}}</span>
                    <span *ngIf="!amountAmortized?.value">$0.00</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let observation
                    of
                    'observationTemplate'">
                    <span>{{ observation.value }}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let paidAmount of
                    'paidAmmountTemplate'">
                    <span *ngIf="paidAmount?.value">${{paidAmount.value | number
                        : '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let amountToPay of
                    'ammountToPayTemplate'">
                    <span *ngIf="amountToPay?.value">${{amountToPay.value |
                        number
                        : '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let toPay of
                    'toPayTemplate'">
                    <span *ngIf="toPay?.value">${{toPay.value | number :
                        '1.2-2'}}</span>
                    <span *ngIf="!toPay || !toPay.value" class="placeholder-color">Ingrese
                        cantidad</span>
                </div>

                <div *dxTemplate="let data of
                    'ocIdTemplate'">
                    <button mat-button (click)="goToPurchaseOrderDetail(data)">
                        <span style="color:blueviolet">
                            <strong>{{data.data.id}}</strong>
                        </span>
                    </button>
                </div>
                <div *dxTemplate="let data of 'deletePurchaseOrder'">
                    <button mat-icon-button color="warn" (click)="deletePurchaseOrder(data)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
                <div *dxTemplate="let data of 'providerTemplate'">
                    <app-supplier-data style="padding-top: 8px" [id]="data.value"></app-supplier-data>
                </div>
            </dx-data-grid>
        </div>
    </div>

    <div id="payment-check-orders" class="order-type-block">
        <div class="header-block">
            <span class="amount-value-label">Total Órdenes de Cheque: </span>
            <span class="amount-value"><strong>${{ paymentCheckOrdersSummary |
                    number : '1.2-2' }}</strong></span>
        </div>

        <div class="content-block">
            <dx-data-grid id="checkOrdersGrid" #checkOrdersGrid [dataSource]="paymentCheckOrders" (onSelectionChanged)="onSelectCheckOrderRow($event)" (onRowUpdated)="onRowUpdated($event)">
                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false">
                </dxo-selection>
                <dxi-column dataField="id" caption="Órdenes de Cheque Id" cellTemplate="ocIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="orderAmount" caption="Sub Total" cellTemplate="orderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="taxPercentage" caption="IVA" cellTemplate="taxesTemplate" [calculateCellValue]="calculateCheckOrderTaxes" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="totalOrderAmount" caption="Total" cellTemplate="totalOrderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="ammountToPay" caption="Monto por Pagar" cellTemplate="ammountToPayTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="toPay" caption="Pagar $" dataType="number" cellTemplate="toPayTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">
                    <dxi-validation-rule type="custom" [validationCallback]="isValid" message="Cantidad
                        Invalida">
                    </dxi-validation-rule>
                </dxi-column>
                <dxi-column dataField="observation" caption="Observación" dataType="text" cellTemplate="observationTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">

                </dxi-column>
                <dxi-column dataField="orderToDelete" caption="Eliminar" cellTemplate="deletePurchaseOrder" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxo-editing mode="cell" [allowUpdating]="true">
                </dxo-editing>

                <div class="padding-top-10" *dxTemplate="let orderAmount of
                    'orderAmountTemplate'">
                    <span *ngIf="orderAmount?.value">${{orderAmount.value |
                        number
                        : '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let taxes of
                    'taxesTemplate'">
                    <span *ngIf="taxes?.value">${{taxes.value | number :
                        '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let totalOrderAmount of
                    'totalOrderAmountTemplate'">
                    <span *ngIf="totalOrderAmount?.value">${{totalOrderAmount.value
                        | number :
                        '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let observation
                    of
                    'observationTemplate'">
                    <span>{{ observation.value }}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let paidAmount of
                    'paidAmmountTemplate'">
                    <span *ngIf="paidAmount?.value">${{paidAmount.value | number
                        : '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let amountToPay of
                    'ammountToPayTemplate'">
                    <span *ngIf="amountToPay?.value">${{amountToPay.value |
                        number
                        : '1.2-2'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let toPay of
                    'toPayTemplate'">
                    <span *ngIf="toPay?.value">${{toPay.value | number :
                        '1.2-2'}}</span>
                    <span *ngIf="!toPay || !toPay.value" class="placeholder-color">Ingrese
                        cantidad</span>
                </div>
                <div *dxTemplate="let data of
                    'ocIdTemplate'">
                    <button mat-button (click)="goToCheckOrderDetail(data)">
                        <span style="color:blueviolet">
                            <strong>{{data.data.id}}</strong>
                        </span>
                    </button>
                </div>
                <div *dxTemplate="let data of 'deletePurchaseOrder'">
                    <button mat-icon-button color="warn" (click)="deletePurchaseOrder(data)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
            </dx-data-grid>
        </div>
    </div>

    <div id="estimate-orders" class="order-type-block">
        <div class="header-block">
            <span class="amount-value-label">Total Órdenes de Estimación:
            </span>
            <span class="amount-value"><strong>${{ estimateOrdersSummary |
                    number
                    : '1.2-2' }}</strong></span>
        </div>

        <div class="content-block">
            <dx-data-grid id="estimateOrdersGrid" #estimateOrdersGrid [dataSource]="estimateOrders" (onSelectionChanged)="onSelectEstimateOrderRow($event)" (onRowUpdated)="onRowUpdated($event)">
                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false">
                </dxo-selection>
                <dxi-column dataField="id" caption="Órdenes de Estimación Id" cellTemplate="ocIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="supplierId" caption="Proveedor" cellTemplate="providerTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="orderAmount" caption="Sub Total" cellTemplate="orderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField=taxAmount caption="IVA" cellTemplate="taxesTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField=ivaCompl caption="IVA Complementario" cellTemplate="taxesTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField=isr caption="ISR" cellTemplate="taxesTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="totalOrderAmount" caption="Total" cellTemplate="totalOrderAmountTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="ammountToPay" caption="Monto por Pagar" cellTemplate="ammountToPayTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column dataField="toPay" caption="Pagar $" dataType="number" cellTemplate="toPayTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">
                    <dxi-validation-rule type="custom" [validationCallback]="isValid" message="Cantidad
                        Invalida">
                    </dxi-validation-rule>
                </dxi-column>
                <dxi-column dataField="observation" caption="Observación" dataType="text" cellTemplate="observationTemplate" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">

                </dxi-column>
                <dxi-column *ngxPermissionsOnly="['remittances:delete']" dataField="orderToDelete" caption="Eliminar" cellTemplate="deleteEstimateOrder" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxo-editing mode="cell" [allowUpdating]="true">
                </dxo-editing>

                <div class="padding-top-10" *dxTemplate="let orderAmount of
                    'orderAmountTemplate'">
                    <span *ngIf="orderAmount?.value">${{orderAmount.value |
                        number
                        : '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let taxes of
                    'taxesTemplate'">
                    <span *ngIf="taxes?.value">${{taxes.value | number :
                        '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let totalOrderAmount of
                    'totalOrderAmountTemplate'">
                    <span *ngIf="totalOrderAmount?.value">${{totalOrderAmount.value
                        | number :
                        '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let paidAmount of
                    'paidAmmountTemplate'">
                    <span *ngIf="paidAmount?.value">${{paidAmount.value | number
                        : '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let observation
                    of
                    'observationTemplate'">
                    <span>{{ observation.value }}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let amountToPay of
                    'ammountToPayTemplate'">
                    <span *ngIf="amountToPay?.value">${{amountToPay.value |
                        number
                        : '1.2-3'}}</span>
                </div>
                <div class="padding-top-10" *dxTemplate="let toPay of
                    'toPayTemplate'">
                    <span *ngIf="toPay?.value">${{toPay.value | number :
                        '1.2-3'}}</span>
                    <span *ngIf="!toPay || !toPay.value" class="placeholder-color">Ingrese
                        cantidad</span>
                </div>
                <div *dxTemplate="let data of 'ocIdTemplate'">
                    <button mat-button (click)="goToEstimationOrderDetail(data)">
                        <span style="color:blueviolet">
                            <strong>{{data.data.id}}</strong>
                        </span>
                    </button>
                </div>
                <div *dxTemplate="let data of 'providerTemplate'">
                    <app-supplier-data style="padding-top: 8px" [id]="data.value"></app-supplier-data>
                </div>
                <div *dxTemplate="let data of 'deleteEstimateOrder'">
                    <button *ngxPermissionsOnly="['remittances:delete']" mat-icon-button color="warn" (click)="deleteEstimateOrder(data)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
            </dx-data-grid>
        </div>
    </div>

</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>