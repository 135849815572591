<app-page-widget (refresh)="refreshData()">
    <app-title>Seleccione una Orden de Compra</app-title>
    <app-subtitle></app-subtitle>

    <!-- <button class="btn"
          mat-raised-button
          color="primary"
          [disabled]="!this.selectedGridItems"
          (click)="seeDetail()">
    Ver detalle de Orden de compra
  </button> -->
    <!-- ============================================================== -->
    <!-- GRID PURCHASE ORDER  -->
    <!-- ============================================================== -->
    <div class="grid-container-div" *ngIf="purchaseOrders.length > 0">
        <dx-data-grid #poGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="purchaseOrders" [masterDetail]="{ enabled: false,
            template: 'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
            no éxisten órdenes de compra." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" (onExporting)="onExporting($event)" (onExported)="onExported($event)"
            (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">
            <dxo-paging [pageSize]="5"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
            </dxo-pager>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" fileName="lista-ordenes-compra"></dxo-export>
            <dxo-paging [pageSize]="10" [pageIndex]="_purchaseOrdersService.pageIndex"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column alignment="center" caption="ID" sortOrder="desc" [groupIndex]="0" dataType="number" groupCellTemplate="purchaseOrderIdGroupTemplate" cellTemplate="purchaseOrderIdGroupTemplate" dataField="id" width="4%" [allowEditing]="false" [allowSearch]="true"
                [allowSorting]="false"></dxi-column>
            <dxi-column alignment="center" caption="COT" width="5%" sortOrder="desc" dataType="text" dataField="quoteId" groupCellTemplate="quoteIdGroupTemplate" cellTemplate="quoteIdTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Fecha" dataField="createdAt" dataType="date" cellTemplate="dateTemplate" sortOrder="desc" alignment="left" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Creado por" dataField="createdBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column alignment="center" caption="Condiciones de Pago" dataField="paymentMethod" dataType="string" cellTemplate="paymentMethod" [allowEditing]="false" [allowFiltering]="true" [allowSearch]="true" [allowSorting]="false" [calculateCellValue]="customizePaymentMethodLabel"></dxi-column>
            <dxi-column alignment="center" caption="Días restantes" dataField="daysOfCreditLeft" cellTemplate="remainingDaysToPay" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
            <dxi-column alignment="left" caption="Proveedor" dataField="supplierName" cellTemplate="supplierNameTemplate" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Cantidad" dataField="requiredQuantity" groupCellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Costo" dataField="supplierCost" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Subtotal" [allowSorting]="false" cellTemplate="costTemplate" [calculateCellValue]="calculateSubtotalTotalCost" alignment="center">
            </dxi-column>
            <dxi-column caption="IVA" [allowSorting]="false" [calculateCellValue]="calculateIva" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Total" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotalCost" alignment="center">
            </dxi-column>
            <dxi-column caption="Estado" dataField="status" cellTemplate="purchaseOrderStatusTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeStatusLabel"></dxi-column>
            <dxi-column caption="Aprobadores Faltantes" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
            <dxi-column caption="Detalle" dataField="purchaseUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span><i class="material-icons icon-gray"
            (click)="seeDetail(data)">
            remove_red_eye
          </i></span>
            </div>

            <div *dxTemplate="let data of 'purchaseOrderIdGroupTemplate'">OC-{{ data.value }}</div>
            <div *dxTemplate="let data of 'quoteIdGroupTemplate'">COT-{{ data.value | idFromUuid }}</div>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>
            <div *dxTemplate="let data of 'createdByTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'paymentMethod'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'remainingDaysToPay'">
                <ng-container *ngIf="data.value; else noData">
                    <strong [style.color]="data.value <0 ? 'red' : 'black'">{{
                        data.value }}
                        días</strong>
                </ng-container>
                <ng-template #noData>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'costTemplate'" class="cost">
                <span>$ {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'supplierNameTemplate'">
                <ng-container *ngIf="data.value; else noSupplier">
                    <span>{{ data.value }}</span>
                </ng-container>
                <ng-template #noSupplier>
                    No hay nombre de proveedor
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'purchaseOrderStatusTemplate'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'quoteIdTemplate'">
                <span [ngClass]="{ 'reference-link': data.key }" (click)="goToQuote(data.key)">
                    <strong>COT-{{ data.value | idFromUuid }}</strong>
                </span>
            </div>
            <div *dxTemplate="let data of 'approverRemaining'">
                <ng-container *ngIf="data.value.length> 0">
                    <ul class="approversRemaining">
                        <li *ngFor="let item of data.value">
                            <app-user-data [id]="item[0]"></app-user-data>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                    <span>Todos los usuarios han aprobado</span>
                </ng-container>
                <ng-container *ngIf="data.value === ''">
                    <span>No se ha enviado a aprobación</span>
                </ng-container>
            </div>
            <div *dxTemplate="let data of 'totalTemplate'" class="cost">
                <span>$ {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalPurchasesOrder }}</h2>
                    <span class="name">Total de ordenes</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
    <div class="grid-container-div text-center" *ngIf="purchaseOrders.length === 0">
        <h2>No posee ordenes de compra</h2>
    </div>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>