<h1 mat-dialog-title>Aprobaciones</h1>
<div mat-dialog-content>
    <dx-data-grid #grid id="grid-container" [dataSource]="approvers" [showColumnLines]="false" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false">
        <dxo-scrolling mode="virtual" showScrollbar="Never"></dxo-scrolling>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>

        <dxi-column caption="Aprobador" cellTemplate="approverTemplate" width="40%" [allowEditing]="false" [allowSearch]="false" dataField="approver" [allowSorting]="false"> </dxi-column>
        <dxi-column caption="Fecha" cellTemplate="approverDateTemplate" width="40%" [allowEditing]="false" [allowSearch]="false" dataField="approverDate" [allowSorting]="false"> </dxi-column>
        <dxi-column caption="Aprobado" width="20%" [allowEditing]="false" [allowSearch]="false" dataField="approved" dataType="boolean" [allowSorting]="false"> </dxi-column>

        <div *dxTemplate="let data of 'approverTemplate'">
            <span>{{ data.value | userData | async }}</span>
        </div>

        <div *dxTemplate="let data of 'approverDateTemplate'">
            <span *ngIf="data.value !== ''">{{ data.value | date }}</span>
            <span *ngIf="data.value === ''">No aprobado</span>
        </div>

    </dx-data-grid>

</div>

<!-- <ol>
  <li *ngFor="let approv of approvs">
    <mat-icon>account_circle</mat-icon>{{approv.email}}
    <mat-icon>verified_user</mat-icon>
    <mat-icon>check_circle</mat-icon>

  </li>
</ol> -->