<app-page-widget noPadding (refresh)="refreshData()" [menuItems]="widgetMenu" id="payment-order">
    <app-title>OP-{{ paymentOrder?.uuid | idFromUuid }} </app-title>
    <app-subtitle>{{ paymentOrder?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <app-action-button flat noText icon="done_all" (click)="viewApprovers()">Aprobaciones
    </app-action-button>

    <div class="actions-block">
        <button mat-raised-button class="action-button mr-5" (click)="goToPreviousUlr()" color="warn">
            <mat-icon>undo</mat-icon>
            Regresar
        </button>
        <ng-template [ngIf]="approveIsAvailable">
            <button mat-raised-button *ngxPermissionsOnly="['payment-orders:can_approve']" class="action-button" (click)="approve()" color="primary">
                <mat-icon>done</mat-icon>
                Aprobar
            </button>
        </ng-template>

        <app-button *ngIf="sendToApproveIsAvailable" class="action-button" icon="camera_roll" (click)="invoiceDialog.open()">Cargar Datos</app-button>
        <button mat-raised-button *ngIf="sendToApproveIsAvailable" class="action-button" (click)="confirmSendToApprove()" color="primary">
            <mat-icon>call_made</mat-icon>
            Enviar a Aprobar
        </button>

        <!-- <button mat-raised-button class="action-button" (click)="getBreakdowns()" color="primary">
            Breakdowns
        </button> -->
        <button mat-raised-button *ngIf="isOrderReadyToDownload" class="action-button" (click)="downloadOrder()" color="primary">
            <mat-icon>cloud_download</mat-icon>
            Descargar orden
        </button>
    </div>

    <div class="header-block">
        <span class="info-label">Estatus:
            <strong>{{ paymentOrder?.status === 5 ? 'Pagada' :
                paymentOrder?.status |
                purchaseOrderStatus }}</strong>
        </span>
        <span class="info-label">Solicitado por:
            <strong>{{ paymentOrder?.createdBy | userData | async }}</strong>
        </span>
    </div>

    <div class="header-block">
        <span class="info-label">{{ paymentOrder?.orderType | orderType }}:
            <strong>{{ orderTypePrefix }}-{{ referenceOrder?.id }}</strong>
        </span>
        <span class="info-label">Monto Total de la Orden:
            <strong>${{ paymentOrder?.totalOrderAmount | number }}</strong>
        </span>
    </div>

    <div id="packages-detail" class="content-block-section" *ngIf="showedSupplier">
        <h3 class="content-title">Proveedor</h3>
        <div>
            <div>
                <span class="info-label">Proveedor: </span>
                <strong>{{ paymentOrder?.supplierId | supplierData | async }}</strong>
            </div>
            <!-- <div><span class="info-label">Moneda: </span><strong>{{ paymentOrder?.supplierId | supplierData:'currency' | async }}</strong></div> -->
            <div>
                <span class="info-label">Razón Social: </span>
                <strong>{{ paymentOrder?.supplierId | supplierData:
                    'socialReason' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">RFC: </span>
                <strong>{{ paymentOrder?.supplierId | supplierData: 'rfc' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">Dirección Comercial:</span>
                <strong>{{ paymentOrder?.supplierId | supplierData:
                    'businessAddress' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">Dirección Fiscal:</span>
                <strong>{{ paymentOrder?.supplierId | supplierData: 'taxAddress'
                    | async
                    }}</strong>
            </div>
            <div>
                <span class="info-label">Teléfono:</span>
                <strong>{{ paymentOrder?.supplierId | supplierData: 'phone' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">email: </span>
                <strong>{{ paymentOrder?.supplierId | supplierData: 'email' |
                    async }}</strong>
            </div>
        </div>
    </div>

    <div id="packages" class="content-block">
        <h3 class="content-title">
            Paquetes de la {{ paymentOrder?.orderType | orderType }}:
            <strong class="reference-link" (click)="goToReferenceOrder()">
                {{ orderTypePrefix }}-{{ referenceOrder?.id }}
            </strong>
        </h3>
        <dx-data-grid #packagesGrid id="packagesGrid" [wordWrapEnabled]="true" [dataSource]="packages" [masterDetail]="{ enabled: false, template:
            'detail' }" [hoverStateEnabled]="false" [wordWrapEnabled]="true" [showColumnLines]="true" [showRowLines]="true" noDataText="Detalle
            de
            cotización." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false">
            <dxo-editing mode="cell" [allowUpdating]="false"></dxo-editing>
            <dxo-export [enabled]="false"></dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-search-panel [visible]="false" [highlightSearchText]="false"></dxo-search-panel>
            <dxo-paging [pageSize]="5"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="Concepto" dataField="concept" width="40%" [allowSorting]="false" [allowSearch]="true" [groupIndex]="0" alignment="left">
            </dxi-column>
            <dxi-column caption="Marca" dataField="supplierBrand" width="20%" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <dxi-column caption="Modelo" dataField="supplierModel" width="20%" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <dxi-column caption="Unidad" dataField="unit" width="6%" cellTemplate="unitTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad" dataField="requiredQuantity" [calculateCellValue]="getQty" width="10%" [allowSorting]="false" cellTemplate="quantityTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Adicionales" dataField="additionToQuantity" width="15%" cellTemplate="additionTemplate" alignment="center"></dxi-column>
            <dxi-column caption="Deductiva" dataField="decreaseToQuantity" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column>
            <dxi-column caption="P.U" dataField="unitPrice" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="P.U Real" dataField="realPrice" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column *ngIf="orderTypePrefix === 'OC'" caption="P.U Proveedor" dataField="supplierCost" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Costo inicial" dataField="costOriginal" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Subtotal" width="15%" [allowSorting]="false" cellTemplate="costTemplate" [calculateCellValue]="calculateSubtotal" alignment="center">
            </dxi-column>
            <dxi-column caption="Impuesto" width="15%" [allowSorting]="false" [calculateCellValue]="calculateTax" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Total" width="15%" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotal" alignment="center">
            </dxi-column>
            <dxo-group-panel [visible]="false">
                <!-- or "auto" -->
            </dxo-group-panel>
            <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                <span>{{ data.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'costTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'totalTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'supplierGroupTemplate'">
                {{ data.key | supplierData | async }}
            </div>
            <div *dxTemplate="let data of 'quoteTemplate'">
                <a mat-icon-button>
                    <mat-icon>file_download</mat-icon>{{ data.supplierQuoteLinkFile }}
                </a>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="requiredQuantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="supplierCost" summaryType="sum" displayFormat="{0}" [customizeText]="formatCost" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="Subtotal" summaryType="sum" displayFormat="{0}" [customizeText]="formatSubTotalGroup" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="Impuesto" summaryType="sum" displayFormat="{0}" [customizeText]="formatIvaGroup" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotalGroup" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>

                <dxi-total-item column="Subtotal" summaryType="sum" alignment="center" [customizeText]="formatSubTotal">
                </dxi-total-item>
                <dxi-total-item column="Impuesto" summaryType="sum" alignment="center" [customizeText]="formatTax">
                </dxi-total-item>
                <dxi-total-item column="Total" summaryType="sum" alignment="center" [customizeText]="formatTotal">
                </dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
    <div class="content-block2-section" *ngIf="_approvers">
        <div class="content-block-section text-center" *ngFor="let approver of
            _approvers">
            <h3 class="content-title">Firma</h3>
            <!-- <ng-container *ngIf="approver.signatureUrl; else noSignature">
        <img [src]="approver.signatureUrl" height="150px" alt="Firma aprobador" srcset="" />
      </ng-container>
      <ng-template #noSignature>
        <h3>Usuario sin firma definida</h3>
      </ng-template> -->
            <div style="height:150px;"></div>
            <div>
                <span class="info-label">Nombre: <b>{{ approver.displayName }}</b></span>
            </div>
            <br />
        </div>
    </div>
</app-page-widget>

<!-- DIALOG EVIDENCE -->
<app-dialog #invoiceDialog noPadding width="650px" [autoFocus]="false">
    <app-title>Facturas</app-title>

    <ng-template [ngIf]="(!formIsValid && formErrors.length> 0) ||
        invoices.length<2">
        <p class="text-red text-center" *ngFor="let error of formErrors">
            {{ error }}
        </p>
    </ng-template>

    <div class="margin-h">
        <mat-form-field class="width-100">
            <input matInput [formControl]="numberInvoiceFormControl" (change)="validateFormControls()" placeholder="Número de la
                    factura" type="text" required>
        </mat-form-field>

        <mat-form-field class="width-100">
            <input matInput [formControl]="costInvoiceFormControl" (change)="validateFormControls()" placeholder="Monto de la
                    factura
                    (con
                    impuesto)" type="text" required>
        </mat-form-field>
    </div>

    <div class="custom-toolbar">
        <app-button default (click)="filesUploaderPdf.openSelectionDialog()">Factura PDF
        </app-button>
        <app-button default (click)="filesUploaderXml.openSelectionDialog()">Factura XML
        </app-button>
    </div>

    <app-files-uploader class="hidden-content" #filesUploaderPdf bucket="cashbox-invoices" [accept]="['application/pdf']" (uploadFinished)="onInvoiceUpload($event)"></app-files-uploader>
    <app-files-uploader class="hidden-content" #filesUploaderXml bucket="cashbox-invoices" [accept]="['text/xml']" (uploadFinished)="onInvoiceUpload($event)"></app-files-uploader>
    <mat-list #invoicesList *ngIf="invoices?.length">
        <mat-list-item *ngFor="let ev of invoices; trackBy: trackInvoice">
            <mat-icon mat-list-icon class="reference-link" (click)="removeFile(ev)" matTooltip="Eliminar
                    Archivo">delete_forever</mat-icon>
            {{ ev.name }}
        </mat-list-item>
    </mat-list>
    <app-action-button (click)="addInvoices()">Aceptar</app-action-button>
</app-dialog>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>