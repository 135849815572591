<app-page-widget title="Nueva Requisición">
    <div class="stepper">
        <mat-horizontal-stepper [linear]="true">
            <mat-step label="{{ steps[0].label }}" [stepControl]="stepOneFormGroup">
                <form [formGroup]="stepOneFormGroup">
                    <div>
                        <button mat-raised-button color="primary" matStepperNext>
              <mat-icon>navigate_next</mat-icon>
              Siguiente
            </button>
                    </div>
                    <div class="step-one">
                        <mat-form-field class="select-type">
                            <mat-select placeholder="Seleccione el Tipo de
                Requisición" (selectionChange)="changedSelectedType()" formControlName="selectTypeCtrl" required>
                                <mat-option *ngFor="let type of types" [value]="type.value">
                                    {{ type.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
            </mat-step>
            <mat-step label="{{ steps[1].label }}" [stepControl]="stepTwoFormGroup">
                <form [formGroup]="stepTwoFormGroup">
                    <div>
                        <button mat-raised-button color="primary" matStepperPrevious>
              <mat-icon>navigate_before</mat-icon>
              Anterior
            </button>
                        <button mat-raised-button color="primary" [disabled]="!preview" (click)="openPreviewDialog()" class="margin-left-12">
              <mat-icon>visibility</mat-icon>
              Vista Previa
            </button>
                        <button mat-raised-button color="primary" [disabled]="!create ||
              !clickAllowed" (click)="createRequisition()" class="margin-left-12 pb-0">
              <mat-icon>done</mat-icon>
              Crear Requisición
            </button>
                    </div>
                    <div class="w-100 d-flex mt-30">
                        <div class="w-33">
                            <!-- <div class="mt-30">Nro. Requisición</div> -->
                            <mat-form-field class="w-90" appearance="fill">
                                <input matInput [formControl]="nroRequisitionsFormControl" placeholder="Nro. Requisición" type="text">
                            </mat-form-field>
                        </div>
                        <div class="w-33 mt-10 ml-10">
                            <button mat-raised-button color="primary" (click)="changeTypePackage('Ordinarios')">
                            Paquetes Ordinarios
                          </button>
                        </div>
                        <div class="w-33 mt-10 ml-10">
                            <button mat-raised-button color="primary" (click)="changeTypePackage('Extraordinarios')">
                            Paquetes Extraordinarios
                          </button>
                        </div>
                    </div>
                    <div class="spinner-wrapper" *ngIf="loading">
                        <mat-spinner [diameter]="60"></mat-spinner>
                    </div>
                    <div id="packagesTabGroup" class="mt-10" class="step-two" *ngIf="!loading">
                        <mat-tab-group>
                            <mat-tab label="Originales">
                                <dx-data-grid #packagesDataGrid id="original-grid-container" [dataSource]="originalPackages" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
                                    [allowColumnResizing]="true" [columnAutoWidth]="false" (onSelectionChanged)="onSelectionChangeOriginal($event)">
                                    <dxo-paging [enabled]="true" [pageSize]="5"></dxo-paging>
                                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[15, 30, 50]" [showInfo]="true">
                                    </dxo-pager>
                                    <!-- <dxo-search-panel [visible]="true" [width]="240" placeholder="Buscar..."> -->
                                    <!-- </dxo-search-panel> -->
                                    <dxo-header-filter [visible]="false"></dxo-header-filter>
                                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                                    <dxo-selection selectAllMode="page" mode="multiple" [selectAllMode]="true" [deferred]="false"></dxo-selection>
                                    <dxi-column caption="ID" dataField="packageId" alignment="left" width="10%" [allowSearch]="true"></dxi-column>
                                    <dxi-column caption="Fecha" dataField="requestDate" alignment="left" dataType="date" width="10%" cellTemplate="dateTemplate" [calculateCellValue]="calculateDateCellValue" [allowFiltering]="true"></dxi-column>
                                    <dxi-column caption="Nivel" dataField="level" alignment="left" [allowSearch]="true" width="10%"></dxi-column>
                                    <dxi-column caption="Código" dataField="code" alignment="left" [allowSearch]="true" width="15%"></dxi-column>
                                    <dxi-column caption="Concepto" dataField="concept" alignment="left" [allowSearch]="true" width="25%"></dxi-column>
                                    <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" dataType="number" [allowFiltering]="false" alignment="left" width="10%"></dxi-column>
                                    <dxi-column caption="Unidad" dataField="unit" cellTemplate="unitTemplate" alignment="center" [allowFiltering]="false" width="10%"></dxi-column>
                                    <dxi-column caption="Costo" dataField="cost" cellTemplate="costTemplate" [allowFiltering]="false" alignment="left" width="10%"></dxi-column>
                                    <dxi-column caption="En Pasivos" dataField="statusPassive" cellTemplate="passiveStatusTemplate" [allowFiltering]="false" alignment="center" width="10%"></dxi-column>
                                    <dxi-column *ngIf="canAddPassive" caption="Enviar a Pasivos" dataField="uuid" cellTemplate="passiveTemplate" [allowFiltering]="false" alignment="center" width="10%"></dxi-column>
                                    <div *dxTemplate="let data of
                    'dateTemplate'">
                                        <strong> {{ data.value | timestampToDate
                      }}</strong> -
                                        <span> hace {{ data.value | timeAgo }}</span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'quantityTemplate'" class="qty">
                                        <span>{{ data.value | number: '1.2-3' }}</span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'passiveStatusTemplate'">
                                        <span *ngIf="data.value === 2" class="icon-gray"><strong>Si</strong></span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'unitTemplate'">
                                        <span>{{ data.value }}</span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'costTemplate'" class="cost">
                                        <span>${{ data.value | number }}</span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'passiveTemplate'" class="cost">
                                        <span>
                      <i class="material-icons icon-gray"
                        matTooltip="Enviar paquete a
                        pasivos"
                        (click)="sendPassive(data)">
                        input</i></span>
                                    </div>
                                </dx-data-grid>
                            </mat-tab>

                            <mat-tab label="Restantes">
                                <dx-data-grid #remainingDataGrid id="grid-container" [dataSource]="remainingPackages" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [allowColumnReordering]="true"
                                    [allowColumnResizing]="true" [columnAutoWidth]="false" useSelectMode="true" (onSelectionChanged)="onSelectionChangeRemaining($event)">
                                    <dxo-paging [pageSize]="5"></dxo-paging>
                                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[15, 30, 50]" [showInfo]="true">
                                    </dxo-pager>
                                    <dxo-search-panel [visible]="true" [width]="240" placeholder="Buscar...">
                                    </dxo-search-panel>
                                    <dxo-header-filter [visible]="false"></dxo-header-filter>
                                    <dxo-filter-row [visible]="false"></dxo-filter-row>
                                    <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
                                    <dxi-column caption="ID" dataField="packageId" alignment="left" width="10%"></dxi-column>
                                    <dxi-column caption="Fecha" dataField="requestDate" alignment="left" width="10%" [calculateCellValue]="calculateDateCellValue" cellTemplate="dateTemplate"></dxi-column>
                                    <dxi-column caption="Nivel" dataField="level" alignment="left" width="10%"></dxi-column>
                                    <dxi-column caption="Código" dataField="code" alignment="left" width="15%"></dxi-column>
                                    <dxi-column caption="Concepto" dataField="concept" alignment="left" width="25%"></dxi-column>
                                    <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" dataType="number" alignment="left" width="10%"></dxi-column>
                                    <dxi-column caption="Unidad" dataField="unit" cellTemplate="unitTemplate" alignment="center" width="10%"></dxi-column>
                                    <dxi-column caption="Costo" dataField="cost" cellTemplate="costTemplate" alignment="left" width="10%"></dxi-column>
                                    <div *dxTemplate="let data of
                    'dateTemplate'">
                                        <strong> {{ data.value | timestampToDate
                      }}</strong> -
                                        <span> hace {{ data.value | timeAgo }}</span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'quantityTemplate'" class="qty">
                                        <span>{{ data.value | number: '1.2-3' }}</span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'unitTemplate'">
                                        <span>{{ data.value }}</span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'costTemplate'" class="cost">
                                        <span>${{ data.value | number }}</span>
                                    </div>
                                </dx-data-grid>
                            </mat-tab>

                            <mat-tab label="Sobrantes">
                                <dx-data-grid #leftoverDataGrid id="leftover-grid-container" [dataSource]="leftoverPackages" [wordWrapEnabled]="true" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [selection]="{
                  showCheckBoxesMode: 'none' }" [rowAlternationEnabled]="false" [allowColumnReordering]="false" [allowColumnResizing]="true" [columnAutoWidth]="false">
                                    <dxo-paging [pageSize]="10"></dxo-paging>
                                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
                                    </dxo-pager>

                                    <!--                  <dxi-column-->
                                    <!--                    caption="ID"-->
                                    <!--                    dataField="packageId"-->
                                    <!--                    alignment="center"-->
                                    <!--                    width="10%"-->
                                    <!--                  ></dxi-column>-->
                                    <dxi-column caption="Concepto" dataField="concept" alignment="left" width="25%"></dxi-column>
                                    <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" dataType="number" alignment="left" width="10%"></dxi-column>
                                    <dxi-column caption="Unidad" dataField="unit" cellTemplate="unitTemplate" alignment="center" width="10%"></dxi-column>
                                    <div *dxTemplate="let data of
                    'quantityTemplate'" class="qty">
                                        <span>{{ data.value | number: '1.2-3' }}</span>
                                    </div>
                                    <div *dxTemplate="let data of
                    'unitTemplate'">
                                        <span>{{ data.value }}</span>
                                    </div>
                                </dx-data-grid>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</app-page-widget>

<app-preview #previewDialog [packages]="previewPackages" [leftoverPackages]="leftoverPackages" [subTitle]="'Paquetes seleccionados
  para
  ésta Requisición'" [gridType]="'package'" (previewItems)="packagesSelectionChangedByPreview($event)">
</app-preview>