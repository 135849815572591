import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as powerbi from 'powerbi-client';

@Component({
  selector: 'report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.css']
})
export class ReportDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('powerBi', { static: false }) powerBiElement: ElementRef;
  @Input('reportId') reportId: string;
  @Input('embedUrl') embedUrl: string;
  @Input('embedToken') embedToken: string;
  accessTokenIsLoaded: boolean = false;

  models: any = powerbi.models;
  powerBiService: any = new powerbi.service.Service(
    powerbi.factories.hpmFactory,
    powerbi.factories.wpmpFactory,
    powerbi.factories.routerFactory
  );

  embedConfiguration: {
    type: string,
    id?: string
    permissions?: string,
    embedUrl?: string,
    tokenType: string,
    accessToken?: string,
    settings: {}
  } = {
      type: 'report',
      permissions: this.models.Permissions.All,
      tokenType: this.models.TokenType.Embed,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        background: this.models.BackgroundType.Transparent
      }
    };

  constructor(
  ) { }

  ngOnInit() { }

  ngAfterViewInit(): void {

    const poweBiDomElement: Element = this.powerBiElement.nativeElement;
    this.embedConfiguration.id = this.reportId;
    this.embedConfiguration.accessToken = this.embedToken;
    this.embedConfiguration.embedUrl = this.embedUrl;
    this.embedConfiguration.id = this.reportId;
    // console.log('embedConfig: ', this.embedConfiguration);
    const report = this.powerBiService.embed(poweBiDomElement, this.embedConfiguration);


  }
}
