<app-page-widget [menuItems]="widgetMenu" (refresh)="refreshData()">
    <app-title>EXT-{{ extraordinary?.uuid | idFromUuid }}</app-title>
    <app-subtitle>{{ extraordinary?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <app-action-button flat noText icon="done_all" (click)="viewApprovers()">Aprobaciones</app-action-button>
    <div class="actions-block">
        <ng-template [ngIf]="sendToApproveIsAvailable">
            <button *ngxPermissionsOnly="['extraordinaries:can_send_to_approve']" mat-raised-button class="action-button" (click)="sendToApprove()" color="primary">
        <mat-icon>call_made</mat-icon>
        Enviar a Aprobar
      </button>
        </ng-template>
        <ng-template [ngIf]="approveIsAvailable">
            <button *ngxPermissionsOnly="['extraordinaries:can_approve']" mat-raised-button class="action-button" (click)="approve()" color="primary">
        <mat-icon>done</mat-icon>
        Aprobar
      </button>
        </ng-template>
    </div>

    <br />

    <div class="header-block">
        <span class="info-label">Estado:
      <strong>{{ extraordinary?.status | extraordinaryStatus }}</strong>
    </span>
        <span class="info-label">Solicitado por:
      <app-user-data *ngIf="extraordinary" [id]="extraordinary.createdBy"></app-user-data>
    </span>
    </div>

    <div class="content-block">
        <h3 class="content-title">Extraordinarios Solicitados</h3>
        <dx-data-grid #dataGrid id="grid-container" [dataSource]="extraordinaryPacks" [showColumnLines]="false" [wordWrapEnabled]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" (onRowUpdated)="onRowUpdated($event)">
            <dxo-search-panel [visible]="displayFilters" placeholder="Buscar..."></dxo-search-panel>
            <dxo-selection selectAllMode="page" mode="single"></dxo-selection>
            <dxi-column caption="ID Paquete" dataField="packageId" [allowEditing]="false" width="10%"></dxi-column>
            <dxi-column caption="Código" dataField="code" [allowEditing]="false" width="10%"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" [allowEditing]="false" [groupIndex]="0" width="50%"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" [allowEditing]="false" width="30%"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" [allowEditing]="false" cellTemplate="unitTemplate" alignment="center" width="7%"></dxi-column>
            <dxi-column caption="Tipo" dataField="type" cellTemplate="typeTemplate" [allowEditing]="false" alignment="center" width="7%"></dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" cellTemplate="quantityTemplate" [allowEditing]="false" dataType="number" alignment="left" width="10%"></dxi-column>
            <dxi-column caption="Costo" dataField="cost" [showWhenGrouped]="false" [allowGrouping]="false" cellTemplate="costTemplate" alignment="left" width="10%" [allowEditing]="false"></dxi-column>
            <dxi-column caption="Total" [calculateCellValue]="calculateTotal" cellTemplate="totalTemplate" [allowEditing]="false" alignment="left" width="10%"></dxi-column>
            <dxi-column caption="Evidencias" dataField="evidences" cellTemplate="evidencesTemplate" [allowEditing]="false" alignment="left" width="10%"></dxi-column>

            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item>
                <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item>
                <dxi-group-item column="dataSheetLink" summaryType="sum" displayFormat="{0}" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item>
            </dxo-summary>

            <div *dxTemplate="let data of 'totalTemplate'">
                <strong>$ {{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'typeTemplate'">
                <span>{{ data.value | packageTypeModel }}</span>
            </div>
            <div *dxTemplate="let data of 'quantityTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'costTemplate'">
                <strong>$ {{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'evidencesTemplate'">
                <ng-container *ngFor="let item of data.value; let i=index">
                    <a href="{{ data.value[i] }}" target="_blank">Evidencia {{ i + 1 }}</a><br><br>
                </ng-container>
            </div>
        </dx-data-grid>
    </div>
</app-page-widget>