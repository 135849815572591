<app-page-widget [menuItems]="widgetMenu" [actionButtons]="widgetActions" (refresh)="refreshData()">
    <app-title>Avance de paquetes - {{ title }}</app-title>
    <app-subtitle></app-subtitle>
    <div class="header justify-content-flex-end" fxLayout="row" fxLayoutAlign="space-between end">
        <h4 class="px-15">Porcentaje ponderado: {{ percentagePonderado | number:'1.2-2' }}%</h4>
        <h4 class="px-15">Monto avanzado: ${{ amountAdvance | number:'1.2-2' }}</h4>
        <h3>
            <mat-checkbox class="example-margin" [(ngModel)]="requestEvidence">
                Solicitar evidencia por cada captura
            </mat-checkbox>
        </h3>
    </div>
    <!-- <ng-container *ngIf="isCreateButtonDisabled === true || isCreateButtonDisabled === false">
        <app-action-button icon="save" [disabled]="isCreateButtonDisabled" (click)="consultPackagesSelected()">ConsultPackageSelected</app-action-button>
    </ng-container> -->
    <ng-container *ngIf="isCreateButtonDisabled === true || isCreateButtonDisabled === false">
        <app-action-button icon="save" [disabled]="isCreateButtonDisabled" (click)="generateAdvance()">Guardar</app-action-button>
    </ng-container>

    <!-- <div class="header my-15" fxLayout="row">
        <h4 class="px-15">Porcentaje ponderado: {{ percentagePonderado | number:'1.2-2' }}%</h4>
        <h4 class="px-15">Monto avanzado: ${{ amountAdvance | number:'1.2-2' }}</h4>
    </div> -->

    <div class="header my-15" fxLayout="row">

        <app-select-box class="" [widthSelect]="350" [dataSource]="suppliers" displayExpr="name" (selectItem)="onSelectBoxSelection($event)" placeholder="seleccionar proveedor">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="220" [dataSource]="assignmentsList" displayExpr="id" (selectItem)="onSelectBoxSelectionAssignment($event)" placeholder="seleccionar asignación">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="220" [dataSource]="levels" displayExpr="name" (selectItem)="onSelectBoxSelectionLevel($event)" placeholder="seleccionar nivel">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="450" [dataSource]="concepts" displayExpr="name" (selectItem)="onSelectBoxSelectionConcept($event)" placeholder="seleccionar concepto">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="200" [dataSource]="advancesSort" displayExpr="advance" (selectItem)="onSelectBoxSelectionAdvance($event)" placeholder="seleccionar avance">
        </app-select-box>

    </div>

    <div class="content" *ngIf="asignmentWithPacks">
        <!-- [columnHidingEnabled]="true" -->
        <dx-data-grid #assignmentsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="asignmentWithPacks" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
            no
            existen registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)"
            (onRowValidating)="onRowValidatingData($event)">
            <dxo-paging [pageSize]="10" [pageIndex]="_assignmentsService.pageIndexWorkforce"></dxo-paging>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" fileName="lista-avances"></dxo-export>
            <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxi-column caption="ID" width="5%" [allowEditing]="false" sortOrder="desc" [allowSearch]="true" dataField="asignmentSmallId" [groupIndex]="0" groupCellTemplate="assignmentGroupTemplate" cellTemplate="assignmentGroupTemplate" width="10%" [allowEditing]="false"
                [allowSorting]="false" [allowSearch]="true" alignment="center">
            </dxi-column>
            <dxi-column caption="Ultimo avance" width="20%" [allowEditing]="false" dataType="date" cellTemplate="dateLastTemplate" dataField="dateLastAdvance" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" [allowSearch]="true" sortOrder="desc"
                alignment="center">
            </dxi-column>
            <dxi-column caption="Semana" width="15%" [allowEditing]="false" cellTemplate="weekTemplate" dataField="week" [allowSorting]="true" [allowSearch]="true" alignment="center">
            </dxi-column>
            <dxi-column caption="ID Paquete" dataField="packageId" width="20%" groupCellTemplate="packageIdGroupTemplate" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Código" dataField="code" width="13%" groupCellTemplate="codeGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" width="15%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" width="15%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" width="30%" groupCellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Nivel" cellTemplate="levelGroupTemplate" groupCellTemplate="levelGroupTemplate" dataType="string" dataField="level" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Proveedor" width="20%" dataField="supplierId" alignment="center" cellTemplate="supplierTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Capturador" width="15%" dataField="capturador" alignment="center" cellTemplate="userDataTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Avance Anterior Acumulado" width="15%" dataField="previousTotalAdvance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Avance Semanal" width="15%" dataField="advance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Avance a la Fecha" width="15%" dataField="totalAdvance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column *ngxPermissionsOnly="['advance:create']" caption="% avanzado" dataField="percentToAdvance" [calculateCellValue]="calcPercentageToAdvance" cellTemplate="percentToAdvanceTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column *ngxPermissionsOnly="['advance:create']" caption="Cantidad avanzada" [calculateCellValue]="calcQuantityToAdvance" dataField="quantityToAdvance" cellTemplate="quantityToAdvanceTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Semana a Capturar" width="15%" [allowEditing]="true" cellTemplate="weekTemplate" dataField="weekRequest" [allowSorting]="true" [allowSearch]="true" alignment="center">
            </dxi-column>
            <dxi-column caption="Detalle" dataField="quoteUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false" [allowEditing]="false"></dxi-column>

            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span>
                        <i class="material-icons icon-gray" (click)="onSelectAdvance(data.key)">
                            remove_red_eye
                        </i>
                    </span>
            </div>

            <div *dxTemplate="let data of 'advanceTemplate'">
                <span *ngIf="data.value">{{ data.value | number:'1.2-3' }} %</span>
                <span *ngIf="!data.value">0%</span>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>

            <div *dxTemplate="let data of 'dateLastTemplate'">
                <span *ngIf="data.value"><strong> {{ data.value | timestampToDate }}</strong> -</span>
                <span *ngIf="data.value"> hace {{ data.value | timeAgo }}</span>
                <span *ngIf="!data.value">--</span>
            </div>

            <div *dxTemplate="let data of 'supplierTemplate'">
                <app-supplier-data [id]="data.value"></app-supplier-data>
            </div>
            <div *dxTemplate="let data of 'userDataTemplate'">
                <app-user-data *ngIf="data.value" [id]="data.value"></app-user-data>
                <span *ngIf="!data.value">--</span>
            </div>
            <div *dxTemplate="let data of 'weekTemplate'">
                <span><strong>{{ data.value }}</strong></span>
            </div>
            <div *dxTemplate="let data of 'packageIdGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'levelGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'percentToAdvanceTemplate'">
                <span *ngIf="data.value">{{ data.value | number:'1.2-3' }}</span>
                <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                        porcentaje</span>
            </div>
            <div *dxTemplate="let data of 'quantityToAdvanceTemplate'">
                <span *ngIf="data.value">{{ data.value }}</span>
                <span *ngIf="!data.value" style="color:#c9c9c9">Introduzca
                        cantidad</span>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'codeGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'assignmentGroupTemplate'">ASG-{{ data.value }}
            </div>


            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalPackages }}</h2>
                    <span class="name">Total Paquetes</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
    <!-- <ng-container *ngIf="assignments">
            <div class="content text-center" *ngIf="assignments.length === 0">
                <h2>No posee asignaciones</h2>
            </div>
        </ng-container> -->
</app-page-widget>

<app-dialog #evidenceDialog noPadding width="650px" [autoFocus]="false">
    <app-title>Evidencias</app-title>

    <app-files-uploader #filesUploader bucket="advances-evidences" (uploadFinished)="onEvidenceUpload($event)">
    </app-files-uploader>

    <mat-nav-list #evidencesList *ngIf="evidences?.length" class="margin-v-2">
        <a *ngFor="let ev of evidences; trackBy: trackEvidence" mat-list-item target="_blank" [href]="ev.downloadUrl">
                {{ ev.name }}
            </a>
    </mat-nav-list>
</app-dialog>