<app-page-widget noPadding>
  <app-title>Plantillas de correo</app-title>
  <app-subtitle>Por favor, configure las notificaciones</app-subtitle>

  <button class="btn" mat-raised-button color="primary" type="button" (click)="newTemplate()">
    <mat-icon>message</mat-icon>
    Crear nueva plantilla
  </button>

  <button
    class="btn"
    [disabled]="!Rows?.length"
    mat-raised-button
    color="primary"
    [disabled]="!this.selectedGridItems"
    (click)="openTemplateEditDialog()"
  >
    <mat-icon>edit</mat-icon>
    Editar Plantilla
  </button>
  <!-- ============================================================== -->
  <!-- GRID TEMPLATES  -->
  <!-- ============================================================== -->
  <dx-data-grid
    #templatesGrid
    id="grid-container"
    [wordWrapEnabled]="true"
    [dataSource]="templates"
    [masterDetail]="{ enabled: false, template: 'detail' }"
    [hoverStateEnabled]="false"
    [showColumnLines]="true"
    [showRowLines]="true"
    noDataText="Actualmente no existen plantillas."
    [showBorders]="true"
    [rowAlternationEnabled]="false"
    [columnAutoWidth]="true"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [(selectedRowKeys)]="Rows"
    (onRowClick)="onGridSelection($event, Rows)"
  >
    <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxi-column
      caption="Creado por"
      width="15%"
      [allowEditing]="false"
      dataField="createdBy"
      cellTemplate="createdByTemplate"
      [allowSorting]="false"
    >
    </dxi-column>
    <dxi-column
      caption="Fecha"
      width="25%"
      [allowEditing]="false"
      dataType="date"
      cellTemplate="dateTemplate"
      dataField="createdAt"
      [calculateCellValue]=""
      [allowSorting]="true"
      sortOrder="desc"
      alignment="center"
    >
    </dxi-column>
    <dxi-column
      caption="Asunto"
      width="20%"
      [allowEditing]="false"
      dataField="messageSubject"
      [allowSorting]="false"
      alignment="left"
    >
    </dxi-column>
    <dxi-column
      caption="Tipo de notificación"
      width="20%"
      [allowEditing]="false"
      dataField="emailType"
      [allowSorting]="false"
      alignment="center"
    >
    </dxi-column>
    <div *dxTemplate="let data of 'dateTemplate'">
      <strong> {{ data.value | timestampToDate }}</strong> -
      <span> hace {{ data.value | timeAgo }}</span>
    </div>
    <div *dxTemplate="let data of 'createdByTemplate'">
      <strong> {{ data.value | userData | async }}</strong>
    </div>
  </dx-data-grid>
</app-page-widget>

<app-template-dialog
  #templateNewDialog
  [title]="'Nueva Plantilla'"
  [isNew]="true"
  [subTitle]="'Agregar plantilla'"
>
</app-template-dialog>
<app-template-dialog
  #templateEditDialog
  [title]="'Editar Template'"
  [isNew]="false"
  [selectedGridTemplate]="selectedGridTemplate"
  [subTitle]="'EDITAR TEMPLATE SUBTITULO'"
>
</app-template-dialog>
