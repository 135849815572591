<app-page-widget [loading]="loading" (refresh)="refreshData()">
    <app-title>Contratos tasa 0%</app-title>
    <app-subtitle>? Crear ?</app-subtitle>

    <mat-grid-list cols="2" rowHeight="100px">
        <mat-grid-tile>
            <app-select-box [widthSelect]="650" [dataSource]="spliers" displayExpr="socialReason" (selectItem)="onSelectBoxSelection($event)" placeholder="selecionar proveedor">
            </app-select-box>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-raised-button color="primary" [disabled]="true">
        <mat-icon>visibility</mat-icon>
        Vista Previa
      </button>
            <button mat-raised-button color="primary" (click)="validTogenerete()">
        Generar subcontrato
      </button>
        </mat-grid-tile>
    </mat-grid-list>

    <dx-data-grid #dxgrid [showBorders]="true" [wordWrapEnabled]="true" [showColumnLines]="true" [showRowLines]="true" [columnAutoWidth]="true" [wordWrapEnabled]="true" [dataSource]="gridSource" [columns]="wfColumns" (onSelectionChanged)="onGridSelection($event, dxgrid)">
        <dxo-paging [pageSize]="5"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
        </dxo-pager>
        <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <div *dxTemplate="let data of 'totalTemplate'">
            <strong *ngIf="data.value !== 0">$ {{ data.value | number: '1.2-3' }}</strong>
            <span *ngIf="data.value === 0">-</span>
        </div>
        <div *dxTemplate="let data of 'costTemplate'">
            <strong *ngIf="data.value !== 0">$ {{ data.value | number }}</strong>
            <span *ngIf="data.value === 0">-</span>
        </div>
        <div *dxTemplate="let data of 'quantityTemplate'">
            <strong *ngIf="data.value !== 0">{{ data.value | number }}</strong>
            <span *ngIf="data.value === 0">-</span>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
    </dx-data-grid>
</app-page-widget>