<app-dialog #appDialog [width]="'90%'" [height]="'90%'">
    <app-title>{{ title }}</app-title>
    <app-subtitle>{{ subtitle }}</app-subtitle>

    <div class="content">
        <!-- Budget Table -->
        <cost-center-opt-budget-table #budgetTable>
        </cost-center-opt-budget-table>

        <!-- Projection Table -->
        <cost-center-opt-projection-table #projectionTable></cost-center-opt-projection-table>
        <!-- Purchase Orders Table -->
        <cost-center-opt-purchase-orders-table #purchaseOrdersTable>
        </cost-center-opt-purchase-orders-table>
        <!-- Payment Check Orders Table -->
        <cost-center-opt-check-orders-table #paymentCheckOrdersTable>
        </cost-center-opt-check-orders-table>

    </div>

</app-dialog>