import { ForgeConfig } from '@bist-env/types';

export const forgeDevConfig: ForgeConfig = {
  hubId: 'b.63ce34e7-d392-4e64-9b90-60abe43640d9',
  // getModelsUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getModels'
  // getFoldersUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getFoldersData',
  // getTokenUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getToken',
  getModelsUrl: 'http://localhost:5000/bist-2-dev/us-central1/getModels',
  getFoldersUrl: 'http://localhost:5000/bist-2-dev/us-central1/getFoldersData',
  getTokenUrl: 'http://localhost:5000/bist-2-dev/us-central1/getToken',
};

export const forgeBrConfig: ForgeConfig = {
  hubId: 'b.63ce34e7-d392-4e64-9b90-60abe43640d9',
  getModelsUrl: 'https://us-central1-bist-br.cloudfunctions.net/getModels',
  getFoldersUrl:
    'https://us-central1-bist-br.cloudfunctions.net/getFoldersData',
  getTokenUrl: 'https://us-central1-bist-br.cloudfunctions.net/getToken',
};

export const forgeUneConfig: ForgeConfig = {
  hubId: '',
  getModelsUrl: 'https://us-central1-bist-une.cloudfunctions.net/getModels',
  getFoldersUrl:
    'https://us-central1-bist-une.cloudfunctions.net/getFoldersData',
  getTokenUrl: 'https://us-central1-bist-une.cloudfunctions.net/getToken',
};

export const forgeLiverpoolConfig: ForgeConfig = {
  hubId: '',
  getModelsUrl:
    'https://us-central1-bist-liverpool.cloudfunctions.net/getModels',
  getFoldersUrl:
    'https://us-central1-bist-liverpool.cloudfunctions.net/getFoldersData',
  getTokenUrl: 'https://us-central1-bist-liverpool.cloudfunctions.net/getToken',
};

export const forgeTrescalaConfig: ForgeConfig = {
  hubId: 'b.63ce34e7-d392-4e64-9b90-60abe43640d9',
  getModelsUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getModels',
  getFoldersUrl:
    'https://us-central1-bist-2-dev.cloudfunctions.net/getFoldersData',
  getTokenUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getToken',
};

export const forgeIcpcConfig: ForgeConfig = {
  hubId: 'b.63ce34e7-d392-4e64-9b90-60abe43640d9',
  getModelsUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getModels',
  getFoldersUrl:
    'https://us-central1-bist-2-dev.cloudfunctions.net/getFoldersData',
  getTokenUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getToken',
};

export const forgeSandboxConfig: ForgeConfig = {
  hubId: 'b.63ce34e7-d392-4e64-9b90-60abe43640d9',
  getModelsUrl:
    'https://us-central1-bist-sandbox2.cloudfunctions.net/getModels',
  getFoldersUrl:
    'https://us-central1-bist-sandbox2.cloudfunctions.net/getFoldersData',
  getTokenUrl: 'https://us-central1-bist-sandbox2.cloudfunctions.net/getToken',
};

export const forgeConfig: ForgeConfig = {
  hubId: 'b.63ce34e7-d392-4e64-9b90-60abe43640d9',
  getModelsUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getModels',
  getFoldersUrl:
    'https://us-central1-bist-2-dev.cloudfunctions.net/getFoldersData',
  getTokenUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getToken',
};
