<app-page-widget noPadding>
    <app-title>REM-{{ remittance?.uuid | idFromUuid }}</app-title>
    <app-subtitle>{{ remittance?.createdAt | timestampToDate:'long' }}</app-subtitle>
    <div class="header-block">
        <span class="info-label">Monto Pagado:
            <strong>${{ remittance?.remittanceAmount | number : '1.2-2' }}</strong>
        </span>
        <span class="info-label">Creada por:
            <app-user-data *ngIf="remittance"
                [id]="remittance.createdBy"></app-user-data>
        </span>
    </div>

    <div class="content-block">
        <h3 class="content-title">Ordenes:</h3>
        <dx-data-grid #grid id="grid-container" [dataSource]="orders" [showColumnLines]="false" [wordWrapEnabled]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false">

            <dxo-search-panel [visible]="false"></dxo-search-panel>
            <dxo-selection selectAllMode="page" mode="single"></dxo-selection>
            <dxi-column caption="Orden de Pago" dataField="id" [allowEditing]="false" alignment="center" width="10%"></dxi-column>
            <dxi-column caption="Orden" dataField="id" [calculateCellValue]="getOrderCellValue" [allowEditing]="false" alignment="center" width="10%"></dxi-column>
            <dxi-column caption="Monto Pagado" dataField="amountPaid" [allowEditing]="false" alignment="center" cellTemplate="paidAmountTemplate" width="20%"></dxi-column>
            <dxi-column caption="Por Pagar" dataField="amountToPay" [allowEditing]="false" alignment="center" cellTemplate="passiveAmountTemplate" width="20%"></dxi-column>
            <dxi-column caption="Proveedor" dataField="supplierId" [allowEditing]="false" alignment="center" cellTemplate="supplierTemplate" width="50%"></dxi-column>

            <div *dxTemplate="let paidAmount of 'paidAmountTemplate'">
                <span *ngIf="paidAmount?.value">${{paidAmount.value | number :
                    '1.2-2'}}</span>
            </div>
            <div *dxTemplate="let passiveAmount of 'passiveAmountTemplate'">
                <span *ngIf="passiveAmount?.value">${{passiveAmount.value |
                    number :
                    '1.2-2'}}</span>
            </div>
            <div *dxTemplate="let supplier of 'supplierTemplate'">
                <span *ngIf="supplier?.value">{{ supplier.value | supplierData |
                    async
                    }}</span>
            </div>
        </dx-data-grid>

    </div>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess"></app-loading-indicator>