<form #formWrapper
      novalidate
      [attr.disabled]="disabled"
      [formGroup]="formGroup"
      (ngSubmit)="onSave()">
  <formly-form #formlyForm
               [form]="formGroup"
               [options]="options"
               [fields]="internalFieldsConfig"
               [model]="model"
               (modelChange)="onChange($event)">
  </formly-form>
</form>

<ng-template [ngIf]="displayButtons">
  <div *ngIf="canReset || !noSaveButton || !noCancelButton" class="buttons-bar">
    <ng-template [ngIf]="!noSaveButton">
      <app-button [icon]="saveButtonIcon"
                  [text]="saveButtonText"
                  [disabled]="disabled || !formGroup.valid"
                  (click)="onSave()">
      </app-button>
    </ng-template>
    <ng-template [ngIf]="!noCancelButton">
      <app-button flat
                  [icon]="cancelButtonIcon"
                  [text]="cancelButtonText"
                  [disabled]="disabled"
                  (click)="onCancel()">
      </app-button>
    </ng-template>
    <ng-template [ngIf]="canReset">
      <app-button flat
                  noText
                  [icon]="resetButtonIcon"
                  [tooltip]="resetButtonText"
                  [disabled]="disabled"
                  (click)="onReset()">
      </app-button>
    </ng-template>
  </div>
</ng-template>
