<ng-template #flatSelect>
  <dx-tag-box #selectTagBox
              [items]="dataSource"
              [width]="widthSelect"
              [searchEnabled]="searchEnabled"
              [displayExpr]="displayExpr"
              [valueExpr]="valueExpr"
              [placeholder]="placeholder"
              applyValueMode="useButtons"
              [showSelectionControls]="showSelectionControls"
              (onValueChanged)="onChangueTagSelection($event)"
              [acceptCustomValue]="false">
  </dx-tag-box>
</ng-template>
<dx-select-box #selectBox
               [items]="dataSource"
               [width]="widthSelect"
               deferRendering="true"
               [searchEnabled]="true"
               [displayExpr]="displayExpr"
               [valueExpr]="valueExpr"
               (onValueChanged)="onChangueSelection($event)"
               [placeholder]="placeholder">
</dx-select-box>

<!-- (onValueChanged)="onChangueTagSelection($event)" -->
