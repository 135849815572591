<app-page-widget #pageWidget (refresh)="refreshData()">
  <app-title>Presupuestos</app-title>
  <app-subtitle>Listado</app-subtitle>

  <app-action-button
    icon="file_upload"
    tooltip="Importar desde archivo Excel"
    (click)="uploadDialog.open()"
    >Importar</app-action-button
  >

  <app-action-button flat noText icon="filter_list" (click)="displayFilters = !displayFilters">
    Filtros
  </app-action-button>

  <dx-data-grid
    noDataText="Sin datos para mostrar."
    [dataSource]="budgets"
    [wordWrapEnabled]="true"
    [showBorders]="true"
    [showColumnLines]="true"
    [showRowLines]="true"
  >
    <dxo-filter-row [visible]="displayFilters"></dxo-filter-row>
    <dxo-search-panel [visible]="displayFilters" placeholder="Buscar..."></dxo-search-panel>
    <dxo-paging [pageSize]="10"></dxo-paging>

    <dxi-column width="5%" caption="ID" dataField="id"></dxi-column>
    <dxi-column
      width="20%"
      caption="Fecha"
      dataField="createdAt"
      alignment="left"
      cellTemplate="dateTpl"
    >
    </dxi-column>
    <dxi-column caption="Usuario" dataField="createdBy" cellTemplate="createdByTpl"> </dxi-column>
    <dxi-column width="8%" caption="Registros" dataField="records"></dxi-column>
    <dxi-column width="8%" caption="Nuevos" dataField="inserts"></dxi-column>
    <dxi-column width="8%" caption="Actualizados" dataField="updates"></dxi-column>
    <dxi-column
      width="15%"
      caption="Estado"
      dataField="status"
      alignment="right"
      cellTemplate="statusTpl"
    >
    </dxi-column>
    <dxi-column
      width="5%"
      caption="Ver"
      dataField="downloadUrl"
      alignment="center"
      cellTemplate="downloadTpl"
    >
    </dxi-column>

    <div *dxTemplate="let data of 'dateTpl'">
      <app-timeago-date [timestamp]="data.value"></app-timeago-date>
    </div>
    <div *dxTemplate="let data of 'createdByTpl'">
      <app-user-data [id]="data.value"></app-user-data>
    </div>
    <div *dxTemplate="let data of 'statusTpl'">
      {{ data.value == 2 ? 'TERMINADO' : 'Procesando: ' + data.data.percentage + '%' }}
    </div>
    <div *dxTemplate="let data of 'downloadTpl'">
      <a target="_blank" [href]="data.value" matTooltip="Descargar">
        <mat-icon style="font-size: 20px;">cloud_download</mat-icon>
      </a>
    </div>
  </dx-data-grid>
</app-page-widget>

<app-dialog #uploadDialog noPadding width="550px">
  <app-title>Importar presupuesto</app-title>

  <app-files-uploader
    bucket="excel-budgets"
    [accept]="[
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel'
    ]"
    (uploadStarted)="uploadDialog.togglePerformingAction(true)"
    (uploadFinished)="onUploadFinished($event)"
  >
  </app-files-uploader>
</app-dialog>
