<app-page-widget (refresh)="refreshData()">

    <div class="stepper">
        <mat-horizontal-stepper [linear]="true">
            <mat-step label="Proveedores" [stepControl]="stepOneFormGroup">
                <form [formGroup]="stepOneFormGroup">
                    <div>
                        <button mat-raised-button color="primary" matStepperNext>
          <mat-icon>navigate_next</mat-icon>
          Siguiente
        </button>
                    </div>
                    <div class="step-one">
                        <mat-form-field class="select-type">
                            <mat-select placeholder="Seleccione el Proveedor" (selectionChange)="changedSelectedSupplier()" formControlName="selectTypeCtrl" required>
                                <mat-option *ngFor="let type of suppliers" [value]="type.uuid">
                                    {{ type.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
            </mat-step>
            <mat-step label="Listado">
                <app-title>Listado</app-title>
                <app-subtitle>Click para seleccionar</app-subtitle>

                <app-action-button flat noText icon="filter_list" (click)="displayFilters = !displayFilters">Mostrar/Ocultar filtros</app-action-button>

                <!-- ============================================================== -->
                <!-- GRID QUOTES  -->
                <!-- ============================================================== -->
                <dx-data-grid #quoteGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="dataSource" [masterDetail]="{ enabled: false, template: 'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no existen cotizaciones."
                    [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" (onSelectionChanged)="onGridSelection($event)">
                    <dxo-paging [pageSize]="5"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
                    </dxo-pager>
                    <dxo-export [enabled]="true" fileName="lista-cotizaciones"></dxo-export>
                    <dxo-filter-row [visible]="displayFilters"></dxo-filter-row>
                    <dxo-search-panel placeholder="Buscar..." [visible]="displayFilters"></dxo-search-panel>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>

                    <dxi-column caption="ID" dataField="quoteId" width="5%" cellTemplate="quoteIdTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center"></dxi-column>
                    <dxi-column caption="Fecha" dataField="createdAt" width="20%" cellTemplate="dateTemplate" [allowEditing]="false" dataType="date" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" sortOrder="desc" alignment="left"></dxi-column>
                    <dxi-column caption="Solicitado por" dataField="createdBy" width="50%" cellTemplate="createdByTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false">
                    </dxi-column>
                    <dxi-column caption="Estado" dataField="status" width="10%" cellTemplate="quoteStatusTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center"></dxi-column>

                    <div *dxTemplate="let data of 'quoteIdTemplate'">
                        <strong>{{ data.value | idFromUuid }}</strong>
                    </div>
                    <div *dxTemplate="let data of 'dateTemplate'">
                        <strong> {{ data.value | timestampToDate }}</strong> -
                        <span> hace {{ data.value | timeAgo }}</span>
                    </div>
                    <div *dxTemplate="let data of 'createdByTemplate'">
                        <strong>{{ data.value | userData | async }}</strong>
                    </div>
                    <div *dxTemplate="let data of 'quoteStatusTemplate'">
                        <span>{{ data.value | quoteStatus }}</span>
                    </div>
                </dx-data-grid>
            </mat-step>
        </mat-horizontal-stepper>
    </div>



</app-page-widget>