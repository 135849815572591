<app-page-widget [menuItems]="widgetMenu" [actionButtons]="widgetActions" (refresh)="refreshData()">
    <app-title>Seleccione o cree una Asignación</app-title>
    <app-subtitle></app-subtitle>

    <app-action-button icon="playlist_add" (click)="newAssignment()">Crear</app-action-button>
    <app-menu-item icon="playlist_add" (click)="newAssignment()">Crear</app-menu-item>

    <div class="header my-15" fxLayout="row">
        <app-select-box class="select ml-15" [widthSelect]="400" [dataSource]="suppliers" valueExpr="uuid" displayExpr="name" value="supplierId" (selectItem)="onSelectBoxSelectionSuppliers($event)" placeholder="Proveedor">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="130" [dataSource]="nroAssignmentsList" displayExpr="id" (selectItem)="onSelectAssignment($event)" placeholder="Asignación">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="150" [dataSource]="statusAssignment" displayExpr="TypeText" (selectItem)="onSelectAssignmentStatus($event)" placeholder="Estatus">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="230" [dataSource]="contractsList" displayExpr="nroContract" (selectItem)="onSelectAssignment($event)" placeholder="Contrato">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="200" [dataSource]="totalAssignmentsList" displayExpr="totalAssignmentFormat" (selectItem)="onSelectAssignment($event)" placeholder="Monto de contrato">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="150" [dataSource]="typePackages" displayExpr="TypeText" (selectItem)="onSelectTypePackages($event)" placeholder="Tipo">
        </app-select-box>
    </div>

    <ng-container *ngIf="assignmentSelected">

        <h3 class="content-title mb-0 mt-5">ASG-{{ assignmentSelected?.assignmentId }}</h3>
        <h5 class="content-title">{{ assignmentSelected?.assignmentCreatedAt | timestampToDate: 'long' }}</h5>
        <div class="header-block d-flex mt-5">
            <span class="total-label">Asignación original:
                <strong>${{ assignmentTotal | number: '1.2-2' }}</strong>
            </span>
            <span class="total-label">Deductiva de la asignación:
                <strong>${{ decreaseContract | number: '1.2-2' }}</strong>
            </span>
            <span class="total-label">Total asignado:
                <strong>${{ totalWithDecrease | number: '1.2-2' }}</strong>
            </span>
            <span class="info-label">Estatus:
                <strong>{{ assignmentSelected?.assignmentStatus | assignmentStatus }}</strong>
            </span>
            <span class="info-label">Solicitado por:
                <app-user-data *ngIf="assignmentSelected" [id]="assignmentSelected.assignmentCreatedBy"></app-user-data>
            </span>
            <div class="actions-block">
                <button class="ml-5" mat-raised-button color="primary" (click)="viewAccountStatus()">
                Ver estado de cuenta
              </button>
            </div>
            <!-- <span *ngIf="assignmentSelected" class="info-label d-flex align-items-center">Estado de cuenta:
              <button class="ml-5" mat-raised-button color="primary" (click)="viewAccountStatus()">
                Ver estado de cuenta
              </button>
            </span> -->
        </div>

        <div class="content-block2-section">
            <div id="packages-detail" class="content-block-section">
                <h3 class="content-title">Proveedor Asignado</h3>
                <div>
                    <div>
                        <span class="info-label">Razón Social: </span>
                        <strong>{{ supplier?.socialReason }}</strong>
                    </div>
                    <div>
                        <span class="info-label">RFC: </span>
                        <strong>{{ supplier?.rfc }}</strong>
                    </div>
                    <div>
                        <span class="info-label">Dirección Comercial:</span>
                        <strong>{{ supplier?.businessAddress }}</strong>
                    </div>
                    <div>
                        <span class="info-label">Dirección Fiscal:</span>
                        <strong>{{ supplier?.taxAddress }}</strong>
                    </div>
                    <div>
                        <span class="info-label">Teléfono:</span>
                        <strong>{{ supplier?.phone }}</strong>
                    </div>
                    <div>
                        <span class="info-label">email: </span>
                        <strong>{{ supplier?.email }}</strong>
                    </div>
                </div>
            </div>
            <div class="content-block-section">
                <h3 class="content-title">Documentos</h3>
                <table class="p-10">
                    <tr class="text-center">
                        <td style='border: 1px solid'>
                            <strong>Contrato ordinario</strong>
                        </td>
                        <td style='border: 1px solid'>
                            <strong>Convenio modificatorio</strong>
                        </td>
                        <td style='border: 1px solid'>
                            <strong>Fianza cumplimiento</strong>
                        </td>
                        <td style='border: 1px solid'>
                            <strong>Fianza de garantìa y vicios ocultos</strong>
                        </td>
                        <td style='border: 1px solid'>
                            <strong>Fianza anticipo</strong>
                        </td>

                    </tr>
                    <tr>
                        <td style='border: 1px solid'>
                            <ng-container *ngFor="let item of assignmentSelected.contract">
                                <a class="ml-5" target="_blank" [href]="item.downloadUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </td>
                        <td style='border: 1px solid' *ngIf="assignmentSelected.documents">
                            <ng-container *ngFor="let item of assignmentSelected.documents">
                                <a *ngIf="item.type === 'Convenio modificatorio'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </td>
                        <td style='border: 1px solid' *ngIf="assignmentSelected.documents">
                            <ng-container *ngFor="let item of assignmentSelected.documents">
                                <a *ngIf="item.type === 'Fianza cumplimiento'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </td>
                        <td style='border: 1px solid' *ngIf="assignmentSelected.documents">
                            <ng-container *ngFor="let item of assignmentSelected.documents">
                                <a *ngIf="item.type === 'Fianza de garantìa y vicios ocultos'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </td>
                        <td style='border: 1px solid' *ngIf="assignmentSelected.documents">
                            <ng-container *ngFor="let item of assignmentSelected.documents">
                                <a *ngIf="item.type === 'Fianza anticipo'" class="ml-5" target="_blank" [href]="item.downloadUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </td>
                    </tr>
                </table>
            </div>
            <br />
        </div>
    </ng-container>


    <div class="grid-container-div" *ngIf="assignments.length > 0">
        <dx-data-grid #assignmentsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="assignments" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
        no
        existen registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="true" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">
            <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
            <dxo-paging [pageSize]="10" [pageIndex]="_assignmentsService.pageIndexSubcontracts"></dxo-paging>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" fileName="lista-asignaciones-subcontratos"></dxo-export>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="ID" dataField="assignmentId" width="5%" sortOrder="desc" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false" alignment="center" [groupIndex]="0" groupCellTemplate="assignmentGroupTemplate" cellTemplate="assignmentGroupTemplate">
            </dxi-column>
            <dxi-column caption="Partida" dataField="departure" sortOrder="desc" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false" alignment="center" groupCellTemplate="departureTemplate" cellTemplate="departureTemplate">
            </dxi-column>
            <dxi-column caption="Contrato" dataField="assignmentContract" sortOrder="desc" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false" alignment="center" groupCellTemplate="assignmentGroupContractTemplate" cellTemplate="assignmentGroupContractTemplate">
            </dxi-column>
            <dxi-column caption="Fecha" width="10%" dataField="assignmentCreatedAt" [calculateCellValue]="calculateDateCellValue" [allowSearch]="true" [allowEditing]="false" dataType="date" cellTemplate="dateTemplate" [allowSorting]="true" sortOrder="desc" alignment="center">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" width="30%" [allowEditing]="false" [allowSorting]="false" alignment="left"></dxi-column>
            <dxi-column caption="Estatus" dataField="assignmentStatus" width="15%" cellTemplate="statusTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false" [allowFiltering]="true" alignment="center" [calculateCellValue]="customizeStatusLabel">
            </dxi-column>
            <dxi-column caption="Proveedor" dataField="supplierId" groupCellTemplate="supplierTemplate" cellTemplate="supplierTemplate" width="30%" [allowSearch]="true" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Solicitante" dataField="assignmentCreatedBy" cellTemplate="userDataTemplate" width="25%" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Monto" width="15%" dataType="number" dataField="totalAssignment" cellTemplate="amountTemplate" [calculateCellValue]="calculateMonto" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [allowSearch]="true" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
            <dxi-column caption="Vista Previa" dataField="assignmentUuid" cellTemplate="previewButtonTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Detalle" dataField="assignmentUuid" cellTemplate="ccButtonTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span><i class="material-icons icon-gray"
                        (click)="detailAssignment(data.key)">
                        remove_red_eye
                    </i></span>
            </div>
            <div *dxTemplate="let data of 'previewButtonTemplate'">
                <span><i class="material-icons icon-green"
                        (click)="previewDetailAssignment(data.key)">
                        <span class="material-symbols-outlined">
                          preview
                          </span>
                </i>
                </span>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="totalAssignment" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>
            <!-- <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="supplierId" displayFormat="{0} adfsdfds" [customizeText]="supplierText" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary> -->
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>
            <div *dxTemplate="let data of 'statusTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'supplierTemplate'">
                <strong>{{ data.value | supplierData | async }}</strong>
            </div>
            <div *dxTemplate="let data of 'userDataTemplate'">
                <app-user-data [id]="data.value"></app-user-data>
            </div>
            <div *dxTemplate="let data of 'amountTemplate'">
                <span>{{ data.value | number:'1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'assignmentGroupTemplate'">ASG-{{ data.value }}
            </div>
            <div *dxTemplate="let data of 'assignmentGroupContractTemplate'">
                <span *ngIf="data.value === ''"></span>
                <span *ngIf="data.value !== ''">{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'departureTemplate'">
                <span *ngIf="data.value === ''"></span>
                <span *ngIf="data.value !== ''">{{ data.value }}</span>
            </div>
            <!-- <div *dxTemplate="let data of 'approverRemaining'">
                <ng-container *ngIf="data.value.length> 0">
                    <ul class="approversRemaining">
                        <li *ngFor="let item of data.value">
                            <app-user-data [id]="item[0]"></app-user-data>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                    <span>Todos los usuarios han aprobado</span>
                </ng-container>
                <ng-container *ngIf="data.value === ''">
                    <span>No se ha enviado a aprobación</span>
                </ng-container>
            </div> -->
            <div *dxTemplate="let data of 'approverRemaining'">
                <ng-container>
                    <ul class="approversRemaining">
                        <li *ngFor="let item of data.value">
                            <app-user-data *ngIf="item[0].length >= 2" [id]="item[0]"></app-user-data>
                            <span *ngIf="item[0].length === 1">{{ item }}</span>
                        </li>
                    </ul>
                </ng-container>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalAssignments }}</h2>
                    <span class="name">Total Asignaciones</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
    <div class="grid-container-div text-center" *ngIf="assignments.length === 0">
        <h2>No posee asignaciones</h2>
    </div>

</app-page-widget>

<app-account-status #previewDialog [assignmentData]="assignmentSelected" [supplier]="supplier" [subTitle]="'Estado de cuenta'" [gridType]="'package'">
</app-account-status>