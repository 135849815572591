<app-page-widget (refresh)="refreshData()">
    <app-title>Indirectos</app-title>
    <app-subtitle>Listado. Click para ver detalles.</app-subtitle>
    <app-action-button flat noText icon="filter_list" (click)="displayFilters=
        !displayFilters">
        Filtros
    </app-action-button>

    <!-- ============================================================== -->
    <!-- GRID INDIRECTS REQUISITIONS -->
    <!-- ============================================================== -->
    <dx-data-grid #indirectsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="requisitionList" [masterDetail]="{ enabled: false,
        template: 'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
        no existen requisiciones de indirectos." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" [(selectedRowKeys)]="rows" (onExporting)="onExporting($event)"
        (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">
        <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-paging [pageSize]="10" [pageIndex]="_requisitionsService.pageIndex"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
        <dxo-export [enabled]="true" fileName="lista-indirectos"></dxo-export>
        <dxi-column caption="ID" width="5%" dataField="id" [groupIndex]="0" alignment="center" [allowSorting]="false" [allowEditing]="false" [allowFiltering]="true">
        </dxi-column>
        <dxi-column caption="Fecha" width="15%" dataType="date" cellTemplate="dateTemplate" dataField="createdAt" sortOrder="desc" alignment="center" [allowSorting]="true" [allowEditing]="false" [allowFiltering]="true">
        </dxi-column>
        <dxi-column caption="Creado por" width="50%" dataField="createdBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Estado" width="10%" dataField="status" cellTemplate="statusCellTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
        </dxi-column>
        <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
        <dxi-column caption="Detalle" dataField="uuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span><i class="material-icons icon-gray"
            (click)="onGridSelection(data)">
            remove_red_eye
          </i></span>
        </div>
        <div *dxTemplate="let data of 'quantityTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <app-timeago-date [timestamp]="data.value"></app-timeago-date>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <strong> {{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'statusCellTemplate'">
            <strong> {{ data.value | requisitionStatus }}</strong>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{totalCount}}</h2>
                <span class="name">Total Indirectos</span>
            </div>
        </div>
    </dx-data-grid>
</app-page-widget>