<app-dialog #dialog>
    <app-title>Datos de Proveedor</app-title>

    <ng-template [ngIf]="data">
        <p *ngIf="data.name">Nombre: <b [innerText]="data.name"></b></p>
        <p *ngIf="data.socialReason">Razón Social: <b [innerText]="data.socialReason"></b></p>
        <p *ngIf="data.type">Tipo: <b [innerText]="data.type"></b></p>
        <p *ngIf="data.contactName">Contacto: <b [innerText]="data.contactName"></b></p>
        <p *ngIf="data.phone">Teléfono: <b [innerText]="data.phone"></b></p>
        <p *ngIf="data.email">E-Mail: <b [innerText]="data.email"></b></p>
        <p *ngIf="data.activity">Actividad: <b [innerText]="data.activity"></b></p>
        <p *ngIf="data.businessAddress">Dirección: <b [innerText]="data.businessAddress"></b></p>
        <p *ngIf="data.taxAddress">Dirección de Facturación: <b [innerText]="data.taxAddress"></b></p>
        <p *ngIf="data.rfc">RFC: <b [innerText]="data.rfc"></b></p>
        <p *ngIf="data.currency">Moneda: <b [innerText]="data.currency"></b></p>
        <br>
    </ng-template>
</app-dialog>

<a matTooltip="Click para ver detalles" [innerText]="data?.name || data?.contactName || data?.email || ''" (click)="dialog.open()">
</a>