<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Entradas</app-title>
    <app-subtitle>Listado</app-subtitle>
    <app-action-button icon="input" routerLink="/storage/list-entries">Dar Entrada
    </app-action-button>
    <div class="grid-container-div padding-h padding-bottom-2">
        <div class="padding-v margin-v color-info">
            Si desea realizar una exportación a excel de la información sin agrupaciones, por favor seleccione <span>Sin Orden</span>.
        </div>
        <dx-data-grid #remissionsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="remissions" [masterDetail]="{
            enabled: false, template:
            'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="No hay registros" [showBorders]="false" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false"
            (onToolbarPreparing)="onToolbarPreparing($event)">
            <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
            <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false">
            </dxo-selection>
            <dxo-export [enabled]="true" fileName="lista-remisiones"></dxo-export>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column alignment="center" caption="ID" dataField="remissionId" groupCellTemplate="remissionIdTemplate" cellTemplate="remissionIdTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column alignment="center" caption="OC" dataField="purchaseOrderId" groupCellTemplate="pOGroupTemplate" cellTemplate="pOGroupTemplate" [groupIndex]="0" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Fecha" dataField="dateRemission" dataType="date" cellTemplate="dateTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="true" [calculateCellValue]="calculateDateCellValue">
            </dxi-column>
            <dxi-column caption="Estado" dataField="statusRemission" cellTemplate="statusTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Origen" dataField="origin" cellTemplate="originTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Destino" dataField="warehouse" cellTemplate="warehousesTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Enviado" cellTemplate="quantitySentTemplate" dataField="quantitySend" alignment="center">
            </dxi-column>
            <dxi-column caption="Recibido" cellTemplate="quantitySentTemplate" dataField="quantityReceived" alignment="center">
            </dxi-column>
            <dxi-column caption="Por surtir" cellTemplate="quantityLeftToSendTemplate" [allowEditing]="false" [allowSearch]="true" dataField="quantityToLeftSend" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Detalle" dataField="remissionUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <dxi-column *ngIf="canDelete" dataField="uuid" caption="Eliminar" cellTemplate="deleteEntry" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <!-- <dxi-column caption="Cambio" dataField="remissionUuid" cellTemplate="ccButtonChangeTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column> -->
            <!-- <dxi-column caption="Entrada" dataField="remissionUuid" cellTemplate="ccButtonEntryTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column> -->
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <button mat-icon-button class="material-icons icon-gray" matTooltip="Eliminar" (click)="detailRemission(data.key)">
                    <mat-icon>remove_red_eye</mat-icon>
                </button>
                <!-- <span>
          <i
            class="material-icons icon-gray"
            matTooltip="Detalle"
            (click)="detailRemission(data.key)">
            remove_red_eye</i></span> -->
            </div>
            <!-- <div *dxTemplate="let data of 'ccButtonChangeTemplate'">
                <span *ngIf="data.key.quantityReceived> 0; else noChange">
                    <i
                        class="material-icons icon-gray"
                        matTooltip="Cambio de almacen"
                        (click)="changeWarehouse(data.key)">
                        shuffle</i></span>
                <ng-template #noChange>
                    --
                </ng-template>
            </div> -->
            <!-- <div *dxTemplate="let data of 'ccButtonEntryTemplate'">
        <span>
          <i class="material-icons icon-gray" matTooltip="Entrada" (click)="entryRemission(data)">
            input</i
          ></span
        >
      </div> -->
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="quantitySend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityReceived" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityToLeftSend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>

                <dxi-total-item column="quantitySend" [customizeText]="formatFooterQuantity" summaryType="sum">
                </dxi-total-item>
                <dxi-total-item column="quantityReceived" [customizeText]="formatFooterQuantity" summaryType="sum">
                </dxi-total-item>
                <!-- <dxi-total-item column="quantityToLeftSend" summaryType="sum">
                </dxi-total-item> -->
            </dxo-summary>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>
            <div *dxTemplate="let data of 'originTemplate'">
                <ng-container *ngIf="data.value; else noWarehouse">
                    <app-supplier-data [id]="data.value"></app-supplier-data>
                    <app-warehouse-data [id]="data.value"></app-warehouse-data>
                </ng-container>
                <ng-template #noWarehouse>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'deleteEntry'">
                <button mat-icon-button color="warn" (click)="deleteEntry(data.key)">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>
            <div *dxTemplate="let data of 'pOGroupTemplate'">OC-{{ data.value }}</div>
            <div *dxTemplate="let data of 'statusTemplate'">
                <strong>{{ data.value | remissionStatus }}</strong>
            </div>
            <div *dxTemplate="let data of 'warehousesTemplate'">
                <ng-container *ngIf="data.value; else noWarehouse">
                    <app-warehouse-data [id]="data.value"></app-warehouse-data>
                </ng-container>
                <ng-template #noWarehouse>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'receiverTemplate'">
                <ng-container *ngIf="data.value; else noReceiver">
                    <strong>{{ data.value }}</strong>
                </ng-container>
                <ng-template #noReceiver>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantitySentTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'quantityApprovedTemplate'">
                <ng-container *ngIf="data.value; else noQuantityApproved">
                    <span> {{ data.value | number: '1.2-2' }}</span>
                </ng-container>
                <ng-template #noQuantityApproved>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
                <ng-container *ngIf="data.value; else noQuantityLeft">
                    {{ data.value | number: '1.2-2' }}
                </ng-container>
                <ng-template #noQuantityLeft>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalRemissions }}</h2>
                    <span class="name">Total Remisiones</span>
                </div>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'remissionIdTemplate'">REM-{{ data.value }}</div>
        </dx-data-grid>
    </div>
</app-page-widget>

<app-dialog #formDialog width="35%">
    <ng-container>
        <app-title>Cambio de almacen</app-title>

        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-top-2" *ngIf="warehouses">
                <div class="content-block margin-top-0 width-500">
                    <mat-select placeholder="Seleccione el almacen destino" (selectionChange)="onChangedWarehouse($event)">
                        <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.uuid">
                            {{ warehouse.name }}
                        </mat-option>
                    </mat-select>
                </div>
                <button class="float-right" [disabled]="disabled" mat-flat-button (click)="saveData()" color="primary">
                    Guardar
                </button>
            </div>
        </div>
    </ng-container>
</app-dialog>