<rmd-loading-indicator *ngIf="readingFile" bar></rmd-loading-indicator>
<rmd-loading-indicator *ngIf="importing" bar></rmd-loading-indicator>

<!-- TODO:
<app-files-selector></app-files-selector>
-->
<input
  *ngIf="!importing"
  #fileSelector
  type="file"
  multiple="false"
  [disabled]="readingFile"
  (change)="setDataToImport(fileSelector.files[0])"
/>

<app-button *ngIf="!importing" icon="file_upload" [text]="'Importar'" (click)="startImport()">
</app-button>

<!-- <app-button *ngIf="importing" [text]="'Detener'" icon="close" warn (click)="stopImport()">
</app-button> -->

<h4 *ngIf="done">Hecho!</h4>
