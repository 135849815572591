import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BudgetCurvaSComponent } from './budget-curva-s/budget-curva-s.component';
import { ConglomeratesBudgetComponent } from './conglomerates-budget/conglomerates-budget.component';
import { MaterialConglomeratesComponent } from './material-conglomerates/material-conglomerates.component';
import { TotalProjectBudgetComponent } from './total-project-budget/total-project-budget.component';

export const PROJECT_BUDGET_REPORT_PAGES_ROUTING: Routes = [
  {
    path: 'project-budget',
    component: TotalProjectBudgetComponent
  },
  {
    path: 'materials-conglomerate',
    component: MaterialConglomeratesComponent
  },
  {
    path: 'conglomerates-budget',
    component: ConglomeratesBudgetComponent
  },
  {
    path: 'budget-curva-s',
    component: BudgetCurvaSComponent
  }
];
export const ProjectBudgetReportsRoutingModule: ModuleWithProviders<any> = RouterModule.forChild(
  PROJECT_BUDGET_REPORT_PAGES_ROUTING
);
