<app-page-widget (refresh)="refreshData()">
    <app-title>Estimaciones</app-title>
    <app-subtitle>Seleccione para ver detalles</app-subtitle>

    <app-action-button icon="playlist_add" (click)="newEstimate()">Crear</app-action-button>
    <!-- <div class="selectProv" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <app-select-box class="select" [widthSelect]="400" [dataSource]="selectStatus" displayExpr="TypeText" (selectItem)="onSelectBoxSelectionEstatus($event)" placeholder="Seleccionar estado">
            </app-select-box>
        </div>
    </div> -->

    <!-- ============================================================== -->
    <!-- GRID Estimates                                                 -->
    <!-- ============================================================== -->
    <!-- <ng-container *ngIf="_estimatesService.pageIndex >= 0"> -->

    <dx-data-grid #estimateGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="estimates" [masterDetail]="{ enabled: false, template:
        'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no éxisten cotizaciones." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [(selectedRowKeys)]="rows" (onExporting)="onExporting($event)" (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">
        <dxo-paging [pageSize]="15" [pageIndex]="_supplierEstimatesService.pageIndex"></dxo-paging>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <!-- <dxo-search-panel [visible]="true" p3laceholder="Buscar..."></dxo-search-panel> -->
        <dxo-export [enabled]="true" fileName="lista-estimaciones"></dxo-export>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10,
          20]" [showInfo]="true">
        </dxo-pager>
        <dxo-selection selectAllMode="page" mode="single" [allowSelectAll]="false"></dxo-selection>
        <dxi-column caption="id" dataField="id" width="6%" [groupIndex]="0" groupCellTemplate="idTemplate" cellTemplate="idTemplate" [allowEditing]="false" [allowSearch]="true"></dxi-column>
        <dxi-column caption="Fecha" dataField="createdAt" width="15%" cellTemplate="dateTemplate" dataType="date" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true" sortOrder="desc" alignment="left"></dxi-column>
        <dxi-column caption="Semana" dataField="weekOfYear" width="7%" alignment="center" cellTemplate="weekTemplate" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true" sortOrder="desc"></dxi-column>
        <dxi-column caption="Proveedor" dataField="supplierId" cellTemplate="supplierTemplate" alignment="center" [allowFiltering]="false" width="25%" [visible]="true"></dxi-column>
        <dxi-column caption="Proveedor" dataField="supplierName" alignment="center" [allowFiltering]="false" width="25%" [visible]="false"></dxi-column>
        <dxi-column caption="Solicitado por" dataField="createdBy" width="20%" cellTemplate="createdByTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [visible]="true">
        </dxi-column>
        <dxi-column caption="Solicitado por" dataField="createdByDisplayName" width="20%" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [visible]="false">
        </dxi-column>
        <dxi-column caption="Monto" dataField="estimateAmount" width="10%" alignment="center" [calculateCellValue]="calculateEstimateAmountCellValue" [allowEditing]="false" [allowSearch]="true">
        </dxi-column>
        <dxi-column caption="Status" dataField="status" cellTemplate="statusTemplate" width="10%" alignment="center" [allowEditing]="false" [allowFiltering]="false" [calculateCellValue]="customizeStatusLabel"></dxi-column>
        <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
        <dxi-column caption="Detalle" dataField="uuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span><i class="material-icons icon-gray"
        (click)="seeDetail(data)">
        remove_red_eye
      </i></span>
        </div>

        <div *dxTemplate="let data of 'idTemplate'">
            <strong>EST-{{ data.value }}</strong>
        </div>

        <div *dxTemplate="let data of 'weekTemplate'">
            <strong>{{ data.value }}</strong>
        </div>

        <div *dxTemplate="let data of 'dateTemplate'">
            <strong>{{ data.value | timestampToDate }}</strong> -
            <span>hace {{ data.value | timeAgo }}</span>
        </div>

        <div *dxTemplate="let data of 'createdByTemplate'">
            <app-user-data [id]="data.value"></app-user-data>
        </div>

        <div *dxTemplate="let data of 'supplierTemplate'">
            <app-supplier-data [id]="data.value"></app-supplier-data>
        </div>

        <div *dxTemplate="let data of 'statusTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{ totalEstimates }}</h2>
                <span class="name">Total Estimaciones</span>
            </div>
        </div>
    </dx-data-grid>
    <!-- </ng-container> -->
</app-page-widget>