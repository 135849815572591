<app-page-widget noPadding noBlocker (refresh)="refreshData()">
  <app-title>Detalles de Caja Chica</app-title>
  <app-subtitle>ID: {{ cashboxUuid | idFromUuid }}</app-subtitle>

  <app-action-button flat noText icon="done_all" (click)="displayApprovals()"
    >Ver Aprobaciones</app-action-button
  >
  <app-action-button flat noText icon="camera_roll" (click)="evidenceDialog.open()"
    >Ver Evidencias</app-action-button
  >
  <app-menu-item icon="done_all" (click)="displayApprovals()">Ver Aprobaciones</app-menu-item>
  <app-menu-item icon="camera_roll" (click)="evidenceDialog.open()">Ver Evidencias</app-menu-item>

  <ng-template #notFoundTpl> <app-not-found></app-not-found> </ng-template>

  <div *ngIf="!notFound; else notFoundTpl">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="status-block">
        <div>
          Estado: <strong>{{ cashboxData?.status | cashboxStatus }}</strong>
        </div>
        <div>
          Solicitado por:
          <app-user-data *ngIf="cashboxData" [id]="cashboxData.createdBy"></app-user-data>
        </div>
      </div>
      <div class="actions-block">
        <ng-template [ngIf]="sendToApproveIsAvailable">
          <app-button
            *ngxPermissionsOnly="['cashbox:can_send_to_approve']"
            icon="call_made"
            (click)="sendToApprove()"
            >Enviar a Aprobar</app-button
          >
        </ng-template>
        <ng-template [ngIf]="approveIsAvailable">
          <app-button *ngxPermissionsOnly="['cashbox:can_approve']" icon="done" (click)="approve()"
            >Aprobar</app-button
          >
        </ng-template>
        <ng-template [ngIf]="cancelIsAvailable">
          <app-button icon="close" (click)="cancel()">Cancelar</app-button>
        </ng-template>
      </div>
    </div>

    <div class="grid-wrapper">
      <dx-data-grid
        [wordWrapEnabled]="true"
        [dataSource]="cashboxPackages"
        [hoverStateEnabled]="false"
        [showColumnLines]="true"
        [showRowLines]="true"
        noDataText="No hay registros"
        [showBorders]="true"
        [rowAlternationEnabled]="false"
        [columnAutoWidth]="true"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
      >
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>

        <dxi-column
          caption="Tipo"
          dataField="type"
          cellTemplate="packageTypeTemplate"
          [allowEditing]="false"
          [allowSorting]="true"
          sortOrder="desc"
          alignment="center"
        >
        </dxi-column>
        <dxi-column
          caption="ID PAQ"
          width="20%"
          dataField="packageId"
          [allowEditing]="false"
          [allowSorting]="true"
          sortOrder="desc"
          alignment="center"
        >
        </dxi-column>
        <dxi-column
          caption="Concepto"
          dataField="concept"
          [allowEditing]="false"
          [allowSorting]="false"
          alignment="center"
        >
        </dxi-column>
        <dxi-column
          caption="Cantidad"
          width="10%"
          dataField="quantity"
          [allowEditing]="false"
          [allowSearch]="false"
          [allowSorting]="false"
        >
        </dxi-column>
        <dxi-column
          caption="Precio"
          width="10%"
          cellTemplate="priceTemplate"
          dataField="cost"
          [allowEditing]="false"
          [allowSearch]="false"
          [allowSorting]="false"
        >
        </dxi-column>
        <dxi-column
          caption="Total"
          width="12%"
          cellTemplate="totalTemplate"
          dataField="total"
          [allowEditing]="false"
          [allowSearch]="false"
          [allowSorting]="false"
        >
        </dxi-column>

        <div *dxTemplate="let data of 'packageTypeTemplate'">
          {{ data.value === packageTypes.indirect ? 'Unidad' : 'Derivado' }}
        </div>
        <div *dxTemplate="let data of 'priceTemplate'">${{ data.value | number }}</div>
        <div *dxTemplate="let data of 'totalTemplate'">
          <b>${{ data.value | number }}</b>
        </div>
      </dx-data-grid>

      <div *ngIf="cashboxData" class="totals">
        <ng-template #noTax>Sin impuesto.</ng-template>

        <span *ngIf="1 != cashboxData.taxType; else noTax">
          Impuesto: {{ cashboxData.taxType | taxType }} ({{ cashboxData.taxPercentage }}%).
        </span>
        <b>Total: ${{ cashboxTotal | number }}</b>
      </div>
    </div>
  </div>
</app-page-widget>

<app-dialog #evidenceDialog noPadding width="650px" [autoFocus]="false">
  <app-title>Evidencias</app-title>
  <app-subtitle>Click para ver</app-subtitle>

  <ng-template [ngIf]="cashboxData?.status == 1 || cashboxData?.status == 2">
    <div class="custom-toolbar margin-bottom-2">
      <app-button default (click)="filesUploader.openSelectionDialog()">Comprobante</app-button>
      <app-button default (click)="filesUploader.openSelectionDialog()">Factura</app-button>
      <app-button default (click)="filesUploader.openSelectionDialog()">XML</app-button>
    </div>

    <app-files-uploader
      #filesUploader
      bucket="cashbox-evidences"
      (uploadFinished)="onEvidenceUpload($event)"
    >
    </app-files-uploader>
  </ng-template>

  <!--
  <app-action-button icon="delete" (click)="removeSelectedEvidence()">Eliminar seleccionados</app-action-button>
  -->

  <ng-template #noEvidenceTpl> <p>No hay evidencias</p> </ng-template>

  <mat-nav-list *ngIf="cashboxData && cashboxData.evidence; else noEvidenceTpl">
    <a
      *ngFor="let ev of cashboxData.evidence; trackBy: trackEvidence"
      mat-list-item
      target="_blank"
      [href]="ev.downloadUrl"
    >
      {{ ev.name }}
    </a>
  </mat-nav-list>

  <!-- TODO: -->
  <br />
</app-dialog>
