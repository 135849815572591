<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Paquetes por recibir</app-title>
    <app-subtitle>Listado</app-subtitle>

    <div class="grid-container-div padding-h padding-bottom-2">
        <dx-data-grid #remissionsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="entries" [masterDetail]="{ enabled: false, template:
      'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="No hay registros" [showBorders]="false" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false"
            (onToolbarPreparing)="onToolbarPreparing($event)">
            <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
            <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false">
            </dxo-selection>
            <dxo-export [enabled]="true" fileName="lista-remisiones"></dxo-export>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column alignment="center" caption="ID" dataField="remissionId" groupCellTemplate="remissionIdTemplate" cellTemplate="remissionIdTemplate" width="5%" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column alignment="center" caption="OC" dataField="purchaseOrderId" groupCellTemplate="pOGroupTemplate" cellTemplate="pOGroupTemplate" [groupIndex]="0" width="5%" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Fecha" dataField="dateRemission" dataType="date" cellTemplate="dateTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="true" [calculateCellValue]="calculateDateCellValue">
            </dxi-column>
            <dxi-column caption="Estado" dataField="statusReceived" cellTemplate="statusTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Almacen" dataField="warehouse" cellTemplate="warehousesTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" width="25%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" width="25%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Cantidad enviada" dataField="quantitySend" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad recibida" dataField="quantityReceived" alignment="center">
            </dxi-column>
            <dxi-column caption="Por surtir" cellTemplate="quantityLeftToSendTemplate" [allowEditing]="false" [allowSearch]="true" dataField="quantityToLeftSend" [calculateCellValue]="calculateQuantityLeftToSendCellValue" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Entrada" dataField="remissionUuid" cellTemplate="ccButtonEntryTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

            <div *dxTemplate="let data of 'warehousesTemplate'">
                <ng-container *ngIf="data.value; else noWarehouse">
                    <app-warehouse-data [id]="data.value"></app-warehouse-data>
                </ng-container>
                <ng-template #noWarehouse>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'ccButtonEntryTemplate'">
                <span>
          <i class="material-icons icon-gray" matTooltip="Entrada"
            (click)="entryRemission(data)">
            input</i></span>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="quantitySend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityReceived" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityToLeftSend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>

            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>
            <div *dxTemplate="let data of 'pOGroupTemplate'">OC-{{ data.value }}</div>
            <div *dxTemplate="let data of 'statusTemplate'">
                <strong>{{ data.value | remissionStatus }}</strong>
            </div>
            <div *dxTemplate="let data of 'receiverTemplate'">
                <ng-container *ngIf="data.value; else noReceiver">
                    <strong>{{ data.value }}</strong>
                </ng-container>
                <ng-template #noReceiver>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantitySentTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'quantityApprovedTemplate'">
                <ng-container *ngIf="data.value; else noQuantityApproved">
                    <span> {{ data.value | number: '1.2-2' }}</span>
                </ng-container>
                <ng-template #noQuantityApproved>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
                <ng-container *ngIf="data.value; else noQuantityLeft">
                    {{ data.value | number: '1.2-2' }}
                </ng-container>
                <ng-template #noQuantityLeft>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalRemissions }}</h2>
                    <span class="name">Total Remisiones</span>
                </div>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'remissionIdTemplate'">REM-{{ data.value }}</div>
        </dx-data-grid>
    </div>
</app-page-widget>

<app-dialog #formDialog>
    <ng-container>
        <app-title>Cantidad a recibir</app-title>

        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-top-2" *ngIf="selectedGridItems">
                <div class="content-block margin-top-0">
                    <div class="width-100">
                        <p>
                            <strong>{{ selectedGridItems[0].concept }}</strong>
                        </p>
                    </div>
                    <div class="width-50">
                        <p>
                            Cantidad enviada:
                            <strong>{{ selectedGridItems[0].quantitySend | number: '1.2-2' }}</strong>
                        </p>
                    </div>
                    <div class="width-50">
                        <p>
                            Cantidad por surtir:
                            <strong>{{ selectedGridItems[0].quantityToLeftSend | number:
                '1.2-2' }}</strong>
                        </p>
                    </div>
                    <mat-form-field class="width-100">
                        <input matInput [formControl]="quantityReceivedFormControl" (change)="validateFormControls()" placeholder="Cantidad a recibir" type="number" [max]="selectedGridItems &&
              selectedGridItems[0].quantity
              ? selectedGridItems[0].quantity
              : 0" min="1" required />
                    </mat-form-field>
                    <ng-container *ngIf="quantityReceivedFormControl.value &&
            (quantityReceivedFormControl.value
          <selectedGridItems[0].quantitySend ||
            quantityReceivedFormControl.value
          <selectedGridItems[0].quantityToLeftSend)">
                        <mat-form-field class="width-100">
                            <textarea matInput maxlength="120" type="textarea" placeholder="Comentario" [formControl]="commentFormControl"></textarea>
                        </mat-form-field>
                    </ng-container>
                    <!-- <dx-data-grid id="packagesGrid" #packagesGrid [dataSource]="selectedGridItems" (onSelectionChanged)="onSelectPurchaseOrderRow($event)" (onRowUpdated)="onRowUpdated($event)">
                        <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"> </dxo-selection>
                        <dxi-column dataField="concept" width="40%" caption="Concepto" cellTemplate="conceptTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="left">
                        </dxi-column>
                        <dxi-column dataField="quantity" caption="Cantidad enviada" cellTemplate="quantitySendTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                        </dxi-column>
                        <dxi-column dataField="quantitiesLeftToSendSum" caption="Cantidad por surtir" cellTemplate="quantityLeftToSendTemplate" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
                        </dxi-column>
                        <dxi-column dataField="quantityReceived" caption="Catidad recibida" dataType="number" cellTemplate="receiverTemplate" [calculateCellValue]="calculateQuantityLeftToSendCellValue" [allowEditing]="true" [allowSearch]="false" [allowSorting]="false" alignment="center">
                            <dxi-validation-rule [validationCallback]="isValid" type="custom" message="Cantidad
                                Invalida">
                            </dxi-validation-rule>
                        </dxi-column>
                        <dxo-editing mode="cell" [allowUpdating]="true"> </dxo-editing>

                        <div class="padding-top-10" *dxTemplate="let data of 'conceptTemplate'">
                            <span *ngIf="data?.value">{{ data.value }}</span>
                        </div>
                        <div class="padding-top-10" *dxTemplate="let quantitySend of 'quantitySendTemplate'">
                            <span *ngIf="quantitySend?.value">{{ quantitySend.value | number: '1.2-2' }}</span>
                        </div>
                        <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
                            <ng-container *ngIf="data.value; else noQuantityLeft">
                                {{ data.value | number: '1.2-2' }}
                            </ng-container>
                            <ng-template #noQuantityLeft>
                                --
                            </ng-template>
                        </div>

                        <div class="padding-top-10" *dxTemplate="let receiver of 'receiverTemplate'">
                            <span *ngIf="receiver?.value">{{ receiver.value | number: '1.2-2' }}</span>
                            <span *ngIf="!receiver || !receiver.value" class="placeholder-color">Ingrese cantidad</span
              >
            </div>
          </dx-data-grid> -->
                </div>
                <button class="float-right" [disabled]="!quantityReceivedFormControl.value" mat-flat-button (click)="saveData()" color="primary">
          Guardar
        </button>
            </div>
        </div>
    </ng-container>
</app-dialog>