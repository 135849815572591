import { Component, OnInit } from '@angular/core';
import { BudgetTableVm } from '@bist-lib/db-api/src/models/budget';


@Component({
  selector: 'cost-center-opt-budget-table',
  templateUrl: './budget-table.component.html',
  styleUrls: ['./budget-table.component.scss']
})
export class BudgetTableComponent implements OnInit {

  packageIsDerivative: boolean = false;

  tableHeaderDefinition: string[] = ['code', 'concept', 'unit', 'quantity', 'cost', 'total'];
  // Table's data source
  viewModel: BudgetTableVm[];

  constructor() { }

  ngOnInit() {

  }
  loadBudgetData(budgetTableVm: BudgetTableVm) {
    this.viewModel = [budgetTableVm]
    // this.packageIsDerivative = budgetTableVm.isBolsa;
  }

}
