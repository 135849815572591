<app-page-widget noPadding (refresh)="refreshData()">
  <app-title>Proyecto</app-title>
  <app-subtitle>Seleccione para gestionar</app-subtitle>

  <app-action-button icon="dns" (click)="openManagementDialog()">Crear</app-action-button>

  <app-datatable
    #divisionsDatatable
    [columns]="dtColumns"
    [rows]="divisions"
    (changedRowsSelection)="openManagementDialog($event)"
  >
  </app-datatable>
</app-page-widget>

<!-- Management Dialog -->
<app-dialog #managementDialog (dismiss)="onCloseManagementDialog()">
  <app-title>{{ selectedDivision ? selectedDivision.name : 'Datos del Proyecto' }}</app-title>
  <app-subtitle>{{ selectedDivision ? 'Datos del Proyecto' : '' }}</app-subtitle>

  <app-menu-item icon="delete" (click)="deleteDivision(selectedDivision)"
    >Eliminar Proyecto</app-menu-item
  >

  <div class="width-400">
    <app-form
      *ngIf="formDataLoaded"
      [fields]="formFields"
      [model]="formData"
      [noCancelButton]="false"
      (save)="saveDivision($event.value)"
      (cancel)="managementDialog.close()"
    >
    </app-form>
  </div>
</app-dialog>
