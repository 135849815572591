<app-page-widget #pageWidget (refresh)="refreshData()">
    <app-title>Seleccione una Orden de Cheque</app-title>
    <app-subtitle></app-subtitle>

    <dx-data-grid #dataGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="paymentCheckOrders" [masterDetail]="{ enabled: false,
        template: 'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
        no éxisten registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" (onExporting)="onExporting($event)" (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
        (onOptionChanged)="changePage($event)">
        <!-- (onSelectionChanged)="onGridSelection($event)" -->
        <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
        <dxo-paging [pageSize]="10" [pageIndex]="_paymentCheckOrdersService.pageIndex"></dxo-paging>
        <!-- <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
        </dxo-pager> -->
        <!-- <dxo-scrolling mode="virtual"></dxo-scrolling> -->
        <dxo-export [enabled]="true" fileName="lista-ordenes-cheque"></dxo-export>
        <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column alignment="center" caption="ID" dataField="id" [groupIndex]="0" dataType="number" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Fecha" dataType="date" dataField="createdAt" cellTemplate="dateTemplate" sortOrder="desc" alignment="left" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true"></dxi-column>
        <dxi-column caption="Creado por" dataField="createdBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
        </dxi-column>
        <dxi-column caption="Concepto" dataField="concept" width="25%" [allowSorting]="false" [allowSearch]="true" alignment="left">
        </dxi-column>
        <dxi-column caption="Unidad" dataField="unit" width="10%" cellTemplate="unitTemplate" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Cantidad" dataField="quantity" width="10%" [allowSorting]="false" cellTemplate="quantityTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="Costo" dataField="cost" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="Subtotal" width="15%" [allowSorting]="false" cellTemplate="subtotalTemplate" [calculateCellValue]="calculateTotalCost" alignment="center">
        </dxi-column>
        <dxi-column caption="Impuestos" width="10%" [allowSorting]="false" cellTemplate="taxTemplate" [calculateCellValue]="calculateTaxes" alignment="center">
        </dxi-column>
        <dxi-column caption="Total" width="15%" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotalOrder" alignment="center">
        </dxi-column>
        <dxi-column caption="Estado" dataField="status" alignment="center" cellTemplate="paymentCheckOrderStatusTemplate" [allowEditing]="false" [allowSorting]="false" [allowFiltering]="false" [calculateCellValue]="customizeStatusLabel"></dxi-column>
        <dxi-column caption="Aprobadores Faltantes" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
        <dxi-column caption="Detalle" dataField="uuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span><i class="material-icons icon-gray"
        (click)="seeDetail(data)">
        remove_red_eye
      </i></span>
        </div>

        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'paymentCheckOrderStatusTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
            <span>{{ data.value | number: '1.0-0' }}</span>
        </div>
        <div *dxTemplate="let data of 'costTemplate'" class="cost">
            <span>$ {{ data.value | number }}</span>
        </div>
        <div *dxTemplate="let data of 'subtotalTemplate'" class="cost">
            <span>$ {{ data.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let data of 'taxTemplate'" class="cost">
            <span>$ {{ data.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let data of 'totalTemplate'" class="cost">
            <span>$ {{ data.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{totalCount}}</h2>
                <span class="name">Total Ordenes</span>
            </div>
        </div>
    </dx-data-grid>
</app-page-widget>