import { Component, OnInit } from '@angular/core';
import { ProjectionTableVm } from '@bist-lib/db-api/src/models/ViewModels/cost-center-opt-Vms';

@Component({
  selector: 'cost-center-opt-projection-table',
  templateUrl: './projection-table.component.html',
  styleUrls: ['./projection-table.component.scss']
})
export class ProjectionTableComponent implements OnInit {
  packageIsDerivative: boolean = false;
  projectionTableColumns: string[] = [
    'weightedAveragePrice',
    'purchasedQuantityTotal',
    'purchasedTotal',
    'remainingPurchaseQty',
    'remainingPurchaseTotal',
    'lastPoUnitPrice',
    'projectionTotal',
    'forecastBalance',
  ];
  tableSource: ProjectionTableVm[];

  constructor() { }

  ngOnInit() {
  }

  loadProjectionTable(inputs: ProjectionTableVm) {
    this.tableSource = [inputs];

  }

}
