import { Component, OnInit, ViewChild } from '@angular/core';
import {
  CostsCenterService,
  PackageToQuote,
  PaymentCheckOrder,
  ProcessedPackage,
  ProcessedPackagesService,
  PurchaseOrder
} from '@bist-lib/db-api';
import { BudgetTableVm } from '@bist-lib/db-api/src/models/budget';
import { ProjectionTableVm, PurchaseOrdersSummaryVm, PurchaseOrderVm } from '@bist-lib/db-api/src/models/ViewModels/cost-center-opt-Vms';
import {
  CostCenterProjectionPipe,
  CostCenterSummaryPipe
} from '@bist-lib/db-api/src/pipes/cost-center/cost-center.pipe';
import { take } from 'rxjs/operators';

import { DialogComponent } from '../../components/dialog';
import { BudgetTableComponent } from './tables/budget-table/budget-table.component';
import { CheckOrdersTableComponent } from './tables/check-orders-table/check-orders-table.component';
import { ProjectionTableComponent } from './tables/projection-table/projection-table.component';
import { PurchaseOrdersTableComponent } from './tables/purchase-orders-table/purchase-orders-table.component';

@Component({
  selector: 'app-cost-center-opt',
  templateUrl: './cost-center-opt.component.html',
  styleUrls: ['./cost-center-opt.component.scss']
})
export class CostCenterOptComponent implements OnInit {
  @ViewChild('appDialog', { static: true }) public appDialog: DialogComponent;
  @ViewChild('budgetTable', { static: true }) public budgetTable: BudgetTableComponent;
  @ViewChild('purchaseOrdersTable', { static: true })
  public purchaseOrdersTable: PurchaseOrdersTableComponent;
  @ViewChild('paymentCheckOrdersTable', { static: true })
  public paymentCheckOrdersTable: CheckOrdersTableComponent;
  @ViewChild('projectionTable', { static: true }) public projectionTable: ProjectionTableComponent;

  title = 'Centro de Costos';
  subtitle = '';

  constructor(
    private _costCenterService: CostsCenterService,
    private _processedPackagesService: ProcessedPackagesService
  ) {}

  ngOnInit() {}

  async open(
    singlePackage: PackageToQuote,
    simulatePurchaseOrder: boolean = false,
    simulatePaymentCheckOrder: boolean = false
  ) {
    this.appDialog.open();
    this.appDialog.toggleLoading(true);

    const budgetTableData: BudgetTableVm = await this.getBudgetTableData(singlePackage.code);
    this.budgetTable.loadBudgetData(budgetTableData);

    const purchaseOrderVms: PurchaseOrderVm[] = await this.getPurchaseOrdersTableData(
      singlePackage.code
    );

    const paymentCheckOrderVms: PurchaseOrderVm[] = await this.getPaymentCheckOrdersTableData(
      singlePackage.code
    );

    if (simulatePurchaseOrder) {
      const simulatedPo: PurchaseOrderVm = {
        createdAt: Date.now(),
        poId: undefined,
        quantity: singlePackage.purchasedQuantity,
        unitPrice: singlePackage.supplierCost,
        isSimulation: true
      };
      purchaseOrderVms.push(simulatedPo);
    }

    if (simulatePaymentCheckOrder) {
      const simulatedPCo: PurchaseOrderVm = {
        createdAt: Date.now(),
        poId: undefined,
        quantity: singlePackage.purchasedQuantity,
        unitPrice: singlePackage.supplierCost,
        isSimulation: true
      };
      paymentCheckOrderVms.push(simulatedPCo);
    }
    const purchaseOrdersSummary: PurchaseOrdersSummaryVm = new CostCenterSummaryPipe().transform(
      purchaseOrderVms,
      budgetTableData.unitPrice
    );

    const paymentCheckOrdersSummary: PurchaseOrdersSummaryVm = new CostCenterSummaryPipe().transform(
      paymentCheckOrderVms,
      budgetTableData.unitPrice
    );

    this.purchaseOrdersTable.loadPurchaseOrdersData(
      purchaseOrderVms,
      singlePackage.concept,
      budgetTableData.unitPrice,
      purchaseOrdersSummary
    );

    this.paymentCheckOrdersTable.loadCheckOrdersData(
      paymentCheckOrderVms,
      singlePackage.concept,
      budgetTableData.unitPrice,
      paymentCheckOrdersSummary
    );

    const projectionTableInputs: ProjectionTableVm = new CostCenterProjectionPipe().transform(
      purchaseOrderVms,
      paymentCheckOrderVms,
      purchaseOrdersSummary,
      paymentCheckOrdersSummary,
      budgetTableData
    );
    this.projectionTable.loadProjectionTable(projectionTableInputs);

    this.appDialog.toggleLoading(false);
  }
  private async getBudgetTableData(code: string) {
    const budget = await this._costCenterService.getBudgetByCode(code);
    return budget;
  }

  private async getPurchaseOrdersTableData(code: string): Promise<PurchaseOrderVm[]> {
    const purchaseOrders: PurchaseOrder[] = await this._costCenterService.getPurchaseOrdersByCode(
      code
    );
    const poPackageProms = purchaseOrders.map((po) => {
      return this._processedPackagesService
        .getPurchaseOrderPackages(po.uuid)
        .pipe(take(1))
        .toPromise();
    });
    const poPackages: ProcessedPackage[] = (await Promise.all(poPackageProms))
      .reduce((acum, current) => [...acum, ...current], [])
      .filter((p) => p.code === code);

    const purchaseOrderVms = purchaseOrders.map(
      (po: PurchaseOrder): PurchaseOrderVm => {
        return {
          createdAt: po.createdAt,
          poId: po.id,
          quantity: poPackages
            .filter((pkg) => pkg.referenceId === po.uuid)
            .reduce((acum, current) => acum + current.requiredQuantity, 0),
          unitPrice: poPackages.filter((pkg) => pkg.referenceId === po.uuid)[0].supplierCost,
          isSimulation: false
        };
      }
    );

    return purchaseOrderVms;
  }

  private async getPaymentCheckOrdersTableData(code: string): Promise<PurchaseOrderVm[]> {
    const paymentCheckOrders: any[] = await this._costCenterService.getPaymentCheckOrdersByCode(
      code
    );

    const poPackageProms = paymentCheckOrders.map((po) => {
      return this._processedPackagesService
        .getPaymentCheckPackages(po.uuid)
        .pipe(take(1))
        .toPromise();
    });

    const poPackages: ProcessedPackage[] = (await Promise.all(poPackageProms))
      .reduce((acum, current) => [...acum, ...current], [])
      .filter((p) => p.code === code);
    const paymentCheckOrderVms = paymentCheckOrders.map((po: PaymentCheckOrder | any):
      | PurchaseOrderVm
      | any => {
      return {
        createdAt: po.createdAt,
        poId: po.id,
        quantity: poPackages
          .filter((pkg) => pkg.referenceId === po.uuid)
          .reduce((acum, current) => acum + current.quantity, 0),
        unitPrice: poPackages
          .filter((pkg) => pkg.referenceId === po.uuid)
          .reduce((acum, current) => acum + current.total, 0),
        unit: poPackages.filter((pkg) => pkg.referenceId === po.uuid)[0].unit,
        type: poPackages.filter((pkg) => pkg.referenceId === po.uuid)[0].type,
        isSimulation: false
      };
    });

    return paymentCheckOrderVms;
  }
}
