<app-page-widget (refresh)="refreshData()" [menuItems]="widgetMenu" id="estimate-order">
    <app-title>OE-{{ estimateOrder?.uuid | idFromUuid }} </app-title>
    <span class="info-label">Estimación:
        <strong>{{ estimateOrder?.nroEstimate }}</strong>
    </span>
    <app-subtitle>{{ estimateOrder?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <app-action-button flat noText icon="refresh" (click)="refreshUsersApprove()">Actualizar aprobadores
    </app-action-button>
    <app-action-button flat noText icon="done_all" (click)="viewApprovers()">Aprobaciones
    </app-action-button>
    <app-action-button flat noText icon="download" (click)="exportData()">Exportar
    </app-action-button>

    <div class="actions-block">
        <div class="left">
            <div class="button-invoices">
                <button mat-raised-button class="action-button" *ngIf="invoices[0]" [disabled]="_paymentOrders.length> 0" (click)="rejectedInvoice(invoices[0]?.uuid)" color="warn">
                    <!-- <mat-icon>call_made</mat-icon> -->
                    Rechazar Factura
                </button>
                <button mat-raised-button *ngIf="invoices" class="action-button" (click)="uploadInvoice('pdf')" color="primary" [disabled]="(invoices[0]?.status === 1 &&
                    (invoices[0]?.files[0].typeFile === 'pdf' ||
                    invoices[0]?.files[1]?.typeFile
                    === 'pdf')) || (invoices[0] &&
                    (invoices[0]?.files[0]?.typeFile
                    === 'pdf' || invoices[0]?.files[1]?.typeFile
                    === 'pdf') && (approveIsAvailable ||
                    sendToRemittancesIsAvailable ||
                    !sendToRemittancesIsAvailable))">
                    <mat-icon>cloud_upload</mat-icon>
                    Cargar Factura PDF
                </button>
                <button mat-raised-button *ngIf="invoices" class="action-button" (click)="uploadInvoice('xml')" color="primary" [disabled]="(invoices[0]?.status === 1 &&
                    (invoices[0]?.files[0].typeFile === 'xml' ||
                    invoices[0]?.files[1]?.typeFile
                    === 'xml')) || (invoices[0] &&
                    (invoices[0]?.files[0]?.typeFile
                    === 'xml' || invoices[0]?.files[1]?.typeFile
                    === 'xml') && approveIsAvailable)">
                    <mat-icon>cloud_upload</mat-icon>
                    Cargar Factura XML
                </button>

            </div>
            <div class="actions-block">
                <div class="left-invoices">
                    <a *ngIf="sendToApproveIsAvailable && statusInvoice !== 4 &&
                        (invoices[0]?.files[0].typeFile === 'xml' ||
                        invoices[0]?.files[1]?.typeFile === 'xml' )" color="warn" (click)="removeInvoice(invoices[0]?.files,
                        invoices[0]?.uuid,
                        'xml')" mat-icon-button>
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                    <a *ngIf="statusInvoice !== 4 &&
                        (invoices[0]?.files[0].typeFile === 'xml' ||
                        invoices[0]?.files[1]?.typeFile === 'xml' )" color="primary" [href]="invoices[0]?.files[0].typeFile
                        === 'xml' ?
                        invoices[0]?.files[0].httpFile :
                        invoices[0]?.files[1].httpFile" target="_blank" mat-icon-button>
                        <mat-icon>insert_drive_file</mat-icon>
                        Factura XML
                    </a>
                </div>
                <div class="right-invoices">
                    <a *ngIf="sendToApproveIsAvailable && statusInvoice !== 4 &&
                        (invoices[0]?.files[0].typeFile === 'pdf' ||
                        invoices[0]?.files[1]?.typeFile === 'pdf' )" color="warn" (click)="removeInvoice(invoices[0]?.files,
                        invoices[0]?.uuid,
                        'pdf')" mat-icon-button>
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                    <a *ngIf="statusInvoice !== 4 &&
                        (invoices[0]?.files[0].typeFile === 'pdf' ||
                        invoices[0]?.files[1]?.typeFile === 'pdf' )" color="primary" [href]="invoices[0]?.files[0].typeFile
                        === 'pdf' ?
                        invoices[0]?.files[0].httpFile :
                        invoices[0]?.files[1].httpFile" target="_blank" mat-icon-button>
                        <mat-icon>insert_drive_file</mat-icon>
                        Factura PDF
                    </a>
                </div>
            </div>
        </div>
        <div class="right">

            <button mat-raised-button class="action-button mr-5" (click)="goToPreviousUlr()" color="warn">
                <mat-icon>undo</mat-icon>
                Regresar
            </button>
            <ng-template [ngIf]="sendToRemittancesIsAvailable">
                <button mat-raised-button class="action-button" *ngxPermissionsOnly="['remittances:can_send_to_remittances']" (click)="sendToRemittances()" color="primary">
                    <mat-icon>playlist_add_check</mat-icon>
                    Enviar a Remesas
                </button>
            </ng-template>
            <!-- <button mat-raised-button class="action-button" *ngIf="approveIsAvailable" (click)="approve()" color="primary">
                <mat-icon>done</mat-icon>
                Aprobar
            </button> -->
            <ng-template [ngIf]="approveIsAvailable && !approveAvailableSupplier">
                <app-button *ngxPermissionsOnly="['estimates:can_approve']" icon="done" class="action-button" (click)="approve()">Aprobar</app-button>
            </ng-template>
            <ng-template [ngIf]="approveIsAvailable && approveAvailableSupplier">
                <button mat-raised-button class="action-button" (click)="approve()" color="primary">
                    <mat-icon>done</mat-icon>
                    Aprobar
                </button>
            </ng-template>

            <button mat-raised-button *ngIf="estimateOrder?.status === 1" class="action-button mr-5" (click)="rejectedOrder()" color="warn">
                <mat-icon>highlight_off</mat-icon>
                Rechazar Orden
            </button>
            <button mat-raised-button class="action-button" *ngIf="sendToApproveIsAvailable" (click)="sendToApprove()" color="primary">
                <mat-icon>call_made</mat-icon>
                Enviar a Aprobar
            </button>
            <button mat-raised-button class="action-button bg-green" *ngIf="isOrderReadyToDownload" (click)="exportData()" color="success">
                <mat-icon>cloud_download</mat-icon>
                Descargar Excel
            </button>

            <button mat-raised-button class="action-button" *ngIf="isOrderReadyToDownload" (click)="downloadOrder()" color="primary">
                <mat-icon>cloud_download</mat-icon>
                Descargar orden
            </button>

            <button *ngIf="_paymentOrders.length> 0; else disabledBtn" mat-raised-button class="action-button" color="primary" (click)="showLogDialog()">
                <mat-icon>remove_red_eye</mat-icon>
                Ver órdenes de pago
            </button>
            <ng-template #disabledBtn>
                <button mat-raised-button class="action-button" disabled>
                    <mat-icon>remove_red_eye</mat-icon>
                    Sin órdenes de pago
                </button>
            </ng-template>

        </div>
    </div>

    <div class="header-block">
        <span class="info-label">Monto Total:
            <strong>${{ estimateOrder?.totalOrderAmount - ivaCompl - isr | number: '1.2-3' }}</strong>
        </span>
        <span class="info-label">Pagado:
            <strong>${{ estimateOrder?.paidAmmount | number: '1.2-3' }}</strong>
        </span>
        <span class="info-label">Pasivos:
            <strong>${{ estimateOrder?.passiveAmmount | number: '1.2-3' }}</strong>
        </span>
    </div>

    <div class="header-block">
        <span class="info-label">Estatus:
            <strong>{{ estimateOrder?.status | estimateOrderStatus }}</strong>
        </span>
        <span class="info-label">Solicitado por:
            <strong>{{ estimateOrder?.createdBy | userData | async }}</strong>
        </span>
    </div>

    <div class="header-block">
        <span class="info-label">Contrato:
            <strong>{{ nroContract }}</strong>
        </span>
    </div>

    <div id="packages-detail" class="content-block-section">
        <h3 class="content-title">Proveedor</h3>
        <div>
            <div>
                <span class="info-label">Proveedor: </span>
                <strong>{{ estimateOrder?.supplierId | supplierData | async }}</strong>
            </div>

            <div>
                <span class="info-label">Razón Social: </span>
                <strong>{{ estimateOrder?.supplierId | supplierData:
                    'socialReason' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">RFC: </span>
                <strong>{{ estimateOrder?.supplierId | supplierData: 'rfc' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">Dirección Comercial:</span>
                <strong>{{ estimateOrder?.supplierId | supplierData:
                    'businessAddress' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">Dirección Fiscal:</span>
                <strong>{{ estimateOrder?.supplierId | supplierData:
                    'taxAddress' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">Teléfono:</span>
                <strong>{{ estimateOrder?.supplierId | supplierData: 'phone' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">email: </span>
                <strong>{{ estimateOrder?.supplierId | supplierData: 'email' |
                    async }}</strong>
            </div>
        </div>
    </div>

    <ng-container *ngIf="estimate">
        <div class="content-block2-section" *ngIf="estimate.commentsRejectedOrder && estimate.commentsRejectedOrder.length > 0">
            <div class="content-block-section ml-8 mt-0 w-100">
                <h3 class="content-title">Comentarios</h3>
                <div>
                    <div *ngFor="let comment of estimate.commentsRejectedOrder; let idx = index">
                        <div>
                            <span class="info-label">Usuario: </span>
                            <app-user-data [id]="comment.userUuid"></app-user-data>
                        </div>
                        <div>
                            <span class="info-label">Fecha: </span>
                            <strong>{{ comment.dateRejected | timestampToDate }}</strong>
                        </div>
                        <div>
                            <span class="info-label">Comentario {{ idx + 1 }}: </span>
                            <strong>{{ comment.comment }}</strong>
                        </div>
                    </div>
                </div>
                <br />
            </div>
            <div class="content-block-section mr-8 mt-0 w-100">
                <h3 class="content-title">Evidencias</h3>
                <div>
                    <div *ngFor="let comment of estimate.commentsRejectedOrder; let idx = index">
                        <div class="d-flex align-items-center" *ngIf="comment.evidences">
                            <ng-container *ngFor="let evidence of comment.evidences; let indexEv = index">
                                <span class="info-label align-items-center">Evidencia {{ indexEv + 1 }}: </span>
                                <a target="_blank" [href]="evidence.evidenceUrl">
                                    <mat-icon matListIcon>cloud_download</mat-icon>
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    </ng-container>


    <div id="packages" class="content-block" *ngIf="estimate">
        <h3 class="content-title">Paquetes</h3>
        <dx-data-grid #eoGrid id="eoGrid" [wordWrapEnabled]="true" [dataSource]="estimatePackages" [masterDetail]="{ enabled: false,
            template: 'detail' }" [hoverStateEnabled]="false" [wordWrapEnabled]="true" [showColumnLines]="false" [showRowLines]="true" noDataText="Sin paquetes
            disponibles." [showBorders]="false" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false">
            <dxo-editing mode="cell" [allowUpdating]="false"></dxo-editing>
            <dxo-export [enabled]="false"></dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-search-panel [visible]="false" [highlightSearchText]="false"></dxo-search-panel>
            <dxo-paging [pageSize]="5"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="ID" dataField="packageId" alignment="left" cellTemplate="idTemplate" width="12%"></dxi-column>
            <dxi-column caption="DESCRIPCIÓN" dataField="concept" width="35%"></dxi-column>
            <dxi-column caption="UNIDAD" dataField="unit" width="15%" cellTemplate="unitTemplate" alignment="center"></dxi-column>
            <dxi-column caption="P.U" dataField="unitPrice" width="10%" cellTemplate="costoTemplate" alignment="center"></dxi-column>
            <dxi-column caption="P.U Real" dataField="realPrice" width="10%" cellTemplate="costoTemplate" alignment="center"></dxi-column>
            <dxi-column caption="CANTIDAD" dataField="quantity" width="10%" cellTemplate="cantidadTemplate" alignment="center"></dxi-column>
            <dxi-column caption="ADICIONALES" dataField="additionToQuantity" width="15%" cellTemplate="additionTemplate" alignment="center"></dxi-column>
            <dxi-column caption="DEDUCTIVA" dataField="decreaseToQuantity" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column>
            <dxi-column caption="TOTAL" dataField="importe" width="10%" [calculateCellValue]="calculateTotalColumn" cellTemplate="importeTemplate">
            </dxi-column>
            <dxi-column caption="% pagado" dataField="percentage" [calculateCellValue]="calcPercentPay" width="10%" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
            <dxi-column caption="% a pagar" dataField="percentToPay" width="10%" [calculateCellValue]="calculatePercentToPay" cellTemplate="estimateTemplate" alignment="center"></dxi-column>
            <dxi-column caption="IMPORTE" dataField="total" width="15%" [calculateCellValue]="calculateImportColumn" cellTemplate="estimateamountTemplate" alignment="right"></dxi-column>
            <div *dxTemplate="let d of 'costoTemplate'">
                <span class="current-value">${{ d.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let d of 'cantidadTemplate'">
                <span class="current-value">{{ d.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let d of 'additionTemplate'">
                <ng-container *ngIf="d.value; else withoutValue">
                    {{ d.value | number: '1.2-3' }}
                </ng-container>
                <ng-template #withoutValue>
                    -
                </ng-template>
            </div>
            <div *dxTemplate="let d of 'decreaseTemplate'">
                <ng-container *ngIf="d.value; else withoutValue">
                    {{ d.value | number: '1.2-3' }}
                </ng-container>
                <ng-template #withoutValue>
                    -
                </ng-template>
            </div>
            <div *dxTemplate="let d of 'estimateTemplate'">
                <span class="current-value">{{ d.value }}%</span>
            </div>
            <div *dxTemplate="let d of 'importeTemplate'">
                <span class="current-value">${{ d.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let d of 'estimateamountTemplate'">
                <span *ngIf="d.data.concept === 'Fondo de garantia'"> - </span>
                <span class="current-value">${{ d.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'idTemplate'">
                <span *ngIf="data.value">{{ data.value }}</span> <span *ngIf="!data.value">NA</span>
            </div>

            <dxo-group-panel [visible]="false"></dxo-group-panel>

            <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                <span>{{ data.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'costTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'percentTemplate'" class="cost">
                <span>{{ data.value | number: '1.2-3'}} %</span>
            </div>
            <div *dxTemplate="let data of 'subtotalTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'taxTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'totalTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>

            <dxo-summary [calculateCustomSummary]="calculateSelectedRow" *ngIf="estimate">
                <dxi-total-item column="concept" summaryType="count" displayFormat="Conceptos: {0}">
                </dxi-total-item>

                <dxi-total-item name="subtotalPacks" summaryType="custom" [customizeText]="formatSubtotalPacks" displayFormat="${0}" showInColumn="total">
                </dxi-total-item>

                <dxi-total-item name="deductive" summaryType="custom" [customizeText]="formatDeductive" displayFormat="${0}" showInColumn="total">
                </dxi-total-item>

                <dxi-total-item name="subtotalPacksDeductive" summaryType="custom" [customizeText]="formatSubtotalPacksDeductive" displayFormat="${0}" showInColumn="total">
                </dxi-total-item>

                <!-- ok no mover-->
                <dxi-total-item name="getGuarantieFound" summaryType="custom" [customizeText]="formatGuarantyFound" showInColumn="total">
                </dxi-total-item>
                <!-- -- -->

                <dxi-total-item name="descuentoPrestamoAbonoPrestamo" summaryType="custom" [customizeText]="formatLoan" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>

                <dxi-total-item name="descuentoPrestamoAbonoAnticipo" summaryType="custom" [customizeText]="formatAdvancedPayment" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>

                <dxi-total-item name="nuevoSubtotal" summaryType="custom" [customizeText]="formatNewSubtotal" displayFormat="${0}" showInColumn="total">
                </dxi-total-item>

                <!-- ok no mover-->
                <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="formatIVA" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>

                <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getIVACompl" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>

                <dxi-total-item *ngIf="addIva" name="calculandoIVA" summaryType="custom" [customizeText]="getISR" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>

                <!-- ok no mover-->
                <dxi-total-item *ngIf="addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="totalWithGuarantyFoundAndIVA" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>
                <dxi-total-item *ngIf="!addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="totalWithGuarantyFound" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>
                <!-- --- -->

                <dxi-total-item *ngIf="estimate.deductiveTotal" name="deductiveTotal" summaryType="custom" [customizeText]="formatDeductiveTotal" displayFormat="${0}" showInColumn="total">
                </dxi-total-item>

                <!-- ok no mover-->
                <dxi-total-item *ngIf="addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConIVAAndDeductiveTotal" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>
                <!-- --- -->
                <dxi-total-item *ngIf="!addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalSinIVAAndDeductiveTotal" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>

                <!-- ok dont move muestra el TOTAL CUANDO TIENE FONDO DE GARANTIA  -->
                <dxi-total-item *ngIf="addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="totalLetterWithIVA" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>
                <dxi-total-item *ngIf="!addIva" name="nuevoSubtotal" summaryType="custom" [customizeText]="totalLetterWithoutIVA" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>

                <!-- ok dont move muestra el TOTAL CUANDO TIENE FONDO DE GARANTIA  -->
                <dxi-total-item *ngIf="hasGuarantteFound && addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConFondoLetraWithDeductiveTotal" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>
                <dxi-total-item *ngIf="hasGuarantteFound && !addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalConFondoLetraSinIVAWithDeductiveTotal" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>
                <!-- -- -->
                <!-- ok dont move muestra el TOTAL CUANDO NO TIENE FONDO DE GARANTIA  -->
                <dxi-total-item *ngIf="!hasGuarantteFound && addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalLetraWithDeductiveTotal" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>
                <dxi-total-item *ngIf="!hasGuarantteFound && !addIva && estimate.deductiveTotal" name="nuevoSubtotal" summaryType="custom" [customizeText]="getTotalLetraSinIVAWithDeductiveTotal" displayFormat="{0}" showInColumn="total">
                </dxi-total-item>
                <!-- -- -->
            </dxo-summary>
        </dx-data-grid>
    </div>
    <div class="content-block2-section" *ngIf="_approvers">
        <div class="content-block-section text-center" *ngFor="let approver of
            _approvers">
            <h3 class="content-title">Firma</h3>
            <!-- <ng-container *ngIf="approver.signatureUrl; else noSignature">
            <img [src]="approver.signatureUrl" height="150px" alt="Firma aprobador" srcset="" />
          </ng-container>
          <ng-template #noSignature>
            <h3>Usuario sin firma definida</h3>
          </ng-template> -->
            <div style="height:150px;"></div>
            <div>
                <span class="info-label">Nombre: <b>{{ approver.displayName }}</b></span>
            </div>
            <br />
        </div>
    </div>

    <payment-order-log #paymentOrderDialog> </payment-order-log>
</app-page-widget>