<app-page-widget (refresh)="refreshData()">
    <app-title>Seleccione o Cree una Cotización</app-title>
    <app-subtitle></app-subtitle>

    <app-action-button icon="playlist_add" routeData="/quotes/new">Crear</app-action-button>
    <app-menu-item icon="playlist_add" routeData="/quotes/new">Crear</app-menu-item>
    <!-- ============================================================== -->
    <!-- GRID QUOTES  -->
    <!-- ============================================================== -->
    <div class="grid-container-div" *ngIf="quotes.length > 0">
        <dx-data-grid #dataGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="quotes" [hoverStateEnabled]="true" [showColumnLines]="true" [showRowLines]="true" noDataText="No hay
      registros" [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="false" (onExporting)="onExporting($event)" (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
            (onOptionChanged)="changePage($event)">
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
            <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" fileName="lista-cotizaciones"></dxo-export>
            <dxo-paging [pageSize]="10" [pageIndex]="_quotesService.pageIndex"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column alignment="center" caption="Cotización" dataField="nroQuote" [groupIndex]="0" sortOrder="desc" groupCellTemplate="quoteIdGroupTemplate" cellTemplate="quoteIdGroupTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Fecha" dataField="quoteCreatedAt" dataType="date" cellTemplate="dateTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="true" [calculateCellValue]="calculateDateCellValue">
            </dxi-column>
            <dxi-column caption="Requisición" dataField="requisitionNro" cellTemplate="requisitionGroupTemplate" groupCellTemplate="requisitionGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Creado por" dataField="quoteCreatedBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [visible]="true">
            </dxi-column>
            <dxi-column dataField="requiredQuantity" caption="Cantidad
        Requerida" [customizeText]="formatQuantity"></dxi-column>
            <dxi-column dataField="purchasedQuantity" caption="Cantidad
        Comprada" [customizeText]="formatQuantity"></dxi-column>
            <dxi-column caption="Estado" dataField="quoteStatus" cellTemplate="quoteStatusTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeStatusLabel">
            </dxi-column>
            <dxi-column caption="Aprobadores Faltantes" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
            <dxi-column caption="Detalle" dataField="quoteUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span><i class="material-icons icon-gray"
            (click)="onSelectQuote(data.key)">
            remove_red_eye
          </i></span>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="requiredQuantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="purchasedQuantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <!-- <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item> -->
                <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <!-- <div *dxTemplate="let data of 'requisitionGroupTemplate'">
                {{ data.value }}
            </div> -->
            <div *dxTemplate="let data of 'requisitionGroupTemplate'">
                <span [ngClass]="{ 'reference-link': data.key }" (click)="goToRequisition(data.key)">
                  REQ-{{data.value | idFromUuid }}</span>
            </div>
            <div *dxTemplate="let data of 'quoteIdGroupTemplate'">
                COT-{{ data.value }}
            </div>
            <div *dxTemplate="let data of 'quoteStatusTemplate'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong>
                <app-timeago-date [timestamp]="data.value"></app-timeago-date>
            </div>
            <div *dxTemplate="let data of 'createdByTemplate'">
                <app-user-data [id]="data.value"></app-user-data>
            </div>
            <div *dxTemplate="let data of 'approverRemaining'">
                <ng-container>
                    <ul class="approversRemaining">
                        <li *ngFor="let item of data.value">
                            <app-user-data *ngIf="item[0].length >= 2" [id]="item[0]"></app-user-data>
                            <span *ngIf="item[0].length === 1">{{ item }}</span>
                        </li>
                    </ul>
                </ng-container>
                <!-- <ng-container>
                    <span>{{data.value}}mmm</span>
                </ng-container> -->
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{totalCount}}</h2>
                    <span class="name">Total Cotizaciones</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
    <div class="grid-container-div text-center" *ngIf="quotes.length === 0">
        <h2>No posee cotizaciones</h2>
    </div>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>