import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  PackageToQuote,
  ProcessedPackage,
  ProcessedPackagesService,
  Quote,
  SupplierQuote,
  WarehousePackageType
} from '@bist-lib/db-api';
import { CostsCenterService } from '@bist-lib/db-api/src/services/costs-center/costs-center.service';
import { CostCenterComponent, PageWidgetComponent } from '@bist-web/shared';
import { CostCenterOptComponent } from '@bist-web/shared/logic-components/cost-center-opt/cost-center-opt.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'materials-list',
  templateUrl: './materials-list.component.html',
  styleUrls: ['./materials-list.component.scss']
})
export class MaterialsListComponent implements OnInit {
  //View children refs

  @ViewChild(PageWidgetComponent, { static: true }) public pageWidget: PageWidgetComponent;
  @ViewChild('dataGrid', { static: false }) public dataGrid: DxDataGridComponent;
  @ViewChild('ccDialog', { static: true }) public ccDialog: CostCenterComponent;
  @ViewChild('ccOptDialog', { static: true }) public ccOptDialog: CostCenterOptComponent;

  public quotes: Quote[];
  public suppliersQuotes: SupplierQuote[] = [];
  public processedPackages: ProcessedPackage[] = [];
  // These are the packages quoted from each supplier of all quotes
  public suppliersQuotePackages: any[];
  totalCount: number;
  expanded = true;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input('packages') public packages: ProcessedPackage[];
  @Input('supplierQuotesData') public supplierQuotesData: SupplierQuote[];

  constructor(
    private _costsService: CostsCenterService,
    private _processedPackagesService: ProcessedPackagesService
  ) {}

  ngOnInit() {
    this.refreshData();
  }

  public refreshData(): void {
    this.pageWidget.toggleLoading(true);

    this._costsService.getQuotes(this.unsubscribe$).subscribe(
      async (quotes: Quote[]) => {
        this.quotes = quotes;
        this.suppliersQuotes = [];
        this.processedPackages = [];
        this.suppliersQuotePackages = [];
        this.quotes.map((singleQuote: Quote) => {
          this._costsService.getSuppliersQuotes(singleQuote.uuid).subscribe((supplierQuotes) => {
            this.suppliersQuotes.push(...supplierQuotes);
            supplierQuotes.map((supQuote) => {
              supQuote.packagesToQuote.map((pkgToQte) => {
                this.suppliersQuotePackages.push({ ...pkgToQte, supplierId: supQuote.supplierId });
              });
            });
          });

          this._costsService
            .getProcessedPackages(singleQuote.uuid)
            .subscribe((processedPkg: ProcessedPackage[]) => {
              this.processedPackages.sort((a, b) => b.quantity - a.quantity).push(...processedPkg);
            });
        });

        const paymentCheckOrders: any[] = await this._costsService.getPaymentCheckOrders();

        const poPackageProms = paymentCheckOrders.map((po) => {
          return this._processedPackagesService
            .getPaymentCheckPackages(po.uuid)
            .pipe(take(1))
            .toPromise();
        });

        const poPackages: ProcessedPackage[] = (await Promise.all(poPackageProms)).reduce(
          (acum, current) => [...acum, ...current],
          []
        );

        this.pageWidget.toggleLoading(false);

        poPackages.map((checkPackages) => {
          this.suppliersQuotePackages.push({ ...checkPackages });
        });

        this.totalCount = this.suppliersQuotePackages.length;
      },
      (err: any) => console['error']('ERR!', err)
    );
  }

  public onRowSelected(singlePackage: PackageToQuote) {
    this.ccOptDialog.open(singlePackage);
    //this.ccDialog.open(singlePackage, true);
  }
  public calculateLeftoverQuantityCellValue(rowData: PackageToQuote) {
    const col: any = this as any;
    if (!rowData.leftoverQuantity) {
      rowData.leftoverQuantity = 0;
    }
    return col.defaultCalculateCellValue(rowData);
  }
  public calculateLeftoverTypeCellValue(rowData: PackageToQuote) {
    const col: any = this as any;
    if (!rowData.leftoverType) {
      rowData.leftoverType = WarehousePackageType.UNDEF;
    }
    return col.defaultCalculateCellValue(rowData);
  }

  public formatQuantity(data: any): string {
    const decimalPipe: DecimalPipe = new DecimalPipe('en');
    const formated: string = decimalPipe.transform(data.value, '1.2-2');
    return formated;
  }

  public formatTotal(data: any): string {
    const decimalPipe: DecimalPipe = new DecimalPipe('en');
    const formated: string = decimalPipe.transform(data.value, '1.2-2');
    return `$${formated}`;
  }

  public calculateTotalCost(column: any): number {
    const quantity: number =
      column.purchasedQuantity || column.quantity
        ? column.purchasedQuantity || column.quantity
        : column.requiredQuantity;
    const rowData: number = Number(quantity) * Number(column.supplierCost) || Number(column.total);
    return rowData;
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'totalGroupCount'
      },
      {
        location: 'before',
        widget: 'dxSelectBox',
        options: {
          width: 200,
          items: [
            {
              value: 'supplierId',
              text: 'Por proveedor'
            },
            {
              value: 'concept',
              text: 'Por concepto'
            }
          ],
          displayExpr: 'text',
          valueExpr: 'value',
          value: 'concept',
          onValueChanged: this.groupChanged.bind(this)
        }
      },
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          width: 136,
          text: 'Contraer Todos',
          onClick: this.collapseAllClick.bind(this)
        }
      }
    );
  }

  collapseAllClick(e) {
    this.expanded = !this.expanded;
    e.component.option({
      text: this.expanded ? 'Contraer Todos' : 'Expandir Todos'
    });
  }
  groupChanged(e) {
    this.dataGrid.instance.clearGrouping();
    this.dataGrid.instance.columnOption(e.value, 'groupIndex', 0);
    this.totalCount = this.suppliersQuotePackages.length;
  }
}
