<app-page-widget>
    <app-title>Nuevo Anticipo</app-title>
    <app-subtitle> Para: Contratos tasa 0% / Subcontratos</app-subtitle>

    <div class="content-section">
        <div class="content-sub-section">
            <h3 class="content-title">Seleccionar sección y proveedor:</h3>
            <div class="content-section">
                <app-select-box class="selectProv" [widthSelect]="300" [dataSource]="categoryes" displayExpr="TypeText" (selectItem)="onSelectBoxSelectionCategory($event)" placeholder="selecionar tipo">
                </app-select-box>
                <div class="selectProv">
                    <app-select-box class="" [widthSelect]="550" [dataSource]="spliers" displayExpr="socialReason" (selectItem)="onSelectBoxSelectionSupplier($event)" placeholder="selecionar proveedor">
                    </app-select-box>
                </div>
            </div>

            <div class="footer margin-left-20">
                <small>
          Generar anticipo sobre asignación previamente aprobada solo se permite un anticipo por
          asignación.
        </small>
            </div>

            <h3 class="content-title margin-top-40">Seleccionar Asignación:</h3>

            <dx-data-grid #assignmentsGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="assignments" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no éxisten registros." [showBorders]="true" [rowAlternationEnabled]="false"
                [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" [(selectedRowKeys)]="Rows" (onRowClick)="rowDetails($event)">
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
                <dxi-column caption="ID" dataField="id" width="7%" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column caption="Contrato" dataField="nroContract" width="7%" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column caption="Fecha" width="25%" dataField="createdAt" [allowEditing]="false" dataType="date" [calculateCellValue]="calculateDateCellValue" cellTemplate="dateTemplate" [allowFiltering]="true" [allowSorting]="true" sortOrder="desc" alignment="center">
                </dxi-column>
                <!-- <dxi-column caption="Status" dataField="status" width="15%" cellTemplate="assignmentStatusTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center"> </dxi-column> -->
                <dxi-column caption="Solicitante" dataField="createdBy" cellTemplate="createdByTemplate" width="25%" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
                </dxi-column>
                <dxi-column caption="Proveedor" dataField="supplierId" cellTemplate="supplierTemplate" width="40%" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
                </dxi-column>
                <dxi-column caption="Monto" width="15%" dataField="totalAssignment" cellTemplate="amountTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
                </dxi-column>
                <div *dxTemplate="let data of 'assignmentStatusTemplate'">
                    <span>{{ data.value | assignmentStatus }}</span>
                </div>
                <div *dxTemplate="let data of 'dateTemplate'">
                    <strong> {{ data.value | timestampToDate }}</strong> -
                    <span> hace {{ data.value | timeAgo }}</span>
                </div>
                <div *dxTemplate="let data of 'amountTemplate'">
                    <span>$ {{ data.value | number }}</span>
                </div>
                <div *dxTemplate="let data of 'createdByTemplate'">
                    <strong>{{ data.value | userData | async }}</strong>
                </div>
                <div *dxTemplate="let data of 'supplierTemplate'">
                    <strong>{{ data.value | supplierData | async }}</strong>
                </div>
            </dx-data-grid>

            <h3 class="content-title margin-top-40 mb-0">Datos para generar anticipo:</h3>
            <h5 class="content-title margin-left-15">(Debe seleccionar primero una asignación)</h5>

            <div class="content-section margin-left-20">
                <form #f="ngForm" [formGroup]="myForm" class="loan-form">
                    <mat-form-field class="loan-full-width">
                        <input formControlName="monto" [(ngModel)]="amount" [readonly]="!Rows?.length" (ngModelChange)="calPercentage()" matInput type="number" placeholder="Monto" required />
                    </mat-form-field>
                    <mat-form-field class="loan-full-width ml-10">
                        <input formControlName="percentage" [(ngModel)]="percentage" [readonly]="!Rows?.length" (ngModelChange)="calAmount()" matInput type="number" placeholder="Porcentaje" required />
                    </mat-form-field>
                    <button mat-raised-button [disabled]="!Rows?.length || (amount === 0 && percentage === 0)" (click)="calculate(f.value)" id="btn" color="primary" type="button">
                        <mat-icon>monetization_on</mat-icon> Calcular
                    </button>
                    <br />
                    <!-- [disabled]="!selectedGridItems" -->
                </form>
            </div>

            <div class="footer">
                <button mat-raised-button [disabled]="!Rows?.length && myForm.invalid" (click)="onSubmit(f.value)" id="btn" color="primary" type="submit">
          <mat-icon>monetization_on</mat-icon> Crear anticipo
        </button>
            </div>
        </div>
    </div>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess"></app-loading-indicator>