<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Seleccione una Orden de Pago</app-title>
    <app-subtitle></app-subtitle>

    <!-- <button class="btn"
          mat-raised-button
          color="primary"
          [disabled]="!this.selectedGridItems"
          (click)="seeDetail()">
    Ver detalle de Orden de Pago
  </button> -->
    <!-- ============================================================== -->
    <!-- GRID PURCHASE ORDER  -->
    <!-- ============================================================== -->
    <dx-data-grid #payGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="paymentsOrders" [masterDetail]="{ enabled: false,
        template:
        'detail' }" [hoverStateEnabled]="true" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no
        éxisten registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" (onSelectionChanged)="onGridSelection($event)" (onExporting)="onExporting($event)"
        (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)" (onOptionChanged)="changePage($event)">

        <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
        <!-- <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
    </dxo-pager> -->
        <dxo-export [enabled]="true" fileName="lista-ordenen-pago"></dxo-export>
        <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-paging [pageSize]="10" [pageIndex]="_paymentOrdersService.pageIndex"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column alignment="center" caption="ID" dataField="id" [groupIndex]="0" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false">
        </dxi-column>
        <dxi-column alignment="center" caption="Orden ID" cellTemplate="orderId" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false" [calculateCellValue]="customizeOrderLabel">
        </dxi-column>
        <dxi-column caption="Fecha" dataType="date" dataField="createdAt" cellTemplate="dateTemplate" sortOrder="desc" alignment="left" [allowEditing]="false" [allowSorting]="true" [allowFiltering]="true" [calculateCellValue]="calculateDateCellValue">
        </dxi-column>
        <dxi-column caption="Creado por" dataField="createdBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
        </dxi-column>
        <dxi-column alignment="left" caption="Proveedor" dataField="supplier.name" cellTemplate="supplierNameTemplate" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Concepto" dataField="concept" [allowSorting]="false" [allowSearch]="true" [groupIndex]="0" alignment="left">
        </dxi-column>
        <dxi-column caption="Marca" dataField="supplierBrand" [allowSorting]="false" [allowSearch]="true" alignment="left">
        </dxi-column>
        <dxi-column caption="Modelo" dataField="supplierModel" [allowSorting]="false" [allowSearch]="true" alignment="left">
        </dxi-column>
        <dxi-column caption="Unidad" dataField="unit" cellTemplate="unitTemplate" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Cantidad" dataField="requiredQuantity" [calculateCellValue]="getQty" [allowSorting]="false" cellTemplate="quantityTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="P.U" dataField="unitPrice" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="P.U Real" dataField="realPrice" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="P.U Proveedor" dataField="supplierCost" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="Costo inicial" dataField="costOriginal" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="Subtotal" [allowSorting]="false" cellTemplate="costTemplate" [calculateCellValue]="calculateSubtotal" alignment="center">
        </dxi-column>
        <dxi-column caption="Impuesto" [allowSorting]="false" [calculateCellValue]="calculateTax" cellTemplate="costTemplate" alignment="center">
        </dxi-column>
        <dxi-column caption="Total" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotal" alignment="center">
        </dxi-column>
        <dxi-column caption="Importe" [allowSorting]="false" cellTemplate="costTemplate" [calculateCellValue]="calculateImportColumn" alignment="center">
        </dxi-column>
        <dxi-column alignment="center" caption="Estado" dataField="status" cellTemplate="statusTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeStatusLabel">
        </dxi-column>
        <dxi-column caption="Aprobadores Faltantes" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
        <dxi-column caption="Detalle" dataField="uuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span><i class="material-icons icon-gray"
        (click)="seeDetail(data)">
        remove_red_eye
      </i></span>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'costTemplate'" class="cost">
            <span>$ {{ data.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
            <span>{{ data.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'totalTemplate'" class="cost">
            <span>$ {{ data.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let data of 'supplierNameTemplate'">
            <ng-container *ngIf="data.value; else noSupplier">
                <span>{{ data.value }}</span>
            </ng-container>
            <ng-template #noSupplier>
                No hay nombre de proveedor
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'">
            <strong>{{ data.value === 'Aprobada' ? 'Pagada' : data.value }}</strong>
        </div>
        <div *dxTemplate="let d of 'estimateTemplate'">
            <span class="current-value">{{ d.value | number: '1.2-2' }}%</span>
        </div>
        <div *dxTemplate="let data of 'orderId'">
            <span [ngClass]="{ 'reference-link': data.key }" (click)="goToOrder(data)">
                <strong>{{ data.value | idFromUuidPrefix }}</strong>
            </span>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{totalCount}}</h2>
                <span class="name">Total Ordenes</span>
            </div>
        </div>

    </dx-data-grid>
    <app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
    </app-loading-indicator>
</app-page-widget>