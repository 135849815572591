<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Proveedores</app-title>
    <app-subtitle>Listado. Seleccione para editar.</app-subtitle>

    <!-- <ng-template [ngxPermissionsExcept]="['impersonate:supplier']"> -->
    <app-action-button icon="file_upload" tooltip="Importar desde archivo Excel" routeData="/settings/suppliers-import">Importar</app-action-button>
    <app-action-button icon="person_add" (click)="openManagementDialog()">Nuevo</app-action-button>
    <!-- </ng-template> -->

    <app-menu-item icon="file_upload" routeData="/settings/suppliers-import">Importar Excel
    </app-menu-item>
    <div>
        <mat-form-field class="searchbar-width">
            <input matInput placeholder="Buscar proveedor por nombre" [formControl]="searchBarString" />
        </mat-form-field>
        <button mat-icon-button (click)="clearSearchString()">
      <mat-icon aria-hidden="false" aria-label="Example home icon">clear</mat-icon>
    </button>
    </div>
    <app-datatable #datatable [columns]="dtColumns" [rows]="suppliers" [showImpersonate]="true" (changedRowsSelection)="openManagementDialog($event)" [filterBy]="searchBarString" [requiredColumns]="requiredProps">
    </app-datatable>
</app-page-widget>

<!-- Management Dialog -->
<app-dialog #managementDialog noPadding (dismiss)="onCloseManagementDialog()">
    <app-title>{{ selectedSupplier ? selectedSupplier.name || selectedSupplier.email : 'Datos del Proveedor' }}
    </app-title>
    <app-subtitle>{{ selectedSupplier ? 'Datos del Proveedor' : '' }}</app-subtitle>

    <!-- DISABLED:
  <app-menu-item icon="delete"
                 (click)="deleteRecord(selectedSupplier)">Eliminar Proveedor</app-menu-item>
  -->

    <div class="width-500">
        <ng-template [ngxPermissionsExcept]="['impersonate:supplier']">
            <mat-tab-group [dynamicHeight]="true">
                <mat-tab label="Datos">
                    <div class="width-700 height-500 padding-2">
                        <app-form [fields]="formFields" [model]="selectedSupplier || {}" [noCancelButton]="false" (save)="saveData($event.value)" (cancel)="managementDialog.close()">
                        </app-form>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="selectedSupplier" label="Usuarios">
                    <app-datatable canSelectMultiple selectWithCheckboxes [filterBy]="['id', 'displayName', 'position']" [columns]="usersDtColumns" [rows]="users" [selected]="dtSelectedUsers" (changedRowsSelection)="setSupplierUsers(selectedSupplier, $event)">
                    </app-datatable>
                </mat-tab>
            </mat-tab-group>
        </ng-template>

        <ng-template [ngxPermissionsOnly]="['impersonate:supplier']">
            <div *ngIf="selectedSupplier && selectedSupplier.impersonate" class="width-100 padding-h">
                <button mat-raised-button color="primary" class="margin-bottom-2" (click)="impersonateSupplier(selectedSupplier)">
          Personificar
        </button>
                <mat-divider></mat-divider>
            </div>
        </ng-template>
    </div>
</app-dialog>