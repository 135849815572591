import { NgModule } from "@angular/core";
import { SharedModule } from '@bist-web/shared/shared.module';
import { NewRemissionComponent } from "./new-remission/new-remission.component";
import { RemissionDetailComponent } from './remission-detail/remission-detail.component';
import { RemissionsListComponent } from "./remissions-list/remissions-list.component";
import { RemissionsComponent } from './remissions.component';
import { SupplierRemissionsRoutingModule } from './remissions.routing';
import { OperationsResumeComponent } from './operations-resume/operations-resume.component';

@NgModule({
  imports: [
    SharedModule,
    SupplierRemissionsRoutingModule
  ],
  entryComponents: [
    RemissionsComponent
  ],
  declarations: [
    NewRemissionComponent,
    RemissionDetailComponent,
    RemissionsListComponent,
    RemissionsComponent,
    OperationsResumeComponent
  ],
  exports: [
    RemissionsComponent
  ]
})
export class SupplierRemissionsModule { }
