<app-page-widget (refresh)="refreshData()">
    <app-title>Historico de Remesas</app-title>
    <app-subtitle></app-subtitle>

    <div class="content-block">
        <dx-data-grid #remittancesGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="remittances" [masterDetail]="{ enabled: false, template:
            'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no éxisten registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false"
            [allowColumnResizing]="false" (onRowClick)="onRowClick($event)">
            <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="ID" dataField="id" width="10%" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Orden" dataField="orders[0].purchaseOrderId" [calculateCellValue]="getOrderCellValue" width="15%" [allowEditing]="false" cellTemplate="orderTemplate" [allowFiltering]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Fecha" dataField="createdAt" width="20%" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" dataType="date" cellTemplate="dateTemplate" [allowFiltering]="true" [allowSorting]="true" sortOrder="desc" alignment="center">
            </dxi-column>
            <dxi-column caption="Monto Pagado" dataField="remittanceAmount" width="30%" cellTemplate="remittanceAmountTemplate" [allowEditing]="false" [allowSorting]="false" [allowFiltering]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Creado por" dataField="createdBy" width="30%" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>

            <div *dxTemplate="let remittanceAmount of
                'remittanceAmountTemplate'">
                <span *ngIf="remittanceAmount?.value">${{ remittanceAmount.value
                    |
                    number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'orderTemplate'">
                <strong> {{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>
            <div *dxTemplate="let data of 'createdByTemplate'">
                <strong>{{ data.value | userData | async }}</strong>
            </div>
        </dx-data-grid>
    </div>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>