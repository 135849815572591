import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BinnacleService, Remission, RemissionsService, Warehouse, WarehousesService } from '@bist-lib/db-api';
import { RemissionPackage } from '@bist-lib/db-api/models';
import { TypeBinnacle } from '@bist-lib/db-api/src/models/binnacle';
import { Entry } from '@bist-lib/db-api/src/models/remission';
import { InfoService, Menu, PageWidgetComponent } from '@bist-web/shared';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { NgxPermissionsService } from 'ngx-permissions';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'remission-detail',
  templateUrl: './remission-detail.component.html',
  styleUrls: ['./remission-detail.component.scss']
})
export class RemissionDetailComponent implements OnInit {
  @ViewChild(PageWidgetComponent, { static: true }) public pageWidget: PageWidgetComponent;

  private remissionId: string;
  public remission: Remission;
  public warehouse: Warehouse;
  public isRemissionReadyToDownload: boolean = true;
  public remissionPackages: RemissionPackage[];
  public delete = true;
  public widgetMenu: Menu = [
    { icon: 'delete_outline', title: 'Eliminar', action: () => this.cancel() }
  ];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _remissionsService: RemissionsService,
    private _infoService: InfoService,
    private _warehousesService: WarehousesService,
    private _binnacleService: BinnacleService,
    private _ngxPermissionsService: NgxPermissionsService,
    private router: Router
  ) {}

  ngOnInit() {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.remissionId = params.remissionId;
      this.refreshData();
    });

    // this.widgetMenu = [];
    // this.getWidgetMenu();
  }

  // public async getWidgetMenu(): Promise<void> {
  //   await this._ngxPermissionsService.hasPermission('remission:delete').then((permission) => {
  //     if (permission) {
  //       this.widgetMenu = [
  //         { icon: 'delete_outline', title: 'Eliminar', action: () => this.cancel() }
  //       ];
  //     }
  //   });
  // }

  refreshData(): void {
    this.pageWidget.toggleLoading(true);
    this._remissionsService.get(this.remissionId).subscribe(
      async (remission: Remission) => {
        const warehouse: Warehouse = await this._warehousesService
          .get(remission.warehouse)
          .pipe(take(1))
          .toPromise();

        this.warehouse = warehouse;

        const dataEntries = await this._remissionsService
          .getRemissionsCollection(remission.uuid, 'entries')
          .getList()
          .pipe(take(1))
          .toPromise();

        dataEntries
          .filter((singleEntry) => singleEntry.type === 1)
          .map((entry: Entry) => {
            const matchedPackageIndex: number = remission.packages.findIndex(
              (singlePackage: RemissionPackage) => singlePackage.concept === entry.concept
            );
            if (matchedPackageIndex !== -1) {
              remission.packages[matchedPackageIndex].quantitySent = entry.quantity;
              remission.packages[matchedPackageIndex].quantityApproved = entry.quantityReceived;
              if (entry.quantityReceived > 0) {
                this.delete = false;
              }
            }
          });

        this.remission = remission;
        this.remissionPackages = remission.packages;
        this.pageWidget.toggleLoading(false);
      },
      (err: any) => console.log(err)
    );
  }

  public cancel(): void {
    if (this.delete === true) {
      this._infoService.confirm('¿Está usted seguro?', 'Cancelar Remisión', true).then((result) => {
        this.pageWidget.toggleLoading(true);
        this._binnacleService
          .create({ type: TypeBinnacle.Remission, data: [this.remission] })
          .subscribe(() => {
            this._remissionsService.forceDelete(this.remission.uuid).subscribe(() => {
              this.pageWidget.toggleLoading(false);
              this._infoService.success('Remisión eliminada');
              this.router.navigate(['/supplier-remissions/list']);
            });
          });
      });
    } else {
      this._infoService.info('Ya se dió entrada a por lo menos un concepto de esta remisión');
    }
  }

  public downloadRemission(): void {
    this.isRemissionReadyToDownload = false;

    setTimeout(() => {
      const order: HTMLElement = document.getElementById('remission');

      html2canvas(order, { useCORS: true, allowTaint: false }).then((canvas: any) => {
        const HTML_Width = canvas.width;
        const HTML_Height = canvas.height;
        const top_left_margin = 15;
        const PDF_Width = HTML_Width + top_left_margin * 2;
        const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
        const canvas_image_width = HTML_Width;
        const canvas_image_height = HTML_Height;
        const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
        canvas.getContext('2d');
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          'JPG',
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );
        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage([PDF_Width, PDF_Height], 'p');
          pdf.addImage(
            imgData,
            'JPG',
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          );
        }
        pdf.save(`remission-${this.remissionId}.pdf`);
      });
    }, 2000);
  }

  //   exportAsPDF(data) {

  //     html2canvas(data).then(canvas => {
  //       const HTML_Width = canvas.width;
  //       const HTML_Height = canvas.height;
  //       const top_left_margin = 15;
  //       const PDF_Width = HTML_Width + (top_left_margin * 2);
  //       const PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
  //       const canvas_image_width = HTML_Width;
  //       const canvas_image_height = HTML_Height;
  //       const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
  //       canvas.getContext('2d');
  //       const imgData = canvas.toDataURL('image/png', 1.0);
  //       const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
  //       for (let i = 1; i <= totalPDFPages; i++) {
  //         pdf.addPage([PDF_Width, PDF_Height], 'p');
  //         pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
  //       }
  //       pdf.save(`${this.quote.folio}.pdf`);
  //     });
  //  }

  printQuote() {
    window.print();
  }
}
