<ng-template #contentTpl>
  <app-widget
    [round]="false"
    [flat]="true"
    [noHeader]="noHeader"
    [stretchHeader]="stretchHeader"
    [noPadding]="noPadding"
    [bigText]="bigText"
    [displayBackButton]="displayBackButton"
    [onBackObservers]="onBackObservers"
    [onRefreshObservers]="onRefreshObservers"
    [onDismissObservers]="widgetDismissObservers"
    [title]="title || null"
    [subTitle]="subTitle || null"
    [actionButtons]="actionButtons"
    [actionsOnBottom]="actionsOnBottom"
    [menuItems]="menuItems"
    [helpLink]="helpLink"
    [loading]="loading"
    [loadPercent]="loadPercent"
    [performingAction]="performingAction"
    [actionPercent]="actionPercent"
  >
    <ng-content></ng-content>
  </app-widget>
</ng-template>
