<mat-form-field class="app-form-password">
  <input matInput
         [type]="showPasswd ? 'text' : 'password'"
         [attr.disabled]="disabled"
         [placeholder]="placeholder"
         [formControl]="control">

  <mat-icon matSuffix
            class="eye"
            (mousedown)="showPasswd = true"
            (mouseup)="showPasswd = false">
    {{ showPasswd ? 'visibility' : 'visibility_off' }}
  </mat-icon>
</mat-form-field>
