<app-page-widget (dismiss)="gotoList()">
  <app-title>Importar Paquetes desde Excel</app-title>
  <app-subtitle>Por favor, seleccione un archivo</app-subtitle>

  <app-excel-import
    [map]="map"
    [service]="packagesService"
    [uploadType]="'PackagesService'"
  ></app-excel-import>
</app-page-widget>
