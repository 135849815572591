<app-page-widget noPadding (refresh)="refreshData()" [menuItems]="widgetMenu" id="payment-check-order">
    <app-title>OCH-{{ paymentCheckOrder?.uuid | idFromUuid }} </app-title>
    <app-subtitle>{{ paymentCheckOrder?.createdAt | timestampToDate: 'long' }}</app-subtitle>

    <app-action-button flat noText icon="done_all" (click)="viewApprovers()">Aprobaciones</app-action-button>

    <div class="actions-block">
        <button mat-raised-button class="action-button mr-5" (click)="goToPreviousUlr()" color="warn">
      <mat-icon>undo</mat-icon>
      Regresar
  </button>
        <ng-template [ngIf]="sendToRemittancesIsAvailable">
            <button mat-raised-button class="action-button" *ngxPermissionsOnly="['remittances:can_send_to_remittances']" (click)="sendToRemittances()" color="primary">
        <mat-icon>playlist_add_check</mat-icon>
        Enviar a Remesas
      </button>
        </ng-template>
        <button mat-raised-button class="action-button" *ngIf="approveIsAvailable" (click)="approve()" color="primary">
      <mat-icon>done</mat-icon>
      Aprobar
    </button>
        <button mat-raised-button class="action-button" *ngIf="sendToApproveIsAvailable" (click)="sendToApprove()" color="primary">
      <mat-icon>call_made</mat-icon>
      Enviar a Aprobar
    </button>

        <button mat-raised-button class="action-button" *ngIf="isOrderReadyToDownload" (click)="downloadOrder()" color="primary">
      <mat-icon>cloud_download</mat-icon>
      Descargar orden
    </button>
    </div>

    <div class="header-block">
        <span class="info-label">Monto Total:
      <strong>${{ paymentCheckOrder?.totalOrderAmount | number: '1.2-2' }}</strong>
    </span>
        <span class="info-label">Pagado:
      <strong>${{ paymentCheckOrder?.paidAmmount | number: '1.2-2' }}</strong>
    </span>
        <span class="info-label">Pasivos:
      <strong>${{ paymentCheckOrder?.passiveAmmount | number: '1.2-2' }}</strong>
    </span>
    </div>

    <div class="header-block">
        <span class="info-label">Estatus:
      <strong>{{ paymentCheckOrder?.status | paymentCheckOrderStatus }}</strong>
    </span>
        <span class="info-label">Solicitado por:
      <strong>{{ paymentCheckOrder?.createdBy | userData | async }}</strong>
    </span>
    </div>

    <div id="packages" class="content-block">
        <h3 class="content-title">Paquetes</h3>
        <dx-data-grid #pcoGrid id="pcoGrid" [wordWrapEnabled]="true" [dataSource]="packages" [masterDetail]="{ enabled: false, template: 'detail' }" [hoverStateEnabled]="false" [wordWrapEnabled]="true" [showColumnLines]="true" [showRowLines]="true" noDataText="Sin paquetes disponibles."
            [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false">
            <dxo-editing mode="cell" [allowUpdating]="false"></dxo-editing>
            <dxo-export [enabled]="false"></dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-search-panel [visible]="false" [highlightSearchText]="false"></dxo-search-panel>
            <dxo-paging [pageSize]="5"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>

            <dxi-column caption="Tipo" dataField="type" cellTemplate="packageTypeTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" width="25%" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <dxi-column caption="Unidad" dataField="unit" width="10%" cellTemplate="unitTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad" dataField="quantity" width="10%" [allowSorting]="false" cellTemplate="quantityTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Costo" dataField="cost" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Subtotal" width="15%" [allowSorting]="false" cellTemplate="subtotalTemplate" [calculateCellValue]="calculateTotalCost" alignment="center">
            </dxi-column>
            <dxi-column caption="Impuestos" width="10%" [allowSorting]="false" cellTemplate="taxTemplate" [calculateCellValue]="calculateTaxes" alignment="center">
            </dxi-column>
            <dxi-column caption="Total" width="15%" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotalOrder" alignment="center">
            </dxi-column>
            <dxo-group-panel [visible]="false"> </dxo-group-panel>

            <div *dxTemplate="let data of 'packageTypeTemplate'">
                {{ data.value === packageTypes.indirect ? 'UNIDAD' : 'DERIVADO' }}
            </div>
            <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                <span>{{ data.value | number: '1.0-0' }}</span>
            </div>
            <div *dxTemplate="let data of 'costTemplate'" class="cost">
                <span>$ {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'subtotalTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'taxTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'totalTemplate'" class="cost">
                <span>$ {{ data.value | number: '1.2-2' }}</span>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-total-item column="Subtotal" summaryType="sum" alignment="center" [customizeText]="formatSubTotal">
                </dxi-total-item>
                <dxi-total-item column="Impuestos" summaryType="sum" alignment="center" [customizeText]="formatImpuestos">
                </dxi-total-item>
                <dxi-total-item column="Total" summaryType="sum" alignment="center" [customizeText]="formatTotal">
                </dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
    <div class="content-block2-section" *ngIf="_approvers">
        <div class="content-block-section text-center" *ngFor="let approver of _approvers">
            <h3 class="content-title">Firma</h3>
            <!-- <ng-container *ngIf="approver.signatureUrl; else noSignature">
        <img [src]="approver.signatureUrl" height="150px" alt="Firma aprobador" srcset="" />
      </ng-container>
      <ng-template #noSignature>
        <h3>Usuario sin firma definida</h3>
      </ng-template> -->
            <div style="height:150px;"></div>
            <div>
                <span class="info-label">Nombre: <b>{{ approver.displayName }}</b></span
        >
      </div>
      <br />
    </div>
  </div>
</app-page-widget>