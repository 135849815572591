import { Pipe, PipeTransform } from '@angular/core';
import { PackageType } from '../../models';
import { BudgetTableVm } from '../../models/budget';
import {
  ProjectionTableVm,
  PurchaseOrdersSummaryVm,
  PurchaseOrderVm
} from '../../models/ViewModels/cost-center-opt-Vms';

@Pipe({ name: 'costCenterSummary' })
export class CostCenterSummaryPipe implements PipeTransform {
  transform(purchaseOrders: PurchaseOrderVm[], budgetUnitPrice: number): PurchaseOrdersSummaryVm {
    return this.calculateSummary(purchaseOrders, budgetUnitPrice);
  }

  private calculateSummary(
    purchaseOrderVms: PurchaseOrderVm[],
    budgetUnitPrice: number
  ): PurchaseOrdersSummaryVm {
    const purchasedQuantityTotal = purchaseOrderVms.reduce((acum, poOrderVm: PurchaseOrderVm) => {
      return acum + poOrderVm.quantity;
    }, 0);

    const totals: number[] = purchaseOrderVms.map((poOrderVm: PurchaseOrderVm | any) => {
      if (poOrderVm.unit === 'BOLSA' && poOrderVm.type === PackageType.Indirects) {
        return poOrderVm.unitPrice;
      } else {
        return poOrderVm.quantity * poOrderVm.unitPrice;
      }
    });

    const totalPurchased: number = totals.reduce((acum, current) => {
      return acum + current;
    }, 0);

    // const weightedAveragePrice = purchasedQuantityTotal / totalPurchased;

    const poCount = purchaseOrderVms.length;

    const puDiffs: number[] = purchaseOrderVms.map((poOrderVm) => {
      return poOrderVm.unitPrice - budgetUnitPrice;
    });
    const averageDiffPrice = puDiffs.reduce((acum, current) => acum + current, 0) / poCount;

    const totalDiffs = purchaseOrderVms.map((poOrderVm) => {
      const puDiff = poOrderVm.unitPrice - budgetUnitPrice;
      const totalDiff = poOrderVm.quantity * puDiff;

      return totalDiff;
    });

    const totalOutcome = totalDiffs.reduce((acum, current) => acum + current, 0);

    return {
      purchasedQuantityTotal,
      totalPurchased,
      averageDiffPrice,
      totalOutcome
    };
  }
}

@Pipe({ name: 'costCenterProjection' })
export class CostCenterProjectionPipe implements PipeTransform {
  transform(
    purchaseOrderVms: PurchaseOrderVm[],
    paymentCheckOrderVms: PurchaseOrderVm[],
    purchaseOrdersSummary: PurchaseOrdersSummaryVm,
    paymentCheckOrdersSummary: PurchaseOrdersSummaryVm,
    budgetTable: BudgetTableVm
  ): ProjectionTableVm {
    const projector: ProjectionTableModel = budgetTable.isBolsa
      ? new BolsaUnitTypeProjection(
          purchaseOrderVms,
          paymentCheckOrderVms,
          purchaseOrdersSummary,
          paymentCheckOrdersSummary,
          budgetTable
        )
      : new ProjectionTableModel(
          purchaseOrderVms,
          purchaseOrderVms,
          purchaseOrdersSummary,
          paymentCheckOrdersSummary,
          budgetTable
        );

    const projectionTableVm: ProjectionTableVm = projector.compute();
    return projectionTableVm;
  }
}

interface IProjectionTableModel {
  compute(): ProjectionTableVm;
  getLastPoUnitPrice(): number;
  getRemainingPurchaseTotal(): number;
}
export class ProjectionTableModel implements IProjectionTableModel {
  lastPoUnitPrice: number;
  purchasedQuantityTotal: number;
  purchasedTotal: number;
  weightedAveragePrice: number;
  budgetTotalQuantity: number;
  budgetTotal: number;

  constructor(
    private purchaseOrderVms: PurchaseOrderVm[],
    private paymentCheckOrderVms: PurchaseOrderVm[],
    private purchaseOrdersSummary: PurchaseOrdersSummaryVm,
    private paymentCheckOrdersSummary: PurchaseOrdersSummaryVm,
    private budgetTable: BudgetTableVm
  ) {
    const totalPurchased =
      purchaseOrdersSummary.totalPurchased + paymentCheckOrdersSummary.totalPurchased;
    const totalPurchasedQuantity =
      purchaseOrdersSummary.purchasedQuantityTotal +
      paymentCheckOrdersSummary.purchasedQuantityTotal;
    this.lastPoUnitPrice =
      this.purchaseOrderVms.length > 0 ? purchaseOrderVms[0].unitPrice : undefined;
    this.purchasedQuantityTotal = totalPurchasedQuantity || 0;
    this.purchasedTotal = totalPurchased || 0;
    this.weightedAveragePrice = totalPurchased / totalPurchasedQuantity || undefined;
    this.budgetTotalQuantity = this.budgetTable.quantity;
    this.budgetTotal = this.budgetTable.total;
  }

  compute(): ProjectionTableVm {
    const projectionTotal: number = this.purchasedTotal + this.getRemainingPurchaseTotal();
    const purchasedQuantityTotal =
      this.budgetTable.unit !== 'BOLSA'
        ? this.budgetTotalQuantity - this.purchasedQuantityTotal || 0
        : this.budgetTotalQuantity - this.purchasedQuantityTotal || this.budgetTotalQuantity;

    return {
      budgetTotal: this.budgetTotal,
      budgetTotalQuantity: this.budgetTotalQuantity,
      isDerivative: this.isBolsa(),
      lastPoUnitPrice: this.lastPoUnitPrice,
      purchasedQuantityTotal: this.purchasedQuantityTotal,
      // purchasedQuantityTotal: this.purchasedQuantityTotal,
      purchasedTotal: this.purchasedTotal,
      weightedAveragePrice: this.weightedAveragePrice,
      // remainingPurchaseQty: this.getRemainingPurchaseQty(),
      remainingPurchaseQty: purchasedQuantityTotal,
      remainingPurchaseTotal: this.getRemainingPurchaseTotal(),
      forecastBalance: projectionTotal - this.budgetTotal,
      projectionTotal
    };
  }
  getLastPoUnitPrice(): number {
    return this.purchaseOrderVms[0].unitPrice;
  }
  getRemainingPurchaseTotal(): number {
    const remainingPurchaseQty = this.budgetTotalQuantity - this.purchasedQuantityTotal;
    return this.purchasedQuantityTotal > 0
      ? remainingPurchaseQty * this.lastPoUnitPrice
      : this.budgetTotal;
  }
  isBolsa(): boolean {
    return false;
  }
  getRemainingPurchaseQty(): number {
    return this.budgetTotalQuantity - this.purchasedQuantityTotal;
  }
}

export class BolsaUnitTypeProjection extends ProjectionTableModel {
  isBolsa(): boolean {
    return true;
  }
  getRemainingPurchaseTotal(): number {
    return this.budgetTotal - this.purchasedTotal;
  }
}
