import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, Observer, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private ngxPermissionsService: NgxPermissionsService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkForPermissions(route.routeConfig.path);
  }
  public canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return of(true);
  }
  private checkForPermissions(path: string) {
    const permissionName = `${path}`;
    const permissionAction = 'all';
    const permission = `${permissionName}:${permissionAction}`;

    return new Observable<boolean>((observer: Observer<boolean>) => {
      this.ngxPermissionsService.hasPermission(permission).then(granted => {
        if (granted) {
          observer.next(granted);
          observer.complete();
        } else {
          this.router.navigate(['/']);
          observer.complete();
        }
      })
    });
  }

}