<app-page-widget [menuItems]="widgetMenu" [actionButtons]="widgetActions" (refresh)="refreshData()">
    <app-title>Avance de paquetes - {{ title }}</app-title>
    <app-subtitle></app-subtitle>

    <div class="content" *ngIf="asignmentWithPacks">
        <!-- [columnHidingEnabled]="true" -->
        <dx-data-grid #assignmentsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="asignmentWithPacks" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
            no
            existen registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="true" (onOptionChanged)="changePage($event)" (onRowValidating)="onRowValidatingData($event)">
            <dxo-paging [pageSize]="50" [pageIndex]="_assignmentsService.pageIndexWorkforce"></dxo-paging>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" fileName="lista-avances"></dxo-export>
            <!-- <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"></dxo-selection> -->
            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxi-column caption="Wbs" [allowEditing]="false" sortOrder="desc" [allowSearch]="true" dataField="wbs" [groupIndex]="0" groupCellTemplate="wbsGroupTemplate" cellTemplate="wbsGroupTemplate" width="10%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true"
                alignment="center">
            </dxi-column>
            <dxi-column caption="ID" [allowEditing]="false" sortOrder="desc" [allowSearch]="true" dataField="asignmentSmallId" [groupIndex]="2" groupCellTemplate="assignmentGroupTemplate" cellTemplate="assignmentGroupTemplate" width="10%" [allowEditing]="false" [allowSorting]="false"
                [allowSearch]="true" alignment="center">
            </dxi-column>
            <!-- <dxi-column caption="Ultimo avance" width="20%" [allowEditing]="false" dataType="date" cellTemplate="dateLastTemplate" dataField="dateLastAdvance" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" [allowSearch]="true" sortOrder="desc"
                alignment="center">
            </dxi-column>
            <dxi-column caption="Semana" width="15%" [allowEditing]="false" cellTemplate="weekTemplate" dataField="week" [allowSorting]="true" [allowSearch]="true" alignment="center">
            </dxi-column> -->
            <dxi-column caption="ID Paquete" dataField="packageId" groupCellTemplate="packageIdGroupTemplate" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Código" dataField="code" groupCellTemplate="codeGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <!-- <dxi-column caption="Cantidad" dataField="quantity" width="15%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column> -->
            <dxi-column caption="Unidad" dataField="unit" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="center"></dxi-column>
            <dxi-column caption="Nivel" cellTemplate="levelGroupTemplate" groupCellTemplate="levelGroupTemplate" dataType="string" dataField="level" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Proveedor" dataField="supplierName" alignment="center" [groupIndex]="1" cellTemplate="supplierTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <!-- <dxi-column caption="Capturador" width="15%" dataField="capturador" alignment="center" cellTemplate="userDataTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Avance Anterior Acumulado" width="15%" dataField="previousTotalAdvance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Avance Semanal" width="15%" dataField="advance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column> -->
            <dxi-column caption="Avance a la Fecha" dataField="totalAdvance" alignment="center" cellTemplate="advanceTemplate" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Monto avanzado a la Fecha" dataField="costAdvance" alignment="center" cellTemplate="advanceCostTemplate" [allowEditing]="false" [allowSearch]="true" [allowFiltering]="true" [allowSorting]="false">
            </dxi-column>
            <!-- <dxi-column *ngxPermissionsOnly="['advance:create']" caption="% avanzado" dataField="percentToAdvance" [calculateCellValue]="calcPercentageToAdvance" cellTemplate="percentToAdvanceTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column *ngxPermissionsOnly="['advance:create']" caption="Cantidad avanzada" [calculateCellValue]="calcQuantityToAdvance" dataField="quantityToAdvance" cellTemplate="quantityToAdvanceTemplate" [allowEditing]="true" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Semana a Capturar" width="15%" [allowEditing]="true" cellTemplate="weekTemplate" dataField="weekRequest" [allowSorting]="true" [allowSearch]="true" alignment="center">
            </dxi-column>
            <dxi-column caption="Detalle" dataField="quoteUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false" [allowEditing]="false"></dxi-column>

            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span>
                        <i class="material-icons icon-gray" (click)="onSelectAdvance(data.key)">
                            remove_red_eye
                        </i>
                    </span>
            </div> -->

            <div *dxTemplate="let data of 'advanceTemplate'">
                <span *ngIf="data.value">{{ data.value | number:'1.2-3' }} %</span>
                <span *ngIf="!data.value">0%</span>
            </div>
            <div *dxTemplate="let data of 'advanceCostTemplate'">
                <span *ngIf="data.value">{{ data.value | number:'1.2-3' }}</span>
                <span *ngIf="!data.value">0</span>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>

            <div *dxTemplate="let data of 'dateLastTemplate'">
                <span *ngIf="data.value"><strong> {{ data.value | timestampToDate }}</strong> -</span>
                <span *ngIf="data.value"> hace {{ data.value | timeAgo }}</span>
                <span *ngIf="!data.value">--</span>
            </div>

            <div *dxTemplate="let data of 'supplierTemplate'">
                <app-supplier-data [id]="data.value"></app-supplier-data>
            </div>

            <div *dxTemplate="let data of 'packageIdGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'levelGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'assignmentGroupTemplate'">ASG-{{ data.value }}
            </div>
            <div *dxTemplate="let data of 'wbsGroupTemplate'">{{ data.value }}
            </div>


            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalPackages }}</h2>
                    <span class="name">Total Paquetes</span>
                </div>
            </div>

            <dxo-summary [skipEmptyValues]="false">
                <!-- <dxi-total-item name="totalAdvance" summaryType="custom" [customizeText]="formatPercentageTotal" displayFormat="${0}" showInColumn="totalAdvance">
                </dxi-total-item> -->
                <!-- <dxi-group-item column="concept" summaryType="count" displayFormat="{0} orders" [customizeText]="formatPercentageTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item> -->
                <dxi-group-item column="totalAdvance" summaryType="avg" displayFormat="{0}" [customizeText]="formatPercentageTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="costAdvance" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
</app-page-widget>