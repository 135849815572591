export const COLSWORKFORCE: any = [
  {
    caption: 'ID Paquete',
    dataField: 'packageId',
    columnHidingEnabled: 'true',
    filterRow: { visible: false },
    allowFiltering: 'false',
    width: '8%'
  },
  {
    caption: 'Fecha',
    dataField: 'requestDate',
    dataType: 'date',
    allowSorting: 'true',
    sortOrder: 'desc',
    filterOperations: [],
    cellTemplate: 'dateTemplate',
    filterRow: { visible: 'false'},
    width: '8%'
  },
  {
    caption: 'Código',
    dataField: 'code',
    width: '10%'
  },
  {
    caption: 'Concepto',
    dataField: 'concept'
  },
  {
    caption: 'Unidad',
    alignment: 'center',
    dataField: 'unit',
    width: '7%'
  },
  {
    caption: 'Cantidad',
    alignment: 'center',
    dataField: 'quantity',
    allowSorting: 'true',
    width: '8%',
    cellTemplate: 'quantityTemplate'
  },
  {
    caption: 'Nivel',
    dataField: 'level',
    width: '10%'
  },
  {
    caption: 'P.U',
    dataField: 'cost',
    alignment: 'center',
    width: '6%',
    cellTemplate: 'costTemplate',
    calculateCellValue: (data: any) => {
      const qty: any = data.cost;
      return `${qty}`;
    }
  },
  {
      caption: 'Total',
      width: '11%',
      alignment: 'left',
      cellTemplate: 'totalTemplate',
      calculateCellValue: (data: any) => {
        const total: any = (data.cost * data.quantity);
        return total;
      }
    }
];
