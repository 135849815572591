import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NewRemissionComponent } from '@bist-web/pages/suppliers/remissions/new-remission/new-remission.component';
import { OperationsResumeComponent } from './operations-resume/operations-resume.component';
import { RemissionDetailComponent } from './remission-detail/remission-detail.component';
import { RemissionsListComponent } from './remissions-list/remissions-list.component';
import { RemissionsComponent } from './remissions.component';

export const SUPPLIER_REMISSIONS_ROUTES: Routes = [
  {

    path: 'supplier-remissions',
    component: RemissionsComponent,
    children: [
      { path: 'list', component: RemissionsListComponent },
      { path: 'new', component: NewRemissionComponent },
      { path: 'detail/:remissionId', component: RemissionDetailComponent },
      { path: 'operations-resume', component: OperationsResumeComponent },
      {
        path: '**',
        redirectTo: 'list'
      }
    ]
  }


];


export const SupplierRemissionsRoutingModule: ModuleWithProviders<any> = RouterModule.forChild(
  SUPPLIER_REMISSIONS_ROUTES
);

