<app-page-widget (refresh)="refreshData()">
    <app-title>Estado de Cuenta</app-title>
    <app-subtitle>Seleccione Proveedor para cargar el Estado de Cuenta</app-subtitle>

    <div class="header-block">
        <div class="supplier-select-box">
            <dx-select-box placeholder="Proveedor" [searchEnabled]="true" [showClearButton]="true" [dataSource]="suppliers" displayExpr="name" (onValueChanged)="onChangeSupplier($event)"></dx-select-box>
        </div>
        <div class="load-balance-button">
            <button mat-raised-button color="primary" [disabled]="!isLoadBalanceButtonAvailable" (click)="loadSupplierBalance()">
        Estado de Cuenta
      </button>
        </div>
    </div>

    <div class="balance-table-container">
        <table class="balance-table" *ngIf="accountStatus">
            <caption>
                Estado de cuenta
            </caption>
            <tbody>
                <tr>
                    <th style="width: 201px;" colspan="3">Cotizaciones Aprobadas</th>
                    <th style="width: 201px;" colspan="3">Anticipos</th>
                    <th style="width: 201px;" colspan="2">Amortizaciones</th>
                </tr>
                <tr>
                    <td style="width: 202px;">Fecha</td>
                    <td style="width: 202px;">Cot</td>
                    <td style="width: 202px;">Monto</td>
                    <td style="width: 202px;">ID</td>
                    <td style="width: 202px;">Otorgado</td>
                    <td style="width: 202px;">Pagado</td>
                    <td style="width: 202px;">Amortización</td>
                    <td style="width: 202px;">Saldo</td>
                </tr>
                <ng-container *ngFor="let row of accountStatus; let i = index">
                    <tr>
                        <td>{{ row.date | date }}</td>
                        <td>{{ row.quote }}</td>
                        <td>{{ row.ammountOrder | number: '1.2-2' }}</td>
                        <td>{{ row.advancedPaymentId }}</td>
                        <td>{{ row.advancedPaymentAmmount | number: '1.2-2' }}</td>
                        <td>{{ row.advancedPaymentAmmountPay | number: '1.2-2' }}</td>
                        <td>{{ row.amortized + row.amortized * 0.16 | number:'1.2-2' }}</td>
                        <td>{{ row.perAmortized | number:'1.2-2' }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</app-page-widget>