<app-section-nav helpLink="http://docs.bist.mx/service-desk/" *ngIf="permissionsLoaded">
  <app-title>Configuración</app-title>

  <app-nav-item *ngFor="let item of allowedItems" [routeData]="item?.routeData">
    {{ item?.title }}
  </app-nav-item>
  <!--<app-nav-item routeData="./divisions-management">Proyecto</app-nav-item>-->
  <!--<app-nav-item routeData="./companies-management">Divisiones</app-nav-item>-->
  <!--<app-nav-item routeData="./projects-management">Partidas</app-nav-item>-->
  <!--<app-nav-item routeData="./users">Usuarios</app-nav-item>-->
  <!--<app-nav-item routeData="./roles">Roles</app-nav-item>-->
  <!--<app-nav-item routeData="./suppliers">Proveedores</app-nav-item>-->
  <!--<app-nav-item routeData="./packages">Paquetes</app-nav-item>-->
  <!--<app-nav-item routeData="./templates">Templates</app-nav-item>-->
  <!--<app-nav-item routeData="./budgets">Presupuestos</app-nav-item>-->
  <!-- TODO: <app-nav-item routeData="./navigation">Navegación</app-nav-item> -->
</app-section-nav>
