import { Injectable } from '@angular/core';
import { PaymentCheckOrdersService } from '@bist-lib/db-api';
import { Quote, User } from '@bist-lib/db-api/models';
import { SharedService } from '@bist-lib/db-api/src/services/shared';
import { Observable, of, Subject, zip } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  Package,
  PackageStatus,
  PackageUnitType,
  ProcessedPackage,
  ProcessedPackageType,
  SupplierQuote
} from '../../models';
import { BudgetTableVm } from '../../models/budget';
import { PackagesService } from '../packages/packages.service';
import { ProcessedPackagesService } from '../processed-packages/processed-packages.service';
import { PurchaseOrdersService } from '../purchase-orders';
import { QuotesService } from '../quotes/quotes.service';
import { SuppliersQuotesService } from '../suppliers-quotes/suppliers-quotes.service';
import { UsersService } from '../users/users.service';

@Injectable()
export class CostsCenterService {
  public constructor(
    public _quotesService: QuotesService,
    private _suppliersQuotesService: SuppliersQuotesService,
    private _processedPackagesService: ProcessedPackagesService,
    private _userService: UsersService,
    private _purchaseOrdersService: PurchaseOrdersService,
    private _paymentCheckOrdersService: PaymentCheckOrdersService,
    private _packagesService: PackagesService,
    private _sharedService: SharedService
  ) {}
  public getQuotes(unsubscribe$: Subject<void>) {
    return this._quotesService.getListOfCurrentProject().pipe(
      switchMap((quoteList: Quote[]) => {
        const createdByUuids: string[] = quoteList.map(
          (singleQuote: Quote) => singleQuote.createdBy
        );

        return zip(
          of(quoteList),
          createdByUuids.length > 0 ? this._userService.getMulti(createdByUuids) : of([])
        );
      }),
      map(([quoteList, users]) => {
        quoteList.map((singleQuote: Quote) => {
          const user: User = users.find(
            (singleUser: User) => singleUser.uuid === singleQuote.createdBy
          );
          singleQuote.createdByDisplayName = user.displayName;
        });
        return quoteList;
      }),
      takeUntil(unsubscribe$)
    );
  }
  public getSuppliersQuotes(quoteId: string): Observable<SupplierQuote[]> {
    return this._suppliersQuotesService.getQuotesByQuoteId(quoteId);
  }
  public getProcessedPackages(quoteId: string) {
    return this._processedPackagesService.getQuotePackages(quoteId);
  }
  /**
   * @param  {string} code
   */
  public async getPurchaseOrdersByCode(code: string) {
    const query: any = {
      where: {
        processedPackageType: ProcessedPackageType.PurchaseOrder,
        status: PackageStatus.InRemittance,
        code
      },
      orderBy: {
        createdAt: 'desc'
      }
    };

    const processedPackages: ProcessedPackage[] = await this._processedPackagesService
      .getListOfCurrentProject(query)
      .pipe(take(1))
      .toPromise();

    const purchaseOrderIds: string[] = processedPackages.map((poId) => poId.referenceId);

    if (purchaseOrderIds.length === 0) {
      return [];
    } else {
      const purchaseOrders = await this._purchaseOrdersService
        .getMulti(purchaseOrderIds)
        .pipe(take(1))
        .toPromise();
      return purchaseOrders;
    }
  }

  /**
   * @param  {string} code
   */
  public async getPaymentCheckOrdersByCode(code: string) {
    const query: any = {
      where: {
        processedPackageType: ProcessedPackageType.PaymentCheckOrder,
        status: PackageStatus.OnCashbox,
        code
      },
      orderBy: {
        createdAt: 'desc'
      }
    };

    const processedPackages: ProcessedPackage[] = await this._processedPackagesService
      .getListOfCurrentProject(query)
      .pipe(take(1))
      .toPromise();

    const paymentCheckOrderIds: string[] = processedPackages.map((poId) => poId.referenceId);

    if (paymentCheckOrderIds.length === 0) {
      return [];
    } else {
      const paymentCheckOrders = await this._paymentCheckOrdersService
        .getMulti(paymentCheckOrderIds)
        .pipe(take(1))
        .toPromise();
      return paymentCheckOrders;
    }
  }

  public async getPaymentCheckOrders() {
    const query: any = {
      where: {
        processedPackageType: ProcessedPackageType.PaymentCheckOrder,
        status: PackageStatus.OnCashbox
      }
      // orderBy: {
      //   createdAt: 'desc'
      // }
    };

    const processedPackages: ProcessedPackage[] = await this._processedPackagesService
      .getListOfCurrentProject(query)
      .pipe(take(1))
      .toPromise();

    const paymentCheckOrderIds: string[] = processedPackages.map((poId) => poId.referenceId);

    if (paymentCheckOrderIds.length === 0) {
      return [];
    } else {
      const paymentCheckOrders = await this._paymentCheckOrdersService
        .getMulti(paymentCheckOrderIds)
        .pipe(take(1))
        .toPromise();
      return paymentCheckOrders;
    }
  }

  public async getBudgetByCode(code: string): Promise<BudgetTableVm> {
    const query = {
      where: {
        code
      }
    };
    let packages = await this._packagesService
      .getListOfCurrentProject(query)
      .pipe(take(1))
      .toPromise();
    packages = packages.filter((p) => p.status !== PackageStatus.Deleted);

    const getRequisitionPackages = await this._sharedService.getRequisitionPackages();
    const originalPackage: Package[] = await this._sharedService.getCostOriginal(
      packages,
      getRequisitionPackages
    );

    const budget: BudgetTableVm = {
      code,
      concept: originalPackage[0].concept,
      unit: originalPackage[0].unit,
      unitPrice: originalPackage[0].cost,
      quantity: 0,
      total: 0,
      isBolsa: originalPackage[0].unit === PackageUnitType.BOLSA,
      remainingQuantity: originalPackage[0].remainingQuantity
    };
    packages.forEach((p) => {
      (budget.quantity += p.quantity),
        originalPackage[0].unit === 'BOLSA'
          ? (budget.total += p.cost)
          : (budget.total += p.quantity * p.cost);
    });

    return budget;
  }
}
