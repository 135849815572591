<div class="content-block">
  <h3 class="content-title">Proyección</h3>

  <mat-table #projectionTable [dataSource]="tableSource">

    <ng-container matColumnDef="weightedAveragePrice">
      <mat-header-cell *matHeaderCellDef> Precio Promedio Ponderado </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <!-- If package is NOT derivative else column not applicable -->
        <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">
          ${{ attribute.weightedAveragePrice||0 | number: '1.2-2' }}
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="purchasedQuantityTotal">
      <mat-header-cell *matHeaderCellDef> Cantidad Comprada </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <!-- If package is NOT derivative else column not applicable -->
        <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">
          {{ attribute.purchasedQuantityTotal||0 | number: '1.2-3' }}
        </ng-template>
      </mat-cell>
    </ng-container>

    <!--        <ng-container matColumnDef="wasteQuantity">-->
    <!--          <mat-header-cell *matHeaderCellDef> Cantidad en Merma </mat-header-cell>-->
    <!--          <mat-cell *matCellDef="let attribute">-->
    <!--            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">-->
    <!--              {{ attribute.wasteQuantity | number: '1.2-3' }}-->
    <!--            </ng-template>-->
    <!--          </mat-cell>-->
    <!--        </ng-container>-->

    <!--        <ng-container matColumnDef="stockQuantity">-->
    <!--          <mat-header-cell *matHeaderCellDef> Cantidad en Stock </mat-header-cell>-->
    <!--          <mat-cell *matCellDef="let attribute">-->
    <!--            <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">-->
    <!--              {{ attribute.stockQuantity | number: '1.2-3' }}-->
    <!--            </ng-template>-->
    <!--          </mat-cell>-->
    <!--        </ng-container>-->

    <ng-container matColumnDef="purchasedTotal">
      <mat-header-cell *matHeaderCellDef> Monto Comprado </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        ${{ attribute.purchasedTotal | number: '1.2-2' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remainingPurchaseQty">
      <mat-header-cell *matHeaderCellDef> Cantidad por Comprar </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <!-- If package is NOT derivative else column not applicable -->
        <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">
          {{ attribute.remainingPurchaseQty | number: '1.2-3' }}
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remainingPurchaseTotal">
      <mat-header-cell *matHeaderCellDef> Monto por Comprar </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        ${{ attribute.remainingPurchaseTotal | number: '1.2-2' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastPoUnitPrice">
      <mat-header-cell *matHeaderCellDef> Último Precio </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <ng-template [ngIf]="attribute.lastPoUnitPrice" [ngIfElse]="notApplicable">
          ${{ attribute.lastPoUnitPrice | number: '1.2-2' }}
        </ng-template>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="projectionTotal">
      <mat-header-cell *matHeaderCellDef> Total </mat-header-cell>
      <mat-cell *matCellDef="let attribute">
        <!-- If package is NOT derivative else column not applicable -->
        <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">
          ${{ attribute.projectionTotal | number: '1.2-2' }}
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="forecastBalance">
      <mat-header-cell *matHeaderCellDef> Balance Pronosticado </mat-header-cell>
      <mat-cell *matCellDef="let attribute" [ngStyle]="{
          color: !packageIsDerivative ? (attribute.forecastBalance  > 0.01 ? 'red' : 'green') : ''
        }">
        <!-- If package is NOT derivative else column not applicable -->
        <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">
          ${{ attribute.forecastBalance | number: '1.2-2' }}
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-template #notApplicable>
      NA
    </ng-template>

    <mat-header-row *matHeaderRowDef="projectionTableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: projectionTableColumns"></mat-row>
  </mat-table>
</div>
