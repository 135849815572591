import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuotesComponent } from './quotes.component';
import { SupplierQuoteDetailPageComponent } from "./supplier-quote-detail/supplier-quote-detail.component";
import { SuppliersQuotesPageComponent } from "./suppliers-quotes/suppliers-quotes.component";

export const SUPPLIER_QUOTES_ROUTES: Routes = [
  {
    path: 'supplier-quotes',
    component: QuotesComponent,

    children: [
      { path: 'list', component: SuppliersQuotesPageComponent },
      { path: 'quote/:quoteId', component: SupplierQuoteDetailPageComponent },
      {
        path: '**',
        redirectTo: 'list'
      }
    ]
  }
];
export const SuppliersQuotesRoutingModule: ModuleWithProviders<any> = RouterModule.forChild(
  SUPPLIER_QUOTES_ROUTES
);
