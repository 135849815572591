<div class="rmd-search-bar-container"
     [class.noborder]="noBorder"
     [class.big]="big"
     [class.custombar]="customBar"
     (click)="input.focus()">
  <mat-icon>search</mat-icon>

  <input #input
         type="text"
         [placeholder]="placeholder"
         [formControl]="formControl">

  <mat-icon [class.visible]="!!input.value" (click)="reset()">backspace</mat-icon>
</div>
