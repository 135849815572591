import { PowerBiConfig } from '@bist-env/types';

/**
 * generalDatasetId is the same dataset for:
 *  - generalBudgetReportId (Presupuesto general)
 *  - generalMtmReportId (Presupuesto conglomerados materiales, máquina y herramienta)
 * */
export const powerBiDevConfig: PowerBiConfig = {
  applicationId: '20fc5c50-4023-4ece-b68e-a214e8315b11',
  groupId: '326c02c2-f74e-4e1c-a619-8533401ead28',
  //getReportUrl: 'http://localhost:5000/bist-2-dev/us-central1/getReport',
  getReportUrl: 'https://us-central1-bist-2-dev.cloudfunctions.net/getReport',
  gereralDatasetId: 'c0180948-af9d-4996-b957-1c7fb379fb20',
  gereralBudgetReportId: 'dfc5e7aa-5700-4c62-af79-e2bad30e9f11',
  generalBudgetConglomerateReportId: '79329580-b2f4-4dda-a96a-bedb78254abd',
  generalMtmReportId: 'd93a1b51-5fe9-4e00-8662-a4a57979b23c',
  advanceDatasetId: 'e331c15f-4d50-4af5-aaa3-99a195f6c9f2',
  advanceReportId: '2ba10410-84c9-4d8b-8033-4e78c712b513',
  remittanceDatasetId: '1f47424b-255f-4d24-a5b0-2e335e7d94b2',
  remittanceReportId: 'a83bc4a1-c457-44c2-b2dd-1a157e0f7c9e',
  remittancePaymentOrdersReportId: 'f6ad77a5-2d28-4c8d-b57d-97fdaafc2395',
  quotesDatasetId: '7232e8eb-5870-4a7d-b66e-25b6763d2d80',
  quotedPackagesReportId: 'cf0a937f-3c46-4613-a18a-a2fa5066ef35',
  purchaseOrdersReportId: '3f1cfbc8-1d5a-4288-af1d-f2aac0d282b6',
  costCenterReportId: '951f58d3-1d9e-4686-a91f-4ef25906ee42',
  requisitionsDatasetId: '19ade825-03fa-456a-87da-cd977e21c278',
  requisitionsReportId: '54f90227-864b-4b4d-8326-bde2c596de96',
  indirectsDatasetId: '11f9bbe6-3c50-4067-bffe-35e71cb859e5',
  indirects1ReportId: 'e1ab7c54-5289-4277-8ea2-ea5dffc43839',
  indirects2ReportId: 'd444ada9-0357-416a-807b-f17d16f7a4fd',
  advancedPaymentsDatasetId: 'd5fd3884-cb21-4ef2-8f7a-abbd9d345879',
  advancedPaymentsGeneralReportId: 'db48c954-2f7b-4a9c-8749-3899b4c9f8f7',
  loansDatasetId: '3a8e6fae-7152-4d1b-a1b9-2c6c2735bb69',
  loansGeneralReportId: '676e3a41-552a-4750-b54b-1ed6aa1ad276',
  cashboxDatasetId: 'e4776a5e-0ed8-4828-aa72-5da295ad6345',
  cashboxGeneralReportId: '973a464a-c4e0-4df2-b19e-d8f0a86a8e0a',
  paymentCheckOrdersDatasetId: 'e9a004f2-af68-448d-9785-7b7cb989782e',
  approvedPaymentCheckOrdersReportId: 'bf6c72bf-f1a2-407a-b0b6-a872a0d22f51',
  workforceDatasetId: '8515014f-3815-4c70-a804-08e735504d30',
  workforcePackagesReportId: '4dfd14a0-972e-4e16-aa9d-e63fee4320b8',
  workforceAssignmentsListReportId: '21d207e6-a44a-4de0-bdc3-660c58fad351',
  workforceEstimateOrdersListReportId: '27a9c281-6cb6-448d-a71a-682454c253bf',
  subcontractsDatasetId: '06950638-43de-4ddb-8dd3-7e6df892e3d5',
  subcontractsAssignmentsListReportId: '81cc3ff5-97fe-4079-bd4f-bf6fc065d777',
  subcontractsEstimateOrdersListReportId:
    '937c104d-d94e-4c3e-96b9-0752ba939449',
  subcontractsPackagesReportId: 'b244051b-caf1-4f32-b64c-2c09547a9fde',
  extraordinariesDatasetId: '5e2799f8-25cb-4ee3-8c6b-3816836ed49a',
  approvedExtraordinariesReportId: 'bba670fd-583c-4f42-9829-810fbf4ca506',
};

export const powerBiConfig: PowerBiConfig = {
  applicationId: '20fc5c50-4023-4ece-b68e-a214e8315b11',
  groupId: '4cbf5b51-c28d-47af-a092-fd51afce37db',
  getReportUrl: 'https://us-central1-bist-2-prod.cloudfunctions.net/getReport',
  gereralDatasetId: '5ac4620e-0544-4c60-ae37-b3ad025fd509',
  gereralBudgetReportId: '8394f3a1-10f0-45e2-b9c4-ba65f289f9e1',
  generalBudgetConglomerateReportId: '74867871-3214-45ce-bb55-8861418e1ef5',
  generalMtmReportId: 'aa691747-5b48-4fcd-903f-586b151c537f',
  advanceDatasetId: '37507287-d8cb-4846-9ca8-76f98d5a216c',
  advanceReportId: '3fddb8b2-cd45-435f-84d3-0f8dfaf3c78d',
  remittanceDatasetId: 'a2df8505-23f0-4100-a1e4-c432c1187e10',
  remittanceReportId: 'b03f38d1-b18f-46ed-b66d-fbe955e5fec7',
  remittancePaymentOrdersReportId: '9fd07b0b-dec8-49fa-91f0-36e8795fe0f5d',
  quotesDatasetId: '2165700d-491b-4240-a354-d10f49493a53',
  quotedPackagesReportId: '42b1458a-7e5a-4084-81f2-9e657637a0a0',
  purchaseOrdersReportId: 'ab03a931-d1e0-40d3-b006-6760bad3a20e',
  costCenterReportId: 'ccf5c7ec-f371-458e-b4b5-12795af81fc7',
  requisitionsDatasetId: '8aab7cfa-0f2f-49c9-8e01-1504b437b707',
  requisitionsReportId: '46c53fc0-83e9-4671-aedf-f8b9cfed13fc',
  indirectsDatasetId: '1f7e6266-d81d-407a-a461-6e0b542c0935',
  indirects1ReportId: '7628d201-e20d-4305-be13-961298e886c0',
  indirects2ReportId: 'da6e8c9b-0c44-4c25-993d-c52d81db0c6e',
  advancedPaymentsDatasetId: 'e059fd39-e682-4591-a847-04cf80e94cb1',
  advancedPaymentsGeneralReportId: 'ec3508eb-d2c3-46fa-bb54-cb75b250301c',
  loansDatasetId: '95d87e0a-7c67-4892-8d8c-8f9720d48e2a',
  loansGeneralReportId: '5a7523d7-d10d-4a38-997a-8c6224469bfb',
  cashboxDatasetId: '67c0c688-b99e-4bf9-bbbb-0c430bec732c',
  cashboxGeneralReportId: '4a9f99fc-ee6d-4ef4-87be-5867b5682ec7',
  paymentCheckOrdersDatasetId: '3ea3606a-a09a-4900-9c01-781282679e92',
  approvedPaymentCheckOrdersReportId: '5dff75ed-f021-49db-b2e8-3b160c9e2981',
  workforceDatasetId: 'ab4095d8-18c7-4921-a821-2b594a886e39',
  workforcePackagesReportId: 'f12c5d77-4012-4d64-b103-7e53b31646bd',
  workforceAssignmentsListReportId: '2df6ab2d-148e-4960-b056-7a3b760469a8',
  workforceEstimateOrdersListReportId: 'ce8fbab0-0b08-40e7-87d3-1b7d32a2a336',
  subcontractsDatasetId: '8c355ce7-d26b-428e-89a7-c5401d99ccb3',
  subcontractsAssignmentsListReportId: '6f403c46-f381-4e48-9585-9adab5746512',
  subcontractsPackagesReportId: 'd52addba-8d98-4ed4-8b13-f2bc8f635dce',
  subcontractsEstimateOrdersListReportId:
    'dd78162a-6e7e-4409-b028-a1c14da82cc7',
  extraordinariesDatasetId: 'cd5a0438-9123-4313-be9d-6742a3cc1d26',
  approvedExtraordinariesReportId: '5c4b8dc4-0e9b-41bb-8c86-4a4d4ab3dda2',
};

export const powerBiTrescalaConfig: PowerBiConfig = {
  applicationId: '20fc5c50-4023-4ece-b68e-a214e8315b11',
  groupId: 'ef01fb60-bebf-4ec9-8e6c-ee9b41e79a4e',
  getReportUrl:
    'https://us-central1-bist-trescala2.cloudfunctions.net/getReport',
  //getReportUrl: 'http://localhost:5000/bist-trescala2/us-central1/getReport',
  gereralDatasetId: '2785973b-b240-4689-9e16-ef66aed85639',
  gereralBudgetReportId: '4171623f-9ef6-420a-a427-aaf73afd5ed6',
  generalBudgetConglomerateReportId: 'fa898f4f-d384-4243-9ad7-8753cf1d3a77',
  generalMtmReportId: '1291bf7c-16d9-41c9-b404-35a2ab244a65',
  advanceDatasetId: 'c5ef5179-5c59-4aba-b316-e6c433e9c2dc',
  advanceReportId: 'b2f577a0-077d-490c-89fe-0ac7ad4fff16',
  remittanceDatasetId: 'e4fa1770-55dd-4d77-84c6-2a0beb3eaf60',
  remittanceReportId: '0519fec6-9d89-4294-9269-346f09679517',
  remittancePaymentOrdersReportId: 'ea8d326c-0032-427f-ab73-101227e8a862',
  quotesDatasetId: '15bd4928-fa9e-4631-98c4-a1cc8a4d8fb8',
  quotedPackagesReportId: '567a9e10-9cbb-44f4-b121-00724dc66983',
  purchaseOrdersReportId: '112a23fa-bac5-49b0-9579-95a00f271f3a',
  costCenterReportId: '9904929d-06dd-456e-b0ad-17582aa7ffa4',
  requisitionsDatasetId: '0a6eaf80-765d-4701-9e71-358859a52c58',
  requisitionsReportId: '697d5668-74e5-43ed-9f21-b82bd0dd246d',
  indirectsDatasetId: '6d2ed318-529d-4422-8faa-a466b1a8fe0f',
  indirects1ReportId: '0cbccd2f-413d-4ac0-9ec2-4e8b59c921d0',
  indirects2ReportId: '16748fe9-c7e7-47ec-b0a5-edcce1349a60',
  advancedPaymentsDatasetId: '7fba0248-3951-40e7-abb5-bee961956580',
  advancedPaymentsGeneralReportId: '6ad3dc24-1f3c-4094-8d79-3f15f5963c32',
  loansDatasetId: 'd0977f4c-c329-4976-91f4-fe0583f6dada',
  loansGeneralReportId: '875d8c44-0ab5-429d-8656-3215e2c800a9',
  cashboxDatasetId: '417494a1-616c-4661-a796-5551edca9958',
  cashboxGeneralReportId: '5b2b15b5-ca96-4afc-8827-69e57d864e84',
  paymentCheckOrdersDatasetId: '78e826d4-009f-4922-90f5-3bdd78c641aa',
  approvedPaymentCheckOrdersReportId: 'c0440d3a-5618-4e22-9114-8ad0445f0952',
  workforceDatasetId: '73e70219-3488-43a8-a35c-6a1db2c731f5',
  workforcePackagesReportId: '719989aa-046e-434d-a1f9-3b8e91b6ede6',
  workforceAssignmentsListReportId: '7635fa0e-af58-43df-80cc-cfad431e7dc0',
  workforceEstimateOrdersListReportId: 'd4f32ea4-c383-4c6c-8bee-25b258a4feb0',
  subcontractsDatasetId: 'a84f1a24-5a9f-4cdb-8042-68d3e10b4f49',
  subcontractsAssignmentsListReportId: '8a8536bf-465c-4659-acea-27f61fa31bba',
  subcontractsPackagesReportId: '14cb868a-1ef9-411b-a453-5b30f3caa5af',
  subcontractsEstimateOrdersListReportId:
    'bda9be72-602f-4305-afd2-8b79bb960d05',
  extraordinariesDatasetId: 'b868206a-10fb-46f0-b050-aeb807024ba2',
  approvedExtraordinariesReportId: '6ad3bc24-729f-4cf1-b79d-3eefe3c56cc5',
};

export const powerBiIcpcConfig: PowerBiConfig = {
  applicationId: '20fc5c50-4023-4ece-b68e-a214e8315b11',
  groupId: '4f77560a-6694-48f8-bab3-44cf38d777e2',
  getReportUrl: 'https://us-central1-icpc-11b95.cloudfunctions.net/getReport',
  //getReportUrl: 'http://localhost:5000/bist-trescala2/us-central1/getReport',
  gereralDatasetId: 'e0c5f328-dca6-4eee-b11d-52a307a99f18',
  gereralBudgetReportId: '808b152e-8bba-4d77-8e87-c8b74373d34a',
  generalBudgetConglomerateReportId: '9082e5b2-6187-42de-86f6-7d73fd878da6',
  generalMtmReportId: 'da26fd13-1795-403e-87ed-1ec83c184f1f',
  advanceDatasetId: '78811b72-f861-4aca-b569-8ed0e0928c5a',
  advanceReportId: 'ab745f6b-2c37-43ac-8f1e-cc26402c65a4',
  remittanceDatasetId: 'c9ab14db-0aa9-460f-8563-1ff532b1da57',
  remittanceReportId: 'dc39bf09-eab1-4242-b04a-e4781ad39b18',
  remittancePaymentOrdersReportId: '0c4c7714-6720-4344-ab82-f462623e3a3d',
  quotesDatasetId: '9aadf701-fee5-4d17-8ff0-056a87dab4e3',
  quotedPackagesReportId: '4fdfccbe-febe-44d0-b38a-cb58b1d1eaa6',
  purchaseOrdersReportId: '2178af9b-7f03-4085-be3b-917f29894b99',
  costCenterReportId: '06a29d25-8489-4e19-9a12-c48542ed54a2',
  requisitionsDatasetId: '2ec9430e-eeb0-4e5d-8a08-190227211bfa',
  requisitionsReportId: 'dd8a5040-8012-4220-af6b-96438c184b25',
  indirectsDatasetId: 'b8ada1e5-2754-4b43-8f4a-c7f4c5ab6153',
  indirects1ReportId: '11e65948-5de1-4c49-969b-0a6f96dd06d1',
  indirects2ReportId: '01022b7b-20d9-4a02-9f53-f7d7a2c9c2aa',
  advancedPaymentsDatasetId: '34b3ec7c-fa77-49ef-8945-d02e4f9d83b0',
  advancedPaymentsGeneralReportId: '1fdb8791-0dd7-4980-b5e4-556f73c67f2a',
  loansDatasetId: 'b55042d0-d438-43d3-a1f6-ac70de53d0a5',
  loansGeneralReportId: '49d53039-7d2b-4774-ab93-215d12f111f0',
  cashboxDatasetId: '2599336e-f808-4340-852d-39143fb2091d',
  cashboxGeneralReportId: 'dce4db55-a6f6-4fab-9a5d-321264c882d5',
  paymentCheckOrdersDatasetId: '3caf5989-9813-4837-ac25-31dc54f397c6',
  approvedPaymentCheckOrdersReportId: 'eb46ca94-b5e3-4af8-a50c-34bd1c1fc264',
  workforceDatasetId: '6587cd3a-ce88-4d49-aafc-8b8e2de61a6f',
  workforcePackagesReportId: 'd5b94806-b994-4479-87f9-8679f8816e88',
  workforceAssignmentsListReportId: 'acd448d0-306e-4981-a952-068ba6b0102b',
  workforceEstimateOrdersListReportId: '346724e3-7eb6-491b-b159-db08aa09fc5d',
  subcontractsDatasetId: '8b1b7c85-ce99-41f6-adfb-ab536b2af72b',
  subcontractsAssignmentsListReportId: '11edb3c0-5708-4022-a412-9831a29821a1',
  subcontractsPackagesReportId: '8f26d2cb-22fe-451e-aa2e-741e247ec7c7',
  subcontractsEstimateOrdersListReportId:
    'a1677307-0172-4669-af3c-811fc098cab3',
  extraordinariesDatasetId: '28e912b7-f374-46c1-933a-2ed9dc04c8c0',
  approvedExtraordinariesReportId: '6510df1b-9544-4943-b372-af1ac71a2e7e',
};

export const powerBiBrConfig: PowerBiConfig = {
  applicationId: '20fc5c50-4023-4ece-b68e-a214e8315b11',
  groupId: '78691b36-1741-46e6-ac30-48f7df77ade8',
  getReportUrl: 'https://us-central1-bist-br.cloudfunctions.net/getReport',
  //getReportUrl: 'http://localhost:5000/bist-br/us-central1/getReport',
  gereralDatasetId: '76c4c90b-82b8-4cb0-bb74-04c496de6c34',
  gereralBudgetReportId: '60bc2f62-8576-4b03-b809-3aecd58b9645',
  generalBudgetConglomerateReportId: '34979268-6304-4eb0-8353-3960e24faf0b',
  generalBudgetConglomerate1ReportId: '26da1331-0b4d-4ba4-8999-bab08735ed8c',
  generalBudgetCurvaSReportId: 'ca1d448b-728d-4d17-9c92-2066daef81a2',
  generalMtmReportId: '137e65e0-84d2-4bdf-84e8-020de69d2ddc',
  advanceDatasetId: 'de591d52-19dd-4f32-b9db-381c6f3f5dca',
  advanceReportId: 'c3a79de3-3f69-4e73-922e-e199df113ab4',
  remittanceDatasetId: '51d6f6d0-0043-4992-8de7-aefa7458a6e0',
  remittanceReportId: '7b46824b-df62-4376-b983-dd391feaa8f8',
  remittancePaymentOrdersReportId: '7b01be0a-949b-4aab-9ee1-351d47f564a3',
  quotesDatasetId: '5fd1cb96-fa9f-4eb4-943e-e59dcc1273d5',
  quotedPackagesReportId: '9b9a0d92-0371-4503-af75-0d35a33d502b',
  purchaseOrdersReportId: '357d3838-061b-4e57-8a26-0ab0ea024ead',
  costCenterReportId: 'e172fbc0-9fb7-4eef-8dbf-2e4259af1103',
  requisitionsDatasetId: 'de9e9950-5928-4a68-82be-6f482160611d',
  requisitionsReportId: 'c4318799-1f64-40e2-b565-36c40f842470',
  indirectsDatasetId: '56caf311-a920-4756-8881-8e512d071ca0',
  indirects1ReportId: '92b4e3a3-34a6-4239-950a-b697b38d5f05',
  indirects2ReportId: '0008b226-18b8-41b5-8a5f-c90fcda9bf03',
  advancedPaymentsDatasetId: '9e7eaace-d927-4053-880d-054541debd8e',
  advancedPaymentsGeneralReportId: 'be68dc83-3c74-4c41-bf68-6bc9916da990',
  loansDatasetId: '6aa8d4f4-0961-478c-97f9-f2c0aa6fb289',
  loansGeneralReportId: '27bf4e98-a03d-4e6c-addd-ccdaf383eb19',
  cashboxDatasetId: '9bc0d650-dfb4-4eb5-b765-6a3a085379f7',
  cashboxGeneralReportId: 'f0125cac-4ec6-4913-a8ab-88518c5e24ef',
  paymentCheckOrdersDatasetId: '253570e3-878f-473a-ab20-f12370da494c',
  approvedPaymentCheckOrdersReportId: 'b7576904-2f00-480d-beeb-9efeb8247b99',
  workforceDatasetId: 'ffc028c0-c52b-4c1e-9ff5-49d8fac29a5d',
  workforcePackagesReportId: 'bb2db44c-5947-440e-8ac2-49efa164356d',
  workforceAssignmentsListReportId: '6b21a5e0-43cb-4b2f-a2c8-a7a0b979c704',
  workforceEstimateOrdersListReportId: '9fdf51be-7b5b-434a-9a97-601038de622e',
  subcontractsDatasetId: '3352dfbd-d3b2-4134-8212-6ac246c14e26',
  subcontractsAssignmentsListReportId: 'ae01d29c-a516-4578-8a66-6fa0b3336171',
  subcontractsPackagesReportId: '44cd2ed0-c475-4423-810f-db9e96ed4648',
  subcontractsEstimateOrdersListReportId:
    '5e4c5e5f-5900-4515-afb3-0f6fb3230e5c',
  extraordinariesDatasetId: 'a2f1ac58-27e3-4fa6-947e-c4ccf547c0d9',
  approvedExtraordinariesReportId: 'fc67154a-1916-495b-a1d6-f6f49d2669a6',
};

export const powerBiLiverpoolConfig: PowerBiConfig = {
  applicationId: '20fc5c50-4023-4ece-b68e-a214e8315b11',
  groupId: 'ab0cbc18-c703-4ad6-9e5f-907814a1ec27',
  getReportUrl:
    'https://us-central1-liverpool-19671.cloudfunctions.net/getReport',
  // getReportUrl: 'http://localhost:5000/liverpool-19671/us-central1/getReport',
  gereralDatasetId: '37ba1e4f-4727-4845-bd98-0511fcadd7ee',
  gereralBudgetReportId: '80419e67-98ce-473e-b0fd-e03a6943f944',
  generalBudgetConglomerateReportId: '488bfa04-407b-4976-ad23-e81cfa42ca6c',
  generalBudgetConglomerate1ReportId: '6acc063d-9558-43e6-9667-6d21c0fc72c9',
  generalBudgetCurvaSReportId: '438dff3b-e1c2-4b34-8b25-b242e3260e59',
  generalMtmReportId: 'c2969089-bf76-4499-94e5-9d9124c8ce77',
  advanceDatasetId: 'ba7c09b7-ceb0-4ca1-8d41-6f7a5cc28524',
  advanceReportId: '077a17e3-1ad9-4bd2-8bf8-5b3c59caa29a',
  remittanceDatasetId: '8bebdbc2-df3e-4b12-8939-423c9c1016ab',
  remittanceReportId: '2fa2e877-d0d6-4e6d-8ee3-078a5b0bf50e',
  remittancePaymentOrdersReportId: '1b1e1a5d-116f-47fc-a302-966c4854e9df',
  quotesDatasetId: '8d783329-f026-41c3-ad57-ccf1cf411537',
  quotedPackagesReportId: 'cc0ab305-4f17-4956-9d37-544126c41aad',
  purchaseOrdersReportId: '74316472-eaa7-4344-be30-aa36d6c4ffb1',
  costCenterReportId: 'ef2a399e-56e8-4604-a642-e126cd077f0a',
  requisitionsDatasetId: '769f91c7-45b5-4464-873d-6d4e52d6fde0',
  requisitionsReportId: '7ac9db1b-b88c-4531-bcf7-e865b1d2b02f',
  indirectsDatasetId: 'a70adf1b-f6c7-47fd-bdbb-79cd13528fcf',
  indirects1ReportId: '79f6ffca-3f2e-4551-9cd7-0595705390a5',
  indirects2ReportId: 'e5d2d7d2-252f-41d8-acbc-2ff8114dbb9f',
  advancedPaymentsDatasetId: '28f6482e-ba80-40b7-ab04-8a812d7c1e2d',
  advancedPaymentsGeneralReportId: '512f0fa3-787e-4546-a8a2-bbe173225af3',
  loansDatasetId: 'a7719889-c1ad-4896-9875-50582fa26438',
  loansGeneralReportId: '90b1be98-8f41-4bd3-a6b7-afaac3dd38ec',
  cashboxDatasetId: '0908269d-8f13-4081-8787-82004700de9d',
  cashboxGeneralReportId: '989e6a25-2267-4aad-a06d-1f6413381e94',
  paymentCheckOrdersDatasetId: '3fecc67a-01b1-400a-9eaf-58ba65586030',
  approvedPaymentCheckOrdersReportId: 'ae2052cd-430e-4afa-a890-1b3551bcde47',
  workforceDatasetId: '776594a5-30ec-45d8-b818-456f95c87837',
  workforcePackagesReportId: 'd7a6436b-88b9-4318-978f-20edea68e6a7',
  workforceAssignmentsListReportId: 'cff6d4dc-0def-420e-b6f7-95b175273880',
  workforceEstimateOrdersListReportId: '853a90f4-6c0b-42e1-8d87-c5a508f072d6',
  subcontractsDatasetId: '10bcea9c-4cb3-4499-8618-902c5b49752e',
  subcontractsAssignmentsListReportId: '88e005f1-4116-49a6-a1cc-85d2626bde4a',
  subcontractsPackagesReportId: '8d85a983-b70e-4aa6-95ef-ce3680380b0e',
  subcontractsEstimateOrdersListReportId:
    '4ac87090-779e-4fc2-9d32-9b0e3e8131c4',
  extraordinariesDatasetId: '0c7a6051-b8ba-4ea6-b0ee-d4e45cba7e9b',
  approvedExtraordinariesReportId: '19fc5183-e120-43a0-a3ab-6aeef28be7ab',
};

export const powerBiUneConfig: PowerBiConfig = {
  applicationId: '20fc5c50-4023-4ece-b68e-a214e8315b11',
  groupId: '4e622195-a3d6-4284-a98d-673cfbf842f7',
  getReportUrl: 'https://us-central1-bist-une.cloudfunctions.net/getReport',
  // getReportUrl: 'http://localhost:5000/bist-une/us-central1/getReport',
  gereralDatasetId: '1f9aab06-a5d4-4e69-ac95-496b24ed89f6',
  gereralBudgetReportId: '267b3aa8-2733-4426-93e4-8ae5224d50ab',
  generalBudgetConglomerateReportId: 'cf6f06f7-8f64-4238-a85a-b43ef82f649d',
  generalBudgetConglomerate1ReportId: '0ff2d53d-2838-4813-80fe-266019394624',
  generalBudgetCurvaSReportId: '5bcbeda3-2ba9-4a0e-afa9-2cea64b21c6b',
  generalMtmReportId: 'f9467c3e-c80f-475d-b82e-19cde8958982',
  advanceDatasetId: '8ae66fae-9a06-4ff0-9ee5-1c0f2542b6c7',
  advanceReportId: '443cfc93-62b0-4899-b905-c4b2cb4d2c16',
  remittanceDatasetId: '37f699e2-a280-43a4-a8c6-08fa2900249e',
  remittanceReportId: '87358ed0-b253-4280-beae-a1a6814c863d',
  remittancePaymentOrdersReportId: '81d45951-2ae0-4862-a1e8-18786073cc68',
  quotesDatasetId: 'fc7076a6-cc8f-48f5-8aac-a9985e3e069c',
  quotedPackagesReportId: 'd3be4700-3206-449f-a05f-c1a20fa86c99',
  purchaseOrdersReportId: 'e95523ad-d07f-4c79-9990-34a846fa4bc9',
  costCenterReportId: '7395d9dc-35d6-4ca4-9286-79cd3999e444',
  requisitionsDatasetId: '13c0f65b-6076-4d70-bf19-3e74175da86d',
  requisitionsReportId: 'b8ea94ef-a1df-40a0-8d00-763d396eb47b',
  indirectsDatasetId: '8c614697-e0e2-46dd-9979-a0731dd9bd6f',
  indirects1ReportId: '79f6ffca-3f2e-4551-9cd7-0595705390a5',
  indirects2ReportId: '6a618e29-38cd-43c8-81b4-c6d75d5b8e02',
  advancedPaymentsDatasetId: '23eaa75a-8977-432d-ba11-c38732f09109',
  advancedPaymentsGeneralReportId: 'e78ca5cb-623a-448f-91a7-548a91d09a5d',
  loansDatasetId: '2485508d-152a-41b1-905c-d7390da84bf9',
  loansGeneralReportId: '90b1be98-8f41-4bd3-a6b7-afaac3dd38ec',
  cashboxDatasetId: '7f06853a-0a59-41e3-be6c-c5b0ef1c38bb',
  cashboxGeneralReportId: 'cfa245da-f191-4220-9bd4-b23b958247a1',
  paymentCheckOrdersDatasetId: 'a0715b01-8d03-47c0-99ca-5611a2c9cb1f',
  approvedPaymentCheckOrdersReportId: '56b02cc8-4b29-4a90-9972-c86e27bd280d',
  workforceDatasetId: '0c175e27-37d1-42c6-8033-d295e387fde6',
  workforcePackagesReportId: '45142d4f-df93-45c3-ab1d-4dc1910d6027',
  workforceAssignmentsListReportId: '7bcdbb36-fa38-40ec-bb98-c681473ee903',
  workforceEstimateOrdersListReportId: '1b7f8609-d082-4b87-987d-87cf98960066',
  subcontractsDatasetId: '070d81b8-194a-4a41-9791-d9b27e063983',
  subcontractsAssignmentsListReportId: 'fb1a9889-69f7-4e6c-a10b-2c2d00aa5a95',
  subcontractsPackagesReportId: '802611d1-c59c-4cd0-8d92-59e391a856a4',
  subcontractsEstimateOrdersListReportId:
    'cac418ff-0cdd-434a-a9dc-1dd42d36bcae',
  extraordinariesDatasetId: 'ca2f7a53-0496-4623-b9f7-adc1af23ce89',
  approvedExtraordinariesReportId: 'f1ef7fd6-6d36-495b-b10c-937866fabffb',
};

export const powerBiSandboxConfig: PowerBiConfig = {
  applicationId: '',
  groupId: '',
  getReportUrl: '',
  gereralDatasetId: '',
  gereralBudgetReportId: '',
  generalBudgetConglomerateReportId: '',
  generalMtmReportId: '',
  advanceDatasetId: '',
  advanceReportId: '',
  remittanceDatasetId: '',
  remittanceReportId: '',
  remittancePaymentOrdersReportId: '',
  quotesDatasetId: '',
  quotedPackagesReportId: '',
  purchaseOrdersReportId: '',
  indirectsDatasetId: '',
  indirects1ReportId: '',
  indirects2ReportId: '',
  costCenterReportId: '2a2a64ad-148a-4959-a735-75840e341bf2',
  requisitionsDatasetId: '0a37d100-0b2e-4209-a3bb-04bc19d76878',
  requisitionsReportId: '50866f33-842a-4637-b034-73cef01f1317',
  advancedPaymentsDatasetId: 'd5fd3884-cb21-4ef2-8f7a-abbd9d345879',
  advancedPaymentsGeneralReportId: '',
  loansDatasetId: '3a8e6fae-7152-4d1b-a1b9-2c6c2735bb69',
  loansGeneralReportId: '',
  cashboxDatasetId: 'e4776a5e-0ed8-4828-aa72-5da295ad6345',
  cashboxGeneralReportId: '',
  paymentCheckOrdersDatasetId: 'e9a004f2-af68-448d-9785-7b7cb989782e',
  approvedPaymentCheckOrdersReportId: '',
  workforceDatasetId: '64b4dd5b-ffc6-4b49-94c4-1f26286fb005',
  workforcePackagesReportId: 'fc280505-25f0-445c-8016-0875004ad5a4',
  workforceAssignmentsListReportId: '',
  workforceEstimateOrdersListReportId: '27a9c281-6cb6-448d-a71a-682454c253bf',
  subcontractsDatasetId: '',
  subcontractsAssignmentsListReportId: '',
  subcontractsPackagesReportId: '',
  subcontractsEstimateOrdersListReportId: '',
  extraordinariesDatasetId: '5e2799f8-25cb-4ee3-8c6b-3816836ed49a',
  approvedExtraordinariesReportId: '',
};
