<ng-template #loadingTpl>
  <rmd-loading-indicator [percent]="uploadProgress"></rmd-loading-indicator>
</ng-template>

<app-files-selector *ngIf="!_uploadingProgress.length; else loadingTpl"
                    #selector
                    single
                    [accept]="accept"
                    (change)="uploadFiles($event)">
</app-files-selector>
