<app-page-widget [menuItems]="widgetMenu" (refresh)="refreshData()">
    <app-title>COT-{{ quote?.uuid | idFromUuid }}</app-title>
    <app-subtitle>{{ quote?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <app-action-button flat noText icon="done_all" (click)="displayApprovals()">Ver Aprobaciones
    </app-action-button>
    <!-- <app-menu-item icon="done_all" (click)="displayApprovals()">Ver Aprobaciones</app-menu-item> -->
    <!-- <app-menu-item icon="cancel" (click)="cancel()">Cancelar</app-menu-item> -->
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="status-block">
            <div>
                Estado:
                <strong>{{ quote?.status | quoteStatus }}</strong>
            </div>
            <div>
                Solicitado por:
                <app-user-data *ngIf="quote" [id]="quote.createdBy"></app-user-data>
            </div>
        </div>
        <div class="actions-block">
            <button mat-raised-button class="action-button mr-5" (click)="goToPreviousUlr()" color="warn">
                <mat-icon>undo</mat-icon>
                Regresar
            </button>
            <ng-template [ngIf]="sendToApproveIsAvailable">
                <app-button *ngxPermissionsOnly="['quotes:can_send_to_approve']" icon="call_made" (click)="sendToApprove()">Enviar a Aprobar</app-button>
            </ng-template>
            <ng-template [ngIf]="approveIsAvailable && !hideApproval">
                <app-button *ngxPermissionsOnly="['quotes:can_approve']" icon="done" (click)="approve()">Aprobar</app-button>
            </ng-template>
            <!-- <ng-template [ngIf]="cancelIsAvailable">
                <app-button icon="cancel" (click)="cancel()">Cancelar</app-button>
            </ng-template> -->
        </div>
    </div>

    <div id="packages-detail">
        <!-- #160: No longer needed
    <h3 class="content-title">Paquetes seleccionados</h3>
    <button class="btn"
            mat-raised-button
            color="primary"
            (click)="generatePurchaseOrders()">
      <mat-icon>playlist_add_check</mat-icon>
      Generar órden(es) de compra
    </button> -->
        <!-- ============================================================== -->
        <!-- GRID SELECTED PACKS  -->
        <!-- ============================================================== -->
        <dx-data-grid #poGrid id="QuoteGrid" [wordWrapEnabled]="true" [dataSource]="elementsSelected" [masterDetail]="{ enabled: true,
            template: 'detail' }" [hoverStateEnabled]="false" [wordWrapEnabled]="true" [showColumnLines]="true" [showRowLines]="true" noDataText="Detalle
            de
            cotización." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false">
            <dxo-editing mode="cell" [allowUpdating]="false"></dxo-editing>
            <dxo-export [enabled]="false"></dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-search-panel [visible]="false" [highlightSearchText]="false"></dxo-search-panel>
            <dxo-paging [pageSize]="5"></dxo-paging>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="false"></dxo-selection>
            <dxo-group-panel [visible]="false"></dxo-group-panel>

            <dxi-column caption="#" dataField="selectedForPo" width="5%" dataType="boolean" [allowEditing]="false"></dxi-column>
            <dxi-column caption="supplierId" dataField="selectedSupplierId" groupCellTemplate="supplierGroupTemplate" [groupIndex]="0" width="40%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <dxi-column caption="Concepto" dataField="concept" width="30%" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <dxi-column caption="Unidad" dataField="unit" width="6%" cellTemplate="unitTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad requerida" dataField="requiredQuantity" width="10%" [allowSorting]="false" cellTemplate="quantityTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad a comprar" dataField="purchasedQuantity" width="10%" [allowSorting]="false" cellTemplate="purchasedQuantityTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad excedente" dataField="leftoverQuantity" width="10%" [allowSorting]="false" alignment="center" [calculateCellValue]="calculateLeftoverQuantityCellValue" cellTemplate="leftoverQuantityTemplate">
            </dxi-column>
            <dxi-column caption="Tipo de excedente" dataField="leftoverType" width="10%" [allowSorting]="false" alignment="center" [calculateCellValue]="calculateLeftoverTypeCellValue" cellTemplate="leftoverTypeTemplate">
            </dxi-column>
            <dxi-column caption="Costo" dataField="supplierCost" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Total" dataField="totalCost" width="15%" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotalCost" alignment="center">
            </dxi-column>
            <!-- <dxi-column caption="Cotización" dataField="supplierQuoteLinkFile"  cellTemplate="quoteTemplate" width="40%" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"> </dxi-column> -->
            <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                <span>{{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'purchasedQuantityTemplate'" class="qty">
                <span>{{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'leftoverQuantityTemplate'" class="qty">
                <span>{{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'leftoverTypeTemplate'" class="qty">
                <span *ngIf="data.value === 'waste'">Merma</span>
                <span *ngIf="data.value === 'stock'">Stock</span>
                <span *ngIf="data.value === ''"></span>
            </div>
            <div *dxTemplate="let data of 'costTemplate'" class="cost">
                <span>$ {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'totalTemplate'" class="cost">
                <span>$ {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'supplierGroupTemplate'">
                {{ data.key | supplierData | async }}
            </div>
            <div *dxTemplate="let data of 'quoteTemplate'">
                <a mat-icon-button>
                    <mat-icon>file_download</mat-icon>{{ data.supplierQuoteLinkFile }} </a>
            </div>
            <div *dxTemplate="let data of 'group'">
                <p>{{ data.key }} | Count: {{ data.overallCount }}</p>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>
            <dxo-summary>
                <dxi-group-item column="supplierCost" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="Total" summaryType="sum" [showInGroupFooter]="false" [customizeText]="formatTotal" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>
            <!-- ============================================================== -->
            <!-- Master Detail  -->
            <!-- ============================================================== -->
            <div *dxTemplate="let detail of 'detail'">
                <strong>Ficha Técnica: </strong>

                <a mat-icon-button [href]="detail.data.dataSheetLinkFile" target="_blank">
                    <mat-icon>file_download</mat-icon>
                </a>
            </div>
        </dx-data-grid>
        <!-- ============================================================== -->
        <!-- END GRID SELECTED PACKS  -->
        <!-- ============================================================== -->
    </div>

    <div class="suppliers-comparative">
        <h3>Comparativo de Proveedores</h3>

        <app-comparative *ngIf="packagesFromQuote" [sealed]="sealed" [packages]="packagesFromQuote" [supplierQuotesData]="SUPPLIER_QUOTEDATA" (selectionChanged)="comparativeChanged($event)">
        </app-comparative>
    </div>

    <app-dialog #advancedDialog width="40%" title="Anticipos" [mat-dialog-close]="true">
        <!-- <ng-container *ngIf="advancedPayments.length > 0">

            <div class="text-center">
                <h4>El proveedor {{ advancedPayments[0].supplierId | supplierData | async }} posee un anticipo</h4>
            </div>
            <div>
                <div>
                    <h4>
                        Monto del anticipo:
                        <strong>{{ advancedPayments[0].advancedPaymentAmount | number:'1.2-2' }}</strong>
                    </h4>
                </div>
                <div>
                    <h4>
                        Pagado:
                        <strong>{{ advancedPayments[0].payOut | number:'1.2-2' }}</strong>
                    </h4>
                </div>
                <div>
                    <h4>
                        Amortizado:
                        <strong>{{ advancedPayments[0].amortized | number:'1.2-2' }}</strong>
                    </h4>
                </div>
                <div>
                    <h4>
                        Por amortizar:
                        <strong>{{ advancedPayments[0].payOut - advancedPayments[0].amortized | number:'1.2-2' }}</strong>
                    </h4>
                </div>
            </div>
            <div class="text-center">
                <h4>¿Cuánto desea amortizar en esta cotización?</h4>
            </div>
            <mat-form-field class="width-100">
                <input matInput [formControl]="amountPercentFormControl" (keyup)="validateFormControls()" placeholder="Porcentaje sobre la cotización" type="number" max="100" min="1" required>
            </mat-form-field>
            <mat-form-field class="width-100">
                <input matInput [formControl]="amountFormControl" (keyup)="validateFormControls()" placeholder="Monto sobre la cotización" type="number" max="100" min="1" required>
            </mat-form-field>
            <div class="mt-5">
                <button mat-raised-button [disabled]="disabledSaveAdvanced" color="primary" (click)="dialogDismissed(true)">
                    Guardar
                </button>
            </div>
        </ng-container> -->
    </app-dialog>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>