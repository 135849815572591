import { NgModule } from "@angular/core";
import { SharedModule } from '@bist-web/shared';
import { QuotesComponent } from './quotes.component';
import { SuppliersQuotesRoutingModule } from "./quotes.routing";
import { SupplierQuoteDetailPageComponent } from './supplier-quote-detail/supplier-quote-detail.component';
import { SuppliersQuotesPageComponent } from './suppliers-quotes/suppliers-quotes.component';

@NgModule({
  imports: [
    SharedModule,
    SuppliersQuotesRoutingModule
  ],
  entryComponents: [
    QuotesComponent
  ],
  declarations: [
    QuotesComponent,
    SupplierQuoteDetailPageComponent,
    SuppliersQuotesPageComponent
  ],
  exports: [
    QuotesComponent
  ]
})
export class SupplierQuotesModule { }
