<input #fileElem
       style="display: none !important;"
       type="file"
       [attr.multiple]="!single ? 'multiple' : null"
       (change)="onFileChange($event)">

<div class="dropzone"
     [class.small]="0">
  <!-- TODO: (drop)="dropFiles($event)" -->
  <!--
  Por favor, arrastre {{ single ? 'un archivo' : 'uno o varios archivos' }} desde su explorador,
  <a href="javascript:void()" (click)="fileElem.click()">o seleccione aquí</a>.
  -->
  Por favor,
  <a (click)="fileElem.click()">
    seleccione {{ single ? 'un archivo' : 'uno o varios archivos' }}
  </a>.
</div>
