<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Salidas</app-title>
    <app-subtitle>Listado</app-subtitle>
    <app-action-button icon="undo" routerLink="/storage/list-departures">Dar Salida
    </app-action-button>

    <div class="grid-container-div padding-h padding-bottom-2">
        <dx-data-grid #remissionsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="remissions" [masterDetail]="{
      enabled: false, template:
      'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="No hay registros" [showBorders]="false" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false"
            (onToolbarPreparing)="onToolbarPreparing($event)">
            <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
            <!-- <dxo-selection selectAllMode="page" mode="multiple" [selectAllMode]="true" [deferred]="false">
            </dxo-selection> -->
            <dxo-export [enabled]="true" fileName="lista-remisiones"></dxo-export>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <!-- <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection> -->
            <dxi-column alignment="center" caption="ID" dataField="remissionId" groupCellTemplate="remissionIdTemplate" cellTemplate="remissionIdTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column alignment="center" caption="OC" dataField="purchaseOrderId" groupCellTemplate="pOGroupTemplate" cellTemplate="pOGroupTemplate" [groupIndex]="0" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Fecha" dataField="dateDeparture" dataType="date" cellTemplate="dateTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="true" [calculateCellValue]="calculateDateCellValue">
            </dxi-column>
            <dxi-column caption="Estado" dataField="statusDeparture" cellTemplate="statusTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Origen" dataField="warehouse" cellTemplate="warehousesTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Destino" dataField="destination" cellTemplate="destinationTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Código" dataField="code" alignment="center" groupCellTemplate="codeGroupTemplate" cellTemplate="codeGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" groupCellTemplate="conceptGroupTemplate" alignment="center" cellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Costo de compra" dataField="cost" cellTemplate="costTemplate" alignment="center" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Salida" [allowEditing]="false" [allowSearch]="true" dataField="quantityDepartures" cellTemplate="quantitySentTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Detalle" dataField="remissionUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <dxi-column *ngIf="canDelete" dataField="uuid" caption="Eliminar" cellTemplate="deleteDeparture" [allowEditing]="false" [allowSearch]="false" [allowSorting]="false" alignment="center">
            </dxi-column>
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span>
          <i
            class="material-icons icon-gray"
            matTooltip="Detalle"
            (click)="detailRemission(data.key)">
            remove_red_eye</i></span>
            </div>
            <div *dxTemplate="let data of 'deleteDeparture'">
                <button mat-icon-button color="warn" (click)="deleteDeparture(data.key)">
          <mat-icon>delete_outline</mat-icon>
        </button>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityDepartures" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityToLeftSend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>
            <div *dxTemplate="let data of 'warehousesTemplate'">
                <ng-container *ngIf="data.value; else noWarehouse">
                    <app-warehouse-data [id]="data.value"></app-warehouse-data>
                </ng-container>
                <ng-template #noWarehouse>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'destinationTemplate'">
                <ng-container *ngIf="data.value !== 'Obra'; else noDestination">
                    <app-warehouse-data [id]="data.value"></app-warehouse-data>
                </ng-container>
                <ng-template #noDestination>
                    {{ data.value }}
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>
            <div *dxTemplate="let data of 'pOGroupTemplate'">OC-{{ data.value }}</div>
            <div *dxTemplate="let data of 'statusTemplate'">
                <strong>{{ data.value | remissionStatus }}</strong>
            </div>
            <div *dxTemplate="let data of 'codeGroupTemplate'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'costTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'receiverTemplate'">
                <ng-container *ngIf="data.value; else noReceiver">
                    <strong>{{ data.value }}</strong>
                </ng-container>
                <ng-template #noReceiver>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantitySentTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'codeTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'quantityApprovedTemplate'">
                <ng-container *ngIf="data.value; else noQuantityApproved">
                    <span> {{ data.value | number: '1.2-2' }}</span>
                </ng-container>
                <ng-template #noQuantityApproved>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
                <ng-container *ngIf="data.value; else noQuantityLeft">
                    {{ data.value | number: '1.2-2' }}
                </ng-container>
                <ng-template #noQuantityLeft>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalRemissions }}</h2>
                    <span class="name">Total Remisiones</span>
                </div>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'remissionIdTemplate'">REM-{{ data.value }}</div>
        </dx-data-grid>
    </div>
</app-page-widget>