<app-section-nav>
    <app-title>Almacén</app-title>

    <app-nav-item routeData="./list">Listado de OC</app-nav-item>
    <app-nav-item routeData="./entries">Entradas</app-nav-item>
    <app-nav-item routeData="./warehouse">Almacen</app-nav-item>
    <app-nav-item routeData="./departures">Salidas</app-nav-item>
    <app-nav-item routeData="./report">Reporte</app-nav-item>
</app-section-nav>

<!-- <router-outlet></router-outlet> -->