<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Usuarios</app-title>
    <app-subtitle>Listado. Seleccione para editar.</app-subtitle>

    <!-- <div>
      <mat-form-field class="searchbar-width">
          <input matInput placeholder="Buscar usuario por nombre" [formControl]="searchBarString" />
      </mat-form-field>
      <button mat-icon-button (click)="clearSearchString()">
    <mat-icon aria-hidden="false" aria-label="Example home icon">clear</mat-icon>
  </button>
  </div> -->
    <app-datatable #datatable [columns]="dtColumns" [rows]="users" [showImpersonate]="false" (changedRowsSelection)="openManagementDialog($event)" [requiredColumns]="requiredProps">
    </app-datatable>
</app-page-widget>

<!-- Management Dialog -->
<app-dialog #managementDialog noPadding (dismiss)="onCloseManagementDialog()">
    <app-title>{{ selectedUser ? selectedUser.displayName || selectedUser.email : 'Datos del Usuario' }}
    </app-title>
    <app-subtitle>{{ selectedUser ? 'Datos del Usuario' : '' }}</app-subtitle>

    <!-- <div class="width-500"> -->
    <ng-template [ngxPermissionsExcept]="['impersonate:supplier']">
        <mat-tab-group [dynamicHeight]="true">
            <mat-tab *ngIf="selectedUser" label="Proveedores">
                <app-datatable canSelectMultiple selectWithCheckboxes [columns]="providersDtColumns" [rows]="providers" [showImpersonate]="false" [selected]="dtSelectedProvidersApprovers" (changedRowsSelection)="setUserApprover(selectedUser, $event)">
                </app-datatable>
            </mat-tab>
        </mat-tab-group>
    </ng-template>
    <!-- </div> -->
</app-dialog>