<app-page-widget (refresh)="refreshData()">
    <app-title>Resúmen de Operaciones</app-title>
    <app-action-button icon="playlist_add" routeData="/supplier-remissions/new">Crear</app-action-button>
    <div class="content-block">
        <div class="grid-container-div padding-h padding-bottom-2">
            <dx-data-grid #remissionsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="entries" [masterDetail]="{
          enabled: false,
          template: 'detail'
        }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="No hay
          registros" [showBorders]="false" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" (onToolbarPreparing)="onToolbarPreparing($event)">
                <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
                <dxo-selection selectAllMode="page" mode="multiple" [deferred]="false"> </dxo-selection>
                <!-- <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export> -->
                <dxo-export [enabled]="true" fileName="lista-remisiones"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
                <dxi-column alignment="center" caption="REM" dataField="remissionId" cellTemplate="remissionIdTemplate" groupCellTemplate="remissionIdTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
                </dxi-column>
                <dxi-column alignment="center" caption="OC" dataField="purchaseOrderId" cellTemplate="pOGroupTemplate" groupCellTemplate="pOGroupTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
                </dxi-column>
                <dxi-column caption="Concepto" dataField="concept" [groupIndex]="0" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
                <!-- <dxi-column caption="Fecha" dataField="dateRemission" width="10%" dataType="date" cellTemplate="dateTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="true" [calculateCellValue]="calculateDateCellValue">
          </dxi-column> -->
                <!-- <dxi-column caption="Estado" dataField="statusReceived" width="10%" cellTemplate="statusTemplate" alignment="center" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
          </dxi-column> -->
                <dxi-column caption="Unidad" dataField="unit" alignment="center"> </dxi-column>
                <dxi-column caption="P.U" dataField="supplierCost" alignment="center" cellTemplate="costTemplate">
                </dxi-column>
                <dxi-column caption="Costo Enviado" dataField="costSend" alignment="center" cellTemplate="costTemplate">
                </dxi-column>
                <dxi-column caption="Costo Recibido" dataField="costReceived" alignment="center" cellTemplate="costTemplate">
                </dxi-column>
                <dxi-column caption="Costo Por Surtir" dataField="costLeftToSend" alignment="center" cellTemplate="costTemplate">
                </dxi-column>
                <dxi-column caption="Enviado" dataField="quantitySend" alignment="center" cellTemplate="quantitySentTemplate">
                </dxi-column>
                <dxi-column caption="Recibido" dataField="quantityReceived" alignment="center" cellTemplate="quantitySentTemplate">
                </dxi-column>
                <dxi-column caption="Por surtir" [allowEditing]="false" [allowSearch]="true" dataField="quantityToLeftSend" cellTemplate="quantityDeparturesTemplate" [allowSorting]="false" alignment="center">
                </dxi-column>
                <dxi-column caption="Comentario" [allowEditing]="false" [allowSearch]="true" dataField="comment" [allowSorting]="false" alignment="center">
                </dxi-column>
                <!-- <dxi-column caption="Detalle" dataField="remissionUuid" cellTemplate="ccButtonTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
          <dxi-column caption="Salida" dataField="remissionUuid" cellTemplate="ccButtonEntryTemplate" width="8%" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column> -->

                <dxo-summary [skipEmptyValues]="true">
                    <dxi-group-item column="quantitySend" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <dxi-group-item column="quantityReceived" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <dxi-group-item column="quantityDepartures" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                    <dxi-group-item column="quantityWarehouse" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                    </dxi-group-item>
                </dxo-summary>

                <div *dxTemplate="let data of 'dateTemplate'">
                    <strong> {{ data.value | timestampToDate }}</strong> -
                    <span> hace {{ data.value | timeAgo }}</span>
                </div>
                <div *dxTemplate="let data of 'pOGroupTemplate'">OC-{{ data.value }}</div>
                <div *dxTemplate="let data of 'statusTemplate'">
                    <strong>{{ data.value | remissionStatus }}</strong>
                </div>
                <div *dxTemplate="let data of 'receiverTemplate'">
                    <ng-container *ngIf="data.value; else noReceiver">
                        <strong>{{ data.value }}</strong>
                    </ng-container>
                    <ng-template #noReceiver>
                        --
                    </ng-template>
                </div>
                <div *dxTemplate="let data of 'quantitySentTemplate'">
                    <strong>{{ data.value | number: '1.2-2' }}</strong>
                </div>
                <div *dxTemplate="let data of 'quantityApprovedTemplate'">
                    <ng-container *ngIf="data.value; else noQuantityApproved">
                        <span> {{ data.value | number: '1.2-2' }}</span>
                    </ng-container>
                    <ng-template #noQuantityApproved>
                        --
                    </ng-template>
                </div>
                <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
                    <ng-container *ngIf="data.value; else noQuantityLeft">
                        {{ data.value | number: '1.2-2' }}
                    </ng-container>
                    <ng-template #noQuantityLeft>
                        --
                    </ng-template>
                </div>
                <div *dxTemplate="let data of 'costTemplate'">
                    <ng-container *ngIf="data.value; else noQuantityLeft">
                        ${{ data.value | number: '1.2-2' }}
                    </ng-container>
                    <ng-template #noQuantityLeft>
                        --
                    </ng-template>
                </div>
                <div *dxTemplate="let data of 'quantityDeparturesTemplate'">
                    <ng-container *ngIf="data.value; else noQuantityLeft">
                        {{ data.value | number: '1.2-2' }}
                    </ng-container>
                    <ng-template #noQuantityLeft>
                        --
                    </ng-template>
                </div>
                <div *dxTemplate="let data of 'totalGroupCount'">
                    <div class="informer">
                        <h2 class="count">{{ totalRemissions }}</h2>
                        <span class="name">Total Remisiones</span>
                    </div>
                </div>
                <div *dxTemplate="let data of 'conceptGroupTemplate'">
                    {{ data.value }}
                </div>
                <div *dxTemplate="let data of 'remissionIdTemplate'">REM-{{ data.value }}</div>
            </dx-data-grid>
        </div>

        <!-- <div class="remission-table-container">
      <table class="remission-table" *ngIf="remissions">
        <caption>
          Desglose de conglomerados
        </caption>
        <tbody>
          <tr>
            <th>Orden de Compra</th>
            <th>Concepto</th>
            <th>Precio Unitario</th>
            <th>Cantidad Total</th>
            <th>Unidad</th>
            <th>Cantidad Enviada</th>
            <th>Cantidad Recibida</th>
            <th>Por Surtir</th>
            <th>Valor Mercancia Entregada</th>
            <th>Valor Mercancia por Entregar</th>
            <th>Comentarios</th>
          </tr>
          <ng-container *ngFor="let purchaseOrder of remissions">
            <tr>
              <td>
                <b>{{ purchaseOrder.purchaseOrderId }}</b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <ng-container *ngFor="let conceptObject of purchaseOrder.packages">
              <tr class="concept">
                <th></th>
                <th>{{ conceptObject.concept }}</th>

                <th>$ {{ conceptObject.unitPrice | number: '1.2-2' }}</th>
                <th>{{ conceptObject.totalQuantity | number: '1.2-2' }}</th>
                <th>{{ conceptObject.unit }}</th>
                <th>{{ conceptObject.totalQuantitySent | number: '1.2-2' }}</th>
                <th>{{ conceptObject.totalQuantityApproved | number: '1.2-2' }}</th>
                <th>{{ conceptObject.quantityLeftToSend | number: '1.2-2' }}</th>
                <th>${{ conceptObject.totalValueOfQuantityApproved | number: '1.2-2' }}</th>
                <th>${{ conceptObject.totalValueOfQuantityLeftToSend | number: '1.2-2' }}</th>
                <th></th>
              </tr>
              <tr>
                <td></td>
                <td><b>Remisión</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr *ngFor="let packageObject of conceptObject.packages">
                <td></td>
                <td>
                  <b>{{ packageObject.remissionId }}</b>
                </td>

                <td></td>
                <td></td>
                <td></td>
                <td>{{ packageObject.quantitySent | number: '1.2-2' }}</td>
                <td>
                  <ng-container *ngIf="packageObject.quantityApproved; else noQuantityApproved">
                    {{ packageObject.quantityApproved | number: '1.2-2' }}
                  </ng-container>
                  <ng-template #noQuantityApproved>
                    --
                  </ng-template>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>--</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div> -->
    </div>
</app-page-widget>