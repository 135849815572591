<app-page-widget [menuItems]="widgetMenu" [actionButtons]="widgetActions" (refresh)="refreshData()">
    <app-title>Seleccione o Cree una Requisición</app-title>
    <app-subtitle></app-subtitle>

    <app-action-button icon="playlist_add" (click)="newRequisition()">Crear</app-action-button>
    <app-menu-item icon="playlist_add" (click)="newRequisition()">Crear Requisición </app-menu-item>

    <div class="selectProv" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <app-select-box [widthSelect]="300" [dataSource]="typePackages" displayExpr="TypeText" (selectItem)="onSelectRequisition($event)" placeholder="Selecionar
                tipo de paquete">
            </app-select-box>
        </div>
        <!-- <div>
            <app-select-box [widthSelect]="220" [dataSource]="requisitionList" displayExpr="requisitionNro" (selectItem)="onSelectRequisitionNro($event)" placeholder="Selecionar
                contrato">
            </app-select-box>
        </div> -->
        <!-- <div>
          <app-select-box [widthSelect]="250" [dataSource]="assignmentsList" displayExpr="totalAssignmentFormat" (selectItem)="onSelectAssignment($event)" placeholder="Selecionar
                monto de contrato">
          </app-select-box>
      </div>
      <div>
          <app-select-box [widthSelect]="200" [dataSource]="typePackages" displayExpr="TypeText" (selectItem)="onSelectTypePackages($event)" placeholder="Selecionar
                tipo">
          </app-select-box>
      </div> -->
    </div>

    <div class="content mt-10" *ngIf="requisitionList.length > 0">
        <dx-data-grid #requisitionsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="requisitionList" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente no
      éxisten registros." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="true" (onExporting)="onExporting($event)" (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
            (onOptionChanged)="changePage($event)">
            <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
            <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
            <!-- <dxo-seach-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
            <dxo-export [enabled]="true" fileName="lista-requisiciones"></dxo-export>
            <dxo-paging [pageSize]="15" [pageIndex]="_requisitionsService.pageIndex"></dxo-paging>
            <!-- <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection> -->
            <dxi-column caption="ID Requisición" dataField="requisitionNro" [groupIndex]="0" alignment="center" sortOrder="desc" groupCellTemplate="requisitionIdGroupTemplate" cellTemplate="requisitionIdGroupTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
            <dxi-column caption="ID Derivado" dataField="derivativeId" alignment="center" sortOrder="desc" groupCellTemplate="derivativeIdGroupTemplate" cellTemplate="derivativeIdGroupTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Fecha" dataType="date" cellTemplate="dateTemplate" dataField="requisitionCreatedAt" sortOrder="desc" alignment="left" [allowEditing]="false" [allowSorting]="true" [calculateCellValue]="calculateDateCellValue"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Tipo" dataField="requisitionTypeName" cellTemplate="requisitionType" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Estado" cellTemplate="assignmentStatusTemplate" dataField="requisitionStatus" alignment="center" [allowEditing]="false" [allowSorting]="false" [allowFiltering]="false" [calculateCellValue]="customizeStatusLabel">
            </dxi-column>
            <dxi-column caption="Solicitante" dataField="createdBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [visible]="true"></dxi-column>
            <dxi-column caption="Solicitante" dataField="createdByDisplayName" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [visible]="false"></dxi-column>
            <dxi-column caption="Aprobadores Faltantes" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
            <dxi-column caption="Catidad" dataField="quantity" [customizeText]="formatQuantity"></dxi-column>
            <dxi-column caption="Costo" dataField="cost" [customizeText]="formatTotal"></dxi-column>
            <dxi-column caption="Total" dataField="totalCost" [customizeText]="formatTotal"></dxi-column>
            <dxi-column caption="Detalle" dataField="quoteUuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span><i class="material-icons icon-gray"
            (click)="detailRequisition(data.key)">
            remove_red_eye
          </i></span>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <!-- <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="empty" [showInGroupFooter]="false" [alignByColumn]="true"></dxi-group-item> -->
                <dxi-group-item column="totalCost" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>
            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong>
                <app-timeago-date [timestamp]="data.value"></app-timeago-date>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'requisitionIdGroupTemplate'">REQ-{{ data.value }}</div>
            <div *dxTemplate="let data of 'derivativeIdGroupTemplate'">{{ data.value }}</div>
            <div *dxTemplate="let data of 'requisitionType'">
                <span>{{ data.value }}</span>
            </div>
            <div *dxTemplate="let data of 'assignmentStatusTemplate'">
                <strong>{{ data.value.status }}</strong>
            </div>
            <div *dxTemplate="let data of 'amountTemplate'">
                <span>$ {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'createdByTemplate'">
                <app-user-data [id]="data.value"></app-user-data>
            </div>
            <div *dxTemplate="let data of 'approverRemaining'">
                <ng-container>
                    <ul class="approversRemaining">
                        <li *ngFor="let item of data.value">
                            <app-user-data *ngIf="item[0].length >= 2" [id]="item[0]"></app-user-data>
                            <span *ngIf="item[0].length === 1">{{ item }}</span>
                        </li>
                    </ul>
                </ng-container>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalRequisitions }}</h2>
                    <span class="name">Total Requisiciones</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
    <div class="content text-center" *ngIf="requisitionList.length === 0">
        <h2>No posee requisiciones</h2>
    </div>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>