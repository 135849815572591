<app-page-widget noPadding (refresh)="refreshData()" [menuItems]="widgetMenu" id="purchase-order">
    <app-title>OC- {{ purchaseOrder?.uuid | idFromUuid }} </app-title>
    <app-subtitle>{{ purchaseOrder?.createdAt | timestampToDate: 'long' }}</app-subtitle>
    <span class="info-label">Cotización:
      <strong>{{ purchaseOrder?.quoteId | idFromUuid }}</strong>
    </span>
    <app-action-button flat noText icon="done_all" (click)="viewApprovers()">Aprobaciones
    </app-action-button>

    <div class="actions-block">
        <div class="left">
            <div class="button-invoices">
                <button mat-raised-button class="action-button" *ngIf="invoices[0]" [disabled]="_paymentOrders.length> 0" (click)="rejectedInvoice(invoices[0]?.uuid)" color="warn">
                    <!-- <mat-icon>call_made</mat-icon> -->
                    Rechazar Factura
                </button>
                <button mat-raised-button *ngIf="invoices" class="action-button" (click)="uploadInvoice('pdf')" color="primary" [disabled]="(invoices[0]?.status === 1 &&
                    (invoices[0]?.files[0].typeFile === 'pdf' ||
                    invoices[0]?.files[1]?.typeFile
                    === 'pdf')) || (invoices[0] &&
                    (invoices[0]?.files[0]?.typeFile
                    === 'pdf' || invoices[0]?.files[1]?.typeFile
                    === 'pdf') && (approveIsAvailable ||
                    sendToRemittancesIsAvailable ||
                    !sendToRemittancesIsAvailable))">
                    <mat-icon>cloud_upload</mat-icon>
                    Cargar Factura PDF
                </button>
                <button mat-raised-button *ngIf="invoices" class="action-button" (click)="uploadInvoice('xml')" color="primary" [disabled]="(invoices[0]?.status === 1 &&
                    (invoices[0]?.files[0].typeFile === 'xml' ||
                    invoices[0]?.files[1]?.typeFile
                    === 'xml')) || (invoices[0] &&
                    (invoices[0]?.files[0]?.typeFile
                    === 'xml' || invoices[0]?.files[1]?.typeFile
                    === 'xml') && approveIsAvailable)">
                    <mat-icon>cloud_upload</mat-icon>
                    Cargar Factura XML
                </button>

            </div>
            <div class="actions-block">
                <div class="left-invoices">
                    <a *ngIf="sendToApproveIsAvailable && statusInvoice !== 4 &&
                        (invoices[0]?.files[0].typeFile === 'xml' ||
                        invoices[0]?.files[1]?.typeFile === 'xml' )" color="warn" (click)="removeInvoice(invoices[0]?.files,
                        invoices[0]?.uuid,
                        'xml')" mat-icon-button>
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                    <a *ngIf="statusInvoice !== 4 &&
                        (invoices[0]?.files[0].typeFile === 'xml' ||
                        invoices[0]?.files[1]?.typeFile === 'xml' )" color="primary" [href]="invoices[0]?.files[0].typeFile
                        === 'xml' ?
                        invoices[0]?.files[0].httpFile :
                        invoices[0]?.files[1].httpFile" target="_blank" mat-icon-button>
                        <mat-icon>insert_drive_file</mat-icon>
                        Factura XML
                    </a>
                </div>
                <div class="right-invoices">
                    <a *ngIf="sendToApproveIsAvailable && statusInvoice !== 4 &&
                        (invoices[0]?.files[0].typeFile === 'pdf' ||
                        invoices[0]?.files[1]?.typeFile === 'pdf' )" color="warn" (click)="removeInvoice(invoices[0]?.files,
                        invoices[0]?.uuid,
                        'pdf')" mat-icon-button>
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                    <a *ngIf="statusInvoice !== 4 &&
                        (invoices[0]?.files[0].typeFile === 'pdf' ||
                        invoices[0]?.files[1]?.typeFile === 'pdf' )" color="primary" [href]="invoices[0]?.files[0].typeFile
                        === 'pdf' ?
                        invoices[0]?.files[0].httpFile :
                        invoices[0]?.files[1].httpFile" target="_blank" mat-icon-button>
                        <mat-icon>insert_drive_file</mat-icon>
                        Factura PDF
                    </a>
                </div>
            </div>
        </div>
        <div class="right">
            <a mat-raised-button class="action-button mr-5" (click)="goToPreviousUrl()" color="warn">
                <mat-icon>undo</mat-icon>
                Regresar
            </a>
            <ng-template [ngIf]="sendToRemittancesIsAvailable">
                <button mat-raised-button class="action-button" *ngxPermissionsOnly="['remittances:can_send_to_remittances']" (click)="sendToRemittances()" color="primary">
                    <mat-icon>playlist_add_check</mat-icon>
                    Enviar a Remesas
                </button>
            </ng-template>
            <button mat-raised-button class="action-button" *ngIf="approveIsAvailable" (click)="approve()" color="primary">
                <mat-icon>done</mat-icon>
                Aprobar
            </button>

            <button mat-raised-button class="action-button" *ngIf="sendToApproveIsAvailable" (click)="sendToApprove()" color="primary">
                <mat-icon>call_made</mat-icon>
                Enviar a Aprobar
            </button>
            <button mat-raised-button class="action-button" *ngIf="isOrderReadyToDownload" (click)="downloadOrder()" color="primary">
                <mat-icon>cloud_download</mat-icon>
                Descargar orden
            </button>

            <button *ngIf="_paymentOrders.length> 0; else disabledBtn" mat-raised-button class="action-button" color="primary" (click)="showLogDialog()">
                <mat-icon>remove_red_eye</mat-icon>
                Ver órdenes de pago
            </button>
            <ng-template #disabledBtn>
                <button mat-raised-button class="action-button" disabled>
                    <mat-icon>remove_red_eye</mat-icon>
                    Sin órdenes de pago
                </button>
            </ng-template>

        </div>
    </div>

    <div class="header-block">
        <span class="info-label">Monto Total:
            <strong>${{ purchaseOrder?.totalOrderAmount | number: '1.2-2' }}</strong>
        </span>
        <span class="info-label">Pagado:
            <strong>${{ purchaseOrder?.paidAmmount | number: '1.2-2' }}</strong>
        </span>
        <span class="info-label">Pasivos:
            <strong>${{ purchaseOrder?.passiveAmmount | number: '1.2-2' }}</strong>
        </span>
    </div>

    <div class="header-block">
        <span class="info-label">Estatus:
            <strong>{{ purchaseOrder?.status | purchaseOrderStatus }}</strong>
        </span>
        <span class="info-label">Solicitado por:
            <strong>{{ purchaseOrder?.createdBy | userData | async }}</strong>
        </span>
    </div>

    <div id="packages-detail" class="content-block-section">
        <h3 class="content-title">Proveedor</h3>
        <div>
            <div>
                <span class="info-label">Proveedor: </span>
                <strong>{{ purchaseOrder?.supplierId | supplierData | async }}</strong>
            </div>
            <!-- <div>
        <span class="info-label">Moneda: </span>
        <strong>{{ purchaseOrder?.supplierId | supplierData:'currency' | async }}</strong>
      </div> -->
            <div>
                <span class="info-label">Razón Social: </span>
                <strong>{{ purchaseOrder?.supplierId | supplierData:
                    'socialReason' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">RFC: </span>
                <strong>{{ purchaseOrder?.supplierId | supplierData: 'rfc' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">Dirección Comercial:</span>
                <strong>{{ purchaseOrder?.supplierId | supplierData:
                    'businessAddress' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">Dirección Fiscal:</span>
                <strong>{{ purchaseOrder?.supplierId | supplierData:
                    'taxAddress' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">Teléfono:</span>
                <strong>{{ purchaseOrder?.supplierId | supplierData: 'phone' |
                    async }}</strong>
            </div>
            <div>
                <span class="info-label">email: </span>
                <strong>{{ purchaseOrder?.supplierId | supplierData: 'email' |
                    async }}</strong>
            </div>
        </div>
    </div>

    <div id="packages" class="content-block">
        <h3 class="content-title">Paquetes</h3>
        <dx-data-grid #poGrid id="poGrid" [wordWrapEnabled]="true" [dataSource]="packages" [masterDetail]="{ enabled: false, template:
            'detail' }" [hoverStateEnabled]="false" [wordWrapEnabled]="true" [showColumnLines]="true" [showRowLines]="true" noDataText="Detalle
            de
            cotización." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false">
            <dxo-editing mode="cell" [allowUpdating]="false"></dxo-editing>
            <dxo-export [enabled]="false"></dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-search-panel [visible]="false" [highlightSearchText]="false"></dxo-search-panel>
            <dxo-paging [pageSize]="5"></dxo-paging>
            <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
            <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
            <dxi-column caption="Concepto" dataField="concept" width="40%" [allowSorting]="false" [allowSearch]="true" [groupIndex]="1" alignment="left">
            </dxi-column>
            <dxi-column caption="Marca" dataField="supplierBrand" width="20%" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <dxi-column caption="Modelo" dataField="supplierModel" width="20%" [allowSorting]="false" [allowSearch]="true" alignment="left">
            </dxi-column>
            <dxi-column caption="Unidad" dataField="unit" width="6%" cellTemplate="unitTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="Cantidad" dataField="requiredQuantity" width="10%" [allowSorting]="false" cellTemplate="quantityTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Costo" dataField="supplierCost" width="15%" [allowSorting]="false" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Subtotal" width="15%" [allowSorting]="false" cellTemplate="costTemplate" [calculateCellValue]="calculateSubtotalTotalCost" alignment="center">
            </dxi-column>
            <dxi-column caption="IVA" width="15%" [allowSorting]="false" [calculateCellValue]="calculateIva" cellTemplate="costTemplate" alignment="center">
            </dxi-column>
            <dxi-column caption="Total" width="15%" [allowSorting]="false" cellTemplate="totalTemplate" [calculateCellValue]="calculateTotalCost" alignment="center">
            </dxi-column>
            <div *dxTemplate="let data of 'quantityTemplate'" class="qty">
                <span>{{ data.value | number: '1.2-3' }}</span>
            </div>
            <div *dxTemplate="let data of 'costTemplate'" class="cost">
                <span>$ {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'totalTemplate'" class="cost">
                <span>$ {{ data.value | number }}</span>
            </div>
            <div *dxTemplate="let data of 'supplierGroupTemplate'">
                {{ data.key | supplierData | async }}
            </div>
            <div *dxTemplate="let data of 'quoteTemplate'">
                <a mat-icon-button>
                    <mat-icon>file_download</mat-icon>{{ data.supplierQuoteLinkFile }}
                </a>
            </div>
            <div *dxTemplate="let data of 'unitTemplate'">
                <span>{{ data.value }}</span>
            </div>

            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="supplierCost" summaryType="sum" displayFormat="{0}" [customizeText]="formatCost" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="Subtotal" summaryType="sum" displayFormat="{0}" [customizeText]="formatSubTotalGroup" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="IVA" summaryType="sum" displayFormat="{0}" [customizeText]="formatIvaGroup" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="Total" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotalGroup" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>

                <dxi-total-item name="subtotalPacks" column="Total" summaryType="sum" [customizeText]="formatSubtotalPacks">
                </dxi-total-item>
                <dxi-total-item name="descuentoPrestamoAbonoAnticipo" displayFormat="{0}" column="Total" summaryType="sum" [customizeText]="formatAnticipo">
                </dxi-total-item>
                <dxi-total-item column="Total" displayFormat="{0}" summaryType="sum" [customizeText]="formatTotal">
                </dxi-total-item>
            </dxo-summary>

            <dxi-column></dxi-column>
        </dx-data-grid>
    </div>
    <div class="content-block2-section" *ngIf="_approvers">
        <div class="content-block-section text-center" *ngFor="let approver of
            _approvers">
            <h3 class="content-title">Firma</h3>
            <div style="height:150px;"></div>
            <div>
                <span class="info-label">Nombre: <b>{{ approver.displayName }}</b></span>
            </div>
            <br />
        </div>
    </div>

    <!-- Crear un component para el log de ordenes de pago -->
    <payment-order-log #paymentOrderDialog> </payment-order-log>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>