<!--
<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="status-block">
    <div>
      Estado:
      <strong>{{ quote?.status | quoteStatus }}</strong>
    </div>
    <div>
      Solicitado por:
      <app-user-data *ngIf="quote" [id]="quote.createdBy"></app-user-data>
    </div>
  </div>
  <div class="actions-block">
    <ng-template [ngIf]="sendToApproveIsAvailable">
      <app-button *ngxPermissionsOnly="['quotes:can_send_to_approve']" icon="call_made" (click)="sendToApprove()">Enviar a Aprobar</app-button>
    </ng-template>
    <ng-template [ngIf]="approveIsAvailable">
      <app-button *ngxPermissionsOnly="['quotes:can_approve']" icon="done" (click)="approve()">Aprobar</app-button>
    </ng-template>
    <ng-template [ngIf]="rejectIsAvailable">
      <app-button icon="close" (click)="cancel()">Cancelar</app-button>
    </ng-template>
    <ng-template [ngIf]="cancelIsAvailable">
      <app-button icon="close" (click)="cancel()">Cancelar</app-button>
    </ng-template>
  </div>
</div>
-->

<ng-template #loadingTpl>
  <rmd-loading-indicator small></rmd-loading-indicator>
</ng-template>

<div *ngIf="!loading; else loadingTpl" class="fa-container">
  xx
</div>
