<app-page-widget noPadding (refresh)="refreshData()">
    <app-title>Paquetes disponibles para dar salida</app-title>
    <app-subtitle>Listado</app-subtitle>
    <div class="grid-container-div padding-h padding-bottom-2">

        <dx-data-grid #remissionsGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="entries" [masterDetail]="{
            enabled: false, template:
            'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="No hay registros" [showBorders]="false" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false"
            (onToolbarPreparing)="onToolbarPreparing($event)" (onSelectionChanged)="onSelectPurchaseOrderRow($event)" (onRowUpdated)="onRowUpdated($event)">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-grouping [autoExpandAll]="expanded"></dxo-grouping>
            <dxo-export [enabled]="true" fileName="lista-remisiones"></dxo-export>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxi-column alignment="center" caption="REM" alignment="left" dataField="remissionId" groupCellTemplate="remissionIdTemplate" cellTemplate="remissionIdTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column alignment="center" caption="OC" alignment="center" dataField="purchaseOrderId" groupCellTemplate="pOGroupTemplate" cellTemplate="pOGroupTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Almacen" dataField="warehouse" cellTemplate="warehousesTemplate" sortOrder="desc" alignment="center" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false">
            </dxi-column>
            <dxi-column caption="Código" dataField="code" alignment="center" groupCellTemplate="codeGroupTemplate" cellTemplate="codeGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Concepto" dataField="concept" groupCellTemplate="conceptGroupTemplate" cellTemplate="conceptGroupTemplate" [groupIndex]="0" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true" alignment="left"></dxi-column>
            <dxi-column caption="Unidad" dataField="unit" groupCellTemplate="unitGroupTemplate" alignment="center" cellTemplate="unitGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Nivel" dataField="level" groupCellTemplate="levelGroupTemplate" alignment="center" cellTemplate="levelGroupTemplate" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Costo de compra" dataField="cost" cellTemplate="costTemplate" alignment="center" [allowEditing]="false" [allowSorting]="false" [allowSearch]="true"></dxi-column>
            <dxi-column caption="Recibido" dataField="quantityReceived" [allowEditing]="false" alignment="center" cellTemplate="quantitySentTemplate">
            </dxi-column>
            <dxi-column caption="Cantidad Salida" [allowEditing]="false" [allowSearch]="true" dataField="quantityDepartures" cellTemplate="quantitySentTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>
            <dxi-column caption="En almacen" [allowEditing]="false" [allowSearch]="true" dataField="quantityWarehouse" cellTemplate="quantitySentTemplate" [allowSorting]="false" alignment="center">
            </dxi-column>

            <dxi-column caption="Salida" dataField="remissionUuid" cellTemplate="ccButtonEntryTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <dxi-column caption="Cambio" dataField="remissionUuid" cellTemplate="ccButtonChangeTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
            <div *dxTemplate="let data of 'ccButtonTemplate'">
                <span>
                    <i
                        class="material-icons icon-gray"
                        matTooltip="Detalle"
                        (click)="detailRemission(data.key)">
                        remove_red_eye</i></span>
            </div>
            <div *dxTemplate="let data of 'ccButtonChangeTemplate'">
                <span *ngIf="data.key.quantityReceived> 0; else noChange">
                    <i
                        class="material-icons icon-gray"
                        matTooltip="Cambio de almacen"
                        (click)="changeWarehouse(data)">
                        shuffle</i></span>
                <ng-template #noChange>
                    --
                </ng-template>
            </div>
            <div class="padding-top-10" *dxTemplate="let toDeparture of
                'departureTemplate'">
                <span *ngIf="toDeparture?.value">{{ toDeparture.value }}</span>
                <span *ngIf="!toDeparture || !toDeparture.value" class="placeholder-color">Ingrese cantidad</span>
            </div>
            <div *dxTemplate="let data of 'ccButtonEntryTemplate'">
                <span>
                    <i
                        class="material-icons icon-gray"
                        matTooltip="Salida"
                        (click)="departureRemission(data)">
                        input</i></span>
            </div>
            <dxo-summary [skipEmptyValues]="true">
                <dxi-group-item column="quantityDepartures" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="cost" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
                <dxi-group-item column="quantityReceived" summaryType="sum" displayFormat="{0}" [customizeText]="formatQuantity" [showInGroupFooter]="false" [alignByColumn]="true">
                </dxi-group-item>
            </dxo-summary>

            <div *dxTemplate="let data of 'dateTemplate'">
                <strong> {{ data.value | timestampToDate }}</strong> -
                <span> hace {{ data.value | timeAgo }}</span>
            </div>
            <div *dxTemplate="let data of 'warehousesTemplate'">
                <ng-container *ngIf="data.value; else noWarehouse">
                    <app-warehouse-data [id]="data.value"></app-warehouse-data>
                </ng-container>
                <ng-template #noWarehouse>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'pOGroupTemplate'">OC-{{ data.value }}</div>
            <div *dxTemplate="let data of 'statusTemplate'">
                <strong>{{ data.value | remissionStatus }}</strong>
            </div>
            <div *dxTemplate="let data of 'codeGroupTemplate'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'unitGroupTemplate'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'levelGroupTemplate'">
                <strong>{{ data.value }}</strong>
            </div>
            <div *dxTemplate="let data of 'costTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'receiverTemplate'">
                <ng-container *ngIf="data.value; else noReceiver">
                    <strong>{{ data.value }}</strong>
                </ng-container>
                <ng-template #noReceiver>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantitySentTemplate'">
                <strong>{{ data.value | number: '1.2-2' }}</strong>
            </div>
            <div *dxTemplate="let data of 'quantityApprovedTemplate'">
                <ng-container *ngIf="data.value; else noQuantityApproved">
                    <span> {{ data.value | number: '1.2-2' }}</span>
                </ng-container>
                <ng-template #noQuantityApproved>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'quantityLeftToSendTemplate'">
                <ng-container *ngIf="data.value; else noQuantityLeft">
                    {{ data.value | number: '1.2-2' }}
                </ng-container>
                <ng-template #noQuantityLeft>
                    --
                </ng-template>
            </div>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ totalRemissions }}</h2>
                    <span class="name">Total Remisiones</span>
                </div>
            </div>
            <div *dxTemplate="let data of 'conceptGroupTemplate'">
                {{ data.value }}
            </div>
            <div *dxTemplate="let data of 'remissionIdTemplate'">REM-{{ data.value }}</div>
        </dx-data-grid>
    </div>
</app-page-widget>

<app-dialog #formDialog>
    <ng-container>
        <app-title>Cantidad de salida</app-title>

        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-top-2" *ngIf="selectedGridItems">
                <div class="content-block margin-top-0">
                    <div class="width-100">
                        <p>
                            <strong>{{ selectedGridItems[0].concept }}</strong>
                        </p>
                    </div>
                    <div class="width-50">
                        <p>
                            Cantidad enviada: <strong>{{
                                selectedGridItems[0].quantitySend }}</strong>
                        </p>
                    </div>
                    <div class="width-50">
                        <p>
                            Cantidad recibida: <strong>{{
                                selectedGridItems[0].quantityReceived }}</strong>
                        </p>
                    </div>
                    <div class="width-50">
                        <p>
                            Cantidad en almacen:
                            <strong>{{
                                selectedGridItems[0].quantityReceived -
                                selectedGridItems[0].quantityDepartures
                                }}</strong>
                        </p>
                    </div>
                    <div class="width-50">
                        <p>
                            Cantidad de salida: <strong>{{
                                selectedGridItems[0].quantityDepartures }}</strong>
                        </p>
                    </div>
                    <mat-form-field class="width-100">
                        <input matInput [formControl]="quantityDepartureFormControl" (change)="validateFormControls()" placeholder="Cantidad de salida" type="number" [max]="selectedGridItems &&
                            selectedGridItems[0].quantity
                            ? selectedGridItems[0].quantity
                            : 0" min="1" required />
                    </mat-form-field>
                    <ng-container>
                        <mat-form-field class="width-100">
                            <textarea matInput maxlength="120" type="textarea" placeholder="Comentario" [formControl]="commentFormControl"></textarea>
                        </mat-form-field>
                    </ng-container>
                </div>
                <button class="float-right" [disabled]="isDisabled()" mat-flat-button (click)="saveData()" color="primary">
                    Guardar
                </button>
            </div>
        </div>
    </ng-container>
</app-dialog>

<app-dialog #formDialogWarehouse width="35%">
    <ng-container>
        <app-title>Cambio de almacen</app-title>

        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-top-2" *ngIf="warehouses">
                <div class="content-block margin-top-0 width-400">
                    <div class="content-block margin-top-0">
                        <div class="width-100">
                            <p>
                                <strong>{{ selectedGridItems[0].concept }}</strong>
                            </p>
                        </div>
                        <div class="width-50">
                            <p>
                                Cantidad recibida: <strong>{{
                                    selectedGridItems[0].quantityReceived }}</strong>
                            </p>
                        </div>
                        <div class="width-50">
                            <p>
                                Cantidad en almacen:
                                <strong>{{
                                    selectedGridItems[0].quantityReceived -
                                    selectedGridItems[0].quantityDepartures
                                    }}</strong>
                            </p>
                        </div>
                        <div class="width-50 margin-bottom-2">
                            <p>
                                Cantidad de salida: <strong>{{
                                    selectedGridItems[0].quantityDepartures }}</strong>
                            </p>
                        </div>
                        <mat-select class="margin-top-20" placeholder="Seleccione el almacen
                            destino" (selectionChange)="onChangedWarehouse($event)">
                            <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.uuid">
                                {{ warehouse.name }}
                            </mat-option>
                        </mat-select>
                        <mat-form-field class="width-100 margin-top-20">
                            <input matInput [formControl]="quantityDepartureFormControl" (change)="validateFormControls()" placeholder="Cantidad de
                                salida" type="number" [max]="selectedGridItems[0].quantityReceived -
                                selectedGridItems[0].quantityDepartures" min="1" required />
                        </mat-form-field>
                        <ng-container>
                            <mat-form-field class="width-100">
                                <textarea matInput maxlength="120" type="textarea" placeholder="Comentario" (change)="validateFormControls()" [formControl]="commentFormControl"></textarea>
                            </mat-form-field>
                        </ng-container>
                    </div>

                </div>
                <button class="float-right" [disabled]="formIsValid" mat-flat-button (click)="saveDataWarehouse()" color="primary">
                    Guardar
                </button>
            </div>
        </div>
    </ng-container>
</app-dialog>