<app-page-widget (refresh)="refreshData()">
    <app-title>COT-{{ dataSource?.quoteId | idFromUuid }}</app-title>
    <app-subtitle> Detalles para cotizar </app-subtitle>
    <div *ngIf="dataSource?.cancellationReason !== undefined" class="text-center">
        <h3>{{ dataSource?.cancellationReason }}</h3>
    </div>

    <div *ngIf="stillAvailableForQuote()">
        <span matTooltip="{{
        !hasFileQuote ? 'IMPORTANTE: Debe adjuntar una archivo de cotización' : null
      }}">
      <button
        *ngIf="hasFileQuote"
        class="btn"
        mat-raised-button
        color="primary"
        (click)="saveQuoteData()"
      >
        <mat-icon>cloud_done</mat-icon>
        Guardar cotización
      </button>
    </span>
        <button *ngIf="!hasFileQuote" mat-raised-button matTooltip="Adjuntar archivo de cotización posteriormente capturar la información" class="btn" color="primary" (click)="attachQuoteFile()">
      <mat-icon>cloud_upload</mat-icon>
      Adjuntar cotización
    </button>
        <a *ngIf="hasFileQuote" (click)="removeQuoteFile()" mat-icon-button>
            <mat-icon>delete_forever</mat-icon>
        </a>
        <a *ngIf="hasFileQuote" [href]="supplierQuoteLinkFile" target="_blank" mat-icon-button>
            <mat-icon>insert_drive_file</mat-icon>
            Cotización
        </a>
    </div>

    <dx-data-grid #supplierQuoteGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="dataSourceGroup" [masterDetail]="{ enabled: true, template: 'detail' }" [hoverStateEnabled]="false" [wordWrapEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
        [disabled]="!hasFileQuote || !stillAvailableForQuote()" noDataText="Detalle de cotización." [showBorders]="true" (dataErrorOccurred)="ondataError($event)" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false">
        <dxo-editing mode="cell" [allowUpdating]="false"> </dxo-editing>
        <dxo-export [enabled]="true" fileName="Cotizacion"></dxo-export>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxo-search-panel [visible]="false"></dxo-search-panel>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column caption="+" width="6%" cellTemplate="statusTemplate" [allowEditing]="false" [allowSearch]="false" dataField="status" [allowSorting]="false" alignment="left"></dxi-column>
        <dxi-column caption="Código" width="12%" [allowEditing]="false" [allowSearch]="false" dataField="code" [allowSorting]="false" alignment="center"></dxi-column>
        <dxi-column caption="Concepto" [allowEditing]="false" [allowSearch]="false" dataField="concept" cellTemplate="conceptTemplate" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Cantidad" width="8%" cellTemplate="requestQtyTemplate" [allowEditing]="false" dataField="requiredQuantity" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Unidad" width="6%" cellTemplate="unitTemplate" [allowEditing]="false" dataField="unit" [allowSorting]="false" alignment="center"></dxi-column>
        <dxi-column *ngxPermissionsOnly="['impersonate:supplier']" dataField="available" caption="Disponible" cellTemplate="availableTemplate" [showEditorAlways]="false" [allowEditing]="false" alignment="center" width="8%">
        </dxi-column>
        <dxi-column dataField="supplierDeliveryTime" caption="# Tiempo" [showEditorAlways]="false" [allowEditing]="false" alignment="center" width="8%">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="supplierDelivery" caption="Tiempo Entrega" [showEditorAlways]="false" [allowEditing]="false" alignment="center" width="10%">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column caption="Cantidad requerida" width="8%" cellTemplate="supplierQtyTemplate" [allowEditing]="false" dataField="supplierQuantity" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Cantidad cotizada" width="8%" cellTemplate="supplierQuotedQtyTemplate" [allowEditing]="false" dataField="supplierQuotedQuantity" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="$ Unitario" width="8%" cellTemplate="supplierCostTemplate" [allowEditing]="false" dataField="supplierCost" [allowSorting]="false" alignment="center">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="numericPattern" message="Cantidad&#34;123.123&#34; números!"></dxi-validation-rule>
        </dxi-column>
        <dxi-column caption="Total Cotizado" name="Total Cotizado" width="8%" [calculateCellValue]="calculateTotalQuoteCost" cellTemplate="supplierTotalTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center"></dxi-column>
        <dxi-column caption="Total Requerido" width="8%" [calculateCellValue]="calculateTotalCost" cellTemplate="supplierTotalTemplate" [allowEditing]="false" [allowSorting]="false" alignment="center"></dxi-column>
        <dxi-column caption="" width="5%" cellTemplate="controlsTemplate" [allowEditing]="false" [allowSearch]="false" dataField="status" [allowSorting]="false" alignment="center">
        </dxi-column>

        <div *dxTemplate="let data of 'conceptTemplate'">
            <div [matTooltip]="data.value" class="one-row">{{ data.value }}</div>
        </div>

        <div *dxTemplate="let data of 'requestQtyTemplate'" class="edit-cells-qty">
            <span>{{ data.value | number: '1.2-3' }}</span>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'" class="edit-cells-status">
            <!-- <span *ngIf="data.data.supplierCost === 0">{{data.value | quotestatus}}</span> -->
            <!-- <span *ngIf="data.data.supplierCost > 0">{{ 3 | quotestatus}}</span> -->
            <a class="file-icon" mat-icon-button *ngIf="data.data.supplierCost > 0">
                <mat-icon>done</mat-icon>
            </a>
        </div>
        <div *dxTemplate="let data of 'supplierQtyTemplate'" class="edit-cells-qty">
            <strong *ngIf="data.data.requiredQuantity">{{
        data.data.requiredQuantity | number: '1.2-3'
      }}</strong>
            <strong *ngIf="!data.data.requiredQuantity"> 0 </strong>
            <!-- <strong *ngIf="data.value === 0">0</strong> -->
        </div>

        <div *dxTemplate="let data of 'supplierQuotedQtyTemplate'" class="edit-cells-qty">
            <strong *ngIf="data.data.supplierQuotedQuantity">{{
        data.data.supplierQuotedQuantity | number: '1.2-3'
      }}</strong>
            <strong *ngIf="!data.data.requiredQuantity"> 0 </strong>
            <!-- <strong *ngIf="data.value === 0">0</strong> -->
        </div>

        <div *dxTemplate="let data of 'supplierCostTemplate'" class="edit-cells-cost">
            <strong>${{ data.value ? data.value : (0 | number) }}</strong>
            <strong *ngIf="data.value === 0">0</strong>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'availableTemplate'" class="edit-cells-cost">
            <span>${{ data.value | number:'1.2-2' }}</span>
        </div>
        <div *dxTemplate="let data of 'supplierTotalTemplate'">
            <strong *ngIf="data.value !== 0">$ {{ data.value | number }}</strong>
            <span *ngIf="data.value === 0">-</span>
        </div>
        <div *dxTemplate="let data of 'controlsTemplate'">
            <a mat-icon-button matTooltip="Editar" (click)="onSelectRow(data.data)">
                <mat-icon>edit</mat-icon>
            </a>
        </div>

        <dxo-summary>
            <dxi-total-item column="Total Cotizado" summaryType="sum" [customizeText]="formatTotalQuoted">
            </dxi-total-item>
            <dxi-total-item column="Total Requerido" summaryType="sum" [customizeText]="formatTotal">
            </dxi-total-item>
        </dxo-summary>
        <!-- ============================================================== -->
        <!-- Master Detail  -->
        <!-- ============================================================== -->
        <div *dxTemplate="let detail of 'detail'">
            <h2>Detalle:</h2>
            <strong>{{ detail.data.uuid }}</strong>
            <div class="master-detail-caption">{{ detail.data.id }}</div>
            <div>
                <strong>Tipo:</strong> {{ detail.data.type }}
                <br />
                <strong>Estado:</strong> {{ detail.data.status | quoteStatus }}
                <br />
                <strong>Marca Solicitada:</strong> {{ detail.data.requiredBrand }} |
                <strong>Marca Proveedor:</strong> {{ detail.data.supplierBrand }}
                <br />
                <strong>Modelo Solicitado:</strong> {{ detail.data.requiredModel }} |
                <strong>Modelo Proveedor:</strong> {{ detail.data.supplierModel }}
                <br />
                <strong>Notas de la requisición:</strong> {{ detail.data.requiredNotes }}
                <br />
                <strong>Notas del proveedor:</strong> {{ detail.data.supplierNotes || detail.data.supplierComments }}
            </div>
            <br />
            <div>
                <h2>Fichas Técnicas:</h2>
                <ul>
                    <li *ngFor="let relatedPackage of detail.data.relatedPackages">
                        <strong>{{ relatedPackage.packageId }} </strong>
                        <span>Ficha Técnica: </span>
                        <ng-container *ngIf="relatedPackage.dataSheetLinkFile; else noDataSheet">
                            <strong>
                <a mat-icon-button [href]="relatedPackage.dataSheetLinkFile" target="_blank">
                  <mat-icon>file_download</mat-icon>
                </a>
              </strong>
                        </ng-container>
                        <ng-template #noDataSheet>
                            <strong>
                No disponible
              </strong>
                        </ng-template>
                    </li>
                </ul>
            </div>

            <!-- ============================================================== -->
            <!-- Grid related items  -->
            <!-- ============================================================== -->
            <dx-data-grid id="gridContainer" width="50%" [dataSource]="detail.data.relatedPackages ? detail.data.relatedPackages : []" [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true">
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
                <dxi-column caption="Fecha requerida" dataField="requestDate" dataType="date"></dxi-column>
                <dxi-column caption="Cantidad requerida" dataField="requiredQuantity"></dxi-column>
            </dx-data-grid>
        </div>
    </dx-data-grid>
</app-page-widget>

<app-dialog #formDialog width="500px" title="Editar paquete a cotizar">
    <table class="width-100" class="bordered-table">
        <tbody>
            <tr>
                <th colspan="2">Concepto</th>
            </tr>
            <tr>
                <td colspan="2">{{ selectedItem?.concept }}</td>
            </tr>
            <tr>
                <th>Cantidad requerida</th>
                <th>Cantidad cotizada</th>
            </tr>
            <tr>
                <td>{{ selectedItem?.requiredQuantity | number: '1.2-3' }}</td>
                <td>{{ selectedItem?.supplierQuotedQuantity || 0 | number: '1.2-3' }}</td>
            </tr>
        </tbody>
    </table>

    <app-form class="margin-top-2" [fields]="dataFormFields" [model]="selectedItem" saveButtonText="Continuar" [noCancelButton]="false" (save)="savePackageToQuote($event.value)" (cancel)="formDialog.close()">
    </app-form>
</app-dialog>