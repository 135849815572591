<app-page-widget (refresh)="refreshData()">
    <app-title>Seleccione una Orden de Estimación</app-title>
    <app-subtitle></app-subtitle>

    <div class="header my-15" fxLayout="row">
        <app-select-box class="select ml-15" [widthSelect]="400" [dataSource]="suppliers" valueExpr="uuid" displayExpr="name" value="supplierId" (selectItem)="onSelectBoxSelectionSuppliers($event)" placeholder="Seleccionar proveedor">
        </app-select-box>
        <app-select-box class="ml-15" [widthSelect]="180" [dataSource]="statusEstimates" displayExpr="TypeText" (selectItem)="onSelectBoxSelectionEstatus($event)" placeholder="Selecionar
          Estatus">
        </app-select-box>
        <!-- <app-select-box class="ml-15" [widthSelect]="200" [dataSource]="estimatesOrderList" displayExpr="nroEstimate" (selectItem)="onSelectEstimate($event)" placeholder="Selecionar
        estimación">
      </app-select-box>
      <app-select-box class="ml-15" [widthSelect]="260" [dataSource]="contractsList" displayExpr="nroContract" (selectItem)="onSelectEstimateContract($event)" placeholder="Selecionar
        contrato">
      </app-select-box>
      <app-select-box class="ml-15" [widthSelect]="260" [dataSource]="estimatesOrderList" displayExpr="totalEstimateFormat" (selectItem)="onSelectEstimate($event)" placeholder="Selecionar
        Monto de estimación">
      </app-select-box> -->
        <!-- <app-select-box class="select ml-15" [widthSelect]="400" [dataSource]="selectStatus" displayExpr="TypeText" (selectItem)="onSelectBoxSelectionEstatus($event)" placeholder="Seleccionar estado">
    </app-select-box> -->
    </div>

    <dx-data-grid class="grid-container-div" #eoGrid id="gridContainer" [wordWrapEnabled]="true" [dataSource]="estimateOrders" [masterDetail]="{ enabled: false,
        template: 'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
        no éxisten datos." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [allowColumnResizing]="false" (onExporting)="onExporting($event)" (onExported)="onExported($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
        (onOptionChanged)="changePage($event)">
        <!-- (onSelectionChanged)="onGridSelection($event)" -->
        <dxo-paging [pageSize]="10" [pageIndex]="_estimateOrdersService.pageIndex"></dxo-paging>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel>
        <dxo-export [enabled]="true" fileName="lista-ordenes-estimacion"></dxo-export>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10,
            20]" [showInfo]="true">
        </dxo-pager>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column alignment="center" caption="ID" dataField="id" [groupIndex]="0" dataType="number" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
        <dxi-column alignment="center" caption="Estimación" dataType="number" dataField="nroEstimate" cellTemplate="estimateIdTemplate" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Fecha" dataField="createdAt" dataType="date" cellTemplate="dateTemplate" dataType="date" sortOrder="desc" alignment="left" [allowSorting]="true" [allowEditing]="false" [allowSearch]="true" [calculateCellValue]="calculateDateCellValue"></dxi-column>
        <dxi-column caption="Creado por" dataField="createdBy" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Proveedor" dataField="supplier" alignment="left" cellTemplate="supplierNameTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
        </dxi-column>
        <dxi-column caption="Concepto" dataField="concept" alignment="left" cellTemplate="supplierNameTemplate" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false">
        </dxi-column>
        <dxi-column caption="UNIDAD" dataField="unit" cellTemplate="unitTemplate" alignment="center"></dxi-column>
        <dxi-column caption="P.U" dataField="unitPrice" cellTemplate="costoTemplate" alignment="center"></dxi-column>
        <dxi-column caption="P.U Real" dataField="realPrice" cellTemplate="costoTemplate" alignment="center"></dxi-column>
        <dxi-column caption="CANTIDAD" dataField="quantity" cellTemplate="cantidadTemplate" alignment="center"></dxi-column>
        <!-- <dxi-column caption="ADICIONALES" dataField="additionToQuantity" cellTemplate="additionTemplate" alignment="center"></dxi-column>
        <dxi-column caption="DEDUCTIVA" dataField="decreaseToQuantity" cellTemplate="decreaseTemplate" alignment="center"></dxi-column> -->
        <!-- <dxi-column caption="Adicionales" dataField="additionToQuantity" width="15%" cellTemplate="additionTemplate" alignment="center"></dxi-column>
        <dxi-column caption="Deductiva" dataField="decreaseToQuantity" width="15%" cellTemplate="decreaseTemplate" alignment="center"></dxi-column> -->
        <dxi-column caption="TOTAL" [calculateCellValue]="calculateTotalColumn" cellTemplate="importeTemplate">
        </dxi-column>
        <!-- <dxi-column caption="%" dataField="percentToPay" [calculateCellValue]="calculatePercentToPay" cellTemplate="estimateTemplate" alignment="center"></dxi-column> -->
        <dxi-column caption="IMPORTE" dataField="importe" [calculateCellValue]="calculateImportColumn" cellTemplate="estimateamountTemplate" alignment="right"></dxi-column>
        <dxi-column alignment="center" caption="Estado" dataField="status" cellTemplate="statusTemplate" [allowEditing]="false" [allowSorting]="false" [allowFiltering]="false" [calculateCellValue]="customizeStatusLabel"></dxi-column>
        <dxi-column caption="Aprobadores Faltantes" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>
        <dxi-column caption="Detalle" dataField="uuid" cellTemplate="ccButtonTemplate" alignment="center" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
        <div *dxTemplate="let data of 'ccButtonTemplate'">
            <span>
                <i class="material-icons icon-gray" (click)="seeDetail(data)">
                    remove_red_eye
                </i>
            </span>
        </div>

        <!-- <dxo-summary [calculateCustomSummary]="calculateSelectedRow">
            <dxi-total-item name="subtotalPacks" summaryType="custom" [customizeText]="formatTotal" displayFormat="${0}" showInColumn="importe">
            </dxi-total-item>
        </dxo-summary> -->
        <dxo-summary [skipEmptyValues]="true">
            <dxi-group-item column="importe" summaryType="sum" displayFormat="{0}" [customizeText]="formatTotal" [showInGroupFooter]="false" [alignByColumn]="true">
            </dxi-group-item>
        </dxo-summary>

        <div *dxTemplate="let data of 'estimateIdTemplate'">
            <span [ngClass]="{ 'reference-link': data.key &&
                data.key.estimateType }" (click)="goToEstimation(data.key)">
                EST-{{data.value | idFromUuid }}</span>
        </div>
        <div *dxTemplate="let d of 'additionTemplate'">
            <ng-container *ngIf="d.value; else withoutValue">
                {{ d.value | number: '1.2-2' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let d of 'decreaseTemplate'">
            <ng-container *ngIf="d.value; else withoutValue">
                {{ d.value | number: '1.2-2' }}
            </ng-container>
            <ng-template #withoutValue>
                -
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'unitTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let d of 'costoTemplate'">
            <span class="current-value">${{ d.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let d of 'cantidadTemplate'">
            <span class="current-value">{{ d.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let d of 'estimateTemplate'">
            <span class="current-value">{{ d.value | number: '1.2-2' }}%</span>
        </div>
        <div *dxTemplate="let d of 'importeTemplate'">
            <span class="current-value">${{ d.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let d of 'estimateamountTemplate'">
            <span *ngIf="d.data.concept === 'Fondo de garantia'"> - </span>
            <span class="current-value">${{ d.value | number: '1.2-2' }}</span>
        </div>
        <div *dxTemplate="let data of 'supplierNameTemplate'">
            <ng-container *ngIf="data.value; else noSupplier">
                <span>{{ data.value }}</span>
            </ng-container>
            <ng-template #noSupplier>
                No hay nombre de proveedor
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>

        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{totalCount}}</h2>
                <span class="name">Total Ordenes</span>
            </div>
        </div>
    </dx-data-grid>
</app-page-widget>