<app-page-widget (refresh)="refreshData()">
    <app-title>Caja Chica</app-title>
    <app-subtitle>Seleccione para ver detalles</app-subtitle>

    <app-action-button icon="playlist_add" routeData="/cashbox/new">Crear</app-action-button>
    <app-menu-item icon="playlist_add" routeData="/cashbox/new">Crear</app-menu-item>
    <dx-data-grid [wordWrapEnabled]="true" [dataSource]="cashboxData" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Sin registros" [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="true"
        [allowColumnResizing]="true" (onRowClick)="onSelectCashbox($event.key)" (onExported)="onExported($event)" (onExporting)="onExporting($event)">
        <!-- <dxo-filter-row></dxo-filter-row> -->
        <dxo-filter-row visible="true"> </dxo-filter-row>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-export [enabled]="true" fileName="lista-caja-chica"></dxo-export>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column caption="ID" width="10%" [allowEditing]="false" [allowSearch]="true" dataField="id" [allowSorting]="false" alignment="center">
        </dxi-column>
        <dxi-column caption="Fecha" width="25%" [allowEditing]="false" dataType="date" cellTemplate="dateTemplate" dataField="createdAt" [calculateCellValue]="calculateDateCellValue" [allowSorting]="true" [allowFiltering]="true" sortOrder="desc" alignment="center">
        </dxi-column>
        <dxi-column caption="Estado" width="30%" cellTemplate="cashboxStatusTemplate" [allowEditing]="false" dataField="status" [allowSorting]="false" [allowFiltering]="false" alignment="center" [calculateCellValue]="customizeStatusLabel">
        </dxi-column>
        <dxi-column caption="Solicitado por" cellTemplate="createdByTemplate" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" dataField="createdBy" [allowSorting]="false" [visible]="true">
        </dxi-column>
        <dxi-column caption="Solicitado por" [allowEditing]="false" [allowSearch]="false" [allowFiltering]="false" dataField="createdByDisplayName" [allowSorting]="false" [visible]="false">
        </dxi-column>
        <dxi-column caption="Aprobadores Faltantes" width="20%" cellTemplate="approverRemaining" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false" [calculateCellValue]="customizeApprovalsLabel"></dxi-column>

        <div *dxTemplate="let data of 'dateTemplate'">
            <strong>{{ data.value | timestampToDate }}</strong> -
            <span>hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'cashboxStatusTemplate'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <app-user-data [id]="data.value"></app-user-data>
        </div>
        <div *dxTemplate="let data of 'approverRemaining'">
            <ng-container *ngIf="data.value.length> 0">
                <ul class="approversRemaining">
                    <li *ngFor="let item of data.value">
                        <app-user-data [id]="item[0]"></app-user-data>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="data.value.length === 0 && data.value !== ''">
                <span>Todos los usuarios han aprobado</span>
            </ng-container>
            <ng-container *ngIf="data.value === ''">
                <span>No se ha enviado a aprobación</span>
            </ng-container>
        </div>
    </dx-data-grid>
</app-page-widget>