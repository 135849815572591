export const COLSASSIGNMENT: any = [
  {
    caption: 'Subcontrato',
    dataField: 'id',
    width: '8%'
  },
  {
    caption: 'Fecha',
    dataField: 'createdAt',
    dataType: 'date',
    allowSorting: 'true',
    sortOrder: 'desc',
    width: '8%'
  },
  {
    caption: 'Creado por',
    dataField: 'createdBy',
    width: '10%'
  },
  {
    caption: 'packages',
    dataField: 'packages',
    // cellTemplate: (data: any) => {
    //   // console.log(data);
    // // '<div *dxTemplate="let pu of '$data'"><span></span></div>'


    // }
  //   '<div *dxTemplate="let pu of 'puData'">
  //   '<span *ngIf="pu?.value">${{pu.value | number : '1.2-2'}}</span>
  // '</div>'
    // calculateCellValue: (data: any) => {
    //   const qty: any = data.cost;
    //   // CANTIDADES A DECIMAL DE 3 CIFRAS
    //   // const decimalPipe: DecimalPipe = new DecimalPipe('en');
    //   // const cantidad = decimalPipe.transform(total, '1.2-2');
    //   return `${qty}`;
    // }
  }
  // {
  //   caption: 'P.U',
  //   dataField: 'cost',
  //   width: '6%',
  //   calculateCellValue: (data: any) => {
  //     const qty: any = data.cost;
  //     // CANTIDADES A DECIMAL DE 3 CIFRAS
  //     // const decimalPipe: DecimalPipe = new DecimalPipe('en');
  //     // const cantidad = decimalPipe.transform(total, '1.2-2');
  //     return `${qty}`;
  //   }
  // },
  // {
  //     caption: 'Total',
  //     width: '11%',
  //     calculateCellValue: (data: any) => {
  //       // COSTOS A DECIMAL DE 2 CIFRAS
  //       const total: any = data.cost * data.quantity;
  //       return '$' + total;
  //     }
  //   }
];
