<div class="content-block">

    <h3 class="content-title">Órdenes de Cheque</h3>
    <div *ngIf="viewModel.purchaseOrderVms.length> 0">
        <mat-table #purchaseOrdersTable [dataSource]="poTableSource">
            <ng-container matColumnDef="createdAt">
                <mat-header-cell *matHeaderCellDef>Fecha</mat-header-cell>
                <mat-cell *matCellDef="let attribute">
                    {{ attribute?.createdAt | timestampToDate: 'long' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="poId">
                <mat-header-cell *matHeaderCellDef>OCH</mat-header-cell>
                <!-- should change background color if simulating purchase order -->
                <mat-cell *matCellDef="let attribute" [ngStyle]="{
                    'background-color': attribute.isSimulation
                    ? '#a4bbd4'
                    : 'white'
                    }">
                    {{ attribute.poId }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef>Cantidad</mat-header-cell>
                <!-- should change background color if simulating purchase order -->
                <mat-cell *matCellDef="let attribute" [ngStyle]="{
                    'background-color': attribute.isSimulation
                    ? '#a4bbd4'
                    : 'white'
                    }">
                    <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">
                        {{ attribute.quantity | number: '1.2-3' }}
                    </ng-template>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="unitPrice">
                <mat-header-cell *matHeaderCellDef>Precio Unitario</mat-header-cell>
                <!-- should change background color if simulating purchase order -->
                <mat-cell *matCellDef="let attribute" [ngStyle]="{
                    'background-color': attribute.isSimulation
                    ? '#a4bbd4'
                    : 'white'
                    }">
                    <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">
                        ${{ attribute?.unitPrice | number: '1.2-2' }}
                    </ng-template>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef>Total</mat-header-cell>
                <!-- should change background color if simulating purchase order -->
                <mat-cell *matCellDef="let attribute" [ngStyle]="{
                    'background-color': attribute.isSimulation
                    ? '#a4bbd4'
                    : 'white'
                    }">

                    <ng-container *ngIf="attribute?.unit === 'BOLSA' &&
                        attribute?.type == 2">
                        ${{ attribute?.unitPrice | number: '1.2-2' }}
                    </ng-container>

                    <ng-container *ngIf="attribute?.unit !== 'BOLSA' ||
                        attribute?.type != 2">
                        ${{ attribute?.unitPrice * attribute.quantity | number: '1.2-2' }}
                    </ng-container>

                    <!-- ${{ attribute?.unitPrice * attribute.quantity | number: '1.2-2' }} -->
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="puDiff">
                <mat-header-cell *matHeaderCellDef>Diferencia PU</mat-header-cell>
                <!-- should change background color if simulating purchase order -->
                <mat-cell *matCellDef="let attribute" [ngStyle]="{
                    'background-color': attribute.isSimulation
                    ? '#a4bbd4'
                    : 'white'
                    }">

                    <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">
                        <span [ngStyle]="{ color: attribute?.unitPrice -
                            viewModel.budgetUnitPrice> 0 ? 'red' : 'green' }">
                            ${{ attribute?.unitPrice - viewModel.budgetUnitPrice
                            | number:
                            '1.2-2' }}
                        </span>
                    </ng-template>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalDiff">
                <mat-header-cell *matHeaderCellDef>Diferencia Total</mat-header-cell>
                <!-- should change background color if simulating purchase order -->
                <mat-cell *matCellDef="let attribute" [ngStyle]="{
                    'background-color': attribute.isSimulation
                    ? '#a4bbd4'
                    : 'white'
                    }">
                    <ng-template [ngIf]="!packageIsDerivative" [ngIfElse]="notApplicable">

                        <ng-container *ngIf="attribute?.unit === 'BOLSA' &&
                            attribute?.type == 2">
                            <span [ngStyle]="{
                                color: attribute?.quantity *(
                                attribute?.unitPrice -
                                viewModel.budgetUnitPrice)> 0 ? 'red' : 'green'
                                }">${{ attribute?.unitPrice -
                                viewModel.budgetUnitPrice | number: '1.2-2' }}</span>
                        </ng-container>
                        <ng-container *ngIf="attribute?.unit !== 'BOLSA' ||
                            attribute?.type != 2">
                            <span [ngStyle]="{
                                color: attribute?.quantity *(
                                attribute?.unitPrice -
                                viewModel.budgetUnitPrice)> 0 ? 'red' : 'green'
                                }">${{ attribute?.quantity *(
                                attribute?.unitPrice -
                                viewModel.budgetUnitPrice) | number: '1.2-2' }}</span>
                        </ng-container>


                    </ng-template>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="poTableHeaderDef"></mat-header-row>
            <mat-row *matRowDef="let row; columns: poTableHeaderDef" [ngClass]="{
                'row-purchase': true }">
            </mat-row>
        </mat-table>

        <mat-table #totalsOCTable [dataSource]="summaryTableSource">
            <ng-container matColumnDef="emptyCell1">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let attribute">{{ ' '}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="emptyCell2">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let attribute">{{ ' '}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="purchasedQuantityTotal">
                <mat-header-cell *matHeaderCellDef>Total Comprado</mat-header-cell>
                <mat-cell *matCellDef="let attribute">
                    <strong>{{ attribute?.purchasedQuantityTotal | number:
                        '1.2-2' }}</strong>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="weightedAvgPrice">
                <mat-header-cell *matHeaderCellDef>Precio Promedio Ponderado</mat-header-cell>
                <mat-cell *matCellDef="let attribute">
                    <ng-template [ngIf]="true" [ngIfElse]="notApplicable">
                        <strong>${{ (attribute?.totalPurchased /
                            attribute?.purchasedQuantityTotal)||0 | number:
                            '1.2-2' }}</strong>
                    </ng-template>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef>Total Cantidad Comprada</mat-header-cell>
                <mat-cell *matCellDef="let attribute">
                    <ng-template [ngIf]="true" [ngIfElse]="notApplicable">
                        <strong>{{ attribute?.totalPurchased | number: '1.2-3'
                            }}</strong>
                    </ng-template>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="averageDiffPrice">
                <mat-header-cell *matHeaderCellDef>Diferencia Promedio PU</mat-header-cell>
                <mat-cell *matCellDef="let attribute">
                    <ng-template [ngIf]="true" [ngIfElse]="notApplicable">
                        <strong [ngStyle]="{ color: attribute.averageDiffPrice>
                            0 ? 'red' :
                            'green' }">
                            ${{ attribute?.averageDiffPrice || 0 | number:
                            '1.2-2' }}
                        </strong>
                    </ng-template>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalOutcome">
                <mat-header-cell *matHeaderCellDef>Total Ahorro/Sobrecosto</mat-header-cell>
                <mat-cell *matCellDef="let attribute">
                    <ng-template [ngIf]="true" [ngIfElse]="notApplicable">
                        <strong [ngStyle]="{ color: attribute.totalOutcome> 0 ?
                            'red' :
                            'green' }">
                            ${{ attribute?.totalOutcome | number: '1.2-2' }}
                        </strong>
                    </ng-template>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="summaryTableDef"></mat-header-row>
            <mat-row *matRowDef="let row; columns: summaryTableDef"></mat-row>
        </mat-table>

    </div>

    <div *ngIf="viewModel.purchaseOrderVms.length === 0">
        <p>No se encontraron órdenes de cheque para este concepto.</p>
    </div>

</div>