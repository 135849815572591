<app-page-widget (refresh)="refreshData()">
    <app-title>Listado de ordenes de compras aprobadas</app-title>
    <app-subtitle></app-subtitle>

    <!-- <button class="btn"
        mat-raised-button
        color="primary"
        [disabled]="!this.selectedGridItems"
        (click)="seeDetail()">
  Ver detalle de Orden de compra
</button> -->
    <!-- ============================================================== -->
    <!-- GRID PURCHASE ORDER  -->
    <!-- ============================================================== -->
    <dx-data-grid #poGrid id="grid-container" [wordWrapEnabled]="true" [dataSource]="purchaseOrders" [masterDetail]="{ enabled: false,
    template: 'detail' }" [hoverStateEnabled]="false" [showColumnLines]="true" [showRowLines]="true" noDataText="Actualmente
    no éxisten órdenes de compra." [showBorders]="true" [rowAlternationEnabled]="false" [columnAutoWidth]="true" [allowColumnReordering]="false" [allowColumnResizing]="false" (onRowClick)="seeDetail($event)">
        <dxo-paging [pageSize]="5"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10]" [showInfo]="true">
        </dxo-pager>
        <!-- <dxo-search-panel [visible]="true" placeholder="Buscar..."></dxo-search-panel> -->
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-export [enabled]="true" fileName="lista-ordenes-compra"></dxo-export>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-selection selectAllMode="page" mode="single" [deferred]="true"></dxo-selection>
        <dxi-column alignment="center" caption="ID" dataType="number" dataField="id" width="5%" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Fecha" dataField="createdAt" dataType="date" width="20%" cellTemplate="dateTemplate" sortOrder="desc" alignment="left" [calculateCellValue]="calculateDateCellValue" [allowEditing]="false" [allowSorting]="true" [allowSearch]="true"></dxi-column>
        <dxi-column caption="Creado por" dataField="createdBy" width="30%" cellTemplate="createdByTemplate" [allowEditing]="false" [allowFiltering]="false" [allowSorting]="false">
        </dxi-column>
        <dxi-column alignment="center" caption="Condiciones de Pago" dataField="paymentMethod" dataType="string" width="15%" cellTemplate="paymentMethod" [allowEditing]="false" [allowFiltering]="true" [allowSearch]="true" [allowSorting]="false" [calculateCellValue]="customizePaymentMethodLabel"></dxi-column>
        <dxi-column alignment="center" caption="Días restantes" dataField="daysOfCreditLeft" width="10%" cellTemplate="remainingDaysToPay" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
        <dxi-column alignment="left" caption="Proveedor" dataField="supplier.name" width="35%" cellTemplate="supplierNameTemplate" [allowEditing]="false" [allowFiltering]="true" [allowSorting]="false"></dxi-column>

        <div *dxTemplate="let data of 'dateTemplate'">
            <strong> {{ data.value | timestampToDate }}</strong> -
            <span> hace {{ data.value | timeAgo }}</span>
        </div>
        <div *dxTemplate="let data of 'createdByTemplate'">
            <span>{{ data.value }}</span>
        </div>
        <div *dxTemplate="let data of 'paymentMethod'">
            <strong>{{ data.value }}</strong>
        </div>
        <div *dxTemplate="let data of 'remainingDaysToPay'">
            <ng-container *ngIf="data.value; else noData">
                <strong [style.color]="data.value <0 ? 'red' : 'black'">{{
          data.value }}
          días</strong>
            </ng-container>
            <ng-template #noData>
                --
            </ng-template>
        </div>
        <div *dxTemplate="let data of 'supplierNameTemplate'">
            <ng-container *ngIf="data.value; else noSupplier">
                <span>{{ data.value }}</span>
            </ng-container>
            <ng-template #noSupplier>
                No hay nombre de proveedor
            </ng-template>
        </div>
    </dx-data-grid>
</app-page-widget>
<app-loading-indicator [loadingVisible]="_isLoadingInfo" [message]="_messageInfoProcess">
</app-loading-indicator>